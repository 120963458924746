import React from "react";
import useSidebar from "../hooks/useSidebar";
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Transition } from "@headlessui/react";

type Props = {
    name: string;
    path: string;
    hidden?: boolean;
    children: React.ReactNode;
};

export default function SidebarItemWithSub(props: Props) {
    const { name, path, hidden } = props;
    const { activeParent, setActiveParent } = useSidebar();
    const isActive = activeParent?.includes(path) === true;

    return (
        <>
            <button
                hidden={hidden}
                onClick={() => {
                    if (path == activeParent) {
                        setActiveParent(null);
                    } else {
                        setActiveParent(path);
                    }
                }}
                className={`btn-outline my-1 block min-w-52 rounded-full px-3 py-2 transition-all duration-300 hover:bg-gray-800 lg:w-full lg:px-4 dark:hover:bg-white/10 ${
                    isActive ? `bg-gray-500 dark:bg-white/10` : ""
                }`}
            >
                <div className={"flex items-center justify-between"}>
                    <p className={"text-sm text-gray-25"}>{name}</p>
                    <ChevronRightIcon
                        className={`hidden size-4 stroke-gray-100 lg:block ${isActive ? "rotate-90" : ""}`}
                    />
                </div>
            </button>

            <Transition
                show={isActive}
                afterLeave={() => {
                    console.log("leave");
                }}
            >
                <div className={"my-1 ml-4 transition duration-300 ease-in-out data-[closed]:opacity-0"}>
                    {props.children}
                </div>
            </Transition>
        </>
    );
}
