import React, { useEffect, useRef } from "react";
import Sortable from "sortablejs";
import { useTrans } from "components";
import { ConfigHeaderItemType } from "components/table_v2/types";
import useRouting from "components/hooks/useRouting";
import ConfigHeaderItem from "modules/crm/contacts/config/ConfigHeaderItem";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import { UseConfigPanelPropsWithoutRef } from "@/components/table_v2/regular/BackendTable";
import { X } from "lucide-react";

type Props = {
    tableMutate: any;
    configPanel: UseConfigPanelPropsWithoutRef,
};

export default function ConfigSideMenu(props: Props) {
    const { configPanel } = props;
    const { t } = useTrans();
    const { push } = useRouting();
    const dragList = useRef(null);

    useEffect(() => {
        if (dragList.current) {
            Sortable.create(dragList.current, {
                handle: ".draggable-item",
                animation: 250,
                onEnd: configPanel.position
            });
        }
    }, [configPanel.data, dragList?.current]);

    if (!configPanel.data) {
        return null;
    }

    return (
        <div
            className={"absolute right-0 top-0 z-10 flex h-full max-w-80 grow flex-col gap-1 overflow-scroll rounded-r-md border border-gray-100 bg-gray-50"}
        >
            <div
                className={"text-base-gray-900 font-normal flex justify-between gap-x-2 border-b border-gray-900/10 p-4 leading-6"}
            >
                <div className={"pt-1"}>
                    {t("tableSettings")}
                </div>

                <div
                    onClick={configPanel.close}
                    className={'flex cursor-pointer items-center rounded border border-gray-900/10 bg-white px-3 hover:bg-primary-25 active:bg-primary-50 dark:bg-slate-500 dark:hover:bg-slate-400'}
                >
                    <X className={'size-4'}/>
                </div>
            </div>

            <div className={"flex flex-col border-b border-gray-900/10"}>
                <div className={"flex w-full flex-row justify-between px-3 py-2"}>
                    <div className={"font-light my-auto flex py-2 text-sm leading-4 text-gray-700"}>
                        {t("shownInTable")}
                    </div>
                    <ButtonComponent
                        label={t("hideAll")}
                        button_style={ButtonStyle.Secondary}
                        onClick={configPanel.removeAll}
                        pending={configPanel.pending}
                    />
                </div>

                <div ref={dragList} className={"mb-3 flex flex-col gap-2"}>
                    {configPanel.data.shown.map((item: ConfigHeaderItemType) => (
                        <ConfigHeaderItem
                            key={item.id}
                            item={item}
                            pending={item.id === configPanel.pendingItem}
                            isShown={true}
                            onClick={() => configPanel.onRemove(item.id)}
                        />
                    ))}
                </div>
            </div>

            <div className={"flex flex-col border-b border-gray-900/10"}>
                <div className={"flex w-full flex-row justify-between px-3 py-2"}>
                    <div className={"font-light my-auto flex py-2 text-sm leading-4 text-gray-700"}>
                        {t("hiddenInTable")}
                    </div>
                    <ButtonComponent
                        label={t("showAll")}
                        button_style={ButtonStyle.Secondary}
                        onClick={configPanel.addAll}
                        pending={configPanel.pending}
                    />
                </div>

                <div className={"mb-3 flex flex-col gap-2"}>
                    {configPanel.data.hidden.map((item: ConfigHeaderItemType) => (
                        <ConfigHeaderItem
                            key={item.id}
                            item={item}
                            pending={item.id === configPanel.pendingItem}
                            isShown={false}
                            onClick={() => configPanel.onAdd(item.id)}
                        />
                    ))}
                </div>
            </div>

            <div className={"flex w-full flex-row-reverse px-4 py-1"}>
                <ButtonComponent
                    button_style={ButtonStyle.Secondary}
                    label={t("createNewAttribute")}
                    onClick={() => push(`/settings/attributes`)}
                    icon={"arrowUpRightSmaller"}
                />
            </div>
        </div>
    );

}
