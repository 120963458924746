import * as React from "react";
import cn from "classnames";

export function Page ({
                          className,
                          children,
                      } : {
    className?: string;
    children?: React.ReactNode;
}){

    return (
        <main className={cn( "bg-bg-default w-full px-6", className )}>
            {children}
        </main>
    );
}