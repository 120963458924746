import React from 'react';
import cn from "classnames";

function getBoolean(value: any) {
    switch (value) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}

type Props = {
    name: string;
    value: boolean;
    label: string;
    bottom_helper?: any;
    defaultValue?: any;
    disabled?: boolean;
    required?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function NewSwitch(props: Props) {
    const { name, disabled, value, label, bottom_helper, defaultValue, required, onChange } = props;

    const switcherCn = cn(
        `w-9 h-5 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:border after:rounded-full after:h-4 after:w-4 after:transition-all`,
        `peer-checked:after:border-white after:bg-white after:border-gray-300`,
        'bg-gray-200 peer-checked:bg-primary-600',
        'peer-focus:ring-primary-300',
    );

    return (
        <label
            className={'relative flex size-full min-h-[3.4rem] cursor-pointer items-center gap-x-3 rounded-md border bg-white px-3 py-1 transition-all ease-in-out placeholder:text-yellow-200'}
        >
            <label
                htmlFor={`disabled-checked-toggle-${name}`}
                className={`relative inline-flex items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            >
                <input
                    id={`disabled-checked-toggle-${name}`}
                    type="checkbox"
                    name={name}
                    checked={value === undefined ? undefined : getBoolean(value)}
                    defaultChecked={defaultValue === undefined ? undefined : getBoolean(defaultValue)}
                    className="peer sr-only cursor-pointer"
                    disabled={disabled}
                    required={required}
                    onChange={onChange}
                />
                <div className={switcherCn}/>
            </label>

            <span className={'flex flex-col gap-1'}>
                <label
                    className={`font-light ml-1 cursor-pointer text-gray-900`}
                    htmlFor={`disabled-checked-toggle-${name}`}
                    dangerouslySetInnerHTML={{ __html: label }}
                />

                {bottom_helper && (
                    <label
                        className={`font-normal -mt-1 ml-1 cursor-pointer text-sm text-gray-900`}
                        htmlFor={`disabled-checked-toggle-${name}`}
                    >
                        {bottom_helper.text}
                    </label>
                )}
            </span>
        </label>
    );
}