import React, { useContext, useState } from "react";
import { useToast, useTrans } from "components";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import TextInput from "components/forms/Text/TextInput";
import useOnChange from "components/hooks/forms/useOnChange";
import NumberInput from "components/forms/Text/NumberInput";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import useHttp from "core/http/useHttp";
import Form from "components/forms/Form";
import useStripcardProgram from "@/routing/store/stripcards/useStripcardProgram";

export default function Settings() {
    const { t } = useTrans();
    const { errorToast, successToast } = useToast();
    const { selectedProgramId } = useStripcardProgram();
    const { account } = useContext(AccountContext);
    const [values, setValues] = useState<Dict>({});
    const onChange = useOnChange(setValues);
    const http = useHttp();
    const [updatePending, setUpdatePending] = useState(false);
    const [data, setData] = useState({
        name: "",
        expiration_days: 0,
        max_amount: 1000,
    });
    const apiUrl = `/accounts/${account.id}/stripcards/programs/${selectedProgramId}/settings`;
    const { pending, mutate } = useFetcher({
        url: apiUrl,
        dataSetter: setData
    });

    const update = () => {
        setUpdatePending(true);
        http.put(apiUrl, values)
            .then(res => {
                mutate();
                successToast();
                setValues({});
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    return (
        <PageContent
            title={t("settings")}
        >
            <ContentCard>
                <Form className={"flex max-w-md flex-col gap-3"} onSubmit={update}>
                    <TextInput
                        id={"name"}
                        name={"name"}
                        label={t("name")}
                        placeholder={t("name")}
                        value={values.name ?? data.name}
                        onChange={e => onChange(e.target.name, e.target.value)}
                    />

                    <NumberInput
                        id={"expiration_days"}
                        name={"expiration_days"}
                        label={t("expirationDays")}
                        placeholder={t("expirationDays")}
                        value={values.expiration_days ?? data.expiration_days ?? 0}
                        onChange={e => onChange(e.target.name, e.target.value)}
                    />

                    <NumberInput
                        id={"max_amount"}
                        name={"max_amount"}
                        label={t("maxAmount")}
                        placeholder={t("maxAmount")}
                        value={values.max_amount ?? data.max_amount ?? 1000}
                        onChange={e => onChange(e.target.name, e.target.value)}
                    />

                    <ButtonComponent
                        className={"max-w-24"}
                        type={"submit"}
                        label={t('submit')}
                        pending={updatePending}
                        button_style={ButtonStyle.Primary}
                    />
                </Form>
            </ContentCard>
        </PageContent>
    );
}
