import React, { useState } from 'react';
import { useToast, useTrans } from "components";
import useHttp from "core/http/useHttp";
import useRouting from "components/hooks/useRouting";
import AuthContainer from "components/auth/AuthContainer";
import { afterLogin } from "@/routing/Router";
import Text from "components/typography/Text";
import useAuth from "components/hooks/auth/useAuth";
import useLocalStorage from "components/hooks/localStorage/useLocalStorage";
import { gAuthToken } from "components/auth/GoogleAuth";
import { paths } from "@/routing/paths";
import AuthCard from "@/components/auth/AuthCard";
import AuthHeader from "@/modules/apps/widgets/editor/pages/auth/components/AuthHeader";
import { Button } from "@/components/v2/Actions/button";

export default function LinkUserToSocial() {
    const { t } = useTrans();
    const http = useHttp();
    const { push } = useRouting();
    const { login } = useAuth();
    const { errorToast } = useToast();
    const { destroy, get } = useLocalStorage();
    const [pending, setPending] = useState(false);

    const accept = () => {
        setPending(true);
        http.post("/social/google/link", {
            token: get(gAuthToken)
        })
            .then((res) => {
                destroy(gAuthToken);
                login(res.data.token);
                push(afterLogin);
            })
            .catch(errorToast)
            .finally(() => setPending(false));
    };

    const decline = () => {
        destroy(gAuthToken);
        push(paths.login);
    };

    return (
        <AuthContainer>
            <AuthCard>
                <AuthHeader
                    title={t('linkSocialTitle')}
                />
                <div className={"flex flex-col gap-2"}>
                    <Text>
                        {t('linkSocialDescription')}
                    </Text>
                    <Button
                        variant={'primary'}
                        onClick={accept}
                        className={"w-full"}
                        isLoading={pending}
                    >
                        {t('linkSocialAccept')}
                    </Button>
                    <Button
                        variant={'default'}
                        onClick={decline}
                        className={"w-full"}
                        isLoading={pending}
                    >
                        {t('back')}
                    </Button>
                </div>
            </AuthCard>
        </AuthContainer>
    );
}