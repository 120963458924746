import React, { ReactNode, useEffect } from "react";
import { ModalProps } from "@/components/hooks/useModal";
import { createPortal } from "react-dom";
import { depth } from "@/core/depth";
import { ModalContext } from "@/components/modals/ModalContext";

export type BaseModalProps = {
    modal: ModalProps;
    depth?: number;
    onOutsideClick?: () => void;
    onClose?: () => void;
    children: ReactNode;
};

export default function BaseModal(props: BaseModalProps) {
    const {
        modal,
        onOutsideClick,
        onClose,
    } = props;

    useEffect(() => {
        function onKeydown(evt: KeyboardEvent) {
            if ("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) {
                onClose && onClose();
                modal.close();
            }
        }

        window.addEventListener("keydown", onKeydown);

        return () => window.removeEventListener("keydown", onKeydown);
    }, [modal.isActive]);

    return createPortal(
        <ModalContext.Provider value={modal}>
            <div
                style={{ zIndex: depth.modal }}
                onClick={() => {
                    onOutsideClick && onOutsideClick();
                    onClose && onClose();
                    modal.close();
                }}
                className={`fixed inset-0 flex items-center justify-center transition-all ${modal.isActive ? "visible bg-black/60" : "invisible"}`}
            >
                <div
                    className={`max-h-[90vh] overflow-auto rounded-lg bg-white text-gray-900 shadow-xl transition-all dark:bg-slate-900 dark:text-white ${modal.isActive ? "scale-100 opacity-100" : "scale-125 opacity-0"}`}
                    onClick={e => e.stopPropagation()}
                >
                    {modal.isActive ? props.children : null}
                </div>
            </div>
        </ModalContext.Provider>
        ,
        document.getElementById('portal')
    );
}