import React from "react";
import Text from "components/typography/Text";
import { useTrans } from "components";
import UIComponent from "components/UIComponent";
import ButtonComponent from "components/buttons/ButtonComponent";
import useRouting from "components/hooks/useRouting";

type Props = {
    name: string;
    header?: {
        title: string;
        description: string;
        action?: any;
    }
    header_row: []
    data_rows: []
};

export default function SimpleTable(props: Props) {
    const { push } = useRouting();
    const { t } = useTrans();

    return (
        <div key={props.name} className={'mt-6 w-full overflow-hidden rounded-2xl border border-gray-200'}>
            {props.header && (
                <div className={'flex items-center justify-between border-b border-gray-200 px-5 pb-4 pt-5'}>
                    <div className={'flex flex-col gap-1'}>
                        <Text size={"lg"} weight={"normal"}>{props.header.title}</Text>
                        {props.header.description && (
                            <Text size={"sm"} color={"text-gray-600"}>{props.header.description}</Text>)}
                    </div>
                    {props.header.action && (
                        <ButtonComponent
                            {...props.header.action}
                            onClick={() => push(props.header.action.action.value)}
                        />
                    )}
                </div>
            )}
            <table className={'w-full table-auto'}>
                <thead className={'border-b border-gray-200 bg-gray-50'}>
                <tr className={'w-full'}>
                    {props.header_row.map((item: any, index: any) => (
                        <th
                            key={`header-${index}`}
                            className={`text- px-5 py-2${item.align} font-normal text-sm text-gray-600`}
                            style={{ width: item.width }}
                        >
                            {item.value}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {props.data_rows?.map((row: any, index: any) =>
                    <tr key={`row-${index}`} className={'w-full border-b border-gray-200 last:border-none'}>
                        {row.map((item: any, index: any) => (
                            <td
                                key={`column-${index}`}
                                className={`text-${item.align} max-w-xs break-all px-5 py-4 text-sm text-gray-600`}
                                style={{ width: item.width }}
                            >
                                {typeof item.value === "object" ?
                                    <UIComponent className={`m-auto`} element={item.value}/> : item.value}
                            </td>
                        ))}
                    </tr>
                )}
                {props.data_rows.length == 0 && (
                    <tr>
                        <td colSpan={props.header_row.length}>
                            <div className={'w-full p-4 text-center'}>
                                <Text size={"sm"} color={"text-gray-400"}>{t('noDataAvailable')}</Text>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}
