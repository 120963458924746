import CRM from "@/components/navigation/desktop/items/CRM";
import { Feature } from "models/account/Account";
import Loyalty from "@/components/navigation/desktop/items/Loyalty";
import Marketing from "@/components/navigation/desktop/items/Marketing";
import Store from "@/components/navigation/desktop/items/Store";
import Apps from "@/components/navigation/desktop/items/Apps";
import Data from "@/components/navigation/desktop/items/Data";
import Automation from "@/components/navigation/desktop/items/Automation";
import React, { useContext } from "react";
import { AccountContext } from "@/context";
import useNavigation from "@/components/navigation/useNavigation";


export default function DesktopMenu() {
    const { account } = useContext(AccountContext);
    const { activeParent, isNavigationFocussed } = useNavigation();

    return (
        <div id={"main-nav"} className={'ml-8 hidden gap-0.5 lg:flex'}>
            <CRM active={isNavigationFocussed && activeParent === "crm"}/>

            {account.usesFeature(Feature.LOYALTY) && (
                <Loyalty active={isNavigationFocussed && activeParent === "loyalty"}/>
            )}

            <Marketing active={isNavigationFocussed && activeParent === "marketing"}/>
            <Store active={isNavigationFocussed && activeParent === "store"}/>
            <Apps active={isNavigationFocussed && activeParent === "apps"}/>
            <Data active={isNavigationFocussed && activeParent === "data"}/>
            <Automation/>
        </div>
    );
}