import React from "react";

function getBoolean(value: any) {
    switch (value) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}

type Props = {
    name: string;
    label?: any;
    value: any;
    disabled?: boolean;
    defaultValue?: any;
    onChange?: any;
    onBlur?: any;
    className?: string;
};

export default function Checkbox(props: Props) {
    const { name, value, defaultValue, label, disabled, onChange, onBlur, className='' } = props;
    const id = `${name}-checkbox`;

    return (
        <div className={`my-4 flex items-center ${className}`}>
            <input
                id={id}
                name={name}
                type="checkbox"
                disabled={disabled}
                checked={value === undefined ? undefined : getBoolean(value)}
                defaultChecked={defaultValue === undefined ? undefined : getBoolean(defaultValue)}
                onBlur={e => onBlur && onBlur(e.target.name, e.target.checked)}
                onChange={e => onChange && onChange(e.target.name, e.target.checked)}
                className={` 
                    size-4 cursor-pointer rounded border-gray-900/10 bg-white text-primary-600 hover:bg-primary-25
                    hover:text-primary-700 focus:ring-0
                    disabled:cursor-not-allowed disabled:border-gray-400 disabled:bg-gray-50 disabled:text-white
                    disabled:checked:border-none disabled:checked:text-gray-50
                    `}
            />
            <label
                htmlFor={id}
                className="text-md font-normal ms-2 cursor-pointer text-primary-900 dark:text-white"
            >
                {label}
            </label>
        </div>
    );
}