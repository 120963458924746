import React from "react";
import SpecialPage from "modules/global/errorPages/SpecialPage";
import useRouting from "components/hooks/useRouting";

export default function MaintenanceMode() {
    const { push } = useRouting();

    return (
        <SpecialPage
            title={"We are in maintenance mode"}
            subTitle={"Please come back in a while."}
            button={{
                label: "Try again",
                onClick: () => push("/home"),
            }}
        />
    );
}
