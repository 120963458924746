import React, { useContext, useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import { AccountContext } from "context";
import useFetcher from "@/repositories/base/useFetcher";
import PageContent from "@/components/containers/PageContent";
import useFilterValues from "@/modules/loyalty/transactions/useFilterValues";
import Table from "@/components/table_v2/Table";
import ContentCard from "@/components/containers/ContentCard";
import ButtonComponent, { ButtonStyle } from "@/components/buttons/ButtonComponent";
import useModal from "@/components/hooks/useModal";
import FormGroup from "@/components/forms/FormGroup";
import Modal from "@/components/modals/types/Modal";
import useHttp from "@/core/http/useHttp";
import { defaultTable } from "@/components/table_v2/types";
import useGiftcardProgram from "@/routing/store/giftcards/useGiftcardProgram";
import useRouting from "@/components/hooks/useRouting";

export default function Reports() {
    const { t } = useTrans();
    const { push } = useRouting();
    const { account } = useContext(AccountContext);
    const { selectedProgramId } = useGiftcardProgram();
    const { errorToast } = useToast();
    const [regeneratePending, setRegeneratePending] = useState(false);
    const [table, setTable] = useState(defaultTable);
    const [selectedReportId, setSelectedReportId] = useState(null);
    const [filterValues, setFilterValues, onFilterChange] = useFilterValues();
    const modal = useModal();
    const http = useHttp();
    const apiUrl = `/accounts/${account.id}/giftcards/programs/${selectedProgramId}/settling/reports`;
    const { mutate, pending } = useFetcher({
        url: apiUrl,
        params: filterValues,
        dataSetter: (data: any) => {
            setTable(data.table);
        },
    });

    useEffect(() => {
        if (selectedReportId) {
            push(`/store/giftcards/programs/${selectedProgramId}/settlement-reports/${selectedReportId}`);
        }
    }, [selectedReportId]);

    const regenerate = () => {
        setRegeneratePending(true);
        http.post(`${apiUrl}/regenerate`)
            .then(() => {
                mutate();
                modal.close();
            })
            .catch(errorToast)
            .finally(() => setRegeneratePending(false));
    };

    return (
        <PageContent
            title={t("reports")}
            subtitle={t("reportsTrackYourSettlements")}
            actions={
                <ButtonComponent
                    onClick={modal.open}
                    icon={"synchronize"}
                    label={t("regenerate")}
                    button_style={ButtonStyle.Secondary}
                />
            }
        >
            <ContentCard>
                <Table
                    table={table}
                    pending={pending}
                    onRowClick={setSelectedReportId}
                    onFilterChange={onFilterChange}
                    filterValues={filterValues}
                />
            </ContentCard>

            <Modal
                modal={modal}
                title={t("regenerate")}
                primaryButton={{
                    label: t("yesIAmSure"),
                    onClick: regenerate,
                    pending: regeneratePending,
                    disabled: regeneratePending,
                }}
            >
                <FormGroup>
                    <p>{t("areYouSureYouWantToRegenerateReports")}</p>
                </FormGroup>
            </Modal>
        </PageContent>
    );
}