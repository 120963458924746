import React from "react";

export function OptionsCouldNotBeLoaded(props: {
    message: string;
}) {
    return (
        <div className="p-4 text-center text-destructive">
            {props.message}
        </div>
    );
}