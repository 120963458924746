import React, { useEffect, useState } from 'react';
import { useTrans } from "components/index";
import useExports from "components/hooks/exports/useExports";
import { ModalProps } from "components/hooks/useModal";
import { upperFirst } from "lodash";
import Form from "components/forms/Form";
import FormGroup from "components/forms/FormGroup";
import FormElement from "components/forms/FormElement";
import Modal from "components/modals/types/Modal";
import TextArea from "components/forms/TextArea/TextArea";
import TextInput from "components/forms/Text/TextInput";

type Props = {
    type: string;
    filterValues: any;
    modal: ModalProps;
    emails: string;
    setEmails: any;
};

export default function Export(props: Props) {
    const { t } = useTrans();
    const [wantsToEmail, setWantsToEmail] = useState(false);
    const { modal, type, filterValues, emails, setEmails } = props;
    const [exportName, setExportName] = useState("");
    const { exportData, exportPending } = useExports({
        onSuccess: modal.close
    });

    useEffect(() => {
        setExportName(`${upperFirst(type)} export`);
    }, [type]);

    const submit = () => {
        exportData(exportName, type, {
            ...filterValues,
            emails: emails
        });
    };

    return (
        <Modal
            modal={modal}
            title={t("export")}
            primaryButton={{
                onClick: submit,
                pending: exportPending
            }}
        >
            <Form onSubmit={submit}>
                <FormGroup modal={true}>

                    <TextInput
                        name="name"
                        required={true}
                        value={exportName}
                        label={t("name")}
                        placeholder={t('name')}
                        onChange={e => setExportName(e.target.value)}
                    />

                    {emails !== undefined && (
                        <div>
                            <FormElement
                                type={"checkbox"}
                                value={wantsToEmail}
                                name={"wantToEmail"}
                                label={t("wouldYouLikeToEmailTheResults")}
                                onChange={() => setWantsToEmail(!wantsToEmail)}
                            />

                            {wantsToEmail && (
                                <TextArea
                                    // tooltip={t("separateMultipleEmailsWithComma")}
                                    value={emails}
                                    placeholder={t("emails")}
                                    name={"emails"}
                                    label={t("emailAddresses")}
                                    onChange={e => setEmails(e.target.value)}
                                />
                            )}
                        </div>
                    )}
                </FormGroup>
            </Form>
        </Modal>
    );
}