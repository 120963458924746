import * as React from "react";
import { forwardRef } from "react";
import * as Headless from "@headlessui/react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const linkVariants = cva(
    "cursor-pointer text-text-link-default underline hover:text-text-link-hover focus:text-text-link-active active:text-text-link-active",
    {
        variants: {
            monochrome: {
                false: "font-[13px] ",
                true: "text-inherit hover:text-inherit hover:opacity-80 active:text-inherit",
            },
            removeUnderline: {
                false: "",
                true: "no-underline",
            },
            disabled: {
                false: null,
                true: "cursor-not-allowed text-text-disabled hover:text-text-disabled active:text-text-disabled",
            }
        },
    }
);

export const Link = forwardRef(function Link(
    {
        monochrome,
        removeUnderline,
        disabled,
        href,
        className,
        ...props
    }: {
        monochrome?: boolean;
        removeUnderline?: boolean;
        disabled?: boolean;
        href: string;
    } & React.ComponentPropsWithoutRef<"a"> &
        VariantProps<typeof linkVariants>,
    ref: React.ForwardedRef<HTMLAnchorElement>
) {
    return (
        <Headless.DataInteractive>
            <a
                ref={ref}
                href={href}
                className={cn(
                    linkVariants({ monochrome, removeUnderline, disabled }),
                    className
                )}
                {...props} // Spread only valid anchor attributes
            />
        </Headless.DataInteractive>
    );
});