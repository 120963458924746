import { Tooltip } from "react-tooltip";
import Text from "components/typography/Text";
import React, { useEffect, useState } from "react";
import { useTrans } from "components";
import useVersion from "components/hooks/versioning/useVersion";
import { reloadCurrentPage } from "core";
import IconOnlyButton from "@/components/navigation/icons/IconOnlyButton";

export default function NewVersionIcon() {
    const { t } = useTrans();
    const { newVersionIsAvailable } = useVersion();
    const [iconWasClicked, setItemWasClicked] = useState(false);

    useEffect(() => {
        if (iconWasClicked) {
            setTimeout(() => reloadCurrentPage(), 1500); // Nice
        }
    }, [iconWasClicked]);

    if (!newVersionIsAvailable) {
        return null;
    }

    return (
        <IconOnlyButton
            isActive={iconWasClicked}
            onClick={() => setItemWasClicked(true)}
        >
            <div
                className={"relative flex"}
                data-tooltip-id={"refresh-tooltip"}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className={`size-6 ${iconWasClicked ? "spin-easing" : ""}`}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                </svg>

                <span className="absolute right-7 top-4 flex size-3">
                    <span
                        className="absolute inline-flex size-full animate-ping rounded-full bg-success-400 opacity-75"
                    />
                    <span className="relative inline-flex size-3 rounded-full bg-success-500"/>
                </span>
            </div>
            <Tooltip
                id={"refresh-tooltip"}
                place={'left'}
                variant={'light'}
                className={`max-w-sm !rounded-lg !px-3 !py-2 !opacity-100 !shadow-md !shadow-slate-900/10`}
            >
                <Text
                    size={'xs'}
                    weight={'light'}
                    color={"text-gray-700"}
                >
                    {t('newVersionAvailableClickMe')}
                </Text>
            </Tooltip>
        </IconOnlyButton>
    );
}
