import { useTrans } from "components";
import { CommandPaletteOption, CommandPaletteOptionType } from "../useCommandPaletteOptions";
import useRouting from "@/components/hooks/useRouting";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";

export default function useSettingsOptions(): CommandPaletteOption[] {
    const { t } = useTrans();
    const accountUser = useCurrentAccountUser();
    const { push } = useRouting();

    const settings = {
        visible: true,
        group: t("navigation"),
        label: t("settings"),
        description: t("settings"),
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/settings/account"),
        tags: [
            "settings",
        ],
    };

    const users = {
        visible: accountUser.hasPermission("admin"),
        group: t("navigation"),
        label: t("users"),
        description: t("userManagement"),
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/settings/user-management/users"),
        tags: [
            "users",
            "user management",
        ],
    };

    return [
        settings,
        users,
    ];
}