import React, { useContext } from 'react';
import Text from "components/typography/Text";
import { ButtonProps } from "components/buttons/ButtonComponent";
import useHttp from "core/http/useHttp";
import { AccountContext } from "context";
import { useToast } from "components";
import CardHeaderContainer from "components/cards/composition/CardHeaderContainer";
import CardHeader from "components/cards/composition/CardHeader";
import Card from 'components/cards/composition/Card';
import CardContent from 'components/cards/composition/CardContent';

type Props = {
    title: string;
    description: string;
    button?: ButtonProps;
    elements: any[];
    mutate: any;
};

export default function DefaultHomePage(props: Props) {
    const http = useHttp();
    const { account } = useContext(AccountContext);
    const { errorToast } = useToast();

    const update = (page: string) => {
        http.put(`/accounts/${account.id}/configuration/home/default-page`, {
            default_page: page
        })
            .then(() => props.mutate())
            .catch(errorToast);
    };

    return (
        <Card>
            <CardHeaderContainer>
                <CardHeader title={props.title} description={props.description}/>
            </CardHeaderContainer>
            <CardContent>
                <div className={"grid grid-cols-3 gap-3"}>
                    {props.elements.map((element: any) => (
                        <div
                            key={element.value}
                            className={"active:shadow-primary-active group cursor-pointer rounded-lg border border-gray-100 p-4 shadow transition-all hover:border-primary-600 dark:bg-slate-700"}
                            onClick={() => update(element.value)}
                        >
                            <div className={"flex w-full items-center justify-center"}>
                                <Text
                                    size={"sm"}
                                    weight={"normal"}
                                    color={"text-gray-700 dark:text-white"}
                                    className={"group-hover:text-blue-600"}
                                >
                                    {element.title}
                                </Text>
                            </div>
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    );
}