import React from "react";
import cn from "classnames";

export type TabGroup = {
    active_tab: TabItem;
    tabs: TabItem[];
};

export type TabItem = {
    key: any;
    name: string;
    errorKeys?: any;
    description?: string;
    actions?: [];
    active?: boolean;
    badge?: any;
    onClick?: any;
    hidden?: boolean;
};

type Props = {
    onTabSelect?: (tab: TabItem) => void;
    tabs: TabItem[];
    setActiveTab: any;
    activeTab: TabItem;
};

export default function TabBar(props: Props) {
    const {
        onTabSelect,
        tabs,
        setActiveTab,
        activeTab,
    } = props;

    return (
        <div className={'flex w-full flex-col overflow-x-auto'}>
            <div className={`mt-1px flex h-full items-end`}>
                {tabs.filter(t => !t.hidden).map(tab => {
                    const isActiveTab = tab.active ? tab.active : tab.name == activeTab?.name;
                    const firstTab = tabs[0].name == tab.name;
                    const tabCn = cn(
                        "flex focus:border-x focus:border-t focus:border-gray-200 items-center select-none py-1 px-[30px] dark:border dark:border-white rounded-t-xl transition ease-linear duration-200 dark:bg-slate-900",
                        isActiveTab ? 'bg-white border border-b-0 dark:border-b-0 border-gray-200 py-2' : 'bg-primary-100 hover:bg-[#caceea] ',
                    );

                    return (
                        <div
                            key={tab.name}
                            className={`relative flex ${isActiveTab ? 'h-10' : 'h-9'} `}
                        >
                            <div
                                className={`absolute -left-5 bottom-0 z-10 h-3`}
                                hidden={!isActiveTab || firstTab}
                            >
                                <div
                                    className={"tabs-corner-before left relative -right-px ml-2 size-3 overflow-hidden"}
                                />
                            </div>
                            <button
                                className={tabCn}
                                onClick={() => {
                                    if (onTabSelect) {
                                        onTabSelect(tab);
                                    } else {
                                        tab.onClick && tab.onClick();
                                        setActiveTab(tab);
                                    }
                                }}
                            >
                                <p
                                    className={`font-normal truncate text-base dark:text-white ${isActiveTab ? 'text-primary-600 opacity-100' : 'opacity-60'}`}
                                >
                                    {tab.name}
                                </p>
                            </button>
                            <div
                                className={`absolute -right-3 bottom-0 z-10 h-3`}
                                hidden={!isActiveTab}
                            >
                                <div
                                    className={`tabs-corner-before right relative -left-px size-3 overflow-hidden`}
                                />
                            </div>
                        </div>
                    );
                })}
                <div className={'shadow-tabs-corner mr-2 flex w-auto grow'}/>
            </div>
        </div>
    );
}
