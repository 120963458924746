import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Button } from '@/components/v2/Actions/button';
import { Link } from '@/components/v2/Navigation/link';
import { ImageIcon } from 'lucide-react';
import cn from 'classnames';

type ThumbnailProps = {
    src?: string | null;
    alt?: string;
    className?: string;
};

export function Thumbnail({
                              src = null,
                              alt = '',
                              className = 'size-12',
                              ...props
                          }: ThumbnailProps & React.ComponentPropsWithoutRef<'span'>) {
    // Choose the element to render based on whether `src` is provided.
    const Component = src ? 'img' : ImageIcon;
    const extraProps = src ? { src, alt } : {};
    const variantClass = src
        ? 'object-cover object-center'
        : 'text-icon-disabled size-1/3 min-h-3 min-w-3 max-h-6 max-w-6 self-center place-self-center';

    return (
        <span
            {...props}
            className={clsx(
                className,
                // Basic layout
                'inline-grid shrink-0 overflow-auto rounded bg-bg-surface-default bg-center outline outline-1 -outline-offset-1 outline-black/10'
            )}
        >
      <Component className={clsx(className, variantClass)} {...extraProps} />
    </span>
    );
}

export default Thumbnail;

type ThumbnailButtonProps = {
    src?: string | null;
    alt?: string;
    className?: string;
} & Omit<React.ComponentPropsWithoutRef<typeof Button>, 'children'>;

export const ThumbnailButton = forwardRef<HTMLButtonElement, ThumbnailButtonProps>(
    function ThumbnailButton({ src, alt = '', className, ...props }, ref) {
        return (
            <Button ref={ref} asChild className="rounded focus-visible:shadow-focus-ring" {...props}>
                <Thumbnail src={src} alt={alt} className={className} />
            </Button>
        );
    }
);

ThumbnailButton.displayName = 'ThumbnailButton';

type ThumbnailLinkProps = {
    src?: string | null;
    alt?: string;
    className?: string;
} & React.ComponentPropsWithoutRef<typeof Link>;

export const ThumbnailLink = forwardRef<HTMLAnchorElement, ThumbnailLinkProps>(
    ({ src, alt = '', className, href, ...props }, ref) => {
        return (
            <Link
                ref={ref}
                href={href}
                className={cn('inline-flex rounded data-[focus]:shadow-focus-ring', className)}
                {...props}
            >
                <Thumbnail src={src} alt={alt} />
            </Link>
        );
    }
);

ThumbnailLink.displayName = 'ThumbnailLink';
