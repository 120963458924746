import { useTrans } from "components";
import { CommandPaletteOption, CommandPaletteOptionType } from "../useCommandPaletteOptions";
import useRouting from "@/components/hooks/useRouting";

export default function useDataOptions(): CommandPaletteOption[] {
    const { t } = useTrans();
    const { push } = useRouting();

    const reports = {
        visible: true,
        group: t("navigation"),
        label: t("reports"),
        description: "Data",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/data/reports"),
        tags: [
            "reports",
        ],
    };

    const imports = {
        visible: true,
        group: t("navigation"),
        label: t("imports"),
        description: "Data",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/data/imports"),
        tags: [
            "imports",
        ],
    };

    const exports = {
        visible: true,
        group: t("navigation"),
        label: t("exports"),
        description: "Data",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/data/exports"),
        tags: [
            "exports",
        ],
    };

    const generating = {
        visible: true,
        group: t("navigation"),
        label: t("generating"),
        description: "Data",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/data/generating"),
        tags: [
            "generating",
        ],
    };

    const files = {
        visible: true,
        group: t("navigation"),
        label: t("files"),
        description: "Data",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/data/files"),
        tags: [
            "files",
        ],
    };

    return [
        reports,
        imports,
        exports,
        generating,
        files,
    ];
}