import React, { useState } from 'react';
import { useToast, useTrans } from "components";
import useRouting from "components/hooks/useRouting";
import AuthContainer from "components/auth/AuthContainer";
import useHttp from "core/http/useHttp";
import Form from 'components/forms/Form';
import useIntercom from "@/components/intercom/useIntercom";
import AuthCard from "@/components/auth/AuthCard";
import AuthHeader from "@/components/auth/AuthHeader";
import { Field, FieldGroup, Fieldset, Label } from "@/components/v2/SelectionAndInput/fieldset";
import { Button } from "@/components/v2/Actions/button";
import { TextInput } from '@/components/v2/SelectionAndInput/textInput';
import Divider from "@/components/ui/Divider";

export default function ForgotPassword() {
    const { t } = useTrans();
    const { errorToast, successToast } = useToast();
    const http = useHttp();
    const { push } = useRouting();
    const { open } = useIntercom();
    const [pending, setPending] = useState(false);
    const [email, setEmail] = useState("");

    const submit = () => {
        setPending(true);
        http.post(`/forgot-password`, {
            email: email,
        })
            .then(() => {
                successToast(t("ifYourAccountWasFoundWeHaveSentAnEmail"));
                push("/login");
            })
            .catch(errorToast)
            .finally(() => setPending(false));
    };

    return (
        <AuthContainer>
            <AuthCard>
                <AuthHeader
                    title={t('forgotPassword')}
                    description={t('pleaseEnterYourEmailToResetYourPassword')}
                />
                <div className={"flex flex-col gap-7"}>
                    <Form onSubmit={submit} className={"flex flex-col gap-3"}>
                        <Fieldset>
                            <FieldGroup>
                                <Field>
                                    <Label>{t("email")}</Label>
                                    <TextInput
                                        name={"email"}
                                        placeholder={t("email")}
                                        value={email}
                                        type={"email"}
                                        required={true}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </Field>
                            </FieldGroup>
                        </Fieldset>

                        <Button
                            isLoading={pending}
                            variant={"primary"}
                            disabled={!email || pending}
                            type={'submit'}
                        >
                            {t('send')}
                        </Button>
                    </Form>
                    <Divider/>

                    <div className={'flex items-center justify-center gap-2'}>
                        <p className={'text-sm text-gray-900'}>
                            {t('needHelp')}
                        </p>
                        <button onClick={open} className={"text-sm text-primary-600 hover:underline"}>
                            {t('startChat')}
                        </button>
                    </div>
                </div>
            </AuthCard>
        </AuthContainer>
    );
}
