import React, { useContext, useState } from "react";
import { AccountContext } from "context";
import useFetcher from "repositories/base/useFetcher";
import { useParams } from "react-router";
import Table from "components/table_v2/Table";
import useFilterValues from "modules/loyalty/transactions/useFilterValues";
import Export from "components/Export";
import useModal from "components/hooks/useModal";
import useExports from "components/hooks/exports/useExports";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import { useTrans } from "components";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import { defaultTable } from "components/table_v2/types";

export default function Settlements() {
    const { t } = useTrans();
    const { account } = useContext(AccountContext);
    const [table, setTable] = useState(defaultTable);
    const [filterValues, setFilterValues, onFilterChange] = useFilterValues();
    const [emails, setEmails] = useState("");
    const { reportId, programId } = useParams<{ reportId: ID, programId: ID }>();
    const apiUrl = `/accounts/${account.id}/giftcards/programs/${programId}/settling/reports/${reportId}/settlements`;
    const exportModal = useModal();
    const { exportPending } = useExports({
        onSuccess: exportModal.close
    });
    const { pending } = useFetcher({
        url: apiUrl,
        params: filterValues,
        dataSetter: setTable,
    });

    return (
        <PageContent
            title={t("settlements")}
        >
            <ContentCard>
                <Table
                    table={table}
                    pending={pending}
                    onFilterChange={onFilterChange}
                    filterValues={filterValues}
                    actions={
                        <ButtonComponent
                            button_style={ButtonStyle.Secondary}
                            label={t("export")}
                            pending={exportPending}
                            onClick={exportModal.open}
                        />
                    }
                />

                <Export
                    modal={exportModal}
                    emails={emails}
                    setEmails={setEmails}
                    filterValues={{
                        settlements_report_id: reportId
                    }}
                    type={"settlements_report"}
                />
            </ContentCard>
        </PageContent>
    );
}