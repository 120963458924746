import React, { useRef, useState } from "react";
import useHttp from "core/http/useHttp";
import useFetcher from "repositories/base/useFetcher";
import { useToast, useTrans } from "components";
import useOnChange from "components/hooks/forms/useOnChange";
import { asFormData, reloadCurrentPage } from "core";
import { PageHeader, PageTitle, } from "@/components/v2/Layout/page/components/pageHeader";
import { Field, FieldGroup, HelpText, Label, } from "@/components/v2/SelectionAndInput/fieldset";
import ReadOnly from "@/components/v2/SelectionAndInput/readOnly";
import { Page } from "@/components/v2/Layout/page/page";
import { Card } from "@/components/v2/Layout/card/Card";
import { TextInput } from "@/components/v2/SelectionAndInput/textInput";
import { Button } from "@/components/v2/Actions/button";
import type { Option } from "@/core/dataTypes/useDataType";
import Dropzone from "@/components/v2/SelectionAndInput/dropzone/dropzone";
import { SelectNative } from "@/components/v2/SelectionAndInput/selectNative";

type ProfileMapping = {
    email: string;
    first_name: string;
    last_name: string;
    locale: string;
    locale_options: Option[];
    avatar: File | null;
    avatar_url: string;
};

export default function Profile() {
    const { t } = useTrans();
    const http = useHttp();
    const { successToast, errorToast } = useToast();
    const [profile, setProfile] = useState<ProfileMapping>(null);
    const [updatePending, setUpdatePending] = useState(false);
    const onProfileChange = useOnChange(setProfile);
    const initialProfile = useRef();
    const hasChanged =
        profile &&
        JSON.stringify(profile) !== JSON.stringify(initialProfile.current);
    useFetcher({
        url: "/users/profile",
        dataSetter: (data) => {
            setProfile(data);
            initialProfile.current = data;
        },
    });

    const update = () => {
        setUpdatePending(true);
        http
            .post(
                "/users/profile",
                asFormData({
                    first_name: profile.first_name,
                    last_name: profile.last_name,
                    locale: profile.locale,
                    avatar: profile.avatar ?? null,
                }),
            )
            .then(() => {
                successToast();
                reloadCurrentPage();
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    if (!profile) return null;

    return (
        <Page>
            <PageHeader>
                <PageTitle title={t("profile")}/>
            </PageHeader>

            <Card>
                <FieldGroup className={"w-full mb-5"}>
                    <div className={"flex w-[44rem] gap-5"}>
                        <Field className={"w-80"}>
                            <Label>{t("email")}</Label>
                            <ReadOnly>{profile.email}</ReadOnly>
                        </Field>

                        <Field className={"w-80"}>
                            <Label>{t("language")}</Label>
                            <SelectNative
                                name={"locale"}
                                value={profile.locale}
                                onChange={(e) => onProfileChange(e.target.name, e.target.value)}
                                autoComplete={"language"}
                            >
                                <option value="en">{t("english")}</option>
                                <option value="nl">{t("dutch")}</option>
                                <option value="fr">{t("french")}</option>
                                <option value="de">{t("german")}</option>
                                <option value="it">{t("italian")}</option>
                                <option value="pl">{t("polish")}</option>
                                <option value="pt">{t("portuguese")}</option>
                                <option value="es">{t("spanish")}</option>
                                <option value="sv">{t("swedish")}</option>
                            </SelectNative>
                        </Field>
                    </div>

                    <div className={"flex w-[44rem] gap-5"}>
                        <Field className={"w-80"}>
                            <Label>{t("firstName")}</Label>
                            <TextInput
                                value={profile.first_name}
                                onChange={(e) => onProfileChange("first_name", e.target.value)}
                            />
                        </Field>

                        <Field className={"w-80"}>
                            <Label>{t("lastName")}</Label>
                            <TextInput
                                value={profile.last_name}
                                onChange={(e) => onProfileChange("last_name", e.target.value)}
                            />
                        </Field>
                    </div>

                    <Field className={"w-[40rem]"}>
                        <Label>{t("avatar")}</Label>

                        <div className={"border rounded bg-white border-gray-20 my-1 p-2"}>
                            <Dropzone
                                previewUrl={profile.avatar_url}
                                onFileSelected={(file) => onProfileChange("avatar", file)}
                                acceptedFileTypes={{
                                    "image/*": [".jpeg", ".jpg", ".png", ".gif"],
                                }}
                            />
                        </div>
                        <HelpText data-slot="description">
                            Accepts .jpeg, .jpg, .png ,svg .gif.
                        </HelpText>
                    </Field>
                </FieldGroup>

                <div>
                    <Button
                        type="button"
                        variant={"default"}
                        onClick={update}
                        isLoading={updatePending}
                        disabled={!hasChanged}
                    >
                        {t("update")}
                    </Button>
                </div>
            </Card>
        </Page>
    );
}
