import React from "react";
import Text from "components/typography/Text";
import cn from "classnames";
import Icon from "components/icons/Icon";
import { HeaderItemType } from "components/table_v2/types";
import PiggyTooltip from "components/ui/PiggyTooltip";

type Props = {
    item: HeaderItemType;
    children: any;
    className?: string;
    onClick?: any;
};

export default function HeaderItem(props: Props) {
    const { item, children, onClick } = props;

    const itemCn = cn(
        `flex space-x-2 min-w-max`,
        { "justify-start": item.align == "left" },
        { "justify-end": item.align == "right" },
        { "justify-center": item.align == "center" },
        { "cursor-pointer": item.sortable },
    );

    const containerCn = cn(
        props.className && props.className,
        'table-cell h-10 group px-4',
    );

    return (
        <th
            className={containerCn}
            onClick={onClick ? () => onClick(item) : null}
        >
            <div
                className={itemCn}
            >
                <Text
                    size={"xs"}
                    weight={"normal"}
                    color={"text-gray-700 dark:text-white"}
                >
                    {children}
                </Text>

                {item.tooltip && (
                    <PiggyTooltip
                        id={item.key}
                        place={'top'}
                        content={item.tooltip}
                    />
                )}

                {item.sortable && (
                    <div className={`flex w-2 flex-col gap-1`}>
                        {item.sortable.icons?.map((icon: any, index: any) =>
                            <Icon
                                key={icon.value + index}
                                attr={"svg"}
                                type={icon.value}
                                className={'w-2'}
                            />
                        )}
                    </div>
                )}
            </div>
        </th>
    );
}