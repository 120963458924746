import React from "react";
import Tag, { TagType } from "components/tag/Tag";
import PiggyTooltip from "components/ui/PiggyTooltip";

export type BadgeType = {
    tags: TagType[];
    tooltip: string;
};

export default function TagList(props: BadgeType) {
    const { tags, tooltip } = props;
    const tag = tags.length > 0 ? tags[0] : null;

    return (
        <div className="relative flex items-center gap-1">
            {tag && (
                <>
                    <Tag key={tag.value} {...tag} />
                    {tags.length > 1 && (
                        <PiggyTooltip id={"tags-list"} content={tooltip} place={"bottom"}>
                            <span className="font-normal h-5 w-full rounded-full bg-gray-200 p-1 text-center text-xs text-gray-900" hidden={tags.length < 1}>
                                +{tags.length - 1}
                            </span>
                        </PiggyTooltip>
                    )}
                </>
            )}
        </div>
    );
}
