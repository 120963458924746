import React, { useEffect, useState } from 'react';
import { useToast, useTrans } from "components";
import useAuth from "components/hooks/auth/useAuth";
import useHttp from "core/http/useHttp";
import useRouting from "components/hooks/useRouting";
import Form from "components/forms/Form";
import AuthContainer from "components/auth/AuthContainer";
import GoogleAuth from 'components/auth/GoogleAuth';
import Divider from "components/ui/Divider";
import { afterLogin, forgotPassword, home, signUpRoute } from "@/routing/Router";
import { openInSameTab } from "core";
import Banner from "components/banner/Banner";
import MultiFactorAuthentication from "modules/auth/login/MultiFactorAuthentication";
import AuthFooter from "@/components/auth/AuthFooter";
import AuthHeader from "@/components/auth/AuthHeader";
import AuthCard from "@/components/auth/AuthCard";
import { Field, FieldGroup, Fieldset, Label } from "@/components/v2/SelectionAndInput/fieldset";
import { TextInput } from "@/components/v2/SelectionAndInput/textInput";
import { Button } from "@/components/v2/Actions/button";
import PasswordInput from "@/components/v2/SelectionAndInput/PasswordInput";
import { NavLink } from "react-router";

export default function Login() {
    const { t } = useTrans();
    const http = useHttp();
    const { login, isLoggedIn } = useAuth();
    const { push, queryParams } = useRouting();
    const { successToast, errorToast } = useToast();
    const [showVerification, setShowVerification] = useState(null);
    const [pending, setPending] = useState(false);
    const [needsToLoginUsingMfa, setNeedsToLoginUsingMfa] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [code, setCode] = useState("");

    useEffect(() => {
        if (queryParams.confirmOrder) {
            localStorage.setItem('confirmOrder', 'true');
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn()) {
            openInSameTab(home);
        }
    }, []);

    useEffect(() => {
        if (code.length == 6) {
            submit();
        }
    }, [code]);

    const handleLogin = (token: string) => {
        login(token);
        push(afterLogin);
    };

    const resendVerify = () => {
        http.post(`/verify/send`, {
            email: email
        })
            .then(() => successToast(t('resendVerificationEmail')))
            .catch(errorToast)
            .finally(() => setShowVerification(false));
    };

    const submit = () => {
        if (email && password) {
            setPending(true);
            http.post(`/login`, {
                email: email,
                password: password,
                code: code,
            })
                .then((res) => {
                    const data = res.data.data;

                    if (data.needs_to_login_using_mfa) {
                        setPending(false);
                        setNeedsToLoginUsingMfa(data.needs_to_login_using_mfa);
                    } else {
                        handleLogin(res.data.data.token);
                    }
                })
                .catch(e => {
                    if (e.message.includes("Your account has not been verified yet. Please check your inbox.")) {
                        setShowVerification(true);
                        setPending(false);
                        return;
                    }

                    setPending(false);
                    errorToast(e);
                });
        }
    };

    return (
        <AuthContainer>
            {needsToLoginUsingMfa ? (
                <MultiFactorAuthentication
                    code={code}
                    setCode={setCode}
                />
            ) : (
                <AuthCard>
                    <AuthHeader
                        title={t('logIn')}
                        description={t("continueToBusinessDashboard")}
                    />
                    <div className={"flex flex-col gap-7"}>
                        {showVerification && (
                            <Banner
                                style={"warning"}
                                body={t('pleaseVerifyUser')}
                                onButtonClick={resendVerify}
                                buttonLabel={t('clickToResendVerification')}
                            />
                        )}
                        <div className={"flex w-full justify-center"}>
                            <GoogleAuth
                                onError={(e: any) => {
                                    errorToast("Error with Google Auth");
                                    console.error(e);
                                }}
                                onSuccess={(res: any) => handleLogin(res.data.token)}
                                authUrl={"/social/google/login"}
                            />
                        </div>

                        <Divider text={t('or')}/>

                        <Form onSubmit={submit} className={"flex flex-col gap-3"}>
                            <Fieldset>
                                <FieldGroup>
                                    <Field>
                                        <Label>{t("email")}</Label>
                                        <TextInput
                                            name={"email"}
                                            placeholder={t("email")}
                                            autoComplete={"email"}
                                            value={email}
                                            type={"email"}
                                            required={true}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </Field>
                                    <Field>
                                        <Label>{t("password")}</Label>
                                        <PasswordInput
                                            autoComplete={"current-password"}
                                            value={password}
                                            required={true}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                    </Field>
                                </FieldGroup>
                            </Fieldset>

                            <Button
                                isLoading={pending}
                                variant={"primary"}
                                disabled={(!email || !password)}
                                type={'submit'}
                            >
                                {t('onboardingLogin')}
                            </Button>

                            <NavLink to={forgotPassword} className={'flex justify-center pt-2'}>
                                <p
                                    className={"font-extralight text-sm text-gray-500 hover:text-primary-600 hover:underline"}
                                >
                                    {t('forgotYourPassword')}
                                </p>
                            </NavLink>
                        </Form>

                        <Divider/>

                        <div className={'flex items-center justify-center gap-2'}>
                            <p className={'text-sm text-gray-900'}>
                                {t('newToPiggy')}
                            </p>
                            <NavLink to={signUpRoute}>
                                <p
                                    className={"text-sm text-primary-600 hover:underline"}
                                >
                                    {t('getStarted')} &rarr;
                                </p>
                            </NavLink>
                        </div>
                    </div>
                </AuthCard>
            )}
            <AuthFooter/>
        </AuthContainer>
    );
}