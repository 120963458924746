import React from "react";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";
import MobileNavItemWithDropdown from "@/components/navigation/mobile/MobileNavItemWithDropdown";
import MobileSubNavItem from "@/components/navigation/mobile/MobileSubNavItem";
import useMarketing from "@/components/navigation/items/useMarketing";

export default function Marketing(props: {
    active: boolean;
}) {
    const { active } = props;
    const marketing = useMarketing();
    const accountUser = useCurrentAccountUser();

    if (!accountUser.hasPermission("marketing")) {
        return null;
    }

    return (
        <MobileNavItemWithDropdown
            path={"marketing"}
            label={"Marketing"}
        >
            {active && (
                <>
                    <MobileSubNavItem
                        name={marketing.campaigns.name}
                        path={marketing.campaigns.path}
                    />
                    <MobileSubNavItem
                        name={marketing.automatedEmails.name}
                        path={marketing.automatedEmails.path}
                    />

                    <MobileSubNavItem
                        name={marketing.templates.name}
                        path={marketing.templates.path}
                    />
                </>
            )}
        </MobileNavItemWithDropdown>
    );
}


