import { InputGroup, TextInput } from "@/components/v2/SelectionAndInput/textInput";
import React, { useState } from "react";
import { EyeIcon, EyeOff } from "lucide-react";
import { Button } from "@/components/v2/Actions/button";
import * as Headless from "@headlessui/react";

type PasswordInputProps = {
    disabled?: boolean
} & Omit<Headless.InputProps, 'as' | 'className' | 'type'>;

const PasswordInput = (props: PasswordInputProps) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleToggle = () => {
        setIsVisible((prev) => !prev);
    };


    return (
        <InputGroup className={"group"}>
            <TextInput
                data-slot="suffix"
                type={isVisible ? "text" : "password"}
                {...props}
            />
            <Button
                data-slot="suffix"
                onClick={handleToggle}
                asChild
                tabIndex={0}
                onKeyDown={(e) => e.key === "Enter" && handleToggle()}
                className={"active::opacity-100 relative flex items-center justify-center rounded-sm p-0.5 opacity-0 transition-opacity ease-in-out focus:shadow-focus-ring group-focus-within:opacity-100 group-hover:opacity-100"}
            >
                {isVisible ? (
                    <EyeOff className="text-icon-secondary-default size-4 hover:cursor-pointer"/>
                ) : (
                    <EyeIcon className="text-icon-secondary-default size-4 hover:cursor-pointer"/>
                )}
            </Button>
        </InputGroup>
    );
};

export default PasswordInput;