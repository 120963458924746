import { useEffect, useState } from "react";
import useRouting from "components/hooks/useRouting";
import { stringifyUrl } from "query-string";

export interface Tab {
    key: string;
    name: string;
    errorKeys?: any;
    hidden?: boolean;
    disabled?: boolean;
}

export default function useTabs(initialTabs: Tab[] = [], deps: any[] = []) {
    const { replace, url, queryParams } = useRouting();
    const [tabs, setTabs] = useState(initialTabs);
    const firstNonHiddenTab = initialTabs.find((tab) => !tab.hidden);
    const [activeTab, setTab] = useState(initialTabs.length > 0 ? firstNonHiddenTab : {
        key: "",
        name: "",
    });

    useEffect(() => {
        setTabs(initialTabs);
    }, deps);

    useEffect(() => {
        if (tabs.length == 0) {
            return;
        }

        const currentTab = tabs.find((tab: any) => tab.key == queryParams?.tab);

        if (currentTab) {
            setActiveTab(currentTab);
        } else {
            setActiveTab(firstNonHiddenTab);
        }
    }, [tabs, url, ...deps]);

    function setActiveTab(tab: Tab) {
        setTab(tab);

        const newURL = stringifyUrl({
            url: url,
            query: {
                ...queryParams,
                tab: tab.key
            },
        });

        replace(newURL);
    }

    return { activeTab, setActiveTab, tabs, setTabs };
}
