import React from 'react';
import Text from "components/typography/Text";
import LogoUrl from "assets/svg/p-logo.svg";
import useRouting from "components/hooks/useRouting";
import LeatLogo from "assets/svg/Leat_Stream_RGB_Black.svg";
import { isLeat } from "core";

type Props = {
    title: string;
    description?: string;
};

export default function AuthHeader(props: Props) {
    const { description, title } = props;
    const { push } = useRouting();

    return (
        <div className={'mb-6 flex items-center justify-between border-b pb-6'}>
            <div className={'flex flex-col'}>
                <h1 className={"font-display text-lg font-medium dark:text-white"}>{title}</h1>
                <Text
                    size={"sm"}
                    weight={"extralight"}
                    color={"text-gray-500 dark:text-white"}
                    dangerously_set_inner_html={true}
                >
                    {description}
                </Text>
            </div>
            <img
                className="size-6 cursor-pointer"
                src={isLeat() ? LeatLogo : LogoUrl}
                alt={"logo"}
                onClick={() => push('/')}
            />
        </div>
    );
}
