export default class ApiError extends Error {
    errors: any[] = [];
    inputErrors: any = {};
    status: number;
    code: number;
    data: any;

    constructor() {
        super();
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}
