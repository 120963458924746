export default class User {
    id: number;
    email: string;
    firstname = "";
    lastname = "";
    isVerified = true;
    locale: string;
    username: string;
    avatar: string;
    createdAt: string;
    preferred_date_format: string;
    preferred_date_time_format: string;
    isSystemAdmin: boolean; // Everybody that works at the company
    isSystemSuperAdmin: boolean; // People that work at the company and have elevated privileges.

    fullName() {
        return (this.firstname + " " + this.lastname).trim();
    }
}