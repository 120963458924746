import { useContext, useState } from "react";
import { useToast, useTrans } from "components";
import useHttp from "core/http/useHttp";
import { AccountContext } from "context";

interface Props {
    onSuccess: () => void;
}

export default function useExports(props: Props) {
    const { t } = useTrans();
    const [pending, setPending] = useState(false);
    const { successToast, errorToast } = useToast();
    const http = useHttp();
    const { account } = useContext(AccountContext);
    const url = `/accounts/${account.id}/management/exports`;

    const exportData = (name: string, type: string, filters = {}) => {
        setPending(true);
        http.post(url, {
            name: name,
            export_type: type,
            ...filters,
        })
            .then(() => {
                successToast(t("exportIsBeingGenerated"));
                props.onSuccess();
            })
            .catch(errorToast)
            .finally(() => setPending(false));
    };

    return { exportData, exportPending: pending };
}