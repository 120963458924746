import { useContext, useEffect } from "react";
import { Theme, ThemeContext } from "context/ThemeContext";

export const themeKey = "theme_setting";

export default function useTheme() {
    const { theme, setTheme } = useContext(ThemeContext);

    useEffect(() => {
        switchTheme(theme);
    }, [theme]);

    const switchTheme = (theme: Theme) => {
        if (theme === 'dark') {
            localStorage[themeKey] = 'dark';
            toDark();
        }

        if (theme === 'light') {
            localStorage[themeKey] = 'light';
            toLight();
        }

        if (theme === 'system') {
            localStorage[themeKey] = 'system';
            const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

            if (prefersDark) {
                toDark();
            } else {
                toLight();
            }
        }

        setTheme(theme);
    };

    const toLight = () => {
        document.documentElement.classList.remove('dark');
    };

    const toDark = () => {
        document.documentElement.classList.add('dark');
    };

    return {
        theme,
        switchTheme
    };
}