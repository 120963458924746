import React, { useEffect, useRef, useState } from "react";
import { Popover, PopoverAnchor, PopoverContent, PopoverTrigger } from "@/components/v2/Overlay/popover";
import { InputGroup, TextInput } from "@/components/v2/SelectionAndInput/textInput";
import { HexColorPicker } from "react-colorful";


interface ColorInputProps {
    value?: string,
    onChange?: (value: any) => void,
    onBlur?: (value: any) => void,
    swatches?: string[],
}

export default function ColorInput({ value = "#FFFFFF", onChange, onBlur, swatches = [] }: ColorInputProps) {
    const [color, setColor] = useState("#ffffff");
    const [open, setOpen] = useState(false);
    const triggerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (value && value !== color) {
            setColor(value);
        }
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value.trim();

        if (!inputValue.startsWith("#")) {
            inputValue = "#" + inputValue.replace(/^#+/, "");
        }

        if (/^#([0-9a-fA-F]{0,6})$/.test(inputValue)) {
            setColor(inputValue);
            onChange?.(inputValue);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            setOpen(true);
        } else if (e.key === "Escape") {
            setOpen(false);
            setTimeout(() => triggerRef.current?.focus(), 0);
        }
    };

    const handleColorPreviewChange = (newColor: string) => {
        setColor(newColor);
        onChange?.(newColor);
    };

    return (
        <Popover
            open={open}
            onOpenChange={(isOpen) => {
                setOpen(isOpen);
                if (!isOpen) {
                    setTimeout(() => triggerRef.current?.focus(), 0);
                }
            }}
        >
            <PopoverTrigger asChild>
                <div ref={triggerRef}>
                    <InputGroup>

                        {/* Color Preview */}
                        <PopoverAnchor asChild>
                            <div
                                data-slot="color"
                                className="rounded ring-1 ring-inset ring-black/10 focus:shadow-focus-ring focus:ring-border-focus"
                                style={{ backgroundColor: color }}
                                tabIndex={0}
                                role="button"
                                aria-label="Open color picker"
                                onKeyDown={handleKeyDown}
                                onClick={() => setOpen(true)}
                            />
                        </PopoverAnchor>
                        {/* Input Field */}
                        <TextInput
                            data-slot="color"
                            value={color}
                            className={"uppercase"}
                            onChange={handleChange}
                            pattern="^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6}|[0-9a-fA-F]{8})$"
                            maxLength={7}
                        />
                    </InputGroup>
                </div>
            </PopoverTrigger>

            <PopoverContent className="w-auto p-2">
                <HexColorPicker
                    color={color}
                    onChange={handleColorPreviewChange}
                    className={"cursor-pointer"}
                />
                <div className={"mt-2 flex w-full gap-1"}>
                    {swatches.map((color, i) => (
                        <div
                            key={color + i}
                            className={`size-6 cursor-pointer rounded ring-1 ring-inset ring-black/10 focus:shadow-focus-ring focus:ring-border-focus`}
                            style={{ backgroundColor: color }}
                            onClick={() => handleColorPreviewChange(color)}
                        />
                    ))}
                </div>
            </PopoverContent>
        </Popover>
    );
}
