import React from "react";
import MediaType from "components/MediaType";
import MediaComponent from "components/MediaComponent";
import useModal from "components/hooks/useModal";
import MediaModal from "components/media/MediaModal";
import { useToast, useTrans } from "components";
import Text from "../typography/Text";
import ButtonComponent, { ButtonStyle } from "../buttons/ButtonComponent";
import cn from "classnames";
import Icon from "../icons/Icon";
import { useDropzone } from "react-dropzone";
import useFileUpload from "../hooks/useFileUpload";
import MediaPreviewModal from "components/modals/MediaPreviewModal";

export type MediaPickerSize = "small" | "large";
export type MediaPickerShape = "square" | "round";

type Props = {
    name: string;
    media: MediaType | null;
    label?: string;
    file_name?: string;
    place_holder?: string;
    with_stock_images?: boolean;
    with_gifs?: boolean;
    with_files?: boolean;
    onChange?: (name: string, media: MediaType) => void;
    onBlur?: (name: string, media: MediaType) => void;
    onClear?: (name: string) => void;
    disabled?: boolean;
    read_only?: boolean;
    size?: MediaPickerSize;
    shape?: MediaPickerShape;
    container?: React.MutableRefObject<null>;
    className?: string;
    accepted_files?: string[];
    max_size?: number;
};

export default function MediaPicker(props: Props) {
    const { t } = useTrans();
    const {
        media,
        onChange,
        onBlur,
        name,
        label,
        file_name,
        place_holder,
        size = "large",
        shape = "square",
        disabled = false,
        accepted_files,
        max_size,
        className
    } = props;
    const mediaSelectModal = useModal();
    const mediaPreviewModal = useModal();
    const isLarge = size === "large";
    const { errorToast } = useToast();
    const { fileHandler } = useFileUpload({
        onChange: onChange,
        onBlur: onBlur,
        name: name,
        label: label
    });
    const onSubmit = (media: MediaType) => {
        onChange && onChange(name, media);
        onBlur && onBlur(name, media);
    };
    const dropRejected = () => errorToast(`${label} drop rejected: File type not supported, or File size too big.`);

    const { getRootProps, getInputProps, isDragReject, isDragAccept } = useDropzone({
        onDropAccepted: fileHandler,
        onDropRejected: dropRejected,
        accept: { "image/*": accepted_files ?? [] },
        minSize: 0,
        maxSize: max_size,
        noClick: true,
        disabled: Boolean(media),
        maxFiles: 1,
    });

    const onClear = () => props.onClear && props.onClear(name);
    const onClick = () => media && mediaPreviewModal.open();

    const imageCn = cn(
        isLarge ? "h-24 w-24" : "h-8 w-8",
        shape === "round" ? "rounded-full" : "rounded",
        media ? "hover:cursor-pointer" : '',
    );

    const containerCn = cn(
        `flex container px-3 py-2 bg-white dark:bg-slate-800 border border-gray-200 rounded-md`,
        className && className,
        disabled ? 'bg-gray-50 cursor-not-allowed' : '',
        media ? "" : `border-dashed ${isDragAccept ? 'bg-primary-25' : 'bg-gray-25'}`,
        isDragAccept ? 'ring-1 ring-primary-400' : '',
        isDragReject ? 'ring-1 ring-error-400 border-error-400' : '',
    );

    return (
        <div className={containerCn}>
            <div
                {...getRootProps()}
                className={'relative flex cursor-default flex-wrap items-center justify-between gap-3'}
            >
                <input {...getInputProps()} />
                <div className={`group relative ${imageCn} shrink-0`} onClick={onClick}>

                    <MediaComponent
                        className={`z-10 flex ${imageCn} object-cover`}
                        media={media ?? {
                            type: "image",
                            value: "",
                            name: ""
                        }}
                    />

                    {media && (
                        <div>
                            <div
                                className={`absolute inset-0 ${imageCn} border border-gray-200 transition-all duration-300 group-hover:bg-gray-900/20`}
                            />
                            <Icon
                                type={"arrowExpand"}
                                attr={"svg"}
                                className={'absolute inset-0 m-auto size-1/2 opacity-0 duration-300 group-hover:animate-appear group-hover:opacity-100'}
                            />
                        </div>
                    )}
                </div>

                {media ? (
                    <div className={'relative flex grow flex-col justify-self-start'}>
                        <Text
                            size={'xs'}
                            weight={'light'}
                            color={disabled ? 'text-gray-400' : 'text-gray-700 dark:text-white'}
                        >
                            {label}
                        </Text>
                        <Text
                            size={'sm'}
                            weight={'light'}
                            color={disabled ? 'text-gray-400' : 'text-gray-700 dark:text-white'}
                            className={'max-w-48 truncate break-all'}
                        >
                            {file_name}
                        </Text>
                    </div>
                ) : (
                    <div className={'flex grow flex-col justify-self-start'}>
                        <Text
                            size={'xs'}
                            weight={'light'}
                            color={"text-gray-700"}
                        >
                            {`${t("dragAndDropOrChooseA", { label: label })}`}
                        </Text>
                        {isLarge && (
                            <Text
                                size={'xs'}
                                weight={'light'}
                                color={'text-gray-400'}
                            >
                                {place_holder ?? ""}
                            </Text>
                        )}
                    </div>
                )}

                <div className={'flex gap-2 justify-self-end'}>
                    <ButtonComponent
                        button_style={ButtonStyle.Secondary}
                        label={media ? t('replaceImage') : t('chooseImage')}
                        onClick={mediaSelectModal.open}
                        disabled={disabled}
                    />

                    {media && props.onClear && (
                        <ButtonComponent
                            button_style={ButtonStyle.Secondary}
                            icon={'trash'}
                            onClick={onClear}
                            disabled={disabled}
                        />
                    )}
                </div>

                <MediaModal {...mediaSelectModal} {...props} onSubmit={onSubmit}/>

                {media ? (
                    <MediaPreviewModal
                        modal={mediaPreviewModal}
                        media={media}
                    />
                ) : null}

            </div>
        </div>
    );
}
