import React from "react";
import NavItemWithDropdown from "@/components/navigation/desktop/NavItemWithDropdown";
import SubNavItem from "@/components/navigation/desktop/SubNavItem";
import useApps from "@/components/navigation/items/useApps";

export default function Apps(props: {
    active: boolean;
}) {
    const { active } = props;
    const apps = useApps();

    return (
        <NavItemWithDropdown
            path={"apps"}
            label={"Apps"}
        >
            {active && (
                <>
                    {apps.customerApp.usesFeature && (
                        <SubNavItem
                            name={apps.customerApp.name}
                            path={apps.customerApp.path}
                        />
                    )}

                    {apps.businessApp.hasPermission && (
                        <SubNavItem
                            name={apps.businessApp.name}
                            path={apps.businessApp.path}
                        />
                    )}

                    {apps.portalSessions.usesFeature && (
                        <SubNavItem
                            name={apps.portalSessions.name}
                            path={apps.portalSessions.path}
                        />
                    )}

                    <SubNavItem
                        name={apps.contactsPortal.name}
                        path={apps.contactsPortal.path}
                    />

                    <SubNavItem
                        name={apps.widget.name}
                        path={apps.widget.path}
                    />

                    <SubNavItem
                        name={apps.walletPasses.name}
                        path={apps.walletPasses.path}
                    />

                    <SubNavItem
                        name={apps.integrations.name}
                        path={apps.integrations.path}
                    />
                </>
            )}
        </NavItemWithDropdown>
    );
}
