import React, { useContext, useState } from "react";
import { AccountContext } from "context";
import { Loader, useToast, useTrans } from "components";
import useHttp from "core/http/useHttp";

type Props = {
    id: string,
    name: string,
    price: string
    image: string
};

export default function WebShopItem(props: Props) {
    const { account } = useContext(AccountContext);
    const { errorToast } = useToast();
    const { t } = useTrans();
    const http = useHttp();
    const [pending, setPending] = useState(false);

    const getUrl = () => {
        setPending(true);
        http.post(`/accounts/${account.id}/web-shop`, {
            item: props.id
        })
            .then((res) => window.open(res.data.data.url))
            .catch(errorToast)
            .finally(() => setPending(false));
    };

    return (
        <div
            className={"relative flex w-96 cursor-pointer flex-col rounded-lg border border-gray-200 p-4 transition-all hover:scale-101"}
            onClick={() => pending ? null : getUrl()}
        >
            <img
                src={props.image}
                alt={"piggy-keycards"}
                className="size-full rounded-md object-cover"
            />
            <div className={"flex justify-between font-medium"}>
                <p>{props.name}</p>
                <p>{props.price}</p>
            </div>
            <p className={"text-gray-400"}>{t('250pieces')}</p>
            <div
                className={`absolute left-0 top-0 flex size-full items-center justify-center rounded-lg bg-black/10 ${pending ? "visible" : "invisible"}`}
            >
                <Loader visible={pending}/>
            </div>
        </div>
    );
}