import React from "react";
import { Text } from "@/components/v2/Typography/text";

export function DefaultOptionLayout(props: {
    label: string
}) {

    return (
        <div
            className="flex items-center gap-2"
        >
            <div className="flex flex-col leading-tight">
                <Text
                    variant={"bodyMd"}
                    tone={"base"}
                    as={"p"}
                >
                    {props.label}
                </Text>
            </div>
        </div>
    );
}