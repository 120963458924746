export default function useOnChange(setStateFunc: any) {
    return (fieldName: any, value: any) => {
        setStateFunc((prevState: any) => {
            return {
                ...prevState,
                [fieldName]: value,
            };
        });
    };
}
