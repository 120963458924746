import Text from "components/typography/Text";
import React from "react";
import { useTrans } from "components";

interface Props {
    pagination: any;
    filterValues?: any;
    onChange: any;
}

export default function LimitAndShowPicker(props: Props) {
    const { pagination, filterValues, onChange } = props;
    const { t } = useTrans();

    return (
        <div className="flex items-center gap-2">
            <select
                key={'limit'}
                value={filterValues?.limit}
                className={'font-extralight h-8 w-16 rounded-lg border border-gray-200 bg-transparent pl-2 text-gray-500 dark:border-white dark:text-white'}
                onChange={onChange}
            >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>

            <div className="flex items-center gap-2">
                {pagination.total && pagination.total > 0 ? (
                    <Text color={"text-gray-500 dark:text-white"} weight={'extralight'}>
                        {t("of")} {pagination.display_total || pagination.total}
                    </Text>
                ) : null}
            </div>
        </div>
    );
}
