import * as React from "react";
import cn from "classnames";

type CardProps = {
    className?: string;
    children?: React.ReactNode;
};

export const Card = (props: CardProps) => {
    const { className, children } = props;

    return (
        <span
            className={cn(
                // Basic layout
                'relative block w-full',
                // Background color + shadow applied to inset pseudo-element, so shadow blends with border in light mode
                'bg-bg-surface-default rounded-lg group-[.has-tabs]:rounded-tl-none shadow-card-default ',
            )}
        >
            <div
                className={cn([
                    // Basic layout
                    'relative block w-full rounded-lg p-4',
                    // Background color
                    // 'bg-bg-surface-default',
                    className,
                ])}
            >
                {children}
            </div>
        </span>
    );
};
