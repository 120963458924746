import React from "react";

type Props = {
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
    children: React.ReactNode;
};

export default function TableRow(props: Props) {

    return (
        <tr
            {...props}
            className={'group relative cursor-pointer border-b border-border-default bg-bg-surface-default *:shadow-table-border hover:bg-bg-surface-secondary-default'}
        >
            {props.children}
        </tr>
    );
}