import React, { ReactNode } from "react";
import { useTrans } from "components";
import { ModalProps } from "components/hooks/useModal";
import { ButtonProps } from "components/buttons/ButtonComponent";
import BaseModal from "@/components/modals/components/BaseModal";
import ModalWidth from "@/components/modals/components/ModalWidth";
import { Button } from "@/components/v2/Actions/button";

type Props = {
    modal: ModalProps;
    title: string;
    primaryButton?: ButtonProps;
    secondaryButton?: ButtonProps;
    onClose?: any;
    children: ReactNode;
};

export default function WarningModal(props: Props) {
    const { t } = useTrans();
    const { modal, onClose, primaryButton, secondaryButton } = props;

    return (
        <BaseModal {...props} modal={modal}>
            <ModalWidth supports={{
                sm: true,
                md: true,
                lg: false,
            }}
            >
                <div className={"flex items-start gap-4 p-6"}>
                    <div className={"rounded-full bg-yellow-600/10 p-4"}>
                        <svg className={"size-12"} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.9147 13.25L12.1847 3.263C11.2187 1.57925 8.78047 1.57925 7.81447 3.263L2.08522 13.25C1.12672 14.921 2.33797 17 4.26997 17H15.73C17.662 17 18.8732 14.921 17.9147 13.25Z"
                                stroke="#FFD84C"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M10 11V6.5"
                                stroke="#FFD84C"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M10 14V14.0075"
                                stroke="#FFD84C"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div>
                        <div>
                            <h1 className={"font-normal text-xl"}>{props.title}</h1>
                        </div>
                        <div className={"mt-4"}>
                            {props.children}
                        </div>
                    </div>
                </div>
                <div
                    className={'flex justify-end gap-2 border-x border-b bg-gray-50 px-6 py-4 dark:bg-slate-400'}
                >
                    <Button
                        variant={"default"}
                        type="button"
                        hidden={secondaryButton?.hidden}
                        onClick={() => {
                            secondaryButton?.onClick && secondaryButton?.onClick();
                            onClose && onClose();
                            modal.close && modal.close();
                        }}
                        isLoading={secondaryButton?.pending}
                        disabled={secondaryButton?.disabled ?? false}
                    >
                        {secondaryButton?.label ? secondaryButton.label : t('cancel')}
                    </Button>

                    <Button
                        variant={"default"}
                        type="button"
                        tone={"critical"}
                        hidden={primaryButton?.hidden}
                        onClick={primaryButton?.onClick}
                        isLoading={primaryButton?.pending}
                        disabled={primaryButton?.disabled ?? false}
                    >
                        {primaryButton?.label ? primaryButton.label : t('delete')}
                    </Button>
                </div>
            </ModalWidth>
        </BaseModal>
    );
}