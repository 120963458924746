import React from 'react';

interface Props {
    percentage: number;
    total: number;
    value?: number;
    color: string
}

export default function TableProgressBar(props: Props) {
    const { percentage, total, value, color } = props;

    return (
        <div className={'flex items-center gap-2'}>
            <span className={'text-xs'}>{value} / {total}</span>
            <div className={'w-full min-w-16'}>
                <div
                    className={"relative h-2 rounded-xl"}
                    style={{
                        backgroundColor: `${color}20`
                    }}
                >
                    <div
                        className={"absolute h-2 rounded-xl"}
                        style={{
                            width: `${percentage}%`,
                            backgroundColor: color
                        }}
                    />
                </div>
            </div>
        </div>

    );
}