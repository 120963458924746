import React from 'react';
import { animated, useSpring } from "react-spring";
import TabBar, { TabItem } from "components/tabs/TabBar_V2";

type Props = {
    className?: string;
    children: React.ReactNode;
    onTabSelect?: (tab: TabItem) => void;
    tabs?: TabItem[];
    setActiveTab?: any;
    activeTab?: TabItem;
};

export default function Card(props: Props) {
    const appear = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }});

    return (
        <animated.div className={props.className} style={appear}>
            {props.tabs && (
                <TabBar
                    onTabSelect={props.onTabSelect}
                    tabs={props.tabs}
                    activeTab={props.activeTab}
                    setActiveTab={props.setActiveTab}
                />
            )}
            <div
                className={`border border-gray-900/10 bg-white shadow-sm dark:border-white dark:bg-slate-800 ${props.tabs ? 'rounded-b-lg border-t-0' : 'rounded-lg'}`}
            >
                {props.children}
            </div>
        </animated.div>
    );
}