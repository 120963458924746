import React from "react";
import cn from "classnames";

type Supports = {
    sm: boolean;
    md: boolean;
    lg?: boolean;
    xl?: boolean
    "2xl"?: boolean
};

export default function ModalWidth(props: {
    supports: Supports,
    children?: React.ReactNode;
}) {
    const { supports } = props;
    const className = cn(
        "w-[85vw]",
        supports.sm ? "sm:w-[34rem]" : "",
        supports.md ? "md:w-[42rem]" : "",
        supports.lg ? "lg:w-[55rem]" : "",
        supports.xl ? "xl:w-[73rem]" : "",
        supports["2xl"] ? "xl:w-[90rem]" : "",
    );

    return (
        <div
            className={className}
        >
            {props.children}
        </div>
    );
}