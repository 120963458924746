import React, { createContext, useState } from "react";

export const DragAndDropContext = createContext(null);

export const DragAndDropProvider = ({ children }: any) => {
    const [isDragging, setDragging] = useState(false);

    return (
        <DragAndDropContext.Provider
            value={{
                isDragging,
                setDragging
            }}
        >
            {children}
        </DragAndDropContext.Provider>
    );
};