import React, { useState } from "react";
import useHttp from "core/http/useHttp";
import { useToast, useTrans } from "components";
import useOnChange from "components/hooks/forms/useOnChange";
import { Page } from "@/components/v2/Layout/page/page";
import { Card } from "@/components/v2/Layout/card/Card";
import { PageHeader, PageTitle, } from "@/components/v2/Layout/page/components/pageHeader";
import { Button } from "@/components/v2/Actions/button";
import { Field, FieldGroup, Label, } from "@/components/v2/SelectionAndInput/fieldset";
import PasswordInput from "@/components/v2/SelectionAndInput/PasswordInput";

export default function Password() {
    const { t } = useTrans();
    const http = useHttp();
    const { successToast, errorToast } = useToast();
    const [updatePending, setUpdatePending] = useState(false);
    const [payload, setPayload] = useState(null);
    const onChange = useOnChange(setPayload);
    const canSubmit =
        payload?.current_password &&
        payload?.new_password &&
        payload?.new_password_confirmation;

    const update = () => {
        setUpdatePending(true);
        http
            .post("users/change-password", payload)
            .then(() => {
                successToast(t("passwordChanged"));
                setPayload(null);
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    return (
        <Page>
            <PageHeader>
                <PageTitle title={t("password")}/>
            </PageHeader>

            <Card>
                <FieldGroup className={"w-full mb-5"}>
                    <Field className={"w-80"}>
                        <Label>{t("currentPassword")}</Label>
                        <PasswordInput
                            value={payload?.current_password ?? ""}
                            onChange={(e) => onChange("current_password", e.target.value)}
                        />
                    </Field>

                    <Field className={"w-80"}>
                        <Label>{t("newPassword")}</Label>
                        <PasswordInput
                            value={payload?.new_password ?? ""}
                            onChange={(e) => onChange("new_password", e.target.value)}
                        />
                    </Field>

                    <Field className={"w-80"}>
                        <Label>{t("confirmPassword")}</Label>
                        <PasswordInput
                            value={payload?.new_password_confirmation ?? ""}
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                            onChange={(e) =>
                                onChange("new_password_confirmation", e.target.value)
                            }
                        />
                    </Field>
                </FieldGroup>

                <div>
                    <Button
                        type="button"
                        variant={"default"}
                        onClick={update}
                        isLoading={updatePending}
                        disabled={!canSubmit}
                    >
                        {t("update")}
                    </Button>
                </div>
            </Card>
        </Page>
    );
}
