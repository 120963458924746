import React, { useContext } from "react";
import { ModalContext } from "@/components/modals/ModalContext";

type Props = {
    title: string;
    subtitle?: string;
    onClose?: () => void;
};

export const ModalHeader = (props: Props) => {
    const { close } = useContext(ModalContext);
    const { title, subtitle, onClose } = props;

    return (
        <div
            className={'flex items-center justify-between rounded-t-xl bg-gray-50 p-4 pl-6 shadow dark:bg-slate-800'}
        >
            <div>
                <h1 className={'font-normal truncate text-xl dark:text-white'}>
                    {title}
                </h1>

                {subtitle && (
                    <h2 className={"text-gray-600"}>{subtitle}</h2>
                )}
            </div>

            <button
                className={'btn-outline rounded-full p-2 hover:bg-gray-100'}
                onClick={onClose ? onClose : close}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
    );
};