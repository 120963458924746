import React from "react";
import MediaComponent from "components/MediaComponent";
import MediaType from "components/MediaType";

export type TagType = {
    value: string;
    color: string;
    className?: string;
    hidden?: boolean;
    media: MediaType
};

export default function Tag(props: TagType) {
    const { value, hidden, className, media } = props;

    return (
        <span
            hidden={hidden}
            className={`tag ${className ? className : ""}`}
        >
            {media && (
                <MediaComponent media={media}/>
            )}
            {value}
        </span>
    );
}
