import { useTrans } from "components";

export default function useHelpCenter() {
    const { language } = useTrans();

    const getUrl = () => {
        if (window.location.host.includes('piggy')) {
            if (language == "en" || language == "nl" || language == "de") {
                return `https://intercom.help/piggy-2cd7a8444cb5/${language}`;
            }

            return `https://intercom.help/piggy-2cd7a8444cb5/en/`;
        }

        if (language == "en" || language == "nl" || language == "de") {
            return `https://support.leat.com/${language}`;
        }

        return `https://support.leat.com/en`;
    };

    return {
        url: getUrl(),
    };
}