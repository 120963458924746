import React from "react";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";
import MobileNavItemWithDropdown from "@/components/navigation/mobile/MobileNavItemWithDropdown";
import MobileSubNavItem from "@/components/navigation/mobile/MobileSubNavItem";
import useLoyalty from "@/components/navigation/items/useLoyalty";

export default function Loyalty(props: {
    active: boolean;
}) {
    const { active } = props;
    const loyalty = useLoyalty();
    const accountUser = useCurrentAccountUser();

    if (!accountUser.hasPermission("loyalty")) {
        return null;
    }

    return (
        <MobileNavItemWithDropdown
            path={"loyalty"}
            label={"Loyalty"}
        >
            {active && (
                <>
                    <MobileSubNavItem
                        name={loyalty.analytics.name}
                        path={loyalty.analytics.path}
                    />
                    <MobileSubNavItem
                        name={loyalty.rewards.name}
                        path={loyalty.rewards.path}
                    />
                    <MobileSubNavItem
                        name={loyalty.transactions.name}
                        path={loyalty.transactions.path}
                    />
                    <MobileSubNavItem
                        name={loyalty.tokens.name}
                        path={loyalty.tokens.path}
                    />
                    <MobileSubNavItem
                        name={loyalty.staged.name}
                        path={loyalty.staged.path}
                    />
                    <MobileSubNavItem
                        name={loyalty.rules.name}
                        path={loyalty.rules.path}
                    />
                    <MobileSubNavItem
                        name={loyalty.tiers.name}
                        path={loyalty.tiers.path}
                    />
                    <MobileSubNavItem
                        name={loyalty.promotions.name}
                        path={loyalty.promotions.path}
                    />
                    {loyalty.referrals.usesFeature && (
                        <MobileSubNavItem
                            name={loyalty.referrals.name}
                            path={loyalty.referrals.path}
                        />
                    )}
                </>
            )}
        </MobileNavItemWithDropdown>
    );
}

