import React, { useContext, useState } from "react";
import { useToast, useTrans } from "components";
import { AccountContext } from "context";
import useFetcher from "@/repositories/base/useFetcher";
import PageContent from "@/components/containers/PageContent";
import ContentCard from "@/components/containers/ContentCard";
import useHttp from "@/core/http/useHttp";
import useGiftcardProgram from "@/routing/store/giftcards/useGiftcardProgram";
import FormElement from "@/components/forms/FormElement";
import FormGroup from "@/components/forms/FormGroup";
import ButtonComponent, { ButtonStyle } from "@/components/buttons/ButtonComponent";
import Form from "@/components/forms/Form";

export default function Settings() {
    const { t } = useTrans();
    const { account } = useContext(AccountContext);
    const { selectedProgramId } = useGiftcardProgram();
    const { errorToast, successToast } = useToast();
    const http = useHttp();
    const [settles, setSettles] = useState<boolean>(null);
    const [frequency, setFrequency] = useState<boolean>(null);
    const [updatePending, setUpdatePending] = useState(false);
    const apiUrl = `/accounts/${account.id}/giftcards/programs/${selectedProgramId}/settling/settings`;
    const { mutate } = useFetcher({
        url: apiUrl,
        dataSetter: data => {
            setSettles(data.settles);
            setFrequency(data.frequency);
        }
    });
    const frequencies = [
        {
            value: "YEAR",
            label: t("yearly"),
        },
        {
            value: "QUARTER",
            label: t("quarterly"),
        },
        {
            value: "MONTH",
            label: t("monthly"),
        },
    ];

    const update = () => {
        setUpdatePending(true);
        http.put(apiUrl, {
            settles: settles,
            frequency: frequency,
        })
            .then(res => {
                mutate();
                successToast();
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    return (
        <PageContent
            title={t("settings")}
        >
            <ContentCard>
                <FormGroup>
                    <Form onSubmit={update}>
                        <FormElement
                            type={"checkbox"}
                            name={"settles"}
                            label={t("programShouldSettleTransactions")}
                            value={settles}
                            onChange={(name, value) => setSettles(value)}
                        />
                        <FormElement
                            type={"select"}
                            label={t("settlementReportFrequency")}
                            options={frequencies}
                            value={frequency}
                            name={"frequency"}
                            sort_type={"none"}
                            onChange={(name, value) => setFrequency(value)}
                        />

                        <ButtonComponent
                            className={"max-w-24"}
                            type={"submit"}
                            label={t('submit')}
                            pending={updatePending}
                            button_style={ButtonStyle.Primary}
                        />
                    </Form>
                </FormGroup>
            </ContentCard>
        </PageContent>
    );
}