import React from "react";
import { cn } from "core";

export type TableCellProps = {
    name?: string // This is only here for easier reference when inspecting in the dom.
    className?: string;
    children?: React.ReactNode
};

export default function TableCell(props: TableCellProps) {

    const classNames = cn(
        props.className,
        'font-thin p-4 ',
    );

    return (
        <td
            className={classNames}
            data-cell={props.name}
        >
            <div>
                {props.children}
            </div>
        </td>
    );
}