import DropdownMenuItem from "components/buttons/dropdown/DropdownMenuItem";
import useAutoClose from "components/hooks/useAutoClose";
import { IconType } from "components/icons/Icon";
import MediaType from "components/MediaType";
import React from "react";
import { ButtonAction } from "components/buttons/ButtonComponent";

export type ItemStyles =
    "default"
    | "warning"
    | "success"
    | "danger"
    | "ghost"
    | "ghost-inverted"
    | "primary"
    | "primary-inverted";

export type DropdownPosition = "right" | "left";

export type DropdownItem = {
    hidden?: boolean;
    style?: ItemStyles;
    label: string;
    onClick: any;
    icon?: IconType;
    disabled?: boolean;
    action?: ButtonAction;
    media?: MediaType;
    className?: string;
    id?: any
};

type Props = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    position: DropdownPosition;
    items?: DropdownItem[][] | DropdownItem[];
    parentRef?: any;
    action?: any;
};

export default function Dropdown(props: Props) {
    const { visible, setVisible, position } = props;
    const ownRef = React.useRef(null);
    const rootEl = props.parentRef ? props.parentRef : ownRef;
    const items = props.items ?? [];
    useAutoClose(rootEl, () => setVisible(false), visible);

    const onClick = (item: any) => {
        if (item.onClick) {
            item.onClick(item);
        }
    };

    if (!visible) {
        return null;
    }

    return (
        <div
            className={`absolute flex flex-col bg-white border border-primary-50 shadow-sm b rounded-lg text-primary-900 z-20 pt-1.5 pb-1.5 pr-3 pl-3 top-full mt-1.5 w-full ${position === "left" ? "left-0" : "right-0"}`}
            ref={rootEl}
            hidden={!visible}
        >
            {items.map((group, index) => {
                if (Array.isArray(group)) {
                    return (
                        <ul className='last:border-b-0 list-none m-0 border-b border-primary-25 pt-1.5 pb-1.5'
                            key={`dropdown__list-group-${index}`}>
                            {group.filter(item => item.hidden !== true).map((item, index) =>
                                <DropdownMenuItem
                                    {...item}
                                    key={`${item.label}-${index}`}
                                    onClick={() => onClick(item)}
                                    className={item.className}
                                />
                            )}
                        </ul>
                    );
                } else {
                    return (
                        items.map((item: any) => (
                            <ul className={"dropdown__list"} key={item.id + item.label}>
                                {!Array.isArray(item) && item.hidden !== true ? (
                                    <DropdownMenuItem
                                        {...item}
                                        key={`${item.label}-${index}-${item.id}`}
                                        onClick={() => onClick(item)}
                                        className={item.className}
                                    />
                                ) : null}
                            </ul>
                        ))
                    );
                }
            })}
        </div>
    );
}
