import React, { CSSProperties, HTMLAttributes, useEffect, useState } from 'react';

type Props = {
    src: string;
    alt?: string;
    onClick?: any;
    style?: CSSProperties;
    className?: HTMLAttributes<HTMLImageElement>["className"];
};

export const fallbackImage = "https://static.piggy.nl/images/fallback_image.png";

export default function ImageComponent(props: Props) {
    const { src, alt, className, onClick, style } = props;
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        setHasError(false);
    }, [src]);

    if (!src) {
        return (
            <img
                src={fallbackImage}
                alt={alt}
                style={style}
                className={className}
                onClick={onClick}
                draggable={false}
            />
        );
    }

    return (
        <img
            data-src={src}
            data-error={hasError ? "true" : "false"}
            onError={() => setHasError(true)}
            src={hasError ? fallbackImage : src}
            alt={alt}
            style={style}
            className={className}
            onClick={onClick}
            draggable={false}
        />
    );
}
