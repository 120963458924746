import HeaderItem from "components/table_v2/components/header/HeaderItem";
import React from "react";
import { HeaderItemType } from "components/table_v2/types";

interface Props {
    className?: string;
    items: HeaderItemType[];
    onHeaderClick: (item: HeaderItemType) => void;
}

export default function Header(props: Props) {
    const { className, items, onHeaderClick } = props;

    return (
        <tr className={className}>
            {items.map((i, index) =>
                <HeaderItem
                    key={`header-${i.value}-${index}`}
                    item={i}
                    onClick={i.sortable ? () => onHeaderClick(i) : null}
                >
                    {i.value}
                </HeaderItem>
            )}
        </tr>
    );
}
