import useRouting from "components/hooks/useRouting";
import { openInNewTab, openInSameTab } from "core";

export default function useBackendAction(props: any) {
    const { push, go } = useRouting();

    const handleAction = () => {
        if (props.action) {
            if (props.action.type == "internal_url") {
                push(props.action.value);

                return;
            }

            if (props.action.type == "external_url") {
                openInNewTab(props.action.value);

                return;
            }

            if (props.action.type == "internal_url_with_params") {
                push(props.action.value);
                go(0);

                return;
            }

            if (props.action.type == "external_url_same_tab") {
                openInSameTab(props.action.value);

                return;
            }
        }

        props.onClick && props.onClick(props);
    };

    return {
        handleAction
    };
}
