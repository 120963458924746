export const urls = {
    ACADEMY: "https://academy.piggy.eu",
    HELP_CENTER: "https://support.business.piggy.nl/knowledge",
    MASTERCLASS: "https://share.hsforms.com/14vdvdv2fT2K6O92HcXN4og42rlh",
    PIGGY_EVENTS: "https://www.piggy.eu/events",
    ROADMAP: "https://roadmap.piggy.eu",
    TERMS_AND_CONDITIONS: "https://www.piggy.eu/terms-and-conditions",
    PRIVACY_STATEMENT: "https://www.piggy.eu/privacy-statement",
    PIGGY_WEBSITE: import.meta.env.VITE_PIGGY_WEBSITE,
    API_HOST: import.meta.env.VITE_API_HOST,
    SHARED_API_HOST: import.meta.env.VITE_SHARED_API_HOST,
    PUSHER_APP_KEY: import.meta.env.VITE_PUSHER_APP_KEY,
    FORM_HOST: import.meta.env.VITE_FORM_HOST,
    APP_DOMAIN: import.meta.env.VITE_APP_DOMAIN ? import.meta.env.VITE_APP_DOMAIN : "localhost",
};

export const emails = {
    support: "support@piggy.eu",
};