import { useState } from "react";

type PaginateData = {
    total: number,
    last_page: number,
    current_page: number,
};

export default function usePaginatedResponse<Model>() {
    const [paginatedResponse, setResponse] = useState<{
        data: Model[],
        total: number,
        last_page: number,
        current_page: number,
    }>({
        data: [],
        total: 0,
        last_page: 1,
        current_page: 1,
    });

    const setPaginatedResponse = (data: Model[], meta: PaginateData) => {
        setResponse({
            data: data,
            ...meta,
        });
    };

    return {
        paginatedResponse,
        setPaginatedResponse,
    };
}