import { uiColors } from "../colors/colors";
import Icon from "../icons/Icon";

export type BadgeProps = {
	value: any;
	color: keyof typeof uiColors;
	className?: string;
	containerClassname?: string;
	uppercase?: boolean;
	inverted?: boolean;
	hidden?: boolean;
	icon?: any;
};

/** @deprecated Use BadgeComponent instead. */
export default function Badge(props: BadgeProps) {
	const {
		value,
		hidden,
		className,
		color,
		containerClassname,
		uppercase,
		icon,
	} = props;

	return (
		<div
			className={`flex items-center ${containerClassname ? containerClassname : ""}`}
		>
			<span
				hidden={hidden}
				className={`font-light flex gap-2 rounded-full px-2 py-0.5 text-sm ${className ? className : ""}}`}
				style={{
					color: uiColors[color],
					backgroundColor: `${uiColors[color]}15`,
				}}
			>
				{uppercase ? value?.toUpperCase() : value}
				{icon && <Icon className={"my-auto size-3"} attr={"svg"} type={icon} />}
			</span>
		</div>
	);
}
