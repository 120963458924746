import { useTrans } from "components";
import { CommandPaletteOption, CommandPaletteOptionType } from "../useCommandPaletteOptions";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";
import useRouting from "@/components/hooks/useRouting";

export default function useMarketingOptions(): CommandPaletteOption[] {
    const { t } = useTrans();
    const accountUser = useCurrentAccountUser();
    const { push } = useRouting();

    const campaigns = {
        visible: accountUser.hasPermission("marketing"),
        group: t("navigation"),
        label: t("campaigns"),
        description: "Marketing",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/marketing/campaigns"),
        tags: [
            "campaigns",
        ],
    };

    const automatedEmails = {
        visible: accountUser.hasPermission("marketing"),
        group: t("navigation"),
        label: t("automatedEmails"),
        description: "Marketing",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/marketing/automated-emails"),
        tags: [
            "automatedEmails",
        ],
    };

    const templates = {
        visible: accountUser.hasPermission("marketing"),
        group: t("navigation"),
        label: t("templates"),
        description: "Marketing",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/marketing/template-gallery"),
        tags: [
            "templates",
        ],
    };

    return [
        campaigns,
        automatedEmails,
        templates,
    ];
}