import React, { useState } from "react";
import FormGroup from "components/forms/FormGroup";
import useHttp from "core/http/useHttp";
import useFetcher from "repositories/base/useFetcher";
import { useToast, useTrans } from "components";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import Modal from "components/modals/types/Modal";
import useModal from "components/hooks/useModal";
import Text from "components/typography/Text";
import DisplayBooleanElement from "components/forms/DisplayFormElements/DisplayBooleanElement";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import DeleteModal from "components/modals/types/DeleteModal";

export default function MultiFactorAuthentication() {
    const { t } = useTrans();
    const http = useHttp();
    const { successToast, errorToast } = useToast();
    const [mfaIsEnabled, setMfaIsEnabled] = useState(false);
    const setupMFAModal = useModal();
    const [qrCode, setQrCode] = useState("");
    const [enablePending, setEnablePending] = useState(false);
    const [disablePending, setDisablePending] = useState(false);
    const [startPending, setStartPending] = useState(false);
    const disableDialog = useModal();
    const apiUrl = '/users/multi-factor-authentication';
    const { mutate } = useFetcher({
        url: apiUrl,
        dataSetter: (data: any) => {
            setMfaIsEnabled(data.mfa_is_enabled);
        },
    });

    const start = () => {
        setStartPending(true);
        http.post(`${apiUrl}/start`)
            .then(res => {
                setQrCode(res.data.data.qr_code);
                setupMFAModal.open();
                mutate();
            })
            .catch(errorToast)
            .finally(() => setStartPending(false));
    };

    const enable = () => {
        setEnablePending(true);
        http.post(`${apiUrl}/enable`)
            .then(() => {
                setupMFAModal.close();
                mutate();
                successToast();
            })
            .catch(errorToast)
            .finally(() => setEnablePending(false));
    };

    const disable = () => {
        setDisablePending(true);
        http.post(`${apiUrl}/disable`)
            .then(() => {
                mutate();
                disableDialog.close();
                successToast();
            })
            .catch(errorToast)
            .finally(() => setDisablePending(false));
    };

    return (
        <PageContent title={t("multiFactorAuthentication")}>
            <ContentCard>
                <FormGroup>
                    <DisplayBooleanElement
                        label={t("status")}
                        value={mfaIsEnabled ? t('enabled') : t('disabled')}
                        underlying_value={mfaIsEnabled}
                    />

                    {mfaIsEnabled ? (
                        <ButtonComponent
                            type={"button"}
                            label={t("disable")}
                            pending={disablePending}
                            onClick={disableDialog.open}
                            button_style={ButtonStyle.Danger}
                        />
                    ) : (
                        <ButtonComponent
                            type={"button"}
                            onClick={start}
                            label={t("start")}
                            pending={startPending}
                            button_style={ButtonStyle.Primary}
                        />
                    )}
                </FormGroup>
            </ContentCard>

            <Modal
                modal={setupMFAModal}
                title={t("linkAuthApp")}
                primaryButton={{
                    label: t("iHaveLinkedTheAuthenticatorAppEnableMFANow"),
                    pending: enablePending,
                    onClick: enable
                }}
            >
                <div className={'flex gap-6'}>
                    <img
                        className={'size-40 rounded-lg border border-gray-900/10 p-4'}
                        alt={"qr-code"}
                        src={qrCode}
                    />
                    <div className={'flex flex-col justify-center gap-1'}>
                        <Text>{t("scanTheQrCode")}</Text>
                        <Text
                            size={'sm'}
                            weight={"extralight"}
                            color={"text-gray-500"}
                            dangerously_set_inner_html={true}
                        >
                            {t("useAuthApp")}
                        </Text>
                    </div>
                </div>
            </Modal>

            <DeleteModal
                modal={disableDialog}
                primaryButton={{
                    onClick: disable,
                    pending: disablePending,
                    label: t("disable"),
                }}
            >
                <p>Are you sure you want to disable MFA?</p>
            </DeleteModal>
        </PageContent>
    );
}