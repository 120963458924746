import React, { forwardRef } from 'react';
import * as Headless from '@headlessui/react';
import cn from "classnames";

type InputGroupProps = React.ComponentPropsWithoutRef<'span'> & {
    children: React.ReactNode;
    className?: string;
};

export const InputGroup = (props: InputGroupProps) => {
    const { children, className } = props;

    return (
        <span
            data-slot="control"
            className={cn(
                'relative isolate block',
                '[&_input]:has-[[data-slot=icon]:first-child]:pl-10 [&_input]:has-[[data-slot=icon]:last-child]:pr-10',
                '[&_input]:has-[[data-slot=color]:first-child]:pl-10 [&_input]:has-[[data-slot=color]:last-child]:pr-10',
                '[&_div]:has-[[data-slot=icon]:first-child]:pl-10 [&_div]:has-[[data-slot=icon]:last-child]:pr-10',
                '[&>[data-slot=icon]]:pointer-events-none [&>[data-slot=icon]]:absolute [&>[data-slot=icon]]:top-[9px] [&>[data-slot=icon]]:z-10 [&>[data-slot=icon]]:size-4 ',
                '[&>[data-slot=icon]:first-child]:left-3 [&>[data-slot=icon]:last-child]:right-3 ',
                '[&>[data-slot=icon]]:text-icon-default dark:[&>[data-slot=icon]]:text-icon-inverse-default',
                '[&>[data-slot=color]]:absolute [&>[data-slot=color]]:top-[7px] [&>[data-slot=color]]:z-10 [&>[data-slot=color]]:size-5',
                '[&>[data-slot=color]:first-child]:left-3 [&>[data-slot=color]:last-child]:right-3 ',
                '[&_input]:has-[[data-slot=prefix]:first-child]:pl-8 [&_input]:has-[[data-slot=prefix]:last-child]:pr-8',
                '[&>[data-slot=prefix]]:absolute [&>[data-slot=prefix]]:top-[7px] [&>[data-slot=prefix]]:z-10 [&>[data-slot=prefix]]:size-5 ',
                '[&>[data-slot=prefix]:first-child]:left-3 [&>[data-slot=prefix]:last-child]:right-3 ',
                '[&_input]:has-[[data-slot=suffix]:last-child]:pr-8',
                '[&>[data-slot=suffix]]:absolute [&>[data-slot=suffix]]:top-[7px] [&>[data-slot=suffix]]:z-10 [&>[data-slot=suffix]]:h-5 ',
                '[&>[data-slot=suffix]:first-child]:left-3 [&>[data-slot=suffix]:last-child]:right-3 ',
                className)}
        >
      {children}
    </span>
    );
};

type TextInputProps = {
    className?: string;
    type?: 'email' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time'
    disabled?: boolean
} & Omit<Headless.InputProps, 'as' | 'className'>;

const TextInputComponent = (props: TextInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { className } = props;

    return (
        <span
            data-slot="control"
            className={cn([
                className,
                // Basic layout
                'appearance-none relative block w-full',
                // Background color + shadow applied to inset pseudo-element, so shadow blends with border in light mode
                'before:absolute before:inset-px before:rounded before:bg-inputV2-bg-surface read-only:bg-bg-surface-secondary-default before:shadow-input before:hover:shadow-input-hover',
                // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
                'dark:before:hidden',
                // Focus ring
                'after:pointer-events-none after:absolute after:inset-0 after:rounded after:ring-inset after:ring-transparent after:focus-within:shadow-focus-ring after:focus-within:ring-border-focus',
                // Disabled state
                'before:has-[[data-disabled]]:bg-bg-surface-disabled before:has-[[data-disabled]]:shadow-none',
            ])}
        >
            <Headless.Input
                ref={ref}
                {...props}
                className={cn([
                    // Basic layout
                    'relative block w-full appearance-none rounded px-3 py-1.5',
                    // Typography
                    'font-tracking-normal font-body text-[13px] font-regular leading-5 text-text-default placeholder:text-inputV2-text-placeholder data-[disabled]:text-text-disabled dark:text-text-inverse-default',
                    // Border
                    'border border-inputV2-border data-[hover]:border-inputV2-border-hover dark:border-border-inverse-default dark:data-[hover]:border-border-inverse-hover',
                    // Background color
                    'bg-inputV2-bg-surface hover:bg-inputV2-bg-surface-hover active:bg-inputV2-bg-surface-active  data-[disabled]:bg-bg-surface-disabled dark:bg-white/5',
                    // Hide default focus styles
                    'focus:outline-none',
                    // Invalid state
                    'data-[invalid]:border-border-critical data-[invalid]:data-[hover]:border-border-critical data-[invalid]:dark:border-border-critical data-[invalid]:data-[hover]:dark:border-border-critical',
                    // Disabled state
                    'data-[disabled]:border-transparent dark:data-[hover]:data-[disabled]:border-transparent ',
                    // System icons
                    'text-text-critical-default dark:text-text-inverse-default',
                    className,
                ])}
            />
        </span>
    );
};

export const TextInput = forwardRef(TextInputComponent);