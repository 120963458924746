import React, { useEffect } from "react";
import { depth } from "core/depth";
import ReactDOM from "react-dom";
import { UsePanelProps } from "components/hooks/usePanel";
import FilterPanel, { FilterPanelProps } from "components/panel_v2/panels/FilterPanel";
import PanelHeader from "components/panel_v2/Header";
import PanelFooter from "components/panel_v2/PanelFooter";
import { ButtonProps } from "../buttons/ButtonComponent";

export type PanelTypes = "default" | "filter" | "tabs";

export type PanelSpecificProps = FilterPanelProps;

export interface CommonProps extends UsePanelProps {
    title: string;
    type: PanelTypes,
    children?: any;
    pending?: boolean;
    disabled?: boolean;
    hasChanged?: boolean;
    size?: "medium" | "large";
    buttons?: ButtonProps[];
    actions?: {
        cancel?: VoidFn;
        submit?: any;
        custom?: VoidFn
        delete?: VoidFn;
    };
    label?: {
        submit?: string;
        custom?: string;
        cancel?: string;
        delete?: string;
    };
    onCancel?: any;
    hasFooter?: boolean;
    canEditTitle?: boolean;
    onTitleBlur?: any;
    customZIndex?: any;
}

type AllProps = CommonProps & PanelSpecificProps;

const Panel = React.forwardRef((props: AllProps, ref: React.RefObject<HTMLDivElement>) => {
    const {
        type,
        isActive,
        close,
        actions,
        buttons,
        title,
        pending,
        disabled,
        canEditTitle,
        onTitleBlur,
        customZIndex,
        onCancel,
    } = props;
    const hasFooter = props.hasFooter === undefined ? true : props.hasFooter;
    const handleCancel = () => {
        actions?.cancel ? actions.cancel() : close();

        onCancel && onCancel();
    };

    useEffect(() => {
        function onKeydown(evt: KeyboardEvent) {
            if ("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) {
                handleCancel();
            }
        }

        window.addEventListener("keydown", onKeydown);

        return () => window.removeEventListener("keydown", onKeydown);
    }, [isActive]);

    return ReactDOM.createPortal(
        <div
            className={`flex ${type == 'tabs' ? 'tab-panel' : ''}`}
        >
            <button
                style={{ zIndex: depth.panel }}
                className={'fixed block size-full select-none bg-gray-900 opacity-25'}
                onClick={handleCancel}
                hidden={!isActive}
            />

            <div
                className={`panel_v2 dark:bg-slate-800 ${isActive ? "is-visible" : "not-visible"}`}
                style={{ zIndex: customZIndex ? customZIndex : depth.panel }}
            >
                <PanelHeader
                    title={title}
                    canEditTitle={canEditTitle}
                    onTitleBlur={onTitleBlur}
                    closeAction={handleCancel}
                />
                <div className={"h-full overflow-y-auto px-4 pb-20 pt-4"} ref={ref}>
                    {isActive && (
                        <>
                            {type == "default" && (
                                props.children
                            )}

                            {type == "filter" && (
                                <FilterPanel {...props} />
                            )}
                        </>
                    )}
                </div>
            </div>

            {hasFooter && (
                <PanelFooter
                    actions={actions}
                    buttons={buttons}
                    label={props.label}
                    pending={pending}
                    disabled={disabled}
                    isActive={isActive}
                    customZIndex={customZIndex}
                />
            )}
        </div>, document.getElementById('portal')
    );
});

Panel.displayName = 'Panel';

export default Panel;
