import React from "react";

type Props = {
    children: JSX.Element | JSX.Element[];
    className?: string;
};

export default function PageBodyWithSidebar(props: Props) {
    return (
        <div
            className={`ml-72 w-[calc(100vw-18rem)] max-w-[calc(100vw-18rem)] grow`}
        >
            {props.children}
        </div>
    );
}
