import React from "react";
import { Navigate } from 'react-router-dom';
import useAuth from "@/components/hooks/auth/useAuth";

export default function PrivateRoute(props: any) {
    const { getToken } = useAuth();

    if (!getToken()) {
        console.log("No token, redirect");
        return <Navigate to={"/"}/>;
    }

    return props.children;
}