import cn from "classnames";
import React, { useRef } from "react";

interface ReadOnlyProps {
    children: React.ReactNode;
    className?: string;
}

const ReadOnly = (props: ReadOnlyProps) => {
    const { className } = props;
    const { children } = props;
    const fieldRef = useRef<HTMLDivElement>(null);

    // Select value on focus – mimics behaviour of native input field when focused with read only
    const handleFocus = () => {
        if (fieldRef.current) {
            const range = document.createRange();
            range.selectNodeContents(fieldRef.current);

            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(range);
        }
    };

    return (
        <span
            data-slot="control"
            className={cn([
                className,
                // Basic layout
                'appearance-none relative block w-full',
                // Background color + shadow applied to inset pseudo-element, so shadow blends with border in light mode
                'before:absolute before:inset-px before:rounded before:bg-bg-surface-secondary-default',
                'before:hidden',
                // Focus ring
                'after:pointer-events-none after:absolute after:inset-0 after:rounded after:ring-inset after:ring-transparent after:focus-within:shadow-focus-ring after:focus-within:ring-border-focus',
            ])}
        >
            <div
                {...props}
                tabIndex={0} // Read only needs to be focusable
                ref={fieldRef}
                onFocus={handleFocus}
                className={cn([
                    // Basic layout
                    'relative block w-full appearance-none pointer-events-none rounded px-3 py-1.5 min-h-[34px]',
                    // Typography
                    'antialiased font-tracking-normal font-normal font-body text-[14px] leading-5 text-text-tertiary ',
                    // Border
                    'border border-inputV2-border',
                    // Background color
                    'bg-bg-surface-secondary-default  data-[disabled]:bg-bg-surface-disabled ',
                    // Hide default focus styles
                    'focus:outline-none',
                ])}
            >
                {children}
            </div>
        </span>
    );
};

export default ReadOnly;