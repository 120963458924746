import React, { LegacyRef, useEffect, useRef } from "react";
import cn from "classnames";
import { Table } from "components/table_v2/types";
import useTableOverflow from "components/table_v2/components/useTableOverflow";
import TableSkeleton from "components/table_v2/components/TableSkeleton";
import EmptyState from "components/EmptyState";
import { useTrans } from "components";
import { ButtonProps } from "components/buttons/ButtonComponent";

type Props = {
    table: Table;
    height?: "barebones" | "searchbar" | "tabs"
    rows: JSX.Element[],
    header: JSX.Element;
    pending: boolean;
    className?: string;
    isPaginated?: boolean;
    dragListRef?: LegacyRef<any>;
    canOverflow?: boolean;
    emptyStateAction?: ButtonProps;
};

export default function BarebonesTable(props: Props) {
    const {
        table,
        rows,
        header,
        pending,
        className,
        dragListRef,
        canOverflow,
        emptyStateAction
    } = props;

    const { t } = useTrans();
    const tableRef = useRef(null);
    const wrapperRef = useRef(null);
    const height = props.height ? props.height : "barebones";
    const isPaginated = props.isPaginated ? props.isPaginated : false;
    const isDraggable = !!props.dragListRef;
    const { isOverflowHeight, isOverflowWidth, check } = useTableOverflow(wrapperRef);
    const hasHeader = header.props.items.length > 0;
    const hasRows = rows.length >= 1;
    const hasColumns = table.header.items.length > 1;

    const heightCn = cn(
        { "max-h-[calc(100vh-250px)]": height == "barebones" },
        { "max-h-[calc(100vh-360px)]": height == "searchbar" },
        { "max-h-[calc(100vh-400px)]": height == "tabs" },
        { "max-h-none": canOverflow },
    );

    const tableCn = cn(
        'border border-gray-900/10 rounded-t-lg relative',
        canOverflow ? '' : 'overflow-auto',
        table.has_config ? 'min-h-[30vh]' : '',
        isPaginated ? 'rounded-t-lg border-b-0' : 'rounded-lg',
        { "shadow-table-bottom": isOverflowHeight && !isOverflowWidth },
        { "shadow-table-side": isOverflowWidth && !isOverflowHeight },
        { "shadow-table": isOverflowWidth && isOverflowHeight },
        isPaginated && !isDraggable ? 'rounded-t-lg border-b-0' : '',
        !isPaginated && !isDraggable ? 'rounded-b-lg' : 'rounded-t-lg  border-b-0 rounded-b-none',
        className,
    );

    useEffect(() => check(), []);

    const renderEmptyState = () => {
        if (hasRows) {
            return;
        }

        if (!table.empty_state) {
            return (
                <EmptyState
                    title={t('defaultEmptyStateTile')}
                    description={t('defaultEmptyStateDescription')}
                    action={emptyStateAction}
                />
            );
        }

        return (
            <EmptyState {...table.empty_state} action={emptyStateAction}/>
        );
    };

    return (
        <div className={`flex flex-col bg-white dark:bg-slate-800 ${heightCn}`}>
            <div
                className={tableCn}
                ref={wrapperRef}
                onScroll={check}
            >
                <table className={`table w-full table-auto`} ref={tableRef}>
                    {hasHeader && (
                        <thead
                            className={`sticky top-0 table-header-group w-full border bg-gray-50 dark:border-white dark:bg-slate-800`}
                            style={{ zIndex: 1 }}
                        >
                        {header}
                        </thead>
                    )}

                    <tbody ref={dragListRef}>

                    {pending && (
                        <TableSkeleton
                            columns={hasColumns ? table.header.items.length : 5}
                            rows={rows.length > 1 ? table.rows.length : 10}
                            type={table.row_type ?? "single"}
                        />
                    )}

                    {!pending && hasRows && rows}

                    </tbody>
                </table>

                {renderEmptyState()}
            </div>
        </div>
    );
}
