import React, { useEffect } from "react";
import IntercomSDK, { update } from '@intercom/messenger-js-sdk';
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";
import { useLocation } from "react-router";

export default function Intercom(props: {
    children: React.ReactElement;
}) {
    const { user } = useCurrentAccountUser();
    const { pathname } = useLocation();
    const [hasBeenInitialized, setHasBeenInitialized] = React.useState(false);
    const appId = "u6dyyoj0";
    const isIntercomEnabled = import.meta.env.VITE_INTERCOM_ENABLED === "true";

    useEffect(() => {
        // This is just setup
        if (!isIntercomEnabled) {
            return;
        }

        IntercomSDK({
            app_id: appId
        });

        setHasBeenInitialized(true);
    }, []);

    useEffect(() => {
        // This one is called whenever we receive a user
        if (!user) {
            return;
        }

        const params = {
            name: "",
            email: "",
            app_id: appId,
        };

        params["name"] = user.firstname + " " + user.lastname;
        params["email"] = user.email;

        update(params);
    }, [user]);

    useEffect(() => {
        // This one needs to be called to make sure we call intercom on url updates as we do not have normal page refreshes.
        if (!isIntercomEnabled || !hasBeenInitialized) {
            return;
        }

        update({
            last_request_at: (new Date()).getTime() / 1000,
        });
    }, [pathname, hasBeenInitialized]);

    return props.children;
}
