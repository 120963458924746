import React from "react";
import DisplayFormElement from "components/forms/DisplayFormElements/DisplayFormElement";
import DisplayTagElement from "components/forms/DisplayFormElements/DisplayTagElement";
import DisplayBooleanElement from "components/forms/DisplayFormElements/DisplayBooleanElement";
import DisplayMediaElement from "components/forms/DisplayFormElements/DisplayMediaElement";

export default function DisplayElement(props: {
    type: string,
    value: any;
    label: any;
    underlying_value?: any;
}) {
    const { type, value, label, underlying_value } = props;

    if (type === "select" || type == "multi_select") {
        return (
            <DisplayTagElement label={label} value={value}/>
        );
    }

    if (type === "checkbox") {
        return (
            <DisplayBooleanElement label={label} value={value} underlying_value={underlying_value}/>
        );
    }

    if (type === "media" || type === "media_upload") {
        return (
            <DisplayMediaElement label={label} value={value}/>
        );
    }

    return (
        <DisplayFormElement label={label} value={value}/>
    );
}
