import { JSX, useEffect, useState } from "react";

let timeout: any;

export default function ShowAfterDelay(props: {
    loading: boolean;
    children: JSX.Element;
    delay?: number;
}) {
    const { loading } = props;
    const [isExpired, setIsExpired] = useState(false);
    const delay = props.delay ? props.delay : 500;

    useEffect(() => {
        setIsExpired(false);

        if (loading) {
            timeout = setTimeout(() => setIsExpired(true), delay);
        }

        return () => clearTimeout(timeout);
    }, [loading]);

    // If the delay has expired, and we are still loading, it's time to render the child component.
    if (isExpired && loading) {
        return props.children;
    }

    return null;
}