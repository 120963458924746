import React from "react";

type Props = {
    className?: string;
    children: React.ReactNode;
    onClick?: () => void;
};

export default function TableHeading(props: Props) {

    return (
        <th className={`font-normal p-4 text-left ${props.className ? props.className : ""}`} onClick={props.onClick}>
            {props.children}
        </th>
    );
}