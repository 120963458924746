import { useState } from "react";
import useLocalStorage from "components/hooks/localStorage/useLocalStorage";
import useFetcher from "repositories/base/useFetcher";
import Account from "models/account/Account";

export default function useAccount() {
    const { get } = useLocalStorage();
    const accountId = get("currentAccountId");
    const [account, setAccount] = useState(new Account());
    const { pending, mutate } = useFetcher({
        url: `/accounts/${accountId}`,
        dataSetter: (data: any) => {
            const account = Object.assign(new Account(), data);
            setAccount(account);
        }
    });

    return { account, setAccount, pending, mutate };
}