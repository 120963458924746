import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import { DefaultLocale } from "core/i18n/locales";

export default i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: DefaultLocale,
        lng: localStorage.getItem("language") ?? (navigator.language && navigator.language.substring(0, 2)),
        debug: false,
        backend: {
            loadPath: "https://translations.piggy.eu/business-dashboard/{{lng}}.json"
        },
    });
