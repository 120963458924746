import { show } from '@intercom/messenger-js-sdk';

export default function useIntercom() {

    const open = () => {
        show();
    };

    return {
        open
    };
}