import React, { useEffect } from "react";
import { depth } from "core/depth";
import ReactDOM from "react-dom";
import { PanelContext } from "components/panel_v2/panelV2/context/PanelContext";

type Props = {
    isActive: boolean;
    close: VoidFn;
    onCancel?: VoidFn;
    children: React.ReactNode;
};

export default function PanelV2(props: Props) {
    const {
        isActive,
        close,
        onCancel
    } = props;

    const handleCancel = () => {
        onCancel && onCancel();
    };

    useEffect(() => {
        function onKeydown(evt: KeyboardEvent) {
            if ("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) {
                handleCancel();
                close();
            }
        }

        window.addEventListener("keydown", onKeydown);

        return () => window.removeEventListener("keydown", onKeydown);
    }, [isActive]);

    return ReactDOM.createPortal(
        <PanelContext.Provider value={{
            isActive: isActive,
            close: close,
            open: open,
        }}
        >
            <div
                style={{ zIndex: depth.panel }}
            >
                <div
                    className={`fixed right-[-36rem] z-[inherit] flex h-screen w-full max-w-xl flex-col overflow-hidden rounded-l-lg bg-white shadow-2xl dark:border-l dark:border-l-gray-300 dark:bg-slate-900 ${isActive ? "panel-visible" : "panel-invisible"}`}
                >
                    {props.children}
                </div>

                <button
                    style={{
                        zIndex: depth.panel - 1
                    }}
                    className={"fixed left-0 top-0 size-full bg-black/50"}
                    onClick={close}
                    hidden={!isActive}
                />

            </div>
        </PanelContext.Provider>
        , document.getElementById('portal')
    );
}