import React from "react";
import Icon, { IconType } from "components/icons/Icon";
import Text from "components/typography/Text";
import ButtonComponent, { ButtonProps } from "components/buttons/ButtonComponent";
import { useTrans } from "components/index";

export type Props = {
    icon?: IconType,
    iconBackgroundTailwindColor?: string,
    title?: string,
    description: string,
    action?: ButtonProps
};

export default function EmptyState(props: Props) {
    const {
        icon,
        iconBackgroundTailwindColor = "bg-gray-50",
        title,
        description,
        action
    } = props;
    const { t } = useTrans();

    return (
        <div className="flex h-96 items-center justify-center">
            <div className={"flex max-w-md flex-col items-center justify-center gap-4 p-8"}>
                {icon &&
                    <Icon
                        type={icon}
                        attr={'svg'}
                        className={`size-12 rounded-lg p-3 ${iconBackgroundTailwindColor}`}
                    />
                }

                <div className="flex flex-col">
                    {title &&
                        <Text
                            render_as={'p'}
                            weight={'normal'}
                            align={'center'}
                            color={'text-gray-900'}
                        >
                            {title}
                        </Text>
                    }

                    <Text
                        render_as={'p'}
                        weight={'extralight'}
                        align={'center'}
                        color={'text-gray-500'}
                    >
                        {description}
                    </Text>

                    {action && (
                        <ButtonComponent
                            {...action}
                            label={t("clearFilters")}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}