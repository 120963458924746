import React, { HTMLProps } from "react";

type Props = {
    children: React.ReactNode;
    className?: HTMLProps<HTMLElement>["className"];
};

export default function SubNavigation(props: Props) {

    if (!props.children) {
        return null;
    }

    return (
        <div
            className={`navigation absolute top-10 w-full min-w-56 text-nowrap rounded-lg opacity-[0.97] shadow-lg backdrop-blur-md ${props.className}`}
        >
            <ul className={"flex flex-col py-1 text-left"}>
                {props.children}
            </ul>
        </div>
    );

}