import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import { useTabs, useTrans } from "components";
import TabItem from "components/filters/TabItem";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import Information from "./Information";
import Sidebar from "components/sidebar/v2/Sidebar";
import SidebarHeader from "components/sidebar/v2/SidebarHeader";
import SidebarMediaHeader from "components/sidebar/v2/SidebarMediaHeader";
import SidebarTabsContainer from "components/sidebar/v2/tabs/SidebarTabsContainer";
import SidebarTabItem from "components/sidebar/v2/tabs/SidebarTabItem";
import ContentNextToSidebar from "components/sidebar/ContentNextToSidebar";
import BasePage from "components/containers/BasePage";
import SidebarBackButton from "components/sidebar/v2/SidebarBackButton";
import Settlements from "./Settlements";
import Cumulative from "modules/store/giftcards/settlementReports/Cumulative";

export default function ReportDetails() {
    const { reportId, programId } = useParams<{ reportId: ID, programId: ID }>();
    const { account } = useContext(AccountContext);
    const { t } = useTrans();
    const [report, setReport] = useState<Dict>({});
    const { tabs, activeTab, setActiveTab } = useTabs([
        {
            name: t("information"),
            key: "information",
        },
        {
            name: t("settlements"),
            key: "settlements",
        },
        {
            name: t("cumulative"),
            key: "cumulative-results",
        },
    ]);
    const apiUrl = `/accounts/${account.id}/giftcards/programs/${programId}/settling/reports/${reportId}`;
    const { mutate } = useFetcher({
        url: apiUrl,
        dataSetter: setReport,
    });

    return (
        <BasePage>
            <Sidebar>
                <SidebarHeader>
                    <SidebarBackButton/>
                    <SidebarMediaHeader
                        className={"mt-2"}
                        title={t("report")}
                        subtitle={report.description}
                    >

                    </SidebarMediaHeader>
                </SidebarHeader>
                <SidebarTabsContainer>
                    {tabs.map((tab, index) => <SidebarTabItem
                        key={index}
                        tab={tab}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                    />)}
                </SidebarTabsContainer>
            </Sidebar>

            <ContentNextToSidebar>
                <TabItem active={tabs[0].key == activeTab.key}>
                    <Information onUpdate={mutate}/>
                </TabItem>

                <TabItem active={tabs[1].key == activeTab.key}>
                    <Settlements/>
                </TabItem>

                <TabItem active={tabs[2].key == activeTab.key}>
                    <Cumulative/>
                </TabItem>
            </ContentNextToSidebar>
        </BasePage>
    );
}

