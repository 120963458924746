import React, { CSSProperties, useEffect, useRef, useState } from "react";

export type IconType =
    "academy"
    | "report"
    | "clock"
    | "calculator"
    | "account"
    | "addAlt"
    | "addSquare"
    | "alert"
    | "alignCenter"
    | "alignLeft"
    | "alignRight"
    | "androidPhone"
    | "announcement"
    | "apps"
    | "primaryRetention"
    | "primaryReward"
    | "sparkle"
    | "primaryCake"
    | "loyalGuests"
    | "primaryWebhook"
    | "arrowBack"
    | "arrowBackWhite"
    | "arrowDiagonalRight"
    | "arrowExpand"
    | "arrowRightNew"
    | "arrowUpRight"
    | "arrowUpRightSmaller"
    | "automatedEmailAutomation"
    | "automations"
    | "bell"
    | "billing"
    | "block"
    | "boltMarketingBeta"
    | "brandingPalette"
    | "buildings"
    | "buildingsMint"
    | "calculated"
    | "calendar"
    | "campaigns"
    | "cancelCircle"
    | "cancelCircleDense"
    | "cards"
    | "caretLeft"
    | "chart-bar"
    | "chart-counter"
    | "chart-line"
    | "chart-pie"
    | "chatBubbles"
    | "chatBubblesAlt"
    | "checkCircle"
    | "checkCircleDense"
    | "checkCircleBlue"
    | "checkCircleGreen"
    | "checkmark"
    | "checkmarkGreen"
    | "checkmarkRound"
    | "checkmarkRound2"
    | "checkmarkWhite"
    | "chevronDown"
    | "chevronLeft"
    | "chevronLeftBlue"
    | "chevronLeftGray500"
    | "chevronRight"
    | "chevronRightBlue"
    | "chevronRightGray"
    | "chevronUpAndDown"
    | "close"
    | "codeGroups"
    | "codes"
    | "codesClosed"
    | "coins"
    | "colorWheel"
    | "columns"
    | "computerScreen"
    | "concept"
    | "connect"
    | "contact"
    | "contactAttribute"
    | "contactAttributeAutomation"
    | "contactAttributeTypeFormBlock"
    | "contactPersonGreen"
    | "contactSubscriptions"
    | "contactTotalRound"
    | "contactUpdated"
    | "contactV2"
    | "contactsImported"
    | "content"
    | "copy"
    | "copyFile"
    | "countDelivered"
    | "crossBlack"
    | "crossGrey"
    | "csv"
    | "csvTransparent"
    | "curlyArrowRight"
    | "customAppLoginIcon"
    | "customAppRegisterIcon"
    | "customAttributes"
    | "customField"
    | "danger"
    | "dashArrowForward"
    | "dashboard"
    | "delayAutomation"
    | "delete"
    | "delivered"
    | "devices"
    | "devicesRound"
    | "digitalRewards"
    | "digitalRewardsCodesImported"
    | "dots"
    | "doubleCarret"
    | "doubleChevron"
    | "download"
    | "downloadStoreApp"
    | "dynamicList"
    | "edit"
    | "edit3"
    | "editEmail"
    | "electricBolt"
    | "ellipsis"
    | "email"
    | "emailClosed"
    | "emailClosedBlue"
    | "emailContact"
    | "emailEditorFiles"
    | "emailEditorPersonalisationTokens"
    | "emailMarketingBeta"
    | "emailMarketingMint"
    | "emailInbox"
    | "emailPlane"
    | "emailSend"
    | "euroAverageRound"
    | "euroBlueRound"
    | "euroRound"
    | "exclamation"
    | "export"
    | "externalRewards"
    | "eye"
    | "eyeClose"
    | "eyeFaded"
    | "eyeOff"
    | "eyeOpen"
    | "files"
    | "filledChevronUp"
    | "filledChevronUpActive"
    | "filter"
    | "filter2"
    | "flash"
    | "flash2"
    | "flash_off"
    | "forms"
    | "formsEmptyState"
    | "formsPresetsSignUp"
    | "formsPresetsSubscribe"
    | "formsPresetsUpdate"
    | "formSubmission"
    | "gift"
    | "giftBlue"
    | "giftcardYellow"
    | "giftcards"
    | "giftcardsImported"
    | "grabHandler"
    | "graphMarketingBeta"
    | "greenMan"
    | "hamburgerWhite"
    | "heart"
    | "highestPrice"
    | "homeScreenButtons"
    | "ifElse"
    | "ifThenAutomation"
    | "import"
    | "import2"
    | "importTable"
    | "infinity"
    | "info"
    | "integrations"
    | "internalEmailAutomation"
    | "knowledgeBase"
    | "layoutGrid"
    | "lightning"
    | "lightningBolt"
    | "lightspeed"
    | "linking"
    | "list"
    | "logo"
    | "logoP"
    | "logout"
    | "loyalty"
    | "loyaltyLightningBoltYellow"
    | "loyaltyTransactionsImported"
    | "magento"
    | "management"
    | "marketing"
    | "memberRound"
    | "merge"
    | "message"
    | "minus"
    | "mobileChat"
    | "moneyRound"
    | "narrowcastingRound"
    | "newContact"
    | "nextPathIcon"
    | "oauth"
    | "openWindow"
    | "options"
    | "outstandingBalance"
    | "overviewPreview"
    | "padlock"
    | "paperAirplane"
    | "partyHorn"
    | "partyHornGreen"
    | "pbIcon"
    | "pencil"
    | "periodicCampaigns"
    | "piggyPointsRound"
    | "plannedEmail"
    | "plannedEmailNew"
    | "play"
    | "plugin"
    | "plus"
    | "plusRound"
    | "plusRoundGreen"
    | "plusSquare"
    | "pointTrigger"
    | "questionMark"
    | "quickActionContact"
    | "recurring"
    | "register"
    | "rewardTrophyPurple"
    | "rewards"
    | "rewardsRound"
    | "rocket"
    | "roles"
    | "rotateBack"
    | "rules"
    | "save"
    | "saveIcon"
    | "search"
    | "searchBlue"
    | "settings"
    | "settings2"
    | "settings3"
    | "settling"
    | "shopify"
    | "shops"
    | "slides"
    | "smsCosts"
    | "socials"
    | "sort"
    | "sortAsc"
    | "sortDesc"
    | "spinner"
    | "standardCampaigns"
    | "star"
    | "staticFormBlock"
    | "staticList"
    | "statistics"
    | "stopSign"
    | "store"
    | "storeApp"
    | "stripcards"
    | "subscriptionTypeFormBlock"
    | "subscriptions"
    | "successIcon"
    | "support"
    | "switch"
    | "synchronize"
    | "table"
    | "tableChevronUpDown"
    | "tableGrid"
    | "tag"
    | "tagRound"
    | "templates"
    | "thankYouEmail"
    | "timeline"
    | "tooltip-new"
    | "tooltipExclamation"
    | "totalActivities"
    | "totalClicks"
    | "totalOpens"
    | "totalRecipients"
    | "transactions"
    | "transactionsPointsRound"
    | "transactionsRound"
    | "trash"
    | "triggeredCampaigns"
    | "trophy"
    | "uniqueOpens"
    | "update"
    | "user"
    | "userAdd"
    | "userBlue"
    | "userGroups"
    | "userMail"
    | "userHeart"
    | "users"
    | "verticalDots"
    | "verticalEllipsis"
    | "wallet"
    | "warning"
    | "warningRound"
    | "warningStroke"
    | "warningStrokeYellow"
    | "webhookAutomation"
    | "wix"
    | "wordpress"
    | "tasks"
    | "finger-print"
    | "blank-file"
    | "loyaltyToken"
    | "arrow-top-right"
    | "tiers"
    | "voucher"
    | "greenVoucher"
    | "giftcardBlue"
    | "grab-list"
    | "staticBlockText"
    | "staticBlockImage"
    | "staticBlockSpacing"
    | "staticBlockDivider"
    | "staticBlockButton"
    | "customBlockText"
    | "customBlockNumber"
    | "customBlockDate"
    | "customBlockCheckbox"
    | "customBlockSwitch"
    | "customBlockSlider"
    | "customBlockTextarea"
    | "customBlockSelect"
    | "customBlockMultiselect"
    | "subscriptionTypeMarketing"
    | "subscriptionTypeFunctional";

export type IconRenderTypes = "svg" | "div" | "button" | "svg2";

type Props = {
    type: IconType;
    attr: IconRenderTypes;
    className?: string;
    onClick?: any;
    disabled?: boolean;
    buttonType?: "button" | "submit";
    style?: CSSProperties;
};

/**
 * @deprecated Do not use this.
 * @param props
 * @constructor
 */
export default function Icon(props: Props) {
    const ImportedIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const importIcon = async (): Promise<void> => {
            try {
                ImportedIconRef.current = await import(
                    `../../assets/svg/icons/${props.type}.svg`
                    );
            } catch (err) {
                console.error(
                    `Icon ${props.type} not found in src/assets/svg/icons directory.`
                );
            } finally {
                setLoading(false);
            }
        };
        importIcon();
    }, [props.type]);

    // See if we can refactor this to use only 1 useEffect
    const SvgIcon: React.FC<any> = (props) => {
        const [element, setElement] = useState<JSX.Element | null>(null);

        useEffect(() => {
            let isMounted = true; // Stepper a flag to check if the component is still mounted

            import(`../../assets/svg/icons/${props.type}.svg`).then((res) => {
                const Icon = res.ReactComponent as React.ComponentType<
                    JSX.IntrinsicElements["svg"]
                >;

                // Check if the component is still mounted before updating the state
                if (isMounted) {
                    setElement(<Icon style={props.style}/>);
                }
            });

            // Cleanup function to handle unmounting
            return () => {
                isMounted = false;
            };
        }, [props.type, props.style]);

        return element;
    };

    if (!loading && ImportedIconRef.current) {
        const { current: Module }: any = ImportedIconRef;

        if (props.attr == "div") {
            return (
                <div
                    onClick={props.onClick}
                    className={`icon ${props.className ? props.className : ""}`}
                    style={{
                        ...props.style,
                        maskImage: `url(${Module.default})`,
                        WebkitMaskImage: `url(${Module.default})`,
                    }}
                    draggable={false}
                />
            );
        }

        if (props.attr == "button") {
            return (
                <button
                    type={props.buttonType}
                    disabled={props.disabled}
                    onClick={props.onClick}
                    className={`icon ${props.className ? props.className : ""}`}
                    style={{
                        ...props.style,
                        maskImage: `url(${Module.default})`,
                        WebkitMaskImage: `url(${Module.default})`,
                    }}
                    draggable={false}
                />
            );
        }

        if (props.attr == "svg2") {
            return <SvgIcon/>;
        }

        return (
            <img
                alt={props.type}
                onClick={props.onClick}
                className={`${props.className ? props.className : ""}`}
                style={props.style}
                src={Module.default}
                draggable={false}
            />
        );
    }

    return null;
}
