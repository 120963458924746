import Icon, { IconType } from "components/icons/Icon";
import React from "react";
import { DropdownItem } from "./Dropdown";

export default function DropdownMenuItem(props: DropdownItem) {
    const { icon, style, label, disabled, hidden, media, className } = props;
    const onClick = disabled ? null : props.onClick;
    const iconToUse = props.media ? props.media.value : props.icon;

    return (
        <li hidden={hidden} onClick={onClick} className={'cursor-pointer'}>
            <div
                className={`flex p-2.5 transition-all duration-300 rounded-lg hover:bg-primary-25 ${style ?? ""} ${
                    disabled ? "cursor-not-allowed" : ""
                }`}
            >
                {iconToUse && (
                    <Icon
                        className={className}
                        type={iconToUse as IconType}
                        attr={"div"}
                    />
                )}
                <button
                    className={`text-sm text-gray-900 font-light !whitespace-normal disabled:cursor-not-allowed`}
                    disabled={disabled}
                    type="button"
                >
                    {label}
                </button>
            </div>
        </li>
    );
}
