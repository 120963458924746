import HeaderItem from "components/table_v2/components/header/HeaderItem";
import React from "react";
import { HeaderItemType } from "components/table_v2/types";
import cn from "classnames";

interface Props {
    items: HeaderItemType[];
    onHeaderClick?: (item: HeaderItemType) => void;
    className?: string;

}

export default function DraggableHeader(props: Props) {
    const { items, onHeaderClick } = props;

    const className = cn(
        props.className && props.className,
        'table-cell h-10 group px-4'
    );

    return (
        <tr>
            <th
                className={className}
            >
                <div/>
            </th>
            {items.map(i =>
                <HeaderItem
                    key={`header-${i.value}`}
                    item={i}
                    onClick={i.sortable ? () => onHeaderClick(i) : null}
                >
                    {i.value}
                </HeaderItem>
            )}
        </tr>
    );
}
