import React from 'react';
import { OptionProps } from "react-select";
import MediaComponent from "components/MediaComponent";
import Text from "components/typography/Text";
import MediaType from "components/MediaType";
import cn from "classnames";

export default function Option(props: OptionProps) {
    const data: {
        value?: string
        label?: string
        description?: string
        media?: MediaType;
        disabled?: boolean;
    } = props.data;
    const innerProps = props.innerProps;

    const wrapperCn = cn(
        "my-0.5 flex items-center gap-3 p-2 transition-all duration-200 hover:bg-primary-50",
        data.disabled ? "cursor-not-allowed" : "cursor-pointer",
        props.isSelected ? "bg-primary-100" : "",
    );

    return (
        <div
            {...innerProps}
            className={wrapperCn}
        >
            {data.media && (
                <MediaComponent media={data.media} className={"max-w-12"}/>
            )}

            <div>
                <Text weight={"normal"}>
                    {data.label}
                </Text>
                <Text dangerously_set_inner_html={true} size={"sm"}>
                    {data.description}
                </Text>
            </div>
        </div>
    );
}