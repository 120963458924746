import React from 'react';
import cn from "classnames";

type Props = {
    children: any;
    disabled?: boolean;
    isFocussed: boolean;
    className?: string;
    innerClassName?: string;
};

export default function FormElementBorder(props: Props) {
    const { disabled, children } = props;

    const ringCn = cn(
        props.className ?? "",
        'relative w-full h-full min-h-[3.4rem] transition-all ease-in-out rounded-md border bg-white',
        disabled ? 'cursor-not-allowed ' : '',
        'border-gray-200',
        props.isFocussed ? 'shadow-custom' : '',
    );

    const innerRing = cn(
        props.innerClassName ?? "",
        "relative mb-0 flex w-full h-full",
    );

    return (
        <div className={ringCn}>
            <div className={innerRing}>
                {children}
            </div>
        </div>
    );
}