import React, { useEffect, useState } from 'react';
import { useToast, useTrans } from "components";
import useOnChange from "components/hooks/forms/useOnChange";
import useRouting from "components/hooks/useRouting";
import useHttp from "core/http/useHttp";
import Form from "components/forms/Form";
import AuthContainer from "components/auth/AuthContainer";
import AuthCard from "@/components/auth/AuthCard";
import AuthHeader from "@/components/auth/AuthHeader";
import { Field, FieldGroup, Fieldset, Label } from "@/components/v2/SelectionAndInput/fieldset";
import { Button } from "@/components/v2/Actions/button";
import PasswordInput from "@/components/v2/SelectionAndInput/PasswordInput";

export default function ResetPassword() {
    const { t } = useTrans();
    const { errorToast, successToast } = useToast();
    const http = useHttp();
    const { push, queryParams } = useRouting();
    const [pending, setPending] = useState(false);
    const [payload, setPayload] = useState({
        password: "",
        password_confirmation: "",
        token: queryParams.token
    });
    const onChange = useOnChange(setPayload);

    useEffect(() => {
        http.get(`/reset-password/${queryParams.token}`)
            .catch(() => push('/home'));
    }, []);

    const submit = () => {
        setPending(true);
        http.post(`/reset-password`, payload)
            .then(() => {
                localStorage.clear();
                successToast(t("reset_password_success"));
                push('/');
            })
            .catch(errorToast)
            .finally(() => setPending(false));
    };

    return (
        <AuthContainer>
            <AuthCard>
                <AuthHeader title={t('passwordReset')}/>
                <Form onSubmit={submit} className={"flex flex-col gap-3"}>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label>{t("newPassword")}</Label>
                                <PasswordInput
                                    name={"password"}
                                    placeholder={t("newPassword")}
                                    autoComplete={"new-password"}
                                    value={payload.password}
                                    required={true}
                                    disabled={pending}
                                    onChange={e => onChange(e.target.name, e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>{t("confirmPassword")}</Label>
                                <PasswordInput
                                    name={"password_confirmation"}
                                    placeholder={t("confirmPassword")}
                                    autoComplete={"new-password"}
                                    value={payload.password_confirmation}
                                    required={true}
                                    disabled={pending}
                                    onChange={e => onChange(e.target.name, e.target.value)}
                                />
                            </Field>
                        </FieldGroup>
                    </Fieldset>

                    <Button
                        isLoading={pending}
                        variant={"primary"}
                        disabled={!payload.password || !payload.password_confirmation}
                        type={'submit'}
                    >
                        {t('submit')}
                    </Button>
                </Form>
            </AuthCard>
        </AuthContainer>
    );
}