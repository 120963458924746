import Text from "components/typography/Text";
import React from "react";
import SidebarHeader, { SidebarHeaderProps } from "./SidebarHeader";
import { NavLink } from "react-router";
import { ChevronRight } from "lucide-react";

type SidebarElement = {
    title?: string;
    items: NavigationSidebarItem[];
};

type NavigationSidebarItem = {
    label: string;
    path: string;
    hidden?: boolean;
};

export interface SidebarProps {
    header?: SidebarHeaderProps;
    actions?: any[];
    elements: SidebarElement[];
}

export default function Sidebar(props: SidebarProps) {
    const { elements } = props;

    return (
        <div
            className={`fixed flex h-full w-72 flex-col overflow-y-auto overflow-x-hidden bg-gray-700 pb-12 dark:bg-slate-700`}
        >
            <SidebarHeader {...props.header} actions={props.actions}/>

            <nav className="w-full">
                {elements.map((e, index) => (
                    <div
                        key={e.title ?? index}
                        className={"mx-4 my-6 border-b border-gray-500 last:border-none"}
                    >
                        {e.title && (
                            <div className={"mb-2"}>
                                <Text
                                    size={"sm"}
                                    weight={"light"}
                                    color={"text-gray-400"}
                                    className={"ml-3 opacity-50"}
                                >
                                    {e.title}
                                </Text>
                            </div>
                        )}
                        <ul className={"list-none pb-5"}>
                            {e.items?.map((i) => (
                                <NavLink
                                    key={i.label}
                                    hidden={i.hidden}
                                    className={({ isActive }) => `flex py-2 px-4 my-0.5 justify-between items-center transition-colors duration-300 cursor-pointer rounded-full dark:hover:bg-slate-800 hover:bg-gray-800 ${
                                        isActive
                                            ? "bg-gray-500 dark:bg-slate-500"
                                            : ""
                                    }`}
                                    to={i.path}
                                >
                                    <Text color={"text-gray-25"} className={'!text-[15px]'}>
                                        {i.label}
                                    </Text>

                                    <ChevronRight className={"size-4"}/>
                                    {/*<Icon*/}
                                    {/*    type={"chevronRightGray"}*/}
                                    {/*    attr={"svg"}*/}
                                    {/*/>*/}
                                </NavLink>
                            ))}
                        </ul>
                    </div>
                ))}
            </nav>
        </div>
    );
}
