import { useTrans } from "components";
import { CommandPaletteOption, CommandPaletteOptionType } from "../useCommandPaletteOptions";
import useRouting from "@/components/hooks/useRouting";

export default function useAutomationOptions(): CommandPaletteOption[] {
    const { t } = useTrans();
    const { push } = useRouting();

    const createAutomation = {
        visible: true,
        group: t("create"),
        label: t("createANewAutomation"),
        description: t("automations"),
        type: CommandPaletteOptionType.CREATE,
        handle: () => push("/automation/create"),
        tags: [
            t("automation"),
            "automation",
        ],
    };

    return [
        createAutomation
    ];
}