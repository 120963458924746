import React from 'react';
import Text from "components/typography/Text";
import { useTrans } from "components";

export default function AuthFooter() {
    const { t } = useTrans();

    return (
        <div
            className={`flex w-full items-center justify-center gap-5  py-6`}
        >
            {/* START //  DO NOT REMOVE THIS PART -- IT'S REQUIRED FOR MOLLIE FOR US TO RECEIVE PAYMENTS */}
            <a href={"https://www.piggy.eu/legal-notice"} target={"_blank"}>
                <Text size={"xs"} color="text-gray-400" className={"md:text-sm"}>{t('aboutUs')}</Text>
            </a>
            <a href={"https://www.piggy.eu/legal/general-terms"} target={"_blank"}>
                <Text
                    size={"xs"}
                    color={"text-gray-400"}
                    className={"capitalize md:text-sm"}
                >
                    {t('termsAndConditions')}
                </Text>
            </a>
        </div>
    );
}
