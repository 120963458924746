import React, { useContext, useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import useExports from "components/hooks/exports/useExports";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import useFilterValues from "modules/loyalty/transactions/useFilterValues";
import useModal from "components/hooks/useModal";
import useRouting from "components/hooks/useRouting";
import Export from "components/Export";
import useOnChange from "components/hooks/forms/useOnChange";
import Table from "components/table_v2/Table";
import useAnalytics from "@/analytics/mixPanel/useAnalytics";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import useHttp from "core/http/useHttp";
import FormGroup from "components/forms/FormGroup";
import FormElement from "components/forms/FormElement";
import Modal from "components/modals/types/Modal";
import { defaultTable } from "components/table_v2/types";
import useGiftcardProgram from "@/routing/store/giftcards/useGiftcardProgram";
import useQueryParams from "@/core/useQueryParams";
import usePanel from "components/hooks/usePanel";
import PanelHeader from "components/panel_v2/panelV2/PanelHeader";
import PanelTitleAndCloseButton from "components/panel_v2/panelV2/PanelTitleAndCloseButton";
import PanelBody from "components/panel_v2/panelV2/PanelBody";
import DisplayElement from "components/DisplayElement";
import PanelV2 from "components/panel_v2/panelV2/PanelV2";
import NewSwitch from "@/components/forms/Switch/NewSwitch";

const initialCard = {
    code: "",
};

export default function Cards() {
    const analytics = useAnalytics();
    const { t } = useTrans();
    const { url, push, queryParams } = useRouting();
    const modal = useModal();
    const exportModal = useModal();
    const { errorToast, successToast } = useToast();
    const [createPending, setCreatePending] = useState(false);
    const [createValues, setCreateValues] = useState<Dict>({});
    const onCreateChange = useOnChange(setCreateValues);
    const [modalData, setModalData] = useState(null);
    const { account } = useContext(AccountContext);
    const [emails, setEmails] = useState("");
    const { selectedProgramId } = useGiftcardProgram();
    const [filterValues, setFilterValues, onFilterChange] = useFilterValues();
    const [selectedCardId, setSelectedCardId] = useState(queryParams["card_id"]);
    const { exportPending } = useExports({
        onSuccess: exportModal.close,
    });
    const { addParam, removeParam } = useQueryParams();
    const [emptyState, setEmptyState] = useState(null);
    const [table, setTable] = useState(defaultTable);
    const panel = usePanel();
    const [selectCardPending, setSelectCardPending] = useState(false);
    const apiUrl = `/accounts/${account.id}/giftcards/programs/${selectedProgramId}/cards`;
    const http = useHttp();
    const [card, setCard] = useState<Dict>(initialCard);
    const { pending } = useFetcher({
        url: apiUrl,
        params: filterValues,
        dataSetter: (data: any) => {
            setEmptyState(data.empty_state);
            setTable(data.table);
            setModalData(data.modal);
        },
    });

    useEffect(() => {
        if (selectedCardId) {
            addParam("card_id", selectedCardId);
        }
    }, [selectedCardId]);

    const fetchCard = (selectedCardId: any) => {
        http.get(`${apiUrl}/${selectedCardId}`)
            .then(res => setCard(res.data.data))
            .catch(errorToast)
            .finally(() => setSelectCardPending(false));
    };

    useEffect(() => {
        if (!selectedCardId) {
            return;
        }

        panel.open();
        fetchCard(selectedCardId);
        setSelectCardPending(true);

    }, [selectedCardId]);

    useEffect(() => {
        if (!panel.isActive) {
            setSelectedCardId(null);
            setCard(initialCard);
            removeParam("card_id");
        }
    }, [panel.isActive]);

    const create = () => {
        setCreatePending(true);
        http.post(apiUrl, createValues)
            .then((res) => {
                successToast();
                push(`${url}/cards/${res.data.data.id}`);
            })
            .catch(errorToast)
            .finally(() => setCreatePending(false));
    };

    const updateCard = (key: string, value: any) => {
        setCreatePending(true);
        http.put(apiUrl + "/" + card.id, {
            [key]: value
        })
            .then(() => {
                successToast();
                fetchCard(card.id);
            })
            .catch(errorToast)
            .finally(() => {
                setCreatePending(false);
            });
    };

    useEffect(() => {
        analytics.trackPage("GiftcardsCardsOverview");
    }, []);

    const giftcardCanBeCreated = () => {
        return !(createValues?.amount && createValues?.contact_email && createValues?.shop && createValues?.email);
    };

    return (
        <PageContent
            title={t("cards")}
            actions={
                <>
                    <ButtonComponent
                        button_style={ButtonStyle.Secondary}
                        label={t("export")}
                        icon={"export"}
                        onClick={exportModal.open}
                        pending={exportPending}
                    />
                    <ButtonComponent
                        button_style={ButtonStyle.Primary}
                        label={t("newGiftCard")}
                        icon={"plusRound"}
                        onClick={modal.open}
                        pending={createPending}
                    />
                </>
            }
        >
            <ContentCard emptyState={emptyState}>
                <Table
                    table={table}
                    pending={pending}
                    filterValues={filterValues}
                    onFilterChange={onFilterChange}
                    onRowClick={setSelectedCardId}
                />
            </ContentCard>

            <Export
                modal={exportModal}
                emails={emails}
                setEmails={setEmails}
                filterValues={{
                    ...filterValues,
                    giftcard_program_id: selectedProgramId,
                }}
                type={"giftcards"}
            />

            <Modal
                modal={modal}
                title={t("add")}
                primaryButton={{
                    onClick: create,
                    pending: createPending,
                    disabled: createPending || giftcardCanBeCreated(),
                }}
            >
                <FormGroup modal={true}>
                    {modalData &&
                        modalData.map((e: any) => (
                            <FormElement
                                key={e.id}
                                {...e}
                                value={createValues[e.name] ? createValues[e.name] : ""}
                                onChange={onCreateChange}
                            />
                        ))}
                </FormGroup>
            </Modal>

            <PanelV2
                {...panel}
            >
                <PanelHeader className={"flex items-center justify-between"}>
                    <PanelTitleAndCloseButton title={t("giftcard")}/>
                    <ButtonComponent
                        label={t("more")}
                        button_style={ButtonStyle.Secondary}
                        icon={"arrowDiagonalRight"}
                        onClick={() => push(`/store/giftcards/cards/${card.id}`)}
                    />
                </PanelHeader>
                <PanelBody>
                    <div className={"flex grow flex-col gap-4"}>
                        <FormGroup className={'flex w-full'}>
                            <DisplayElement type={"text"} value={card.type} label={t("type")}/>
                            <DisplayElement type={"text"} value={card.hash} label={t("hash")}/>
                            <DisplayElement type={"text"} value={card.amount} label={t("amount")}/>
                            <DisplayElement type={"text"} value={card.created_at} label={t("createdAt")}/>
                            <DisplayElement type={"text"} value={card.updated_at} label={t("updatedAt")}/>

                            <div>
                                <NewSwitch
                                    name={"upgradeable"}
                                    value={card.upgradeable}
                                    label={t('upgradeable')}
                                    onChange={(e) => updateCard('upgradeable', e.target.checked)}
                                />
                            </div>

                            <div className={"mt-3"}>
                                <NewSwitch
                                    name={"active"}
                                    label={t("active")}
                                    value={card.active}
                                    onChange={e => updateCard("active", e.target.checked)}
                                />
                            </div>

                        </FormGroup>
                    </div>
                </PanelBody>
            </PanelV2>
        </PageContent>
    );
}
