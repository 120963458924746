import { useCallback } from "react";

export type ExportDataType = Dict;

const useExportCSV = () => {
    return useCallback((data: ExportDataType[], fileName = "export.csv") => {
        if (!data || data.length === 0) {
            console.warn("No data to export.");
            return;
        }

        // Extract dynamic headers from the first object in the data array
        const headers = Object.keys(data[0]);

        // Convert data into an array of values based on dynamic keys
        const csvRows = data.map(item => headers.map(key => item[key] ?? "")); // Ensure missing values are empty

        // Combine headers and rows into a CSV formatted string
        const csvString = [headers, ...csvRows]
            .map(row => row.join(";")) // Use semicolon (;) as separator
            .join("\n"); // Join all rows with a newline

        // Stepper a Blob from the CSV string
        const blob = new Blob([csvString], { type: "text/csv" });

        // Generate a download link and trigger the download
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }, []);
};

export default useExportCSV;