import { useTrans } from "components";
import { useContext } from "react";
import { AccountContext } from "context";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";

export default function useApps() {
    const { t } = useTrans();
    const { account } = useContext(AccountContext);
    const base = "/apps";
    const accountUser = useCurrentAccountUser();

    return {
        customerApp: {
            name: "Customer App",
            path: `${base}/customer-app`,
            usesFeature: account.uses_customer_app,
        },
        businessApp: {
            name: "Business App",
            path: `${base}/business-app/devices`,
            hasPermission: accountUser.hasPermission("devices"),
        },
        portalSessions: {
            name: "Portal Sessions",
            path: `${base}/portal-sessions/overview`,
            usesFeature: account.uses_portal_sessions,
        },
        contactsPortal: {
            name: t("contactsPortal"),
            path: `${base}/contacts-portal/overview/information`,
        },
        widget: {
            name: "Widget",
            path: `${base}/widgets`,
        },
        walletPasses: {
            name: "Wallet pass",
            path: `${base}/wallet-passes`,
        },
        integrations: {
            name: t("integrations"),
            path: `${base}/integrations`,
        },
    };
}