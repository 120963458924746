import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import cn from "classnames";

type Props = {
    src: string
    autoplay?: boolean;
    loop?: boolean;
    style?: any;
    className?: string;
    onEvent?: any;
    rendererSettings?: any;
    renderer?: string;
};

export default function LottiePlayer(props: Props) {
    const { src, style, onEvent, rendererSettings, renderer } = props;

    const autoplay = props.autoplay !== undefined ? props.autoplay : true;
    const loop = props.loop !== undefined ? props.loop : true;

    const className = cn(
        props.className ?? ""
    );

    return (
        <div className={className}>
            <Player
                src={src}
                autoplay={autoplay}
                loop={loop}
                style={style}
                onEvent={onEvent}
                rendererSettings={rendererSettings}
                renderer={renderer}
            />
        </div>
    );
}

