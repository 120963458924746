import React from "react";
import cn from "classnames";
import Text from "components/typography/Text";
import MediaComponent from "components/MediaComponent";
import MediaType from "components/MediaType";
import FormElement from "components/forms/FormElement";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";

type Props = {
    name?: string;
    className?: string;
    title: string;
    subTitle?: string;
    sub_title?: string;
    icon?: JSX.Element;
    media?: MediaType;
    has_checkbox?: boolean;
    active?: boolean;
    disabled?: boolean;
    onClick?: any;
    hasButton?: boolean;
    buttonText?: string;
    onButtonClick?: () => void;
    button?: any;
};

export default function SelectableListItem(props: Props) {
    const { icon, title, subTitle, sub_title, active, onButtonClick, buttonText, media, onClick, has_checkbox } = props;
    const hasButton = props.hasButton == undefined ? false : props.hasButton;

    const className = cn(
        props.className && props.className,
        active ? "bg-primary-50 shadow-secondary-hover" : 'hover:bg-primary-25 hover:shadow-primary-hover dark:hover:bg-slate-600'
    );

    return (
        <div
            className={`active:shadow-secondary-hover mb-1 flex w-full justify-between rounded-lg border border-gray-900/10 bg-white px-4 py-3 transition-all dark:bg-slate-400 ${props.button ? "" : "cursor-pointer"} ${className ? className : ''}`}
            onClick={() => onClick && onClick(props)}
        >
            <div className={"flex items-center gap-1"}>
                {has_checkbox && (
                    <div className={"mr-1 flex h-14 w-auto shrink-0 items-center"}>
                        <FormElement type={"checkbox"} name={""} onChange={null} variant={"default"} value={active}/>
                    </div>
                )}

                {icon && (
                    <div className={"mr-4 flex size-14 shrink-0 rounded-full"}>
                        {icon}
                    </div>
                )}

                {media && (
                    <div className={"mr-4 flex size-14 shrink-0 overflow-hidden rounded"}>
                        <MediaComponent className={'w-full'} media={media}/>
                    </div>
                )}

                <div className={"flex flex-col self-center"}>
                    <Text size={"lg"}>{title}</Text>
                    <Text
                        render_as={"p"}
                        color={"text-gray-500"}
                        size={"sm"}
                        weight={"extralight"}
                        dangerously_set_inner_html={true}
                    >
                        {subTitle ? subTitle : sub_title}
                    </Text>
                </div>
            </div>

            {props.button && (
                <div
                    className={"table__column table__boxed-column table__boxed-column--button"}
                >
                    {props.button}
                </div>
            )}

            {hasButton && (
                <div
                    className={"table__column table__boxed-column table__boxed-column--button"}
                >
                    <ButtonComponent
                        button_style={ButtonStyle.Primary}
                        label={buttonText}
                        onClick={onButtonClick}
                    />
                </div>
            )}
        </div>
    );
}
