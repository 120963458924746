import React, { useRef, useState } from "react";
import { format, isValid, toDate } from "date-fns";
import { Popover, PopoverAnchor, PopoverContent, PopoverTrigger } from "@/components/v2/Overlay/popover";
import { InputGroup, TextInput } from "@/components/v2/SelectionAndInput/textInput";
import { Calendar } from "@/components/v2/SelectionAndInput/calendar";
import { Calendar as CalendarIcon } from "lucide-react";

interface DateInputProps {
    name?: string;
    placeholder?: string;
    value?: string;
    onChange?: (date: Date) => void;
    onBlur?: (date: Date) => void;
    disabled?: boolean;
}

function formatDate(date: Date): string {
    return date && isValid(date) ? format(date, "dd MMMM yyyy") : "";
}

export function parseInputToDate(input: string): Date | null {
    return toDate(input);
}

export default function DateInput(props: DateInputProps) {
    const { value, name, onChange, onBlur, placeholder, disabled = false } = props;
    const [date, setDate] = useState<Date | undefined>(value ? parseInputToDate(value) ?? undefined : undefined);
    const [inputValue, setInputValue] = useState(value ? formatDate(date) : "");
    const [open, setOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const setFocusOnInput = () => {
        setTimeout(() => {
            inputRef.current?.focus();
            // Move cursor to end.
            inputRef.current.selectionStart = inputRef.current.value.length;
            inputRef.current.selectionEnd = inputRef.current.value.length;
        }, 50);
    };

    return (
        <Popover
            open={open}
            onOpenChange={(isOpen) => {
                setOpen(isOpen);

                if (!isOpen) {
                    setFocusOnInput();
                }
            }}
        >
            <PopoverTrigger asChild id="date-input-group">
                <PopoverAnchor>
                    <InputGroup>
                        <CalendarIcon data-slot="icon" className="text-icon-default"/>
                        <TextInput
                            ref={inputRef}
                            name={name}
                            data-slot="input"
                            placeholder={placeholder}
                            value={inputValue}
                            onChange={e => {
                                // This only gets triggered when people actually type

                                setInputValue(e.target.value);
                                const newDate = parseInputToDate(e.target.value);

                                setDate(newDate);
                                onChange(newDate);
                            }}
                            onBlur={e => {
                                if (onBlur) {
                                    const newDate = parseInputToDate(e.target.value);
                                    onBlur(newDate);
                                }
                            }}
                            disabled={disabled}
                        />
                    </InputGroup>
                </PopoverAnchor>
            </PopoverTrigger>

            <PopoverContent className="w-auto" align="start">
                <Calendar
                    mode="single"
                    selected={date}
                    onSelect={(selectedDate) => {
                        setDate(selectedDate);
                        onChange(selectedDate);

                        setInputValue(formatDate(selectedDate));
                        setOpen(false);
                        setFocusOnInput();
                    }}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    );
}
