import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import { useTabs, useToast, useTrans } from "components";
import TabItem from "components/filters/TabItem";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import useFilterValues from "modules/loyalty/transactions/useFilterValues";
import useHttp from "core/http/useHttp";
import Transactions from "modules/store/giftcards/cards/Transactions";
import Recipients from "modules/store/giftcards/cards/Recipients";
import Information from "modules/store/giftcards/cards/Information";
import Sidebar from "components/sidebar/v2/Sidebar";
import SidebarHeader from "components/sidebar/v2/SidebarHeader";
import SidebarMediaHeader from "components/sidebar/v2/SidebarMediaHeader";
import SidebarTabsContainer from "components/sidebar/v2/tabs/SidebarTabsContainer";
import SidebarTabItem from "components/sidebar/v2/tabs/SidebarTabItem";
import ContentNextToSidebar from "components/sidebar/ContentNextToSidebar";
import BasePage from "components/containers/BasePage";
import SidebarBackButton from "components/sidebar/v2/SidebarBackButton";

export default function CardsDetails() {
    const { cardId } = useParams<{ id: ID, cardId: ID }>();
    const { account } = useContext(AccountContext);
    const [filterValues, setFilterValues, onFilterChange] = useFilterValues();
    const { t } = useTrans();
    const http = useHttp();
    const { errorToast } = useToast();
    const [updatePending, setUpdatePending] = useState(false);
    const apiUrl = `/accounts/${account.id}/giftcards/cards/${cardId}`;
    const [card, setCard] = useState<Dict>({});
    const { tabs, activeTab, setActiveTab } = useTabs([
        {
            name: t("info"),
            key: "info",
        },
        {
            name: t("transactions"),
            key: "transactions",
        },
        {
            name: t("recipients"),
            key: "recipients",
        },
    ]);
    useFetcher({
        url: apiUrl,
        params: filterValues,
        dataSetter: setCard,
    });

    return (
        <BasePage>
            <Sidebar>
                <SidebarHeader>
                    <SidebarBackButton/>
                    <SidebarMediaHeader
                        className={"mt-4"}
                        title={card.hash}
                        subtitle={card.description}
                    >

                    </SidebarMediaHeader>
                </SidebarHeader>
                <SidebarTabsContainer>
                    {tabs.map((tab, index) => <SidebarTabItem
                        key={index}
                        tab={tab}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                    />)}
                </SidebarTabsContainer>
            </Sidebar>

            <ContentNextToSidebar>
                <TabItem active={tabs[0].key == activeTab.key}>
                    <Information/>
                </TabItem>

                <TabItem active={tabs[1].key == activeTab.key}>
                    <Transactions/>
                </TabItem>

                <TabItem active={tabs[2].key == activeTab.key}>
                    <Recipients/>
                </TabItem>
            </ContentNextToSidebar>
        </BasePage>
    );
}

