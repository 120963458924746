import React from 'react';
import Text from "components/typography/Text";

type Props = {
    percentage: number;
    title: string;
    description?: string;
    color: string
    className: string
};

export default function UsagesProgress(props: Props) {
    const isCompleted = props.percentage === 100;

    return (
        <div className={'rounded-lg bg-gray-50 p-3'}>
            <div className={"mb-2 flex justify-between"}>
                <Text size={"sm"} weight={"normal"} color={"text-gray-700"}>{props.title}</Text>
                <Text size={"sm"} weight={"extralight"} color={isCompleted ? "text-primary-600" : "text-gray-500"}>{props.description}</Text>
            </div>
            <div className={'relative h-[4px] w-full overflow-hidden rounded-full bg-white'}>
                <div
                    className={`h-[4px] ${props.color} rounded-full transition-all`}
                    style={{ width: `${props.percentage}%` }}
                />
            </div>
        </div>
    );
}