import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "context";
import OpenOrderModal from "modules/home/OpenOrderModal";
import { useToast, useTrans } from "components";
import useHttp from "core/http/useHttp";
import Usages from "modules/home/blocks/Usages";
import PlanMeeting from "modules/home/blocks/PlanMeeting";
import QuickActions from "modules/home/blocks/QuickActions";
import useAnalytics from "@/analytics/mixPanel/useAnalytics";
import Banner from "components/banner/Banner";
import Text from "components/typography/Text";
import useRouting from "components/hooks/useRouting";
import BlogPosts from "modules/home/blocks/BlogPosts";
import DefaultHomePage from "modules/home/blocks/DefaultHomePage";
import useFetcher from "repositories/base/useFetcher";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";

export default function Overview() {
    const analytics = useAnalytics();
    const { account } = useContext(AccountContext);
    const { user } = useCurrentAccountUser();
    const { successToast, errorToast } = useToast();
    const { t } = useTrans();
    const http = useHttp();
    const { replace } = useRouting();
    const [data, setData] = useState({
        title: "",
        content: [],
    });

    const { mutate } = useFetcher({
        url: `/accounts/${account.id}/configuration/home`,
        dataSetter: setData
    });

    useEffect(() => {
        analytics.trackPage('DashboardHomeOverview');
    }, []);

    useEffect(() => {
        const redirectElement = data.content.find((element: any) => element.type === 'redirect');
        if (redirectElement) {
            replace(redirectElement.url);
        }
    }, [data.content]);

    const resendVerify = () => {
        http.post(`/verify/send`, { email: user.email })
            .then(() => successToast(t('resendVerificationEmail')))
            .catch(errorToast);
    };

    const ElementMapper = (element: any) => {
        if (element.type == "blog-posts") {
            return <BlogPosts {...element} />;
        }

        if (element.type == "usages") {
            return <Usages {...element} />;
        }

        if (element.type == "plan-meeting") {
            return <PlanMeeting {...element} />;
        }

        if (element.type == "quick-actions") {
            return <QuickActions {...element} />;
        }

        if (element.type == "default-homepage") {
            return <DefaultHomePage {...element} mutate={mutate}/>;
        }
    };

    return (
        <div className={"container mx-auto mt-6 px-2 lg:mt-12"}>
            <div className={`flex flex-col`}>
                {!user.isVerified && (
                    <div className={'mb-7 w-full'}>
                        <Banner
                            style={"warning"}
                            body={t('pleaseVerifyUser')}
                            onButtonClick={resendVerify}
                            buttonLabel={t('clickToResendVerification')}
                        />
                    </div>
                )}

                <div className={"flex flex-col pb-8"}>
                    <Text size={"xl"} className={"mb-5 ml-2 dark:text-white"}>{data.title}</Text>
                    <div className={"flex w-full flex-col gap-4"}>
                        {data.content
                            .filter((element: any) => element.type !== "redirect")
                            .map((element: any) => (
                                <ElementMapper key={element.type} {...element} />
                            ))}
                    </div>
                </div>
                <OpenOrderModal/>
            </div>
        </div>
    );
}
