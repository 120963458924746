import * as React from "react";
import { Loader2 } from 'lucide-react';
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const spinnerVariants = cva(
    "disabled:icon-disabled",
    {
        variants: {
            size: {
                small: "size-4",
                large: "size-10",
            },
        },
        defaultVariants: {
            size: "small", // Set a default size
        },
    }
);

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof spinnerVariants> {
    /** Size of the spinner */
    size?: 'small' | 'large';
    /** Accurately explain the state of the requested action. For example, “Loading”, “Submitting”, “Processing” */
    accessibilityLabel?: string;
    /** Indicates if the parent is focusable */
    hasFocusableParent?: boolean;
}

const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>(
    ({ size = "small", accessibilityLabel = "Loading", hasFocusableParent = false, ...props }, ref) => {
        return (
            <div
                ref={ref}
                role="status"
                aria-live={hasFocusableParent ? "polite" : "assertive"}
                aria-label={accessibilityLabel}
                {...props}
                className={cn(size, )}
            >
                <Loader2 className={cn(spinnerVariants({ size }), "animate-spin")} />
            </div>
        );
    }
);

Spinner.displayName = "Spinner";

export { Spinner, spinnerVariants };
