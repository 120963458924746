import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import MixPanelContext from "@/analytics/mixPanel/MixPanelContext";

export default function WithMixPanel(props: any) {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (isInitialized) {
            return;
        }

        const token = import.meta.env.MIXPANEL_PROJECT_TOKEN;

        if (!token) {
            return;
        }

        mixpanel.init(token, {
            api_host: "https://api-eu.mixpanel.com",
            persistence: 'localStorage',
            // batch_requests: false,
            // debug: true
        });

        setIsInitialized(true);
    }, []);

    const trackPage = (pageName: string, additionalProps?: any) => {
        trackEvent(
            'PageViewed',
            {
                'page_name': pageName,
                ...additionalProps
            }
        );
    };

    const trackFlow = (domain: string, stepName: string) => {
        trackEvent('FlowInteraction', { 'domain': domain, 'step': stepName });
    };

    const trackEvent = (eventName: string, props: any = {}) => {
        isInitialized && mixpanel.track(eventName, props);
    };

    const identify = (id: string) => {
        isInitialized && mixpanel.identify(id);
    };

    const reset = () => {
        isInitialized && mixpanel.reset();
    };

    return (
        <MixPanelContext.Provider value={{
            trackPage: trackPage,
            trackFlow: trackFlow,
            trackEvent: trackEvent,
            identifyUser: identify,
            forgetUser: reset
        }}
        >
            {props.children}
        </MixPanelContext.Provider>
    );
}