import React from "react";
import Text from "components/typography/Text";
import useBackendAction from "components/hooks/useBackendAction";
import MediaType from "components/MediaType";
import Icon from "components/icons/Icon";

type Props = {
    id: string;
    title: string;
    description: string;
    media: MediaType;
    width: string;
    action: any;
};

export default function BlogPost(props: Props) {
    const { handleAction } = useBackendAction(props);

    return (
        <div
            id={props.id}
            className={"active:shadow-primary-active group cursor-pointer rounded-lg border border-gray-50 bg-gray-50 p-4 transition-all hover:border-primary-600 dark:bg-slate-700"}
            onClick={handleAction}
            style={{
                gridColumn: `span ${props.width}`
            }}
        >
            <div id={props.id} className={"flex w-full items-center justify-between"}>
                <div className={"flex flex-col"}>
                    <Text size={"sm"} weight={"normal"} color={"text-gray-700 dark:text-white"} className={"font-display group-hover:text-gray-900"}>
                        {props.title}
                    </Text>
                    <Text size={"xs"} weight={"light"} color={"text-gray-600 dark:text-white"} className={"group-hover:text-gray-900"}>
                        {props.description}
                    </Text>
                </div>
                <Icon
                    type={"dashArrowForward"}
                    attr={"div"}
                    className={"size-5 bg-gray-300 opacity-50 transition-all group-hover:opacity-100"}
                />
            </div>
        </div>
    );
}