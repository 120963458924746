import { createContext } from "react";

type NavigationContextType = {
    activeParent: any;
    setActiveParent: any;
    onMouseEnter: any;
    onMainNavClick: any;
    isNavigationFocussed: boolean;
    setNavigationFocussed: any;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    isUserDropdownOpen: boolean,
    setUserDropdownOpen: (open: boolean) => void,
};

export const NavigationContext = createContext<Partial<NavigationContextType>>({});
