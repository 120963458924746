import React, { useContext, useState } from "react";
import { useToast, useTrans } from "components";
import { AccountContext } from "context";
import useFetcher from "repositories/base/useFetcher";
import PageContent from "components/containers/PageContent";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import useRouting from "components/hooks/useRouting";
import useHttp from "core/http/useHttp";
import SimpleStatisticsCard from "modules/store/giftcards/programs/statistics/components/SimpleStatisticsCard";
import useGiftcardProgram from "@/routing/store/giftcards/useGiftcardProgram";
import useExportCSV from "@/modules/store/giftcards/programs/statistics/components/useExportCSV";
import DropdownButton from "@/components/buttons/DropdownButton";

export default function Statistics() {
    const { t } = useTrans();
    const { account } = useContext(AccountContext);
    const { selectedProgramId } = useGiftcardProgram();
    const { errorToast } = useToast();
    const exportCSV = useExportCSV();
    const [data, setData] = useState({
        [selectedProgramId]: [],
    });
    const[shopOptions, setShopOptions] = useState(null);
    const [updatePending, setUpdatePending] = useState(false);
    const { push } = useRouting();
    const http = useHttp();
    const apiUrl = `/accounts/${account.id}/giftcards/programs/${selectedProgramId}/statistics`;
    const { mutate, pending } = useFetcher({
        url: apiUrl,
        dataSetter: (res: any) => {
            setData({
                [selectedProgramId]: res.metric_ids,
            });
            setShopOptions(res.shop_options);
        }
    });

    const download = () => {
        setUpdatePending(true);
        http.get(`${apiUrl}/download`)
            .then((res) => {
                console.log(res.data.data);
                exportCSV(res.data.data, 'filename');
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    const recalculate = () => {
        setUpdatePending(true);
        http.post(`${apiUrl}/recalculate`)
            .then(() => mutate())
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    if (!data) {
        return null;
    }

    return (
        <PageContent
            title={t("statistics")}
            actions={[
                <DropdownButton
                    key={"dropdown"}
                    label={t("actions")}
                    position={'right'}
                    items={[
                        [
                            {
                                style: "primary",
                                label: t('recalculate'),
                                onClick: recalculate,
                                icon: "calculator"
                            },
                        ],
                    ]}
                />,
                <ButtonComponent
                    key={"branding"}
                    button_style={ButtonStyle.Secondary}
                    onClick={() => push('/data/reports')}
                    label={t("advanced")}
                    icon={"arrowUpRightSmaller"}
                    pending={updatePending}
                />,
            ]}
        >
            <div className={'flex flex-wrap gap-2'}>
                {data[selectedProgramId]?.map((metricId: any) => (
                    <div
                        key={metricId}
                        className={'w-full md:w-[calc(50%-0.25rem)]'}
                    >
                        <SimpleStatisticsCard programId={selectedProgramId} metricId={metricId} shopOptions={shopOptions}/>
                    </div>
                ))}
            </div>
        </PageContent>
    );
}
