import React, { useState } from "react";
import cn from "classnames";
import { BaseProps } from "@piggy-loyalty/ui-components/lib/form/types/base";
import Label from "@piggy-loyalty/ui-components/lib/components/Label";
import FormElementWrapper from "@piggy-loyalty/ui-components/lib/components/elements/FormElementWrapper";
import PiggyTooltipV2 from "components/ui/PiggyTooltipV2";

export interface SliderProps extends BaseProps {
    maxAmount?: number;
    minAmount?: number;
    step?: number;
    onMouseUp?: any;
}

const Slider = React.forwardRef((props: SliderProps, ref: any) => {
    const {
        label,
        className,
        disabled,
        onChange,
        name,
        value,
        defaultValue,
        onBlur,
        bottom_helper,
        top_helper,
        styling,
        status,
        maxAmount,
        minAmount,
        step,
        onMouseUp,
    } = props;
    const [internalValue, setInternalValue] = useState(value ?? defaultValue);
    const inputCn = cn(
        "w-full h-2 bg-gray-100 rounded-lg appearance-none cursor-pointer",
    );

    return (
        <FormElementWrapper
            name={"form-slider"}
            className={className}
            isDisabled={disabled}
            bottom_helper={bottom_helper}
            top_helper={top_helper}
        >
            {label && (
                <Label
                    status={status}
                    styling={styling}
                    disabled={disabled}
                >
                    {label}
                </Label>
            )}

            <PiggyTooltipV2
                id={`range-${name}-tooltip`}
                content={internalValue?.toString()}
                place={"top"}
            >
                <input
                    id={`range-${name}`}
                    data-tip={true}
                    data-for={`range-${name}-tooltip`}
                    type="range"
                    min={minAmount}
                    max={maxAmount}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={(e) => {
                        setInternalValue(e.target.value);
                        onChange && onChange(name, e.target.value);
                    }}
                    onBlur={(e) => {
                        onBlur && onBlur(name, e.target.value);
                    }}
                    onMouseUp={(e: any) => {
                        onMouseUp && onMouseUp(name, e.target.value);
                    }}
                    step={step}
                    className={inputCn}
                    ref={ref}
                />
            </PiggyTooltipV2>
        </FormElementWrapper>
    );
});

Slider.displayName = "Slider";

export default Slider;