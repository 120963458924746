import React from "react";
import cn from "classnames";
import { SizeTypes, TextRenderTypes, WeightTypes } from "./typographyTypes";

export interface TextProps {
    children?: any;
    className?: string;
    hidden?: boolean;
    tooltip?: string;
    size?: SizeTypes;
    weight?: WeightTypes;
    color?: string;
    render_as?: TextRenderTypes;
    dangerously_set_inner_html?: boolean;
    onClick?: () => void;
    align?: string;
    text?: string;
}

export default function Text(props: TextProps) {
    const {
        size = 'md',
        weight = 'regular',
        render_as = "p",
        color = "text-gray-900",
        dangerously_set_inner_html = false,
        onClick,
        align
    } = props;

    const sizeCn = cn(
        { "text-xs": size == "xs" },
        { "text-sm": size == "sm" },
        { "text-base": size == "md" },
        { "text-lg": size == "lg" },
        { "text-xl": size == "xl" },
        { "text-2xl": size == "2xl" },
        { "text-3xl": size == "3xl" },
        { "text-4xl": size == "4xl" },
        { "text-5xl": size == "5xl" },
    );

    const weightCn = cn(
        { "font-thin": weight == "thin" }, //100
        { "font-extralight": weight == "extralight" }, //200
        { "font-light": weight == "light" }, //300
        { "font-normal": weight == "normal" }, //400
        { "font-medium": weight == "medium" }, //500
        { "font-semibold": weight == "semibold" }, //600
        { "font-bold": weight == "bold" }, //700
        { "font-extrabold": weight == "extrabold" }, //800
        { "font-black": weight == "black" }, //900
    );

    const alignCn = cn(
        { "text-center": align == "center" },
        { "text-left": align == "left" },
        { "text-right": align == "right" },
    );

    const classNames = cn(
        sizeCn,
        weightCn,
        color,
        alignCn,
        onClick ? "hover:cursor-pointer hover:underline" : "",
        props.className ?? "",
    );

    if (render_as == "label") {
        return (
            <label
                hidden={props.hidden}
                className={classNames}
                dangerouslySetInnerHTML={dangerously_set_inner_html ? { __html: props.children } : null}
                onClick={onClick}
            >
                {dangerously_set_inner_html ? undefined : props.children}
            </label>
        );
    }

    if (render_as == "span") {
        return (
            <span
                hidden={props.hidden}
                className={classNames}
                dangerouslySetInnerHTML={dangerously_set_inner_html ? { __html: props.children } : null}
                onClick={onClick}
            >
                {dangerously_set_inner_html ? undefined : props.children}
            </span>
        );
    }

    return (
        <p
            hidden={props.hidden}
            className={classNames}
            dangerouslySetInnerHTML={dangerously_set_inner_html ? { __html: props.children } : null}
            onClick={onClick}
        >
            {dangerously_set_inner_html ? undefined : props.children}
        </p>
    );
}