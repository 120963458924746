import React, { ReactNode } from "react";

export default function ContentNextToSidebar(props: {
    children: ReactNode;
}) {
    return (
        <div className={"lg:ml-72"}>
            {props.children}
        </div>
    );
}