import React from "react";
import { animated, useSpring } from "react-spring";
import useRouting from "@/components/hooks/useRouting";
import Text from "@/components/typography/Text";
import Anchor from "@/components/html/Anchor";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

type Props = {
    title: string;
    subtitle?: string;
    actions?: any;
    hasBack?: boolean;
    onBack?: any;
    backTitle?: string;
    children?: any;
};

export default function PageContent(props: Props) {
    const { actions, subtitle } = props;
    const appear = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 0,
    });

    return (
        <animated.section
            className={`px-2 pb-8 lg:px-4`}
            style={appear}
        >
            <div className="ml-12 flex h-24 items-center lg:ml-0">
                <div className={"flex flex-col truncate pl-2"}>
                    <Title {...props}/>

                    {subtitle && (
                        <p className={"font-light truncate text-base text-gray-500"}>
                            {subtitle}
                        </p>
                    )}
                </div>
                <div className={`right-0 top-0 ml-auto flex items-center gap-2`}>
                    {Array.isArray(actions) ? actions.map((action: any) => action) : actions}
                </div>
            </div>

            {props.children}
        </animated.section>
    );
}

function Title(props: {
    title: string,
    hasBack?: boolean,
    backTitle?: string,
    onBack?: any;
}) {
    const { title, hasBack, backTitle, onBack } = props;
    const { goBack } = useRouting();

    const Title = () => (
        <Text
            size={"xl"}
            className={"truncate dark:text-white "}
        >
            {title}
        </Text>
    );

    if (hasBack) {
        if (backTitle) {
            return (
                <div className={'flex items-center gap-3'}>
                    <Anchor
                        className={"flex max-h-24 cursor-pointer"}
                        onClick={onBack ? onBack : () => goBack()}
                    >
                        <Text
                            size={"xl"}
                            weight={"light"}
                            className={"truncate hover:cursor-pointer hover:underline dark:text-white"}
                            color={'text-gray-400'}
                        >
                            {backTitle}
                        </Text>

                    </Anchor>

                    <ChevronRightIcon className={`size-4 fill-gray-400`}/>

                    <Title/>
                </div>
            );
        }

        return (
            <Anchor
                className={"flex max-h-24 cursor-pointer"}
                onClick={onBack ? onBack : () => goBack()}
            >
                <ChevronLeftIcon className={`m-auto mr-3 size-4 fill-gray-400`}/>
                <Title/>
            </Anchor>
        );
    }

    return <Title/>;
}