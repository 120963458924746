import React from "react";
import { AccountContext } from "context";
import useAccount from "@/components/hooks/account/UseAccount";
import LoadingScreen from "@/components/LoadingScreen";
import SelectedAccountRoutes from "@/routing/accounts/SelectedAccountRoutes";

export default function AccountRoutes() {
    const accountContext = useAccount();

    if (!accountContext.account.id) {
        return <LoadingScreen/>;
    }

    return (
        <AccountContext.Provider value={accountContext}>
            <SelectedAccountRoutes/>
        </AccountContext.Provider>
    );
}
