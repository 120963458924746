import React from "react";
import { useTrans } from "components";
import useRouting from "components/hooks/useRouting";
import AuthContainer from "components/auth/AuthContainer";
import useAuth from "components/hooks/auth/useAuth";
import AuthFooter from "@/components/auth/AuthFooter";
import AuthHeader from "@/components/auth/AuthHeader";
import AuthCard from "@/components/auth/AuthCard";
import { Button } from "@/components/v2/Actions/button";

export default function InactiveAccount() {
    const { t } = useTrans();
    const { logout } = useAuth();
    const { push } = useRouting();

    return (
        <AuthContainer>
            <AuthCard>
                <AuthHeader
                    title={t('thisAccountIsInactive')}
                    description={t('accountIsInactiveDescription')}
                />
                <div className={"flex flex-col gap-2"}>
                    <Button
                        variant={'primary'}
                        onClick={() => push('/start')}
                    >
                        {t('selectDifferentAccount')}
                    </Button>
                    <Button
                        variant={'default'}
                        onClick={() => logout()}
                    >
                        {t('logout')}
                    </Button>
                </div>
            </AuthCard>
            <AuthFooter/>
        </AuthContainer>
    );
}
