import User from "models/user/User";

export type Permission =
    "admin"
    | "contacts"
    | "loyalty"
    | "stripcards"
    | "giftcards"
    | "prepaid"
    | "marketing"
    | "shops"
    | "narrowcasting"
    | "devices";

export default class AccountUser {
    user = new User();
    role: {
        id: number;
        name: string;
    } = null;
    permissions: Partial<Record<Permission, boolean>> = {};
    isAdmin: boolean; // This determines whether someone is admin for their account. Not for the global system.

    hasPermission(permission: Permission) {

        if (this.permissions.admin === true) {
            return true;
        }

        return this.permissions[permission] === true;
    }
}