import React, { useContext, useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import { AccountContext } from "context";
import useFetcher from "repositories/base/useFetcher";
import { useParams } from "react-router";
import Table from "components/table_v2/Table";
import useFilterValues from "modules/loyalty/transactions/useFilterValues";
import Panel from "components/panel_v2/Panel";
import usePanel from "components/hooks/usePanel";
import useHttp from "core/http/useHttp";
import FormElement from "components/forms/FormElement";
import FormGroup from "components/forms/FormGroup";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import useExports from "components/hooks/exports/useExports";
import useModal from "components/hooks/useModal";
import Export from "components/Export";
import { defaultTable } from "components/table_v2/types";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";

export default function Cumulative() {
    const { t } = useTrans();
    const { account } = useContext(AccountContext);
    const { errorToast } = useToast();
    const [table, setTable] = useState(defaultTable);
    const panel = usePanel();
    const http = useHttp();
    const [selectedId, setSelectedId] = useState(null);
    const [emails, setEmails] = useState("");
    const exportModal = useModal();
    const { exportPending } = useExports({
        onSuccess: exportModal.close
    });
    const [pending, setPending] = useState(null);
    const [result, setResult] = useState(null);
    const [filterValues, setFilterValues, onFilterChange] = useFilterValues();
    const { reportId, programId } = useParams<{ reportId: ID, programId: ID }>();
    const apiUrl = `/accounts/${account.id}/giftcards/programs/${programId}/settling/reports/${reportId}/cumulative-results`;
    const { mutate } = useFetcher({
        url: apiUrl,
        params: filterValues,
        dataSetter: setTable,
    });

    useEffect(() => {
        if (selectedId) {
            setPending(true);
            http.get(`${apiUrl}/${selectedId}`)
                .then(res => setResult(res.data.data))
                .catch(errorToast)
                .finally(() => setPending(false));
        }
    }, [selectedId]);

    useEffect(() => {
        if (selectedId) {
            panel.open();
        } else {
            panel.close();
        }
    }, [selectedId]);

    const update = (key: string, value: any) => {
        setPending(true);
        http.put(`${apiUrl}/${selectedId}`, {
            [key]: value
        })
            .then(() => mutate())
            .catch(errorToast)
            .finally(() => setPending(false));
    };

    return (
        <PageContent
            title={t("cumulative")}
        >
            <ContentCard>
                <Table
                    table={table}
                    onFilterChange={onFilterChange}
                    filterValues={filterValues}
                    onRowClick={setSelectedId}
                    actions={
                        <ButtonComponent
                            button_style={ButtonStyle.Secondary}
                            label={t("export")}
                            pending={exportPending}
                            onClick={exportModal.open}
                        />
                    }
                />

                <Panel
                    {...panel}
                    close={() => {
                        setSelectedId(null);
                    }}
                    title={t("result")}
                    type={'default'}
                    pending={false}
                    label={{ custom: t("verify"), cancel: t("close") }}
                >
                    {result && (
                        <FormGroup>
                            <FormElement
                                defaultValue={result.status}
                                label={t("status")}
                                type={"select"}
                                name={"status"}
                                sort_type={"none"}
                                options={result.status_options}
                                onBlur={update}
                            />
                        </FormGroup>
                    )}
                </Panel>


                <Export
                    modal={exportModal}
                    emails={emails}
                    setEmails={setEmails}
                    filterValues={{
                        settlements_report_id: reportId
                    }}
                    type={"cumulative_settlements_report"}
                />
            </ContentCard>
        </PageContent>
    );
}