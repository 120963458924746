import React from "react";
import FormGroup from "components/forms/FormGroup";
import { useTrans } from "components";
import DisplayFormElement from "components/forms/DisplayFormElements/DisplayFormElement";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";

export default function Settings() {
    const { t } = useTrans();
    const { user } = useCurrentAccountUser();

    return (
        <PageContent title={t("settings")}>
            <ContentCard>
                <FormGroup>
                    <DisplayFormElement label={t('dateFormat')} value={user.preferred_date_format}/>
                    <DisplayFormElement label={t('dateTimeFormat')} value={user.preferred_date_time_format}/>
                </FormGroup>
            </ContentCard>
        </PageContent>
    );
}