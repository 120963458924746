import React, { ReactNode } from "react";
import { ButtonProps } from "components/buttons/ButtonComponent";
import { useTrans } from "components";
import { ModalProps } from "components/hooks/useModal";
import BaseModal from "@/components/modals/components/BaseModal";
import ModalWidth from "@/components/modals/components/ModalWidth";
import { Button } from "@/components/v2/Actions/button";

type Props = {
    modal: ModalProps;
    title?: string;
    primaryButton?: ButtonProps;
    secondaryButton?: ButtonProps;
    onClose?: any;
    depth?: any;
    children?: ReactNode;
};

export default function DeleteModal(props: Props) {
    const { t } = useTrans();
    const { modal, primaryButton ,secondaryButton } = props;

    return (
        <BaseModal modal={modal}>
            <ModalWidth supports={{
                sm: true,
                md: true,
            }}
            >
                <div className={"flex items-start gap-4 p-6"}>
                    <div className={"rounded-full bg-red-600/10 p-4"}>
                        <svg className={"size-12"} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Size=Default">
                                <path
                                    id="Vector"
                                    d="M9.0002 9.66667V5.66667M9.0002 12.3268V12.3335M1.66687 5.94467L5.94487 1.66667H12.0555L16.3335 5.94467V12.0553L12.0555 16.3333H5.94487L1.66687 12.0553V5.94467Z"
                                    stroke="#E02731"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                        </svg>
                    </div>
                    <div>
                        <div>
                            <h1 className={"font-normal text-xl"}>{props.title ?? t("areYouSure")}</h1>
                        </div>
                        <div className={"mt-4"}>
                            {props.children ?? <p className={"font-light"}>{t("areYouSureDelete")}</p>}
                        </div>
                    </div>
                </div>
                <div
                    className={'flex justify-end gap-2 rounded-lg border-x border-b bg-gray-50 px-6 py-4 dark:bg-slate-600'}
                >
                    <Button
                        variant={"default"}
                        type="button"
                        hidden={secondaryButton?.hidden}
                        onClick={() => {
                            secondaryButton?.onClick && secondaryButton?.onClick();
                            modal.close && modal.close();
                        }}
                        isLoading={secondaryButton?.pending}
                        disabled={secondaryButton?.disabled ?? false}
                    >
                        {secondaryButton?.label ? secondaryButton.label : t('cancel')}
                    </Button>

                    <Button
                        variant={"default"}
                        type="button"
                        tone={"critical"}
                        hidden={primaryButton?.hidden}
                        onClick={primaryButton?.onClick}
                        isLoading={primaryButton?.pending}
                        disabled={primaryButton?.disabled ?? false}
                    >
                        {primaryButton?.label ? primaryButton.label : t('delete')}
                    </Button>

                </div>
            </ModalWidth>
        </BaseModal>
    );
}