import React from "react";
import { Text } from "@/components/v2/Typography/text";

export function OptionLayoutWithDescription(props: {
    label: string;
    description?: string;
}) {
    const { label, description } = props;

    return (
        <div className="flex flex-col justify-center text-left">
            <Text variant={"bodyMd"} tone={"base"} as={"p"}>{label}</Text>
            {description && (
                <Text
                    variant={"bodyXs"}
                    tone={"secondary"}
                    as={"p"}
                >
                    {description}
                </Text>
            )}
        </div>
    );
}