import React, { useContext, useState } from "react";
import { useToast, useTrans } from "components";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import useGiftcardProgram from "@/routing/store/giftcards/useGiftcardProgram";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import useHttp from "core/http/useHttp";
import Form from "components/forms/Form";
import GroupsSearch from "modules/store/giftcards/programs/components/GroupsSearch";

export default function Groups() {
    const { t } = useTrans();
    const { errorToast, successToast } = useToast();
    const { selectedProgramId } = useGiftcardProgram();
    const { account } = useContext(AccountContext);
    const http = useHttp();
    const [selectedGroups, setSelectedGroups] = useState(null);
    const [updatePending, setUpdatePending] = useState(false);
    const apiUrl = `/accounts/${account.id}/giftcards/programs/${selectedProgramId}/groups`;
    const { pending, mutate } = useFetcher({
        url: apiUrl,
        dataSetter: setSelectedGroups
    });

    const update = () => {
        setUpdatePending(true);
        http.put(apiUrl, {
            selected_groups: selectedGroups
        })
            .then(res => {
                mutate();
                successToast();
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    return (
        <PageContent
            title={t("groups")}
        >
            <ContentCard>
                <Form className={"flex max-w-md flex-col gap-3"} onSubmit={update}>

                    <GroupsSearch
                        name={"groups"}
                        value={selectedGroups}
                        onChange={(name, value) => setSelectedGroups(value)}
                    />

                    <ButtonComponent
                        className={"max-w-24"}
                        type={"submit"}
                        label={t('submit')}
                        pending={updatePending}
                        button_style={ButtonStyle.Primary}
                    />
                </Form>
            </ContentCard>
        </PageContent>
    );
}
