import React, {useState} from "react";
import {ChevronDown} from "lucide-react";
import {cn} from "@/lib/utils";
import {Popover, PopoverContent, PopoverTrigger,} from "@/components/v2/Overlay/popover";
import {useTrans} from "components";

export interface Option {
    value: string;
    label: string;
    disabled?: boolean;
    description?: string;
    icon?: React.ReactNode;
}

export interface Props {
    getDisplayValue: (option: string) => React.ReactNode;
    noValuesSelectedMessage?: string;
    selected: string;
    disabled?: boolean;
    className?: string;
    children: React.ReactNode;
}

export function Select(props: Props) {
    const { t } = useTrans();
    const {
        getDisplayValue,
        selected,
        noValuesSelectedMessage,
        disabled = false,
        className,
    } = props;
    const [open, setOpen] = useState(false);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <span
                    data-slot="control"
                    className={cn([
                        className,
                        // Basic layout
                        'relative block w-full',
                        // Background color + shadow applied to inset pseudo-element, so shadow blends with border in light mode
                        'before:absolute before:inset-px before:rounded before:bg-inputV2-bg-surface before:shadow',
                        // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
                        'dark:before:hidden',
                        // Focus ring
                        'after:pointer-events-none after:absolute after:inset-0 after:rounded after:ring-inset after:ring-transparent after:focus-within:shadow-focus-ring after:focus-within:ring-border-focus',
                        // Disabled state
                        'before:has-[[data-disabled]]:bg-bg-surface-disabled before:has-[[data-disabled]]:shadow-none',
                    ])}
                >
                    <button
                        type="button"
                        role="combobox"
                        aria-expanded={open}
                        className={cn([
                            // Basic layout
                            'relative flex flex-row items-center w-full rounded px-3 py-1.5',
                            // Options (multi-select)
                            '[&_optgroup]:font-semibold',
                            // Typography
                            'font-tracking-normal text-left font-body text-[13px] font-regular leading-5 text-text-default placeholder:text-inputV2-text-placeholder data-[disabled]:text-text-disabled dark:text-text-inverse-default',
                            // Border
                            'border border-inputV2-border data-[hover]:border-inputV2-border-hover dark:border-border-inverse-default dark:data-[hover]:border-border-inverse-hover',
                            // Background color
                            'bg-inputV2-bg-surface hover:bg-inputV2-bg-surface-hover active:bg-inputV2-bg-surface-active data-[disabled]:bg-bg-surface-disabled dark:bg-white/5',
                            // Hide default focus styles
                            'focus:outline-none',
                            // Invalid state
                            'data-[invalid]:border-border-critical data-[invalid]:data-[hover]:border-border-critical data-[invalid]:dark:border-border-critical data-[invalid]:data-[hover]:dark:border-border-critical',
                            // Disabled state
                            'data-[disabled]:border-transparent dark:data-[hover]:data-[disabled]:border-transparent ',
                        ])}
                        disabled={disabled}
                    >
                        <div className="relative w-full items-center">
                            <div className={'flex flex-wrap gap-1.5'}>
                                {selected == undefined ? (
                                    <span className={"text-inputV2-text-placeholder"}>
                                        {noValuesSelectedMessage ?? t("select")}
                                    </span>
                                ) : (
                                    getDisplayValue(selected)
                                )}
                            </div>
                        </div>
                        <ChevronDown className={"size-4 shrink-0 translate-x-0.5 "} color="var(--neutral-500)"/>
                    </button>
                </span>
            </PopoverTrigger>
            <PopoverContent className={cn("p-0 w-[--radix-popover-trigger-width]", className)}>
                {props.children}
            </PopoverContent>
        </Popover>
    );
}