import React from "react";
import { NavLink } from "react-router";
import useSidebar from "../hooks/useSidebar";

type Props = {
    name: string;
    path: string;
    hidden?: boolean;
};

export default function SidebarRouteSubItem(props: Props) {
    const { setOpen } = useSidebar();

    return (
        <NavLink
            to={props.path}
            hidden={props.hidden}
            className={({ isActive }) => `border-l btn-outline rounded-r-full border-l-white/10 my-1.5 transition-all duration-300 hover:bg-gray-800 block min-w-52 lg:w-full dark:hover:bg-white/10 ${
                isActive ? `dark:bg-white/10 border-l-white/80` : ""
            }`}
            onClick={() => setOpen(false)}
        >
            <div
                className={"flex items-center justify-between px-4 py-1.5"}
            >
                <p className={"text-sm text-gray-25"}>{props.name}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="hidden size-4 stroke-gray-100 lg:block"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                </svg>
            </div>
        </NavLink>
    );
}
