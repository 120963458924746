import React, { useContext, useEffect, useState } from "react";
import { useTabs, useTrans } from "components";
import TabItem from "components/filters/TabItem";
import Sidebar from "components/sidebar/v2/Sidebar";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import SidebarHeader from "components/sidebar/v2/SidebarHeader";
import SidebarTabsContainer from "components/sidebar/v2/tabs/SidebarTabsContainer";
import SidebarTabItem from "components/sidebar/v2/tabs/SidebarTabItem";
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import ContentNextToSidebar from "components/sidebar/ContentNextToSidebar";
import BasePage from "components/containers/BasePage";
import SimpleHeader from "components/sidebar/v2/SimpleHeader";
import Cards from "modules/store/stripcards/programs/Cards";
import Transactions from "modules/store/stripcards/programs/Transactions";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import { StripcardProgram } from "@/routing/store/stripcards/StripcardRoutes";
import cn from "classnames";
import Settings from "modules/store/stripcards/programs/Settings";
import Groups from "modules/store/stripcards/programs/Groups";
import useStripcardProgram from "@/routing/store/stripcards/useStripcardProgram";
import { depth } from "core/depth";

export default function Overview() {
    const { t } = useTrans();
    const { selectedProgram, setSelectedProgram } = useStripcardProgram();
    const { account } = useContext(AccountContext);
    const [programs, setPrograms] = useState<StripcardProgram[]>([]);
    const apiUrl = `/accounts/${account.id}/stripcards/programs`;
    useFetcher({
        url: apiUrl,
        dataSetter: setPrograms
    });
    const { tabs, activeTab, setActiveTab } = useTabs([
        {
            name: t("cards"),
            key: "cards",
        },
        {
            name: t("transactions"),
            key: "transactions",
        },
        {
            name: t("groups"),
            key: "groups",
        },
        {
            name: t("settings"),
            key: "settings",
        },
    ]);

    useEffect(() => {
        if (selectedProgram) {
            return;
        }

        if (programs.length > 0) {
            setSelectedProgram(programs[0]);
        }
    }, [programs.length]);

    return (
        <BasePage>
            <Sidebar>
                <SidebarHeader>
                    <SimpleHeader title={t("stripcards")}/>

                    <p className={"ml-1 mt-4 text-xs text-gray-200"}>{t('selectedProgram')}</p>
                    <Listbox value={selectedProgram} onChange={setSelectedProgram}>
                        <ListboxButton
                            className={cn(
                                'relative block w-full rounded-lg bg-white py-1.5 pr-8 pl-3 text-left text-sm/6 shadow',
                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25'
                            )}
                        >
                            {selectedProgram?.name}
                            <ChevronDownIcon
                                className="group pointer-events-none absolute right-2.5 top-2.5 size-4 fill-black/60"
                                aria-hidden="true"
                            />
                        </ListboxButton>

                        <ListboxOptions
                            anchor="bottom"
                            transition
                            style={{ zIndex: depth.navigation - 1 }}
                            className={cn(
                                'w-[var(--button-width)] rounded-xl border border-black/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                                'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                            )}
                        >
                            {programs.map((program) => (
                                <ListboxOption
                                    key={program.name}
                                    value={program}
                                    className="group flex cursor-pointer select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-black/10"
                                >
                                    <CheckIcon className="invisible size-4 fill-black group-data-[selected]:visible"/>
                                    <div className="text-sm/6">{program.name}</div>
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </Listbox>

                </SidebarHeader>
                <SidebarTabsContainer>
                    {tabs.map((tab, index) => <SidebarTabItem
                        key={index}
                        tab={tab}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                    />)}
                </SidebarTabsContainer>
            </Sidebar>

            {selectedProgram && (
                <ContentNextToSidebar>
                    <TabItem active={activeTab.key == tabs[0].key}>
                        <Cards/>
                    </TabItem>

                    <TabItem active={activeTab.key == tabs[1].key}>
                        <Transactions/>
                    </TabItem>

                    <TabItem active={activeTab.key == tabs[2].key}>
                        <Groups/>
                    </TabItem>

                    <TabItem active={activeTab.key == tabs[3].key}>
                        <Settings/>
                    </TabItem>
                </ContentNextToSidebar>
            )}
        </BasePage>
    );
}