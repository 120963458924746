import React, { useState } from 'react';
import { useTrans } from "components";
import { Label } from "@/components/v2/SelectionAndInput/fieldset";
import { ComboBox } from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/ComboBox";
import { SingleSelectOption } from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/SingleSelectOption";
import { normalizeToArray } from "core";
import DisplayValueWithThumbnail
    from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/helpers/DisplayValueWithThumbnail";
import { ComboBoxDropdown } from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/ComboBoxDropdown";
import {
    OptionLayoutWithThumbnail
} from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/helpers/OptionLayoutWithThumbnail";
import MediaType from "@/components/MediaType";

interface Option {
    value: string;
    label: string;
    media: MediaType
}

export type Props = {
    loading: boolean;
    onChange: (name: string, value: string) => void;
    values: Dict;
    countries: Option[];
};

export default function CountryComboBox(props: Props) {
    const { loading, onChange, values, countries } = props;
    const { t } = useTrans();
    const [searchCountry, setSearchCountry] = useState('');

    const handleSelectCountry = (selected: string) => {
        if (values.country === selected) {
            onChange('country', null);
        } else {
            onChange('country', selected);
        }
    };
    return (
        <div>
            <Label>{t("country")}</Label>
            <ComboBox
                loading={loading}
                search={searchCountry}
                setSearch={setSearchCountry}
                noValuesSelectedMessage={t('country')}
                filterFn={(value, search) => {
                    if (!value) {
                        return 1;
                    }

                    const country = countries.find((option) => option.value === value);

                    if (!country) {
                        return 0;
                    }

                    return country.label.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
                }}
                selected={normalizeToArray(values.country)}
                getDisplayValue={selected => (
                    <DisplayValueWithThumbnail
                        label={countries.find((countryOption: Option) => countryOption.value === selected)?.label}
                        thumbnail={countries.find((countryOption: any) => countryOption.value === selected)?.media.value}
                    />
                )}
            >
                <ComboBoxDropdown noResultsMessage={t("countryNotFound")}>
                    {countries.map((country: any) => (
                        <SingleSelectOption
                            key={country.value}
                            value={country.value}
                            onSelect={handleSelectCountry}
                            selected={normalizeToArray(values.country).includes(country.label)}
                        >
                            <OptionLayoutWithThumbnail
                                label={country.label}
                                thumbnail={country.media.value}
                            />
                        </SingleSelectOption>
                    ))}
                </ComboBoxDropdown>
            </ComboBox>
        </div>
    );
}