import React from "react";
import UIComponent, { UIComponentMapperProps } from "components/UIComponent";
import { FormRowType } from "components/ui/FormRow";
import Text from "components/typography/Text";
import InfoBanner from "components/banner/InfoBanner";
import { SizeTypes } from "components/typography/typographyTypes";

type Props = {
    label?: string;
    label_size?: SizeTypes;
    description?: string;
    rows: FormRowType[];
    banner: any;
    data: any;
    onClick: any;
    onChange: any;
    onBlur: any;
};

export default function FormGroupUI(props: Props & UIComponentMapperProps) {
    const { rows, label, label_size, description, data, onClick, onChange, onBlur, banner } = props;

    const getElements = () => {
        return rows.map((row, i) => (
                <UIComponent
                    key={`form-group-row-${i}`}
                    element={row}
                    data={data}
                    onClick={onClick}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            )
        );
    };

    return (
        <div
            className={"w-full space-y-4 border-b border-gray-200 py-6 first:pt-0 last:border-0 last:pb-0"}
            onClick={onClick ? onClick : null}
        >
            <div className="block" hidden={!label && !description}>
                {label && (
                    <Text size={label_size ?? "lg"} render_as={"span"}>
                        {label}
                    </Text>
                )}
                {description && (
                    <Text size={"sm"} color={"text-gray-500"} render_as={"p"}>
                        {description}
                    </Text>
                )}
            </div>
            <div className="">
                {banner ? (
                    <div className={"flex justify-between gap-5"}>
                        <div className={"max-w-screen-sm grow"}>
                            {getElements()}
                        </div>
                        <div className={"w-2/5 max-w-[540px]"}>
                            <InfoBanner {...banner} />
                        </div>
                    </div>
                ) : <div className={"max-w-screen-sm"}>
                    {getElements()}
                </div>
                }
            </div>
        </div>
    );
}