import * as React from "react";
import useRouting from "@/components/hooks/useRouting";
import { Text } from "@/components/v2/Typography/text";
import Anchor from "@/components/html/Anchor";
import { ArrowLeft, ArrowRight } from "lucide-react";
import cn from "classnames";

export function PageHeader({
                               className,
                               children,
                           }: {
    className?: string;
    children?: React.ReactNode;
}) {

    return (
        <div className={cn("flex flex-row w-full py-5 align-top text-nowrap", className)}>
            {children}
        </div>
    );
}

export function PageTitle(props: {
    title: string,
    subtitle?: string,
    hasBack?: boolean,
    backTitle?: string,
    onBack?: any;
}) {
    const { title, subtitle, hasBack, backTitle, onBack, } = props;
    const { goBack } = useRouting();

    const Title = () => (
        <div className={"w-full space-y-0.5"}>
            <Text
                variant={"headingLg"}
                as={"h1"}
                className={"py-0.5"}
            >
                {title}
            </Text>
            {subtitle && (
                <Text as={"p"} variant={"bodyMd"} tone={"secondary"}>
                    {subtitle}
                </Text>
            )}
        </div>
    );

    if (hasBack) {
        if (backTitle) {
            return (
                <div className={' flex w-full  gap-3'}>
                    <Anchor
                        className={"peer flex cursor-pointer  text-text-tertiary hover:text-text-default hover:underline"}
                        onClick={onBack ? onBack : () => goBack()}
                    >

                        <Text
                            variant={"headingLg"}
                            as={"h1"}
                            className={"py-0.5"}
                        >
                            {backTitle}
                        </Text>

                    </Anchor>

                    <ArrowRight
                        className={`mt-1.5 size-4 text-text-tertiary`}
                    />

                    <Title/>
                </div>
            );
        }

        return (
            <div className={'flex flex-col '}>
                <Anchor
                    className={"flex cursor-pointer"}
                    onClick={onBack ? onBack : () => goBack()}
                >
                    <ArrowLeft className={`fill-icon-secondary m-auto mr-3 size-4`}/>
                    <Title/>
                </Anchor>
            </div>
        );
    }

    return <Title/>;
}