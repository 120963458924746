import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import { useTabs, useTrans } from "components";
import TabItem from "components/filters/TabItem";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import Information from "./Information";
import Sidebar from "components/sidebar/v2/Sidebar";
import SidebarHeader from "components/sidebar/v2/SidebarHeader";
import SidebarMediaHeader from "components/sidebar/v2/SidebarMediaHeader";
import SidebarTabsContainer from "components/sidebar/v2/tabs/SidebarTabsContainer";
import SidebarTabItem from "components/sidebar/v2/tabs/SidebarTabItem";
import ContentNextToSidebar from "components/sidebar/ContentNextToSidebar";
import BasePage from "components/containers/BasePage";
import SidebarBackButton from "components/sidebar/v2/SidebarBackButton";
import Settlements from "./Settlements";

export default function CardsDetails() {
    const { transactionId } = useParams<{ transactionId: ID }>();
    const { account } = useContext(AccountContext);
    const { t } = useTrans();
    const apiUrl = `/accounts/${account.id}/giftcards/transactions/${transactionId}`;
    const [transaction, setTransaction] = useState<Dict>({});
    useFetcher({
        url: apiUrl,
        dataSetter: setTransaction,
    });
    const { tabs, activeTab, setActiveTab } = useTabs([
        {
            name: t("info"),
            key: "info",
        },
        {
            name: t("settlements"),
            key: "settlements",
        },
    ]);

    return (
        <BasePage>
            <Sidebar>
                <SidebarHeader>
                    <SidebarBackButton/>
                    <SidebarMediaHeader
                        className={"mt-4"}
                        title={transaction.hash}
                        subtitle={transaction.description}
                    >

                    </SidebarMediaHeader>
                </SidebarHeader>
                <SidebarTabsContainer>
                    {tabs.map((tab, index) => <SidebarTabItem
                        key={index}
                        tab={tab}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                    />)}
                </SidebarTabsContainer>
            </Sidebar>

            <ContentNextToSidebar>
                <TabItem active={tabs[0].key == activeTab.key}>
                    <Information/>
                </TabItem>

                <TabItem active={tabs[1].key == activeTab.key}>
                    <Settlements/>
                </TabItem>

            </ContentNextToSidebar>
        </BasePage>
    );
}

