import React from 'react';
import { animated, useSpring } from "react-spring";
import ImageComponent from "@/components/image/ImageComponent";
import Text from "@/components/typography/Text";
import { useTrans } from "components";
import ButtonComponent, { ButtonStyle } from "@/components/buttons/ButtonComponent";
import { X } from "lucide-react";

export type Props = {
    logo: any;
    color: string;
    onClick: (prev: any) => void;
};

export default function MobilePreview(props: Props) {
    const { logo, color, onClick } = props;
    const { t } = useTrans();
    const appear = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 300,
    });

    return (
        <animated.div style={appear}>
            <div
                className={` h-fit flex-col rounded-lg border border-gray-900/10 bg-white sm:hidden`}
            >
                <div className={'flex items-center justify-between border-b border-b-gray-900/10 p-4'}>
                    <div className='flex grow items-center justify-between gap-1'>
                        <Text weight={'semibold'}>{t('preview')}</Text>
                        <X className={'size-4'} onClick={onClick}/>

                    </div>
                </div>
                <div className={'p-6'}>
                    <div className={'grid grid-cols-2'}>
                        <div>
                            <p className={'font-extralight text-sm text-gray-500'}>{t('toEmail')}</p>
                            <p className={'font-extralight text-sm text-gray-500'}>{t("subject")}</p>
                        </div>
                        <div>
                            <Text size={'sm'} weight={'extralight'}>john.doe@email.com</Text>
                            <Text size={'sm'} weight={'extralight'}>{t("helloWorld")}</Text>
                        </div>
                    </div>
                </div>
                <div className={'rounded-b-lg p-6'} style={{ backgroundColor: color }}>
                    <div className={'flex flex-col gap-6 bg-white p-4'}>
                        {logo ? (
                            <ImageComponent className={'mx-auto size-12 rounded object-cover'} src={logo}/>
                        ) : (
                            <Text size={"3xl"} weight={'extrabold'} className={'text-center'}>
                                {t("yourLogo")}
                            </Text>
                        )}
                        <div
                            className="h-5 rounded-lg bg-gradient-to-l from-gray-900/10"
                        />
                        <div className={'flex flex-col gap-1'}>
                            <div className="h-3 w-1/2 rounded-lg bg-gradient-to-l from-gray-900/10"/>
                            <div className="h-3 rounded-lg bg-gradient-to-l from-gray-900/10"/>
                            <div className="h-3 w-3/4 rounded-lg bg-gradient-to-l from-gray-900/10"/>
                        </div>
                        <div
                            className="h-6 w-1/2 rounded-lg bg-gray-200"
                            style={{ backgroundColor: color }}
                        />
                    </div>

                    <ButtonComponent
                        button_style={ButtonStyle.Secondary}
                        label={t("back")}
                        onClick={onClick}
                        className={'w-full sm:hidden'}
                    />
                </div>
            </div>
        </animated.div>
    );
}