import Text from "components/typography/Text";
import React, { useEffect, useRef, useState } from "react";
import FormElementWrapper from "@piggy-loyalty/ui-components/lib/components/elements/FormElementWrapper";
import cn from "classnames";
import { useTrans } from "components";
import FormElementBorder from "components/forms/FormElementBorder";
import ImageComponent from "components/image/ImageComponent";

type Props = {
    colors?: string[];
    name: string;
    id?: any;
    label?: string;
    value?: any;
    onChange?: (name: string, value: any) => void;
    onBlur?: (name: string, value: any) => void;
    onClick?: (name: string, value: any) => void;
    pending?: boolean;
    disabled?: boolean;
    required?: boolean;
    tooltip?: string;
    bottom_helper?: string;
    top_helper?: string;
};

export default function MediaUpload(props: Props) {
    const {
        name,
        value,
        label,
        onChange,
        disabled,
        bottom_helper,
        top_helper,
        pending
    } = props;
    const { t } = useTrans();
    const pickerRef = useRef(null);
    const [isFocussed, setFocussed] = useState(false);
    const hiddenFileInput = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [dataUrl, setDataUrl] = useState("");

    useEffect(() => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = e => setDataUrl(e.target.result.toString());
            reader.readAsDataURL(selectedFile);
        }
    }, [selectedFile]);

    const handleClick = () => hiddenFileInput.current.click();

    const disabledState = cn({ "cursor-not-allowed opacity-50": disabled });

    const buttonCn = cn(
        "rounded-md bg-none border m-2.5 px-4 h-8",
        disabledState
    );

    const labelCn = cn(
        "transition-colors truncate",
    );

    return (
        <FormElementWrapper
            name={"form-checkbox"}
            className={""}
            isDisabled={disabled}
            bottom_helper={bottom_helper}
            top_helper={top_helper}
        >
            <FormElementBorder {...props} isFocussed={isFocussed}>
                <div className={"flex w-full items-center justify-between"}>
                    <div
                        className={"flex w-full items-center gap-4"}
                        ref={pickerRef}
                    >
                        <ImageComponent
                            className={'m-1.5 mr-0 size-12 shrink-0 rounded-md border border-[#DED9ED] bg-[#F8F8F8FF] object-cover'}
                            src={dataUrl ? dataUrl : value}
                        />

                        {label && (
                            <div>
                                <label className={labelCn}>
                                    {label}
                                </label>
                                <Text size={"xs"}>{selectedFile?.name}</Text>
                            </div>
                        )}

                    </div>

                    <input
                        accept="image/*"
                        type="file"
                        onChange={event => {
                            const file = event.target.files[0];
                            onChange(name, file);
                            setSelectedFile(file);
                        }}
                        ref={hiddenFileInput}
                        className={'hidden'}
                        disabled={disabled || pending}
                    />

                    <button
                        className={buttonCn}
                        type="button"
                        onClick={disabled ? null : handleClick}
                        disabled={disabled || pending}
                        hidden={disabled}
                    >
                        {t('choose')}
                    </button>
                </div>
            </FormElementBorder>
        </FormElementWrapper>
    );
}