import React from 'react';
import { useTrans } from "components";
import ButtonComponent, { ButtonProps, ButtonStyle } from "components/buttons/ButtonComponent";
import { depth } from "core/depth";

type Props = {
    classNames?: string;
    pending?: boolean;
    disabled?: boolean;
    actions: {
        cancel?: () => void;
        submit?: () => void;
        custom?: () => void;
        delete?: () => void;
    };
    buttons?: ButtonProps[];
    label?: {
        submit?: string;
        cancel?: string;
        delete?: string;
        custom?: string
    },
    isActive?: any;
    customZIndex?: number;
};

export default function PanelFooter(props: Props) {
    const { actions, pending, label, isActive, classNames, buttons, customZIndex } = props;
    const { t } = useTrans();

    return (
        <div
            style={{ zIndex: customZIndex ? customZIndex : depth.panel }}
            className={`panel-footer dark:bg-slate-700 ${isActive ? 'is-active' : 'not-active'} ${classNames ? classNames : ''}`}
        >
            {actions?.submit && (
                <ButtonComponent
                    button_style={ButtonStyle.Primary}
                    disabled={props.disabled}
                    pending={pending}
                    onClick={actions.submit}
                    label={label?.submit ? label.submit : t('submit')}
                />
            )}

            {buttons && buttons.map((button: any) => <ButtonComponent key={button.id} {...button}/>)}

            {actions?.cancel && (
                <ButtonComponent
                    button_style={ButtonStyle.Secondary}
                    pending={pending}
                    onClick={actions.cancel}
                    label={label?.cancel ? label.cancel : t('cancel')}
                />
            )}

            {actions?.custom && (
                <ButtonComponent
                    button_style={ButtonStyle.Secondary}
                    disabled={false}
                    pending={pending}
                    onClick={actions.custom}
                    label={label?.custom ? label.custom : t('cancel')}
                />
            )}

            {actions?.delete && (
                <ButtonComponent
                    icon={"delete"}
                    button_style={ButtonStyle.Warning}
                    label={label?.delete ? label.delete : t('delete')}
                    onClick={props.actions.delete}
                    pending={pending}
                    disabled={pending}
                />
            )}
        </div>
    );
}
