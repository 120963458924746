import * as React from 'react';
import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import { OctagonAlert } from "lucide-react";

export function Fieldset({
                             className,
                             ...props
                         }: { className?: string } & Omit<Headless.FieldsetProps, 'as' | 'className'>) {
    return (
        <Headless.Fieldset
            {...props}
            className={clsx(className, '[&>*+[data-slot=control]]:mt-6 [&>[data-slot=text]]:mt-1')}
        />
    );
}

export function Legend({
                           className,
                           ...props
                       }: { className?: string } & Omit<Headless.LegendProps, 'as' | 'className'>) {
    return (
        <Headless.Legend
            data-slot="legend"
            {...props}
            className={clsx(
                className,
                'font-tracking-normal font-body text-[16px] font-medium leading-5 text-text-default data-[disabled]:text-text-disabled '
            )}
        />
    );
}

export function FieldGroup({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
    return <div data-slot="control" {...props} className={clsx(className, "space-y-4")}/>;
}

// Use to place multiple input fields next to each other horizontally.
export function FieldLayout({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
    return <div {...props} data-slot="control" className={clsx(className, "flex w-full space-x-4")}/>;
}

export function Field({ className, ...props }: { className?: string } & Omit<Headless.FieldProps, 'as' | 'className'>) {
    return (
        <Headless.Field
            {...props}
            className={clsx(
                className,
                '[&>[data-slot=label]+[data-slot=control]]:mt-1',
                '[&>[data-slot=label]+[data-slot=description]]:mt-1',
                '[&>[data-slot=description]+[data-slot=control]]:mt-1',
                '[&>[data-slot=control]+[data-slot=description]]:mt-1',
                '[&>[data-slot=control]+[data-slot=error]]:mt-1',
            )}
        />
    );
}

export function Label({ className, ...props }: { className?: string } & Omit<Headless.LabelProps, 'as' | 'className'>) {
    return (
        <Headless.Label
            data-slot="label"
            {...props}
            className={clsx(
                className,
                'font-tracking-normal font-body text-[14px] font-medium leading-5 text-text-default data-[disabled]:text-text-disabled group-data-[disabled]:text-text-disabled dark:text-text-inverse-default'
            )}
        />
    );
}

export function HelpText({
                             className,
                             ...props
                         }: { className?: string } & Omit<Headless.DescriptionProps, 'as' | 'className'>) {
    return (
        <Headless.Description
            data-slot="description"
            {...props}
            className={clsx(
                className,
                'font-tracking-normal font-normal font-body text-[13px] leading-4 text-text-tertiary data-[disabled]:text-text-disabled dark:text-text-inverse-secondary'
            )}
        />
    );
}

export function InlineError({
                                className,
                                children,
                                ...props
                            }: { className?: string } & Omit<Headless.DescriptionProps, 'as' | 'className'>) {
    return (
        <div
            className={clsx(className, "flex flex-row items-center gap-1.5 text-text-critical-default")}
            data-slot="error"
        >
            {children && <OctagonAlert className="size-4"/>}
            <Headless.Description
                {...props}
                className={'font-tracking-normal font-body text-[13px] font-regular leading-5'}
            >
                {children}
            </Headless.Description>
        </div>
    );
}
