import { useTrans } from "components";

export default function useData() {
    const { t } = useTrans();
    const base = "/data";

    return {
        reports: {
            name: t("reports"),
            path: `${base}/reports`,
        },
        imports: {
            name: t("imports"),
            path: `${base}/imports`,
        },
        exports: {
            name: t("exports"),
            path: `${base}/exports`,
        },
        generating: {
            name: t("generating"),
            path: `${base}/generating`,
        },
        files: {
            name: t("files"),
            path: `${base}/files`,
        },
        backgroundTasks: {
            name: t("backgroundTasks"),
            path: `${base}/background-tasks`,
        },
    };
}