import React, { useState } from 'react';

import Select from 'react-select';

type Props = {
    defaultValue?: any;
    isMulti: boolean;
    [key: string]: any;
};

export default function NewSelect(props: Props) {
    const { defaultOptions, options, isMulti } = props;
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let defaultValue;
    if (isMulti) {
        const defaultValues = props.defaultValue ? props.defaultValue.split(";") : [];
        defaultValue = options.filter((o: any) => defaultValues.includes(o.value));
    } else {
        defaultValue = props.defaultValue ? options.find((o: any) => o.value == props.defaultValue) : undefined;
    }

    return (
        <Select
            name={props.name}
            isMulti={isMulti}
            value={props.value}
            defaultValue={defaultValue}
            className="basic-single"
            classNamePrefix="select"
            isDisabled={props.disabled}
            isLoading={props.pending}
            isClearable={props.is_clearable}
            isSearchable={props.searchable}
            options={props.options}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={(newValue) => {
                if (isMulti) {
                    props.onBlur(props.name, newValue.map((o: any) => o.value).join(";"));
                } else {
                    props.onBlur(props.name, newValue.value);
                }

            }}
        />
    );
}