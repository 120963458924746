import React, { useContext } from "react";
import Page from "components/containers/Page";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import { AccountContext } from "context";
import useRouting from "components/hooks/useRouting";
import { useTrans } from "components";
import ButtonComponent from "components/buttons/ButtonComponent";
import WebShopItem from "modules/webshop/WebShopItem";

export default function WebShop() {
    const { account } = useContext(AccountContext);
    const { push } = useRouting();
    const { t } = useTrans();

    return (
        <Page>
            <PageContent title={"Webshop"}>
                <ContentCard>
                    {account.country == 'NL' ? (
                        <div className={"flex gap-4"}>
                            <WebShopItem
                                id={"Piggy-keycards-EUR"}
                                name={"Keycards - Piggy branded"}
                                price={"€99"}
                                image={"https://static.piggy.nl/webshop_afbeeldingen/keycards.jpg"}
                            />
                            <WebShopItem
                                id={"ipad_stand-EUR"}
                                name={t('iPadStand')}
                                price={"€149"}
                                image={"https://static.piggy.nl/webshop_afbeeldingen/stalen_houder.jpg"}
                            />
                        </div>
                    ) : (
                        <div className={"flex flex-col justify-center gap-4 p-12"}>
                            <p>This is currently not available in your country.</p>
                            <div>
                                <ButtonComponent
                                    onClick={() => push('/home')}
                                    label={"Take me home"}
                                />
                            </div>
                        </div>
                    )}
                </ContentCard>
            </PageContent>
        </Page>
    );
}