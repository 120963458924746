import useCrmOptions from "@/modules/global/commandPalette/options/useCrmOptions";
import useLoyaltyOptions from "@/modules/global/commandPalette/options/useLoyaltyOptions";
import useStoreOptions from "@/modules/global/commandPalette/options/useStoreOptions";
import useMarketingOptions from "@/modules/global/commandPalette/options/useMarketingOptions";
import useAppsOptions from "@/modules/global/commandPalette/options/useAppsOptions";
import useDataOptions from "@/modules/global/commandPalette/options/useDataOptions";
import useAutomationOptions from "@/modules/global/commandPalette/options/useAutomationOptions";
import useActionOptions from "./options/useActionOptions";
import useSettingsOptions from "@/modules/global/commandPalette/options/useSettingsOptions";

export enum CommandPaletteOptionType {
    ACTION = "action",
    NAVIGATION = "navigation",
    CREATE = "create",
}

export type CommandPaletteOption = {
    visible: boolean;
    group: string,
    type: CommandPaletteOptionType,
    handle: (args?: any) => void,
    label: string
    description: string
    tags: string[];
    data?: Record<string, any>;
};

export default function useCommandPaletteOptions(): CommandPaletteOption[] {
    const crm = useCrmOptions();
    const loyalty = useLoyaltyOptions();
    const store = useStoreOptions();
    const marketing = useMarketingOptions();
    const apps = useAppsOptions();
    const data = useDataOptions();
    const automation = useAutomationOptions();
    const settings = useSettingsOptions();
    const actions = useActionOptions();

    return [
        ...crm.filter(i => i.visible),
        ...loyalty.filter(i => i.visible),
        ...store.filter(i => i.visible),
        ...marketing.filter(i => i.visible),
        ...apps.filter(i => i.visible),
        ...data.filter(i => i.visible),
        ...automation.filter(i => i.visible),
        ...settings.filter(i => i.visible),
        ...actions.filter(i => i.visible),
    ];

}