import React, { useState } from "react";
import { useTrans } from "components";
import MediaModalHeaderV2 from "components/media/modal/MediaModalHeaderV2";
import MediaType from "components/MediaType";
import { ModalProps } from "components/hooks/useModal";
import useLocalTabs from "components/hooks/useLocalTabs";
import StockImages from "components/media/mediaModalComponents/StockImages";
import OwnImages from "components/media/mediaModalComponents/OwnImages";
import Gifs from "components/media/mediaModalComponents/Gifs";
import BaseModal from "@/components/modals/components/BaseModal";
import ModalWidth from "@/components/modals/components/ModalWidth";

type Props = {
    onSubmit: (media: MediaType) => void;
    with_stock_images?: boolean;
    with_files?: boolean;
    with_gifs?: boolean;
    container?: any;
};

export default function MediaModal(props: Props & ModalProps) {
    const { t } = useTrans();
    const {
        isActive,
        close,
        with_files,
        with_stock_images,
        with_gifs
    } = props;
    const [selected, setSelected] = useState<MediaType>(null);
    const { activeTab, setActiveTab, tabs } = useLocalTabs([
        {
            key: "library",
            name: t("library"),
            hidden: !with_files,
        },
        {
            key: "unsplash",
            name: "Unsplash",
            hidden: !with_stock_images,
        },
        {
            key: "giphy",
            name: "Giphy",
            hidden: with_gifs === false || !with_stock_images,
        },
    ]);

    const onSubmit = (media: MediaType) => {
        props.onSubmit(media);
        props.close();
    };

    return (
        <BaseModal modal={props}>
            <ModalWidth supports={{
                sm: true,
                md: true,
                lg: true,
            }}
            >
                <MediaModalHeaderV2
                    tabs={tabs}
                    title={"Media"}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />

                {isActive && (
                    <>
                        <div hidden={activeTab.key != "library"}>
                            <OwnImages
                                selected={selected}
                                onCancel={close}
                                setSelected={setSelected}
                                onSubmit={() => onSubmit(selected)}
                            />
                        </div>

                        <div hidden={activeTab.key != "unsplash"}>
                            <StockImages
                                selected={selected}
                                onCancel={close}
                                setSelected={setSelected}
                                onSubmit={() => onSubmit(selected)}
                            />
                        </div>

                        <div hidden={activeTab.key != "giphy"}>
                            <Gifs
                                selected={selected}
                                onCancel={close}
                                setSelected={setSelected}
                                onSubmit={() => onSubmit(selected)}
                            />
                        </div>
                    </>
                )}
            </ModalWidth>
        </BaseModal>
    );
}
