import { useTrans } from "components";

export default function useMarketing() {
    const { t } = useTrans();
    const base = "/marketing";

    return {
        campaigns: {
            name: t("campaigns"),
            path: `${base}/campaigns`,
        },
        automatedEmails: {
            name: t("automatedEmails"),
            path: `${base}/automated-emails`,
        },
        templates: {
            name: t("templates"),
            path: `${base}/template-gallery`,
        },
    };
}