import PiggyFormElement, { Props as ComponentPackageProps, } from "@piggy-loyalty/ui-components/lib/form/FormElement";
import { FormElementType as PiggyFormElementType } from "@piggy-loyalty/ui-components/lib/form/types/base";
import { useTrans } from "components";
import ContactsSearch, { ContactSearchProps } from "components/forms/Search/ContactsSearch";
import React from "react";
import FormElementCheckbox from "components/forms/FormElements/Checkbox/FormElementCheckbox";
import FormElementColor from "components/forms/FormElements/ColorPicker/FormElementColor";
import AsyncSelect from "components/forms/multiSelect/AsyncSelect";
import NewSelect from "components/forms/multiSelect/NewSelect";
import Search from "components/forms/Search/Search";
import MediaUpload from "components/forms/FormElements/MediaUpload/MediaUpload";
import Slider from "components/ui/Slider";
import TipTap from "@/components/forms/TipTap/OLD/TipTap";
import RichTextEditor from "components/forms/ContentEditable/RichTextEditor";
import NewSwitch from "components/forms/Switch/NewSwitch";
import { FormElementType } from "@/core/dataTypes/useFormElement";

export type FormElementProps = Props & ComponentPackageProps;

export type AnyFormElementType = FormElementType
    | PiggyFormElementType
    | "rich_text_editor"
    | "rich_text_editor_new"
    | "contacts_search"
    | "search"
    | "async_select"
    | "async_multi_select"
    | "new_select"
    | "new_multi_select"
    | "boolean"
    | 'new_switch'
    | "range";

interface Props {
    type: AnyFormElementType;
    hidden?: boolean;
    placeholder?: string;
    handleClassName?: string;
    barClassName?: string;
    options?: any;
    onChange?: (name: string, value: any) => void;
    onMouseOver?: any;
    onMouseOut?: any;
    variant?: 'boxed' | 'default';
    hasCopy?: any;
    mediaRenderer?: any;
    entity?: string;
    onMouseUp?: any;

    [key: string]: any;
}

export default function FormElement(props: FormElementProps) {
    const { t } = useTrans();
    const { id, hidden, barClassName, onMouseOver, onMouseOut } = props;
    const options = (props.options && Array.isArray(props.options)) ? props.options : [];
    const isGrouped = (options: any[]) =>
        options.filter((o) => o.group_name).length > 0;

    const getElement = () => {
        switch (props.type) {
            case "rich_text_editor_new":
                return <TipTap {...props} />;
            case "rich_text_editor":
                return <RichTextEditor {...props} />;
            case "async_multi_select":
                return <AsyncSelect
                    {...props}
                    options={props.options as any}
                    isLoading={props.isLoading as boolean}
                    isMulti={true}
                />;
            case "async_select":
                return <AsyncSelect
                    {...props}
                    options={props.options as any}
                    isLoading={props.isLoading as boolean}
                    isMulti={false}
                />;
            case "new_select":
                return <NewSelect {...props} isMulti={false}/>;
            case "new_multi_select":
                return <NewSelect {...props} isMulti={true}/>;
            case "media_upload":
                return <MediaUpload {...props as any} />;
            case "contacts_search":
                return <ContactsSearch {...(props as ContactSearchProps)} />;
            case "search":
                return <Search entity={props.entity} {...props} />;
            case "checkbox":
            case "boolean":
                return <FormElementCheckbox {...props as any} />;
            case "color":
            case "inline_color":
                return <FormElementColor {...props as any} />;
            case "range":
            case "slider":
                return <Slider {...props as any} />;
            case "new_switch":
                return <NewSwitch {...props as any} />;
            case "toggle":
                return <NewSwitch
                    {...props as any}
                    onChange={e => props.onChange(props.name, e.target.checked)}
                />;
            default:
                return (
                    <PiggyFormElement
                        {...props}
                        variant={props.variant ?? 'boxed'}
                        type={props.type as PiggyFormElementType}
                        options={options}
                        defaultValue={
                            props.defaultValue === null
                                ? undefined
                                : props.defaultValue
                        }
                        locale={
                            {
                                startTime: t("startTime"),
                                endTime: t("endTime"),
                                day: t("day"),
                                month: t("month"),
                                year: t("year"),
                                search: t("search"),
                                searchPlaceholder: t("search"),
                                sunday: t("su"),
                                monday: t("mo"),
                                tuesday: t("tu"),
                                wednesday: t("we"),
                                thursday: t("th"),
                                friday: t("fridayShort"),
                                saturday: t("sa"),
                                ok: t("OK"),
                                today: t("today"),
                                yesterday: t("yesterday"),
                                hours: t("hours"),
                                minutes: t("minutes"),
                                seconds: t("seconds"),
                                copy: t("copy"),
                                copied: t("copied"),
                                enterValidEmail: t("enterValidEmail"),
                                enterValidPhone: t("enterValidPhone"),
                                enterValidUrl: t("enterValidUrl"),
                                boolean: {
                                    true: t("true"),
                                    false: t("false"),
                                },
                                upload: t("upload"),
                                remove: t("remove"),
                                noFileSelected: t("noFileSelected"),
                                choose: t("choose"),
                                startDate: t("startDate"),
                                endDate: t("endDate"),
                                addFile: t("addFile"),
                                clickToBrowse: t("clickToBrowse"),
                                orDragAndDrop: t("orDragAndDrop"),
                                imageDescription: t("imageDescription"),
                                replace: t("replace"),
                                maxFileSizeError: t("maxFileSizeError"),
                            } as any
                        }
                        groupBy={isGrouped(options) ? "group_name" : null}
                    />
                );
        }
    };

    return (
        <div
            id={id ?? ""}
            className={`${
                barClassName ? barClassName : "my-2 w-full"
            } font-light`}
            hidden={hidden}
            onMouseOver={onMouseOver ?? null}
            onMouseOut={onMouseOut ?? null}
        >
            {getElement()}
        </div>
    );
}
