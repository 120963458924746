import React from "react";
import Pagination from "components/pagination/Table/Pagination";
import { FilterItem } from "../../filters/OverviewHeader";
import { Table } from "components/table_v2/types";
import SimpleBackendTableDraggable from "components/table_v2/draggable/SimpleBackendTableDraggable";
import FiltersSearchBar from "components/table_v2/components/searchbar/FiltersSearchBar";
import cn from "classnames";

type Props = {
    table: Table;
    onRowClick?: (id: string) => void;
    pending?: boolean;
    actions?: any;
    className?: string;
    onFilterChange: any;
    filterValues: any;
    emptyStateAction?: any;
    pagination?: any;
    onDragEnd: any;
    filters?: Array<FilterItem | FilterItem[]>;
    height?: "barebones" | "searchbar" | "tabs"
};

export default function BackendTableDraggable(props: Props) {
    const {
        table,
        className,
        pending,
        onRowClick,
        onFilterChange,
        filterValues,
        emptyStateAction,
        onDragEnd,
        actions,
        height
    } = props;
    const pagination = props.pagination ? props.pagination : table.pagination ? table.pagination : null;

    const wrapperCn = cn(
        'flex flex-col',
        className
    );

    return (
        <div className={wrapperCn}>
            {table.action_bar && (
                <FiltersSearchBar
                    {...table.action_bar}
                    actions={actions}
                    filterValues={filterValues}
                    onFilterChange={onFilterChange}
                    pending={pending}
                />
            )}

            <SimpleBackendTableDraggable
                table={table}
                pending={pending}
                emptyStateAction={emptyStateAction}
                onRowClick={onRowClick ? onRowClick : null}
                onFilterChange={onFilterChange}
                onDragEnd={onDragEnd}
                height={height ?? "searchbar"}
                isPaginated={false}
            />

            {table && table.pagination && table.rows?.length >= 1 && (
                <Pagination
                    pagination={pagination}
                    filterValues={filterValues}
                    onFilterChange={onFilterChange}
                />
            )}
        </div>
    );
}
