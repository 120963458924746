import React from "react";
import Image from "components/image/ImageComponent";
import { animated, useSpring } from "react-spring";
import MediaType from "components/MediaType";

type Props = {
    item: MediaType;
    isActive: boolean;
    onSelect: (item: MediaType) => void;
};

export default function MediaModalContentItem(props: Props) {
    const { onSelect, item, isActive } = props;
    const { name, value, thumbnail_url } = item;
    const imageAppear = useSpring({
        from: { opacity: .5, marginTop: "5rem" },
        to: { opacity: 1, marginTop: "0rem" },
        delay: 50
    });

    return (
        <animated.li
            className={`flex flex-col px-[6px] py-1.5`}
            style={imageAppear}
        >
            <button
                className={`ring-blue-500/50 ${isActive ? 'shadow-primary-hover ring-2 ' : 'ring-0 hover:ring-1'} hover:shadow-primary-hover overflow-hidden rounded-lg border border-gray-200 transition-all duration-300`}
                onClick={() => onSelect(item)}
            >
                <Image
                    alt={name}
                    className={`h-24 w-[150px] object-cover transition-all duration-300 hover:scale-105`}
                    src={thumbnail_url ?? value}
                />
            </button>

            <p className="text-center text-xs text-gray-900">
                {name}
            </p>
        </animated.li>
    );
}
