import Text from "components/typography/Text";
import React, { useEffect, useRef, useState } from "react";
import FormElementWrapper from "@piggy-loyalty/ui-components/lib/components/elements/FormElementWrapper";
import cn from "classnames";
import { useTrans } from "components";
import { ColorPicker } from "components/forms/FormElements/ColorPicker/ColorPicker";
import FormElementBorder from "components/forms/FormElementBorder";

type Props = {
    colors?: string[];
    name: string;
    id?: any;
    label?: string;
    value?: any;
    defaultValue?: any;
    onChange?: (name: string, value: any) => void;
    onBlur?: (name: string, value: any) => void;
    onClick?: (name: string, value: any) => void;
    pending?: boolean;
    disabled?: boolean;
    required?: boolean;
    tooltip?: string;
    bottom_helper?: string;
    top_helper?: string;
};

export default function FormElementColor(props: Props) {
    const {
        name,
        value,
        defaultValue,
        label,
        onBlur,
        colors,
        disabled,
        bottom_helper,
        top_helper,
        pending
    } = props;
    const { t } = useTrans();
    const pickerRef = useRef(null);
    const [isFocussed, setFocussed] = useState(false);
    const [pickerVisible, setPickerVisible] = useState(false);
    const [pickerValue, setPickerValue] = useState(value ?? defaultValue);

    const handleClickOutside = () => {
        if (pickerRef && !pickerRef?.current?.contains(event.target)) {
            hidePicker();

            if (props.onBlur) {
                setPickerValue(pickerValue);
                props.onBlur(name, pickerValue);
            }
            document.removeEventListener("mousedown", handleClickOutside);
        }
    };

    useEffect(() => {
        if (pickerVisible) {
            document.addEventListener("mousedown", handleClickOutside);
            document.onkeydown = (evt: KeyboardEvent) => {
                let isEscape = false;
                if ("key" in evt) {
                    isEscape = evt.key === "Escape" || evt.key === "Esc";
                }
                if (isEscape) {
                    hidePicker();
                }
            };
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
            document.onkeydown = null;
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.onkeydown = null;
        };
    }, [pickerVisible, pickerValue]);

    const showPicker = () => {
        setFocussed(true);
        setPickerVisible(true);
    };

    const hidePicker = () => {
        setFocussed(false);
        setPickerVisible(false);
    };

    const onChange = (color: string) => {
        if (props.onChange) {
            props.onChange(name, color);
        }

        setPickerValue(color);
    };

    const disabledState = cn({ "cursor-not-allowed opacity-50": disabled });

    const buttonCn = cn(
        "rounded-md bg-none border m-2.5 px-4 h-8",
        disabledState
    );

    const colorInputCn = cn(
        "m-1.5 mr-0 w-12 h-12 rounded-md border border-[#DED9ED] bg-[#F8F8F8FF] shrink-0 cursor-pointer"
    );

    const labelCn = cn(
        "transition-colors truncate",
    );

    return (
        <FormElementWrapper
            name={"form-checkbox"}
            className={""}
            isDisabled={disabled}
            bottom_helper={bottom_helper}
            top_helper={top_helper}
        >
            <FormElementBorder {...props} isFocussed={isFocussed}>
                <div className={"flex w-full items-center justify-between"}>
                    <div
                        className={"flex w-full items-center gap-4"}
                        ref={pickerRef}
                    >
                        <div
                            className={colorInputCn}
                            style={{
                                backgroundColor:
                                    pickerValue ?? value ?? defaultValue,
                            }}
                            onClick={disabled ? null : showPicker}
                            hidden={disabled}
                        />

                        {label && (
                            <div>
                                <label className={labelCn}>
                                    {label}
                                </label>
                                <Text size={"xs"}>{pickerValue}</Text>
                            </div>
                        )}

                        {pickerVisible && (
                            <div className={"absolute top-full z-50"}>
                                <ColorPicker value={pickerValue} onChange={onChange} colors={colors ?? []}/>
                            </div>
                        )}
                    </div>
                </div>
            </FormElementBorder>
        </FormElementWrapper>
    );
}