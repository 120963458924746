import React from "react";

export type Props = {
    title: string;
};

export default function SimpleHeader(props: Props) {
    const { title } = props;

    return (
        <div className={`mb-3 px-3`}>
            <p className={'hidden pt-8 text-xl text-white lg:block'}>
                {title}
            </p>
        </div>
    );
}
