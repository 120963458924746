import ImageComponent from "components/image/ImageComponent";
import MediaType from "components/MediaType";
import LottiePlayer from "components/media/LottiePlayer";
import React, { CSSProperties } from "react";
import Icon, { IconRenderTypes, IconType } from "./icons/Icon";
import cn from "classnames";
import { abbreviate } from "core";

interface Props {
    media: MediaType;
    className?: string;
    renderAs?: IconRenderTypes;
    style?: CSSProperties;
    show_default?: boolean;
}

export default function MediaComponent(props: Props) {
    const {
        media,
        style,
    } = props;

    const value = media?.value ?? "";
    const type = media?.type ?? "string";
    const renderAs = props.renderAs ? props.renderAs : "svg";
    const showDefault =
        props.show_default !== undefined ? props.show_default : true;
    const className = cn(props.className);

    const abbreviation = () => {
        return abbreviate(value);
    };

    if (type === "icon") {
        return (
            <Icon
                className={className}
                type={value as IconType}
                attr={renderAs}
                style={style}
            />
        );
    }

    if (type == "image" || type == "gif") {
        return <ImageComponent style={style} className={className} src={value}/>;
    }

    if (type == "string" && value) {
        return (
            <div
                className={`font-light flex size-12 select-none items-center justify-center bg-primary-600 text-lg text-white ${
                    className ? className : ""
                }`}
            >
                {abbreviation()}
            </div>
        );
    }

    if (type == "avatar") {
        return (
            <div
                className={`font-light flex size-12 select-none items-center justify-center rounded-full bg-primary-600 text-lg text-white ${
                    className ? className : ""
                }`}
            >
                <ImageComponent src={value} className={'rounded-full'}/>
            </div>
        );
    }

    if (type == "number" && value) {
        return (
            <div
                className={`font-light flex size-12 select-none items-center justify-center rounded-full bg-primary-600 text-lg text-white  ${
                    className ? className : ""
                }`}
            >
                {value}
            </div>
        );
    }

    if (type == "lottie" && value) {
        return (
            <div className={className}>
                <LottiePlayer {...props.media} src={value}/>
            </div>
        );
    }

    if (showDefault) {
        // When we cannot create anything else, return and image with letters

        return (
            <ImageComponent
                style={style}
                className={className}
                src={"https://static.piggy.nl/images/fallback_image.png"}
            />
        );
    }

    return null;
}
