import moment from "moment";
import lodashTruncate from "lodash/truncate";
import sortBy from "lodash/sortBy";

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { get, isEqual, isObject, transform, values } from "lodash";
import hexRgb from "hex-rgb";
import { MergeTagGroup } from "@/components/forms/TipTap/mergeTagsMention/MergeTagsList";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function isLeat(): boolean {
    return window.location.href.includes("leat");
}

export function formatToSelectValues(arrayToFormat: any, keyNameForValue: string, keyNameForLabel: string, formatLabel?: any, orderByLabel?: boolean): any[] {
    if (!Array.isArray(arrayToFormat)) {
        return [];
    }

    const array = orderByLabel ? sortBy(arrayToFormat, keyNameForLabel) : arrayToFormat;

    return array.map((item: any) => {
        if (item) {
            return {
                value: item[keyNameForValue] ? item[keyNameForValue].toString() : "",
                label: formatLabel ? formatLabel(item[keyNameForLabel]) : item[keyNameForLabel],
            };
        } else {
            return {
                value: "",
                label: "",
            };
        }
    });
}

export function isEmpty(obj: object) {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
}

export function isEnter(keycode: string) {
    return keycode == "Enter";
}

export function formatFileSize(file: File) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i;
    let bytes = file.size;

    for (i = 0; bytes >= 1024 && i < 4; i++) {
        bytes /= 1024;
    }

    return bytes.toFixed(2) + units[i];
}

export function abbreviate(value: string) {
    if (!value) {
        return "AB";
    }

    if (value.includes(" ")) {
        return value.split(" ", 2).map((i) => i.charAt(0));
    }

    if (value.length === 3) {
        return value.toUpperCase();
    }

    return value.slice(0, 2).toUpperCase();
}

export function newMoment(date: any = new Date(), format = "YYYY-MM-DD[T]HH:mm:ssZ") {
    if (format) {
        return moment(date, format).locale(window.navigator.language);
    }

    return moment(date).locale(window.navigator.language);
}

export function truncate(text: string, length = 10): string {
    return lodashTruncate(text, { length: length });
}

export const capitalize = (text: string) => {
    if (text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
};

export const goRoutesBack = (currentRoute: string, amount = 1) => {
    const pieces = currentRoute.split("/");

    for (let i = 0; i < amount; i++) {
        pieces.pop();
    }
    return pieces.join("/");
};

export const round = (number: number, decimals = 0) => {
    if (decimals == 0) {
        return Math.round(number);
    }
    const multiplier = 10 * decimals;
    return Math.round(number * multiplier) / multiplier;
};

export const qrCodeUrl = (content: string) => {
    return `https://api.qrserver.com/v1/create-qr-code/?size500x500&margin=20&data=${content}`;
};

export const formatNumber = (number: number) => {
    return number.toLocaleString();
};

export const toLowerCaseAndNoSpaces = (string: string) => {
    if (string) {
        return string.replace(/\s+/g, '_').toLowerCase();
    }
};

export const toLowerCaseAndReplaceSpacesWithHyphens = (string: string) => {
    if (string) {
        return string.replace(/\s+/g, '-').toLowerCase();
    }
};

export const getToken = () => {
    return localStorage.getItem("token");
};

export const openInNewTab = (url: string) => {
    window.open(url);
};

export const openInSameTab = (url: string) => {
    window.open(url, "_self");
};

export const reloadCurrentPage = () => window.location.reload();

export const isAValidEmailAddress = (email: string) => {
    return Boolean(
        email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
};

export const randomWidth = (min: number, max: number, withPx = true) => {
    const width = Math.floor(Math.random() * (max - min + 1) + min);

    return withPx ? width + "px" : width;
};

export const toSnakeCaseWithoutSpecialChars = (string: string) => {
    const withoutLeftBracket = string.replace(/\[/g, '');
    const withoutBrackets = withoutLeftBracket.replace(/]/g, '');
    return withoutBrackets.replace(/[;:|/§±!@#$%^&'()*"<,>\\}{=+.?~`]/gi, '').replace(/\s/gi, "_").toLowerCase();
};

export const asFormData = (obj: Record<any, any>) => {
    const formData = new FormData();

    for (const key in obj) {

        let value = obj[key];

        if (value === null) {
            value = "";
        }

        formData.append(key, value);
    }

    return formData;
};

export function difference(object: object, base: object) {
    function changes(object: object, base: object) {
        return transform(object, function (result: any, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }

    return changes(object, base);
}

export function push(baseObject: object, path: string, value: any) {
    return get(baseObject, path).push(value);
}

export function arrayMove(arr: any[], fromIndex: number, toIndex: number) {
    const element = arr[fromIndex];

    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);

    return arr;
}

export function hexToRgbForTailwindConfig(hex: string) {
    try {
        const values = hexRgb(hex, { format: "array" }); // This returns [r,g,b,alpha]
        values.pop(); // remove alpha (handled by tailwind)

        return values.join(","); // Return as a comma-delimited string. Tailwind then makes into rgb(r,g,b, {a})
    } catch (error) {
        return hex;
    }
}

export function encodeForUrl(value: string) {
    return encodeURIComponent(value).toString();
}

export function contrast(hexColor: string) {
    // Extract the red, green, and blue components from the hex color
    const [red, green, blue] = hexColor.match(/\w\w/g).map((hex) => parseInt(hex, 16));

    // Calculate the average luminance
    const luma = (red + green + blue) / 3;

    // Determine the text color based on the luminance
    return luma < 128 ? '#ffffff' : '#000000';
}

export function getInitials(name: string): string {

    if (!name) {
        return '';
    }

    // Remove extra spaces and split the name into words.
    const words = name.trim().split(/\s+/);

    if (words.length === 0) return '';

    // Always take the first letter of the first word.
    const firstInitial = words[0].charAt(0).toUpperCase();

    // If there is more than one word, take the first letter of the last word.
    const lastInitial =
        words.length > 1 ? words[words.length - 1].charAt(0).toUpperCase() : '';

    return firstInitial + lastInitial;
}

export function wrapInCurlyBraces(value: string) {
    return '{{' + value + '}}';
}

export function replaceMentions(htmlString: any) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    tempDiv.querySelectorAll("span.mention").forEach((span) => {
        const dataId = span.getAttribute("data-id"); // Extract data-id
        if (dataId) {
            const textNode = document.createTextNode(wrapInCurlyBraces(dataId));
            span.replaceWith(textNode);
        }
    });

    return tempDiv.innerHTML;
}

export function mergeTagsToHtml(htmlString: string, mergeTags: MergeTagGroup[] | null) {

    if (htmlString === undefined || htmlString === null) {
        return "";
    }

    // Create a regex to find placeholders like {{contact.firstname}}
    const regex = /\{\{(.*?)\}\}/g;

    return htmlString.replace(regex, (match, key) => {
        // Find the corresponding label from mergeTags
        let found = null;

        for (const group of mergeTags) {
            const option = group.options.find(opt => opt.value === key);
            if (option) {
                found = {
                    label: `${group.title}: ${option.title}`,
                    value: option.value
                };
                break;
            }
        }

        // If no match is found, keep the placeholder as is
        if (!found) return match;

        // Return the formatted <span> as a string
        return `<span label="${found.label}" data-value="${found.value}" value="${found.value}" data-merge-tag-node="true" contenteditable="false" class="bg-primary-50 text-primary-600 px-1.5 py-0.5 rounded-md">${found.label}</span>`;
    });
}

export const objectIsEmptyOrKeysHaveNoValues = (obj: object): boolean => {

    if (isEmpty(obj)) {
        return true;
    }

    return values(obj).every(isEmpty);
};

export const normalizeToArray = (value: any): any[] => {

    if (value === undefined || value === null) {
        return [];
    }

    if (Array.isArray(value)) {
        return value;
    }

    if (typeof value === 'number') {
        return [value];
    }

    if (value.includes(";")) {
        return value.split(";");
    }

    return [value];
};

export const isNullOrUndefined = (value: any) => {
    if (value === undefined || value === null) {
        return true;
    }

    return false;
};

export const parseValueToTimeInputs = (value: string) => {

    if (isNullOrUndefined(value)) {
        return {
            start: null,
            end: null
        };
    }

    const inputs = value.split(";");

    return {
        start: inputs[0],
        end: inputs[1],
    };
};

export const isZeroOrNull = (value: any) => {
    return value === 0 || value === null;
};

export const isValidUrl = (value: string): boolean => {
    const regex = /^(https?:\/\/)(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

    return regex.test(value);
};