import { createContext } from "react";

type PiggyContextProps = {
    upgradeModalContent: {
        module: string;
    };
    setUpgradeModalContent: any;
    isUpgradeModalVisible: boolean;
    setUpgradeModalVisible: any;
    helpCentre: any;
};

export const PiggyContext = createContext<Partial<PiggyContextProps>>({});