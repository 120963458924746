import React from "react";
import Loader from "@/components/Loader";

const LoadingScreen = ({ message = "" }) => {

    return (
        <div className={"flex min-h-screen w-full items-center justify-center"}>
            <Loader visible={true}/>

            {message && (
                <p className={"text-xl"}>{message}</p>
            )}
        </div>
    );
};

export default LoadingScreen;
