import React from "react";
import useRouting from "components/hooks/useRouting";
import { useTrans } from "components";

type Props = {
    className?: string;
    goToRoute?: string
};

export default function SidebarBackButton(props: Props) {
    const { goToRoute } = props;
    const { push, goBack } = useRouting();
    const { t } = useTrans();
    const navigate = () => goToRoute ? push(goToRoute) : goBack();

    return (
        <button
            className={`btn-outline my-6 hidden items-center gap-2 rounded-full py-2 pl-2 pr-3 text-white transition-all hover:bg-white/10 lg:flex ${props.className ? props.className : ""}`}
            onClick={navigate}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-3"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"/>
            </svg>

            <p className={"text-base"}>
                {t("back")}
            </p>
        </button>
    );
}