import React from "react";
import Text, { TextProps } from "components/typography/Text";
import FormElement, { FormElementProps } from "components/forms/FormElement";


type Props = {
    id: string;
    type: string;
    element: {
        leading_text?: TextProps,
        text: TextProps,
        input: FormElementProps
    };
    onChange?: (name: string, value?: any) => void;
    onBlur?: (name: string, value?: any) => void;
};

export default function PreviewComponent(props: Props) {
    const { element, onChange, onBlur } = props;

    return (
        <div className={'flex'}>
            {element.leading_text && (
                <Text
                    {...element.leading_text}
                    className={'my-auto w-1/3 pr-4'}
                >
                    {element.leading_text.text}
                </Text>
            )}

            <div className={'flex w-full items-center justify-between gap-4 rounded-lg border bg-gray-25 p-4'}>
                <Text {...element.text}>
                    {element.text.text}
                </Text>
                <FormElement
                    {...element.input}
                    barClassName={'w-40 shrink-0'}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </div>
        </div>
    );
}