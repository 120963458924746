import { ReactRenderer } from "@tiptap/react";
import MentionList from "@/components/forms/TipTap/mergeTagsMention/MergeTagsList";
import tippy from "tippy.js";
import { SuggestionOptions } from "@tiptap/suggestion";

const tipTapMergeTags = (mergeTags: any): Omit<SuggestionOptions, "editor"> => {
    return {
        char: "{{",
        allowedPrefixes: null,
        items: () => mergeTags,
        render: () => {
            let component: any;
            let popup: any;

            return {
                onStart: (props: any) => {
                    component = new ReactRenderer(MentionList, {
                        props: props,
                        editor: props.editor,
                    });

                    if (!props.clientRect) {
                        return;
                    }

                    popup = tippy('body', {
                        getReferenceClientRect: props.clientRect,
                        appendTo: () => document.body,
                        content: component.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: 'manual',
                        placement: 'bottom-start',
                        duration: 100,
                    });
                },

                onUpdate(props: any) {
                    component.updateProps(props);

                    if (!props.clientRect) {
                        return;
                    }

                    popup[0].setProps({
                        getReferenceClientRect: props.clientRect,
                    });
                },

                onKeyDown(props: any) {
                    if (props.event.key === 'Escape') {
                        popup[0].hide();

                        return true;
                    }

                    return component.ref?.onKeyDown(props);
                },

                onExit() {
                    popup[0].destroy();
                    component.destroy();
                },
            };
        }
    };
};

export default tipTapMergeTags;