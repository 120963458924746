import React, { useRef, useState } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import FloatingLabel from "components/forms/ContentEditable/FloatingLabel";
import useAutoClose from "components/hooks/useAutoClose";
import { BaseProps } from "@piggy-loyalty/ui-components/lib/form/types/base";
import Helper from "@piggy-loyalty/ui-components/lib/components/Helper";
import customCss from './custom_css.css?inline';

type ToolbarItem = "mergetags" | "emoticons" | "link";

type ToolbarOption = {
    name: string,
    items: ToolbarItem[],
};

type Plugin =
    "mergetags"
    | "emoticons"
    | 'advlist'
    | 'autolink'
    | 'link'
    | 'image'
    | 'lists'
    | 'charmap'
    | 'preview'
    | 'anchor'
    | 'pagebreak'
    | 'searchreplace'
    | 'wordcount'
    | 'visualblocks'
    | 'visualchars'
    | 'code'
    | 'fullscreen'
    | 'insertdatetime'
    | 'media'
    | 'table'
    | 'help';

interface RichTextEditorProps {
    has_toolbar?: boolean;
    is_inline?: boolean;
    height?: string;
    merge_tags?: any[];
    output_format?: "text" | "html";
    plugins?: Plugin[];
    toolbar_options?: ToolbarOption[] | any;
}

export default function RichTextEditor(props: RichTextEditorProps & BaseProps) {
    const {
        value,
        defaultValue,
        label,
        name,
        onChange,
        onBlur,
        has_toolbar,
        is_inline,
        height,
        bottom_helper,
        top_helper,
        merge_tags,
        toolbar_options,
        output_format,
        plugins
    } = props;
    const containerRef = useRef(null);
    const editorRef = useRef(null);
    const [isInputFocussed, setIsInputFocussed] = useState(false);

    useAutoClose(
        containerRef,
        () => setIsInputFocussed(false),
        isInputFocussed
    );

    const handle = (e: any) => {
        const content = e.target.getContent({ format: output_format });

        onBlur && onBlur(name, content);
    };

    const getHeightInPixels = () => {
        if (height == "medium") {
            return "min-h-[150px]";
        }

        if (height == "large") {
            return "min-h-[350px]";
        }

        return "";
    };

    return (
        <div className={`w-full bg-white text-primary-600`}>
            {top_helper && (
                <div className={"text-gray-900"}>
                    <Helper text={top_helper.text} status={top_helper.status} align={top_helper.align}/>
                </div>
            )}

            <div
                ref={containerRef}
                className={`relative flex flex-col`}
            >
                {has_toolbar && <div id={name} className={'absolute top-[44px] z-50'}/>}

                <div
                    className={`rounded-lg border border-gray-100 p-2 ${getHeightInPixels()} text-gray-900`}
                >
                    <FloatingLabel
                        active={isInputFocussed}
                        required={props.required}
                        focussed={isInputFocussed}
                        disabled={props.disabled}
                        className={'font-normal text-sm text-gray-500'}
                    >
                        {label}
                    </FloatingLabel>
                    <Editor
                        inline={is_inline}
                        onInit={(evt, e) => editorRef.current = e}
                        initialValue={defaultValue}
                        value={value}
                        onEditorChange={(value, editor) => onChange && onChange(name, editor.getContent({ format: output_format }))}
                        onFocusIn={() => setIsInputFocussed(true)}
                        onBlur={e => onBlur && handle(e)}
                        apiKey={"twvoxxufuhkpkun7v6p94kcxujdf82w174mdprmkw1236ycz"}
                        init={{
                            placeholder: props.placeholder,
                            menubar: false,
                            toolbar: toolbar_options,
                            plugins: plugins,
                            formats: {
                                underline: { inline: "u", exact: true },
                            },
                            mergetags_list: merge_tags,
                            content_style: customCss,
                            branding: false
                        }}
                    />
                </div>
            </div>

            {bottom_helper && (
                <div className={"text-gray-900"}>
                    <Helper {...bottom_helper}/>
                </div>
            )}
        </div>
    );
}
