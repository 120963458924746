import React, { useContext, useState } from 'react';
import { Loader, useToast, useTrans } from "components";
import { AccountContext } from "context";
import useHttp from "core/http/useHttp";
import useFetcher from "repositories/base/useFetcher";
import Card from "components/cards/composition/Card";
import CardHeaderContainer from "components/cards/composition/CardHeaderContainer";
import CardHeader from "components/cards/composition/CardHeader";
import CardContent from "components/cards/composition/CardContent";
import useExportCSV from "@/modules/store/giftcards/programs/statistics/components/useExportCSV";
import moment from "moment";
import { Field, FieldGroup, FieldLayout, Fieldset, Label } from "@/components/v2/SelectionAndInput/fieldset";
import DateRange from "@/components/v2/SelectionAndInput/dateRange/dateRange";
import { datesToDateRange } from "@/components/v2/SelectionAndInput/dateRange/helpers/dateRangeHelpers";
import { format } from "date-fns/format";
import useOnChange from "@/components/hooks/forms/useOnChange";
import { Select } from "@/components/v2/SelectionAndInput/Select";
import DisplayValueForSingleSelect
    from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/helpers/DisplayValueForSingleSelect";
import { SelectDropdown } from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/SelectDropdown";
import { SingleSelectOption } from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/SingleSelectOption";
import {
    OptionLayoutWithDescription
} from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/helpers/OptionLayoutWithDescription";
import { Button } from "@/components/v2/Actions/button";
import DateInput from "@/components/v2/SelectionAndInput/dateInput";

type Props = {
    metricId: string;
    programId: number;
    shopOptions: ShopOption[];
};

type ShopOption = {
    uuid: string;
    label: string;
};

export default function SimpleStatisticsCard(props: Props) {
    const { metricId, programId, shopOptions } = props;
    const { errorToast } = useToast();
    const { t } = useTrans();
    const { account } = useContext(AccountContext);
    const http = useHttp();
    const [statistic, setStatistic] = useState(null);
    const [updatePending, setUpdatePending] = useState(false);
    const exportCSV = useExportCSV();
    const apiUrl = `/accounts/${account.id}/giftcards/programs/${programId}/statistics`;
    const onChange = useOnChange(setStatistic)
    const { pending, mutate } = useFetcher({
        url: `${apiUrl}/${metricId}`,
        dataSetter: setStatistic
    });

    const update = () => {
        setUpdatePending(true);
        http.put(`${apiUrl}/${metricId}`, statistic)
            .then(() => mutate())
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    const download = (statistic: any) => {
        exportCSV([{
            title: statistic.title,
            start_date: moment(statistic.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_format: moment(statistic.end_format).format('YYYY-MM-DD HH:mm:ss'),
            value: statistic.value,
            shop: statistic.selected_shop_name ?? t("allShops")
        }], statistic.title);
    };

    if (!statistic) {
        return (
            <Card>
                <div className={"flex h-20 w-full items-center justify-center"}>
                    <Loader visible={pending} type={"tailwindSpinner"}/>
                </div>
            </Card>
        );
    }

    return (
        <Card>
            <CardHeaderContainer>
                <CardHeader title={statistic.title} description={statistic.display_date}/>
                <div className={'flex gap-1'}>
                    <Button onClick={() => download(statistic)}>Download</Button>
                    <Button onClick={update} isLoading={updatePending} variant={'primary'}>{t('calculate')}</Button>
                </div>
            </CardHeaderContainer>
            <CardContent>
                <div className={'relative flex items-center gap-4'}>
                    <Fieldset className={'w-full'}>
                        <FieldGroup className={'w-full'}>
                            <FieldLayout className={'flex justify-between '}>
                                <Field className={'w-full'}>
                                    <Label>{t("dates")}</Label>
                                    {statistic.should_use_range_picker ? (
                                        <DateRange
                                            dateRange={datesToDateRange(statistic.start_date, statistic.end_date)}
                                            onChange={dateRange => {
                                                onChange("start_date", format(dateRange.from, "yyyy-MM-dd"));
                                                onChange("end_date", format(dateRange.to, "yyyy-MM-dd"));
                                            }}
                                        />
                                    ) : (
                                        <DateInput
                                            value={statistic.end_date ?? ""}
                                            onChange={date => {
                                                onChange("start_date", format(date, "yyyy-MM-dd"));
                                                onChange("end_date", format(date, "yyyy-MM-dd"));
                                            }}
                                        />
                                    )}
                                </Field>
                                <Field className={'w-full'}>
                                    <Label>{t("shops")}</Label>
                                    <Select
                                        selected={statistic.shop_value}
                                        noValuesSelectedMessage={t('allShops')}
                                        getDisplayValue={selected => (
                                            <DisplayValueForSingleSelect
                                                onClear={() => {
                                                    onChange('business_profile_uuid', null)
                                                    onChange('shop_value', null)
                                                }}
                                                label={shopOptions.find(shop => shop.uuid == selected)?.label}
                                            />
                                        )}
                                    >
                                        <SelectDropdown>
                                            {shopOptions.map(shop => (
                                                <SingleSelectOption
                                                    key={shop.uuid}
                                                    value={shop.uuid}
                                                    onSelect={selected => {
                                                        onChange('business_profile_uuid', selected)
                                                        onChange('shop_value', selected)
                                                    }}
                                                    selected={shop.uuid == statistic.shop_value}
                                                >
                                                    <OptionLayoutWithDescription
                                                        label={shop.label}
                                                    />
                                                </SingleSelectOption>
                                            ))}
                                        </SelectDropdown>
                                    </Select>
                                </Field>
                            </FieldLayout>
                        </FieldGroup>
                    </Fieldset>
                </div>
                <div className={'flex justify-center p-8'}>
                    <p className={'font-semibold text-4xl'}>{statistic.value}</p>
                </div>
            </CardContent>
        </Card>
    );
}