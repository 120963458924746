import React from "react";
import Text from "../../typography/Text";
import cn from "classnames";

export type RadioInputProps = {
    id: string;
    title: string;
    sub_title?: string;
    is_selected: boolean;
};

type Props = {
    id: string;
    selectedOption: any;
    setSelectedOption: any;
    option: RadioInputProps;
    onBlur: any;
};

export default function RadioInput(props: Props) {
    const { option } = props;

    const checked = option.id == props.selectedOption;

    const onChange = () => {
        props.setSelectedOption(option.id);
        props.onBlur(option.id);
    };

    const containerCn = cn(
        "flex flex-row gap-4 p-3 cursor-pointer flex-1 border border-gray-200 rounded-lg bg-white transition-all",
        option.sub_title ? '' : 'items-center',
        checked ? 'shadow-secondary-hover' : 'hover:shadow-primary-hover'
    );
    const inputCn = cn(
        "w-5 h-5 mx-1 text-blue-600 bg-white border-gray-200 focus:ring-primary-600 focus:ring-2 cursor-pointer",
        option.sub_title ? 'mt-2' : '',
    );

    return (
        <div
            className={containerCn}
            onClick={onChange}
        >
            <input
                id={props.id}
                type="radio"
                checked={checked}
                name={props.id}
                className={inputCn}
            />
            <div>
                <Text>{option.title}</Text>
                {option.sub_title && (
                    <Text size={"sm"} color={"text-gray-500"}>{option.sub_title}</Text>
                )}
            </div>
        </div>
    );
}