import React, { useState } from "react";

import Saturation from "@uiw/react-color-saturation";
import Hue from "@uiw/react-color-hue";

import { hexToHsva, hsvaToHex, } from '@uiw/color-convert';
import Swatch, { SwatchRectRenderProps } from "@uiw/react-color-swatch";
import { trim } from "lodash";

type Props = {
    value: any;
    onChange: any;
    colors: string[];
};

export const ColorPicker = (props: Props) => {
    const { value, onChange, colors } = props;
    const [hsva, setHsva] = useState({ h: 0, s: 0, v: 68, a: 1 });

    const sanitizeInput = (value: string) => {
        let color = value.replace(/[^A-Za-z0-9\s!?]/g,'');
        color = "#" + trim(color, "#");
        return color;
    };

    return (
        <div className={"space-y-3 rounded border bg-white p-4"}>
            <div>
                <Saturation
                    hsva={hsva}
                    onChange={(newColor) => {
                        onChange(hsvaToHex(hsva));
                        setHsva({ ...hsva, ...newColor, a: hsva.a });
                    }}
                />
            </div>
            <div>
                <Hue
                    hue={hsva.h}
                    style={{ width: '100%' }}
                    bgProps={{
                        style: { borderRadius: 2 },
                    }}
                    onChange={(newHue) => {
                        onChange(hsvaToHex(hsva));
                        setHsva({ ...hsva, ...newHue });
                    }}
                />
            </div>
            <div>
                <Swatch
                    colors={props.colors}
                    color={value}
                    style={{
                        justifyContent: "space-between"
                    }}
                    rectRender={(props: SwatchRectRenderProps) => (
                        <div style={{ ...props.style, padding: "1rem" }} className={"border border-gray-100"} onClick={props.onClick}/>
                    )}
                    onChange={(hsva) => {
                        onChange(hsvaToHex(hsva));
                        setHsva(hsva);
                    }}
                />
            </div>
            <div>
                <input
                    className={`align-left w-full rounded border border-gray-100 bg-gray-25 p-1.5 text-center text-sm transition-all`}
                    value={value ?? ""}
                    onChange={(event) => {
                        onChange(sanitizeInput(event.target.value));
                        if (event.target.value.length > 2) {
                            setHsva(hexToHsva(event.target.value));
                        }
                    }}
                />
            </div>
        </div>
    );
};