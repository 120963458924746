import React, { useState } from "react";
import { Button } from "@/components/v2/Actions/button";
import { useTrans } from "components";

export const chunkErrors = [
    "Loading chunk",
    "Loading CSS chunk",
    "ChunkLoadError",
    "Unexpected token '<'",
    "Unexpected token",
    "Unexpected token <",
    "expected expression, got '<'",
];

const wasChunkError = (error: any) => chunkErrors.some((err) => error.message?.includes(err));

export default function ErrorHasOccurred(props: any) {
    const error = props.error;
    const { t } = useTrans();
    const newVersionAvailable = wasChunkError(error);
    const [pending, setPending] = useState(false);

    return (
        <div className="default-bg-colors flex h-screen items-center justify-center">
            <div className={"overflow-hidden rounded-lg border border-gray-300 bg-white shadow-lg"}>

                <div className={"border-b border-gray-200 bg-gray-50 px-6 pb-3 pt-4 shadow"}>
                    <h2 className={"text-3xl"}>
                        {newVersionAvailable ? t("refresh") : " Oops!"}
                    </h2>
                </div>

                <p className={"mt-4 px-6 text-gray-900"}>
                    {newVersionAvailable ? t("newVersionAvailable") : t("sorrySomethingWentWrong")}
                </p>

                <div className={"mt-10 p-6"}>
                    <Button
                        type="button"
                        variant={"primary"}
                        isLoading={pending}
                        onClick={() => {
                            setPending(true);
                            window.location.replace(`/home`);
                        }}
                        disabled={pending}
                    >
                        {newVersionAvailable ? t("refresh") : t("back")}
                    </Button>
                </div>
            </div>
        </div>
    );
}