import React, { useState } from 'react';
import { useToast, useTrans } from "components";
import useAuth from "components/hooks/auth/useAuth";
import useRouting from "components/hooks/useRouting";
import useOnChange from "components/hooks/forms/useOnChange";
import Form from "components/forms/Form";
import AuthContainer from "components/auth/AuthContainer";
import Divider from "components/ui/Divider";
import { afterLogin, checkEmail } from "@/routing/Router";
import useGtm from "components/hooks/useGTM";
import GoogleAuth from "components/auth/GoogleAuth";
import useHttp from "core/http/useHttp";
import { urls } from "core/services/urls/urls";
import { paths } from "@/routing/paths";
import { NavLink } from "react-router";
import { encodeForUrl } from "core";
import AuthFooter from "@/components/auth/AuthFooter";
import AuthHeader from "@/components/auth/AuthHeader";
import AuthCard from "@/components/auth/AuthCard";
import { Field, FieldGroup, FieldLayout, Fieldset, Label } from "@/components/v2/SelectionAndInput/fieldset";
import { TextInput } from "@/components/v2/SelectionAndInput/textInput";
import { Button } from "@/components/v2/Actions/button";
import { Checkbox, CheckboxField } from "@/components/v2/SelectionAndInput/checkbox";
import PasswordInput from "@/components/v2/SelectionAndInput/PasswordInput";

export default function SignUp() {
    const { t } = useTrans();
    const gtm = useGtm();
    const http = useHttp();
    const { login } = useAuth();
    const { push, queryParams } = useRouting();
    const { successToast, errorToast } = useToast();
    const [pending, setPending] = useState(false);
    const [payload, setPayload] = useState({
        firstname: queryParams.firstName ?? "",
        lastname: queryParams.lastName ?? "",
        email: queryParams.email ? queryParams.email.toString() : "",
        password: "",
        password_confirmation: "",
        terms_and_conditions: false,
    });
    const onChange = useOnChange(setPayload);
    const isPayloadValid = Boolean(
        payload.firstname &&
        payload.lastname &&
        payload.email &&
        payload.password &&
        payload.password_confirmation &&
        payload.terms_and_conditions
    );

    const submit = () => {
        setPending(true);
        http.post(`/sign-up`, payload)
            .then(() => {
                successToast(t("signUpSuccessfulCheckYourInbox"));

                gtm.push({
                    event: "sign_up",
                });

                push(`${checkEmail}?email=${encodeForUrl(payload.email)}`);
            })
            .catch(e => {
                errorToast(e);
                setPending(false);
            });
    };

    const handleLogin = (token: string) => {
        successToast(t("signUpWasSuccessful"));
        login(token);
        push(afterLogin);
    };

    return (
        <AuthContainer>
            <AuthCard>
                <AuthHeader
                    title={t('signUp')}
                    description={t('tryPiggyForFree')}
                />
                <div className={"flex flex-col gap-7"}>
                    <div className={"flex justify-center"}>
                        <GoogleAuth
                            onError={errorToast}
                            onSuccess={(res: any) => handleLogin(res.data.token)}
                            authUrl={"/social/google/sign-up"}
                        />
                    </div>

                    <Divider text={t('or')}/>

                    <Form onSubmit={submit} className={"flex flex-col gap-3"}>
                        <Fieldset>
                            <FieldGroup>
                                <FieldLayout>
                                    <Field className={"w-full"}>
                                        <Label>{t("firstName")}</Label>
                                        <TextInput
                                            name={"firstname"}
                                            placeholder={t("firstName")}
                                            autoComplete={"given-name"}
                                            value={payload.firstname}
                                            type={"text"}
                                            required={true}
                                            onChange={e => onChange(e.target.name, e.target.value)}
                                        />
                                    </Field>
                                    <Field className={"w-full"}>
                                        <Label>{t("lastName")}</Label>
                                        <TextInput
                                            name={"lastname"}
                                            placeholder={t("lastName")}
                                            autoComplete={"family-name"}
                                            value={payload.lastname}
                                            type={"text"}
                                            required={true}
                                            onChange={e => onChange(e.target.name, e.target.value)}
                                        />
                                    </Field>
                                </FieldLayout>
                                <Field>
                                    <Label>{t("email")}</Label>
                                    <TextInput
                                        name={"email"}
                                        placeholder={t("email")}
                                        autoComplete={"email"}
                                        value={payload.email}
                                        type={"email"}
                                        required={true}
                                        onChange={e => onChange(e.target.name, e.target.value)}
                                    />
                                </Field>
                                <Field>
                                    <Label>{t("password")}</Label>
                                    <PasswordInput
                                        name={"password"}
                                        placeholder={t("password")}
                                        autoComplete={"new-password"}
                                        value={payload.password}
                                        required={true}
                                        onChange={e => onChange(e.target.name, e.target.value)}
                                    />
                                </Field>
                                <Field>
                                    <Label>{t("confirmPassword")}</Label>
                                    <PasswordInput
                                        name={"password_confirmation"}
                                        placeholder={t("confirmPassword")}
                                        autoComplete={"new-password"}
                                        value={payload.password_confirmation}
                                        required={true}
                                        onChange={e => onChange(e.target.name, e.target.value)}
                                    />
                                </Field>
                                <CheckboxField>
                                    <Checkbox
                                        name="terms_and_conditions"
                                        checked={payload.terms_and_conditions}
                                        onClick={() => onChange("terms_and_conditions", !payload.terms_and_conditions)}
                                    />
                                    <Label
                                        onClickCapture={(e) => e.stopPropagation()}
                                        dangerouslySetInnerHTML={{
                                            __html: t('iAgreeWithTheTerms', {
                                                terms: urls.TERMS_AND_CONDITIONS,
                                                privacy: urls.TERMS_AND_CONDITIONS,
                                            }),
                                        }}
                                    />
                                </CheckboxField>
                            </FieldGroup>
                        </Fieldset>

                        <Button
                            isLoading={pending}
                            variant={"primary"}
                            disabled={!isPayloadValid}
                            type={'submit'}
                        >
                            {t('create')}
                        </Button>
                    </Form>

                    <Divider/>

                    <div className={'flex items-center justify-center gap-2'}>
                        <p className={'text-sm text-gray-900'}>
                            {t('alreadyHaveAnAccount')}
                        </p>
                        <NavLink to={paths.login}>
                            <p className={"text-sm text-primary-600 hover:underline"}>
                                {t('login')} &rarr;
                            </p>
                        </NavLink>
                    </div>
                </div>
            </AuthCard>
            <AuthFooter/>
        </AuthContainer>
    );
}