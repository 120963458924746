import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import useFilterValues from "modules/loyalty/transactions/useFilterValues";
import Table from "components/table/Table";
import { useToast, useTrans } from "components";
import FormGroup from "components/forms/FormGroup";
import FormElement from "components/forms/FormElement";
import Modal from "components/modals/types/Modal";
import useHttp from "core/http/useHttp";
import useModal from "components/hooks/useModal";
import useOnChange from "components/hooks/forms/useOnChange";
import TableRow from "components/table/TableRow";
import TableHeading from "components/table/TableHeading";
import TableCell from "@/components/table/TableCell";
import TableHead from "components/table/TableHead";
import TableBody from "components/table/TableBody";
import TablePagination from "components/table/TablePagination";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import BusinessProfileSearch from "components/forms/Search/BusinessProfileSearch";
import usePaginatedResponse from "components/table/hooks/usePaginatedResponse";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";

export default function Transactions() {
    const { t } = useTrans();
    const { cardId } = useParams<{ cardId: ID }>();
    const [createPending, setCreatePending] = useState(false);
    const { account } = useContext(AccountContext);
    const modal = useModal();
    const { errorToast, successToast } = useToast();
    const http = useHttp();
    const [filterValues, setFilterValues, onFilterChange] = useFilterValues();
    const apiUrl = `/accounts/${account.id}/giftcards/cards/${cardId}/transactions`;
    const [values, setValues] = useState<Dict>({});
    const { paginatedResponse, setPaginatedResponse } = usePaginatedResponse<{
        id: number,
        amount: number,
        business_profile: number,
        type: string,
        channel: number,
        created_at: number,
    }>();
    const onChange = useOnChange(setValues);
    const { pending, mutate } = useFetcher({
        url: apiUrl,
        params: filterValues,
        dataSetter: setPaginatedResponse,
    });

    const create = () => {
        setCreatePending(true);
        http.post(apiUrl, values)
            .then(res => {
                mutate();
                successToast(res.data.data.message);
                modal.close();
                setValues({});
            })
            .catch(errorToast)
            .finally(() => setCreatePending(false));
    };

    return (
        <PageContent
            title={t("transactions")}
            actions={
                <ButtonComponent
                    key={'create'}
                    button_style={ButtonStyle.Primary}
                    label={t('newTransaction')}
                    icon={'plusRound'}
                    onClick={modal.open}
                    pending={pending}
                />
            }
        >
            <ContentCard>
                <Table>
                    <TableHead>
                        <TableHeading>
                            {t("amount")}
                        </TableHeading>
                        <TableHeading>
                            {t("businessProfile")}
                        </TableHeading>
                        <TableHeading>
                            {t("type")}
                        </TableHeading>
                        <TableHeading>
                            {t("channel")}
                        </TableHeading>
                        <TableHeading>
                            {t("createdAt")}
                        </TableHeading>
                    </TableHead>

                    <TableBody>
                        {paginatedResponse.data.map(transaction => (
                            <TableRow key={transaction.id}>
                                <TableCell>
                                    {transaction.amount}
                                </TableCell>
                                <TableCell>
                                    {transaction.business_profile}
                                </TableCell>
                                <TableCell>
                                    {transaction.type}
                                </TableCell>
                                <TableCell>
                                    {transaction.channel}
                                </TableCell>
                                <TableCell>
                                    {transaction.created_at}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    {...paginatedResponse}
                    limit={filterValues.limit}
                    onPageClick={page => onFilterChange("page", page)}
                    onLimitChange={limit => onFilterChange("limit", limit)}
                />
            </ContentCard>

            <Modal
                modal={modal}
                title={t("create")}
                primaryButton={{
                    onClick: create,
                    pending: createPending,
                    disabled: createPending,
                }}
            >
                <FormGroup modal={true}>
                    <FormElement
                        label={t("amount")}
                        type={"number"}
                        name={"amount"}
                        value={values.amount ?? ""}
                        onChange={onChange}
                    />

                    <BusinessProfileSearch
                        name={"shop"}
                        value={values.business_profile}
                        onChange={onChange}
                    />
                </FormGroup>
            </Modal>

        </PageContent>
    );
}

