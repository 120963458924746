import { getToken } from "core";
import Account from "models/account/Account";
import useLocalStorage from "components/hooks/localStorage/useLocalStorage";
import { urls } from "core/services/urls/urls";
import useAnalytics from "@/analytics/mixPanel/useAnalytics";
import useRouting from "components/hooks/useRouting";

export default function useAuth() {
    const analytics = useAnalytics();
    const { set, destroy } = useLocalStorage();
    const { push } = useRouting();

    function switchToAccount(newAccount: Account) {
        set("currentAccountId", newAccount.id);
        clearSelectedPrograms();
    }

    function isLoggedIn(): boolean {
        return getToken() !== null;
    }

    function login(token: string) {
        localStorage.setItem("token", token);
    }

    function logout(pushToLogin = false) {
        try {
            return fetch(`${urls.API_HOST}/api/v1/business/logout`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
                .finally(() => {

                    clearLocalStorageVariables();

                    if (pushToLogin) {
                        push("/login");
                    }

                    analytics.forgetUser();
                });
        } catch (error) {
            clearLocalStorageVariables();

            if (pushToLogin) {
                push("/login");
            }
        }
    }

    function clearSelectedPrograms() {
        destroy("selected_giftcard_program");
        destroy("selected_stripcard_program");
    }

    function clearLocalStorageVariables() {
        destroy("token");
        destroy("currentAccountId");
        clearSelectedPrograms();
    }

    return {
        isLoggedIn,
        logout,
        login,
        switchToAccount,
        getToken,
        clearSelectedPrograms
    };
}