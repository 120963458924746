import React, { KeyboardEvent } from "react";
import Text from "components/typography/Text";
import { X } from 'lucide-react';

type Props = {
    title: string;
    canEditTitle?: boolean;
    closeAction?: VoidFn;
    onTitleBlur?: (title: string) => Promise<any>
    classNames?: string;
};

export default function PanelHeader(props: Props) {
    const { title, onTitleBlur, classNames, closeAction } = props;
    const canEditTitle = props.canEditTitle === undefined ? false : props.canEditTitle;

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" || event.key === "Escape") {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
        }
    };

    return (
        <div className={`header_v2 ${classNames ? classNames : ""}`}>
            <div className={"header_v2__heading-wrapper items-center gap-2"}>
                {canEditTitle ? (
                    <input
                        key={title}
                        className={"mx-0 my-auto mr-4 grow rounded-md border border-white bg-[unset] p-3 text-xl text-white transition-all duration-200 hover:border-primary-50 focus:border-primary-50 focus:outline-none"}
                        defaultValue={title}
                        onKeyDown={onKeyDown}
                        onBlur={(event) => onTitleBlur(event.target.value)}
                    />
                ) : (
                    <Text size={"xl"} color={"text-gray-25"}>{title}</Text>
                )}
            </div>

            {closeAction && (
                <button onClick={closeAction}>
                    <X className={"size-4 text-white"} />
                </button>
            )}
        </div>
    );
}
