import React from 'react';

type Props = {
    className?: string;
    children: React.ReactNode;
};

export default function CardContent(props: Props) {
    return (
        <div className={`p-5 ${props.className ? props.className : ""}`}>
            {props.children}
        </div>
    );
}