import React from "react";

type Props = {
    children: JSX.Element | JSX.Element[];
};

export default function PageBody(props: Props) {
    return (
        <div
            className={`w-screen max-w-[100vw]`}
        >
            {props.children}
        </div>
    );
}
