export enum Feature {
    EVENTS = "events",
    SHIFTS = "shifts",
    VISITS = "visits",
    LOYALTY = "loyalty",
    BOOKINGS = "bookings",
    LIFECYCLE = "lifecycle",
    REFERRALS = "referrals",
    STRIPCARDS = "stripcards",
}

export type GiftcardProgram = {
    id: number;
    uuid: string;
    name: string;
    image_url: string;
};

export default class Account {
    id: number;
    uuid: string;
    company: string;
    industry: string;
    currency: string;
    locale: string;
    timezone: string;
    default_home_page: string;
    country: string;
    email: string;
    secret: string;
    is_leat_account: boolean;
    features: Partial<Record<Feature, boolean>> = {};
    is_demo_account: boolean;
    uses_stripcards: boolean;
    uses_customer_app: boolean;
    uses_portal_sessions: boolean;
    giftcard_programs: GiftcardProgram[] = [];

    usesFeature(feature: Feature) {
        return this.features[feature] === true;
    }
}

