import React, { forwardRef, useEffect, useImperativeHandle, useState, } from 'react';
import List from "@/components/forms/TipTap/mergeTagsMention/List";
import { ChevronRight } from "lucide-react";

export type TagOption = {
    id: string,
    display: string
    hasChildren: boolean;
};

export type MergeTagGroup = {
    title: string;
    type: string;
    options: any[];
};

type Props = {
    items: MergeTagGroup[],
    query: string;
    command: any;
};

export default forwardRef((props: Props, ref): any => {
    const { query, items } = props;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [filtered, setFiltered] = useState<TagOption[]>([]);
    const [selectedMergeTagCategory, setSelectedMergeTagCategory] = useState(null);

    useEffect(() => {
        if (selectedMergeTagCategory || query) {
            const filtered = items
                .filter(i => selectedMergeTagCategory.type ? i.type == selectedMergeTagCategory.type : i)
                .flatMap(i => i.options)
                .map(o => ({
                    id: o.value,
                    display: o.title,
                    hasChildren: false,
                }))
                .filter(item => JSON.stringify(item).toLowerCase().includes(query.toLowerCase()));

            setFiltered(filtered);
        } else {
            const filtered = items
                .map(o => ({
                    id: o.type,
                    display: o.title,
                    hasChildren: true,
                }));

            setFiltered(filtered);
        }
    }, [selectedMergeTagCategory, query]);

    const selectItem = (index: number) => {
        const item = filtered[index];

        if (item) {
            if (item.hasChildren) {
                setSelectedMergeTagCategory(item);
            } else {
                props.command({
                    id: item.id,
                    value: item.id,
                    label: selectedMergeTagCategory.display + ": " + item.display
                });
            }
        }
    };

    const upHandler = () => {
        setSelectedIndex((selectedIndex + filtered.length - 1) % filtered.length);
    };

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % filtered.length);
    };

    const enterHandler = () => {
        selectItem(selectedIndex);
    };

    useEffect(() => {
        setSelectedIndex(0);
    }, [filtered]);

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }: any) => {
            if (event.key === 'ArrowUp') {
                upHandler();
                return true;
            }

            if (event.key === 'ArrowDown') {
                downHandler();
                return true;
            }

            if (event.key === 'ArrowLeft' && selectedMergeTagCategory) {
                setSelectedMergeTagCategory(null);

                return true;
            }

            if (event.key === 'ArrowRight' && !selectedMergeTagCategory) {
                selectItem(selectedIndex);

                return true;
            }

            if (event.key === 'Enter') {
                enterHandler();
                return true;
            }

            return false;
        },
    }));

    return (
        <List
            selectedIndex={selectedIndex}
            items={filtered}
            onSelect={selectItem}
            render={item => (
                <div className={'flex items-center justify-between gap-6'}>
                    <div className={'text-sm'}>{item.display}</div>
                    {item.hasChildren && <ChevronRight className={'size-4'}/>}
                </div>
            )}
        />
    );
});