import React, { useEffect, useState } from "react";
import { SidebarContext } from "./context/SidebarContext";
import { depth } from "core/depth";
import useRouting from "@/components/hooks/useRouting";

type Props = {
    className?: string;
    children: React.ReactNode;
};

export default function Sidebar(props: Props) {
    const [isOpen, setOpen] = useState(false);
    const { currentPath } = useRouting();
    const [activeParent, setActiveParent] = useState<string>(currentPath);

    useEffect(() => {
        function onKeydown(evt: KeyboardEvent) {
            if ("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) {
                setOpen(false);
            }
        }

        window.addEventListener("keydown", onKeydown);

        return () => window.removeEventListener("keydown", onKeydown);
    }, [isOpen]);

    return (
        <SidebarContext.Provider value={{
            isOpen: isOpen,
            setOpen: setOpen,
            activeParent: activeParent,
            setActiveParent: setActiveParent,
        }}
        >
            <aside
                style={{ zIndex: depth.navigation - 1 }}
                className={`fixed h-screen w-0 max-w-72 overflow-y-auto overflow-x-hidden bg-gray-700 pb-12 shadow-xl lg:block lg:w-72 lg:min-w-72 dark:border-r-gray-300 dark:bg-slate-800 lg:dark:border-r ${isOpen ? "absolute min-w-full" : ""}`}
            >
                {props.children}
            </aside>

            <button
                id={"mobile-sidebar-nav"}
                style={{ zIndex: depth.navigation - 1 }}
                className={`hover:bg-white/16 absolute left-2 top-7 rounded-full border border-slate-100 bg-gray-700 p-2 text-white shadow-xl lg:hidden`}
                onClick={() => setOpen(!isOpen)}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                >
                    {isOpen ? (
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                    ) : (
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                        />
                    )}
                </svg>
            </button>
        </SidebarContext.Provider>
    );
}
