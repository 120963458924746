import React, { ForwardedRef } from "react";
import cn from "classnames";
import Text from "../typography/Text";
import { IconType } from "../icons/Icon";
import ButtonComponent, { ButtonProps as ButtonComponentButtonProps } from "../buttons/ButtonComponent";

export type BannerStyles = "default" | "warning" | "primary" | "success" | "danger";

type BannerProps = {
    style: BannerStyles;
    body: string;
    title?: any;
    icon?: IconType;
    hidden?: boolean;
    alignment?: string;
    primaryButton?: ButtonComponentButtonProps;
    secondaryButton?: ButtonComponentButtonProps;
};

type ButtonProps = {
    onButtonClick?: any;
    buttonLabel?: string;
};

const InfoBanner = React.forwardRef((props: BannerProps & ButtonProps, ref: ForwardedRef<any>) => {
    const { body, title, style, icon, alignment = 'left', hidden = false } = props;

    const containerCn = cn(
        style === "default" ? "text-gray-700 bg-gray-50" : "",
        style === "warning" ? "text-gray-700 bg-amber-50" : "",
        style === "primary" ? "text-gray-700 bg-primary-100" : "",
        style === "success" ? "text-gray-700 bg-success-50" : "",
        style === "danger" ? "text-error-700 bg-error-50" : "",
    );

    const iconCn: string = cn(
        style === "default" ? "bg-gray-600" : "",
        style === "warning" ? "bg-amber-400" : "",
        style === "primary" ? "bg-primary-600" : "",
        style === "success" ? "bg-green-600" : "",
        style === "danger" ? "bg-red-600" : "",
    );

    const alignmentCn = cn(
        { "items-start": alignment === "left" },
        { "items-center": alignment === "center" },
    );

    return (
        <div
            className={`flex flex-col gap-4 rounded-lg border p-4 transition-all ${containerCn} ${alignmentCn}`}
            role="alert"
            hidden={hidden}
        >
            {/* The "info" icon is temporarily commented out, must come back to this later */}
            {/*<div>*/}
            {/*    <Icon type={icon ??  "info"} attr={"div"} className={`w-6 h-6 ${iconCn}`}/>*/}
            {/*</div>*/}
            {title && (
                <Text
                    render_as={"p"}
                    size={"md"}
                    weight={"normal"}
                    className={"pb-1 text-inherit"}
                    dangerously_set_inner_html={true}
                >
                    {title}
                </Text>
            )}
            <Text
                render_as={"p"}
                size={"sm"}
                weight={"extralight"}
                className={"text-inherit"}
                dangerously_set_inner_html={true}
            >
                {body}
            </Text>

            {(props.primaryButton || props.secondaryButton) && (
                <div className={"flex gap-2"}>
                    {props.primaryButton && <ButtonComponent {...props.primaryButton}/>}
                    {props.secondaryButton && <ButtonComponent {...props.secondaryButton}/>}
                </div>
            )}
        </div>
    );
});

InfoBanner.displayName = "InfoBanner";

export default InfoBanner;
