import React from "react";
import cn from "classnames";
import Text from "components/typography/Text";
import LimitAndShowPicker from "components/pagination/Table/LimitAndShowPicker";
import Icon from "components/icons/Icon";

type Props = {
    pagination: any;
    onFilterChange: any;
    filterValues?: any;
    position?: string;
    classes?: string;
};

export default function Pagination(props: Props) {
    const { pagination, filterValues, onFilterChange } = props;
    const classes = cn(
        props.classes,
        'h-16 border border-t-0 border-gray-900/10 rounded-b-lg p-4 z-10',
    );

    const isLastPage = pagination.next ? false : (pagination.last_page === pagination.page) || (pagination.lastPage === pagination.page);

    const getPagesRange = (start: number, total: number) => {
        const paginationRange = [];
        const startingPage = start <= 3 ? 1 : start - 1;

        const createLink = (i: number) => {
            return (
                <button
                    key={`page_number__${i}`}
                    className={`mx-1 flex size-7 cursor-pointer items-center justify-center ${pagination.page === i ? 'rounded-full border' : ''}`}
                    onClick={() => onFilterChange("page", i)}
                >
                    <Text
                        color={"text-gray-500 dark:text-white"}
                        weight={"extralight"}
                        size={"sm"}
                        className={`${pagination.page === i ? 'p-0.5 text-opacity-60' : ''}`}
                        key={`page_text__${i}`}
                    >
                        {i}
                    </Text>
                </button>
            );
        };

        const createDots = () => {
            return <Text
                key="dots"
                color={"text-gray-500"}
                weight={'light'}
                className={'mr-1 mt-0.5 h-7 px-1'}
            >...</Text>;
        };

        // If there are no pages return nothing
        if (total < 1) return [];

        // If totalPages is less than 6, iterate
        // over that number and return the page links
        // Otherwise create the first three page links
        // with dots and last page link.
        if (total < 6) {
            for (let i = 1; i <= total; i++) {
                paginationRange.push(createLink(i));
            }

            return paginationRange;
        }

        if (total && start < total - 2) {
            for (let i = startingPage; i <= startingPage + 2 && i < total; i++) {
                paginationRange.push(createLink(i));
            }

            // Stepper the dots
            paginationRange.push(createDots());

            // Last page link
            for (let i = total; i <= total; i++) {
                paginationRange.push(createLink(i));
            }

            return paginationRange;
        }

        if (total >= start - 2) {
            paginationRange.push(createLink(1));

            // Stepper the dots
            paginationRange.push(createDots());

            // Last links
            for (let i = total - 2; i <= total; i++) {
                paginationRange.push(createLink(i));
            }

            return paginationRange;
        }
    };

    return (
        <div className={classes}>
            <div className="flex items-center justify-between gap-4">
                <LimitAndShowPicker
                    pagination={pagination}
                    filterValues={filterValues}
                    onChange={(event: any) => onFilterChange("limit", event.target.value)}
                />

                <div className={'flex'}>
                    <button
                        onClick={() => onFilterChange("page", pagination.page - 1)}
                        className={`flex size-7 items-center justify-center rounded-full enabled:active:bg-primary-25 disabled:cursor-not-allowed`}
                        disabled={pagination.page == 1}
                    >
                        <Icon type={"chevronLeftGray500"} attr={"svg"}/>
                    </button>

                    <div className={'flex'}>
                        {getPagesRange(pagination.page, pagination.last_page)}
                    </div>

                    <button
                        className={`flex size-7 items-center justify-center rounded-full active:bg-primary-25 ${isLastPage ? 'cursor-not-allowed' : ''}`}
                        onClick={() => onFilterChange("page", pagination.page + 1)}
                        disabled={isLastPage}
                    >
                        <Icon type={"chevronLeftGray500"} attr={"svg"} className={'rotate-180 dark:text-white'}/>
                    </button>
                </div>
            </div>
        </div>
    );
}
