import React from "react";
import NavItemWithoutDropdown from "@/components/navigation/desktop/NavItemWithoutDropdown";

export default function Automation() {

    return (
        <NavItemWithoutDropdown
            path={"/automation"}
            label={"Automation"}
        />
    );
}

