import { useState } from "react";

export default function useTableOverflow(wrapperRef: any) {
    const [isOverflowHeight, setIsOverflowHeight] = useState(false);
    const [isOverflowWidth, setIsOverflowWidth] = useState(false);

    const check = () => {
        if (wrapperRef.current.scrollHeight - wrapperRef.current.scrollTop === wrapperRef.current.clientHeight) {
            setIsOverflowHeight(false);
        } else {
            setIsOverflowHeight(true);
        }

        if (wrapperRef.current.scrollWidth - wrapperRef.current.scrollLeft === wrapperRef.current.clientWidth) {
            setIsOverflowWidth(false);
        } else {
            setIsOverflowWidth(true);
        }
    };

    return { isOverflowHeight, isOverflowWidth, check };
}
