import React from "react";

export default function SidebarHeader(props: any) {
    return (
        <div
            className={`mx-4 flex flex-col items-center justify-center gap-2 border-gray-500 pb-6 lg:items-start lg:justify-start lg:border-b`}
        >
            {props.children}
        </div>
    );
}
