import { useEffect } from "react";

export default function useAutoClose(ref: any, handler: any, updateCondition: any) {
    useEffect(() => {
            const listener = (event: any) => {
                if ( !ref ) {
                    return;
                }
                // Do nothing if clicking ref's element or descendent elements
                if ( !ref.current || ref.current.contains(event.target) ) {
                    return;
                }

                if ( updateCondition ) {
                    handler(event);
                }
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        [ref, handler]
    );
}
