export default function useLocalStorage() {

    const get = (key: string) => {
        return localStorage.getItem(key);
    };

    const set = (key: string, value: any) => {
        localStorage.setItem(key, value && value.toString());
    };

    const destroy = (key: string) => {
        localStorage.removeItem(key);
    };

    return { set, get, destroy };
}
