import VisibilityButton from "modules/crm/contacts/config/buttons/VisibilityButton";
import React from "react";
import { ConfigHeaderItemType } from "components/table_v2/types";
import SpinningWheel from "components/ui/SpinningWheel";
import DraggableButton from "modules/crm/contacts/config/buttons/DraggableButton";

type Props = {
    item: ConfigHeaderItemType
    pending: boolean;
    onClick: (item: ConfigHeaderItemType) => void;
    isShown: boolean;
};

export default function ConfigHeaderItem(Props: Props) {
    const { item, onClick, pending, isShown } = Props;

    return (
        <div
            className={"mx-2 flex flex-row justify-between rounded bg-white hover:bg-primary-25 hover:ring-2 hover:ring-primary-600/[.1]"}
            id={item.id}
            key={`draggable-config-header-item-${item.id}`}
        >
            <div className={"m-2 flex flex-row gap-1 truncate"}>
                {isShown ?
                    <DraggableButton
                        icon={item.drag_icon}
                        className={"draggable-item cursor-grab"}
                        iconClassName={""}
                    />
                    :
                    <div></div>
                }
                <div className={`font-extralight m-auto flex truncate ${isShown ? 'text-gray-500' : 'text-gray-400'}`}>
                    <p className={"... truncate"}>{item.label}</p>
                </div>
            </div>
            <div className={"m-2 flex truncate rounded-md hover:bg-primary-100"}>
                {pending ? (
                    <SpinningWheel/>
                ) : (
                    <VisibilityButton
                        icon={item.visibility_icon}
                        iconClassName={item.disabled ? '' : 'cursor-pointer'}
                        onClick={item.disabled ? null : () => onClick(item)}
                    />
                )}
            </div>
        </div>
    );
}
