import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import { useToast, useTrans } from "components";
import useHttp from "core/http/useHttp";
import useModal from "components/hooks/useModal";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import DisplayElement from "components/DisplayElement";
import FormGroup from "components/forms/FormGroup";

export default function Information() {
    const { t } = useTrans();
    const { cardId } = useParams<{ cardId: ID }>();
    const [createPending, setCreatePending] = useState(false);
    const { account } = useContext(AccountContext);
    const modal = useModal();
    const { errorToast, successToast } = useToast();
    const http = useHttp();
    const apiUrl = `/accounts/${account.id}/giftcards/cards/${cardId}/information`;
    const [values, setValues] = useState<Dict>({});
    const [card, setCard] = useState<Dict>({});
    const { pending, mutate } = useFetcher({
        url: apiUrl,
        dataSetter: setCard,
    });

    const create = () => {
        setCreatePending(true);
        http.post(apiUrl, values)
            .then(res => {
                mutate();
                successToast(res.data.data.message);
                modal.close();
                setValues({});
            })
            .catch(errorToast)
            .finally(() => setCreatePending(false));
    };

    return (
        <PageContent
            title={t("information")}
        >
            <ContentCard>
                <FormGroup>
                    <DisplayElement type={"text"} value={card.type} label={t("type")}/>
                    <DisplayElement type={"text"} value={card.hash} label={t("hash")}/>
                    <DisplayElement type={"text"} value={card.amount} label={t("amount")}/>
                    <DisplayElement type={"text"} value={card.created_at} label={t("createdAt")}/>
                    <DisplayElement type={"text"} value={card.updated_at} label={t("updatedAt")}/>
                </FormGroup>
            </ContentCard>
        </PageContent>
    );
}

