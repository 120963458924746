import React from 'react';

type Props = {
    children: React.ReactNode;
};

export default function AuthContainer(props: Props) {

    return (
        <main
            className={'flex min-h-screen flex-col items-center justify-between bg-white pt-4 sm:bg-primary-50 sm:pt-36 dark:bg-gray-900'}
        >
            {props.children}
        </main>
    );
}