import { useEffect, useState } from "react";

export interface Tab {
    key: string;
    name: string;
    hidden?: boolean;
    disabled?: boolean;
    tooltip?: {
        text: string,
        knowledgeBaseLink?: string,
    }
    apiUrl?: string;

    [key: string]: any
}

/**
 * Simple copy for from normal useTabs but without url state
 * @param initialTabs
 * @param deps
 * @param initFirstTab
 */
export default function useLocalTabs(initialTabs: Tab[] = [], deps: any[] = [], initFirstTab = true) {
    const [tabs, setTabs] = useState(initialTabs);
    const [activeTab, setTab] = useState<Tab>(initFirstTab ? initialTabs[0] : null);

    useEffect(() => {
        setTabs(initialTabs);
    }, deps);

    useEffect(() => {
        // When tabs are async
        if (tabs.length == 0) {
            return;
        }

        if (initFirstTab) {
            setActiveTab(tabs[0]);
        }
    }, [tabs, ...deps]);

    function setActiveTab(tab: Tab) {
        setTab(tab);
    }

    return { activeTab, setActiveTab, tabs, setTabs };
}
