import React from 'react';
import { ChevronLeftIcon } from "@heroicons/react/24/solid";

type Props = {
    title: string;
};

const AuthHeader = (props: Props) => {
    return (
        <button className={`mb-4 flex items-center gap-2 `}>
            <ChevronLeftIcon className={"size-4 text-buttonColor outline-gray-500"}/>
            <p className={'font-[unset] text-lg text-buttonColor'}>{props.title}</p>
        </button>
    );
};

export default AuthHeader;