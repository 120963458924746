import React from 'react';
import cn from "classnames";
import { TailwindBorderColors } from "components/colors/colors";

type Props = {
    hidden?: boolean;
    color?: TailwindBorderColors,
    className?: string;
    text?: string;
};

export default function Divider(props: Props) {

    const hidden = props.hidden !== undefined ? props.hidden : false;

    const dividerCn = cn(
        "border-b h-px relative",
        props.className,
        props.color
    );

    return (
        <div className={dividerCn} hidden={hidden}>
            &nbsp;
            <div className={"absolute -top-3 w-full text-center"}>
                {props.text && (
                    <span className={"font-extralight bg-white px-2 lowercase"}>{props.text}</span>
                )}
            </div>
        </div>
    );
}