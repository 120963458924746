import React from "react";
import PanelCloseButton from "components/panel_v2/panelV2/PanelCloseButton";

type Props = {
    title: string;
};

export default function PanelTitleAndCloseButton(props: Props) {
    return (
        <div className={"flex items-center gap-3"}>
            <PanelCloseButton/>
            <p className={"text-lg text-white"}>{props.title}</p>
        </div>
    );
}