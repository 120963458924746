import { useEffect, useState } from "react";
import useSWR from "swr";
import axios from 'axios';

const env = import.meta.env;
const currentVersion = env.VITE_VERSION;

export default function useVersion() {
    const [newVersionIsAvailable, setNewVersionIsAvailable] = useState(false);
    const [availableVersion, setAvailableVersion] = useState(null);
    const fetcher = (url: string) => axios.get(url).then(res => res.data);
    const { data } = useSWR('/version.json', fetcher, {
        revalidateOnFocus: true
    });

    useEffect(() => {
        if (!data) {
            return;
        }

        const newVersion = data.version;

        setAvailableVersion(newVersion);

        if (newVersion && currentVersion) { // Check if they are both available. Otherwise, do not show anything.
            if (currentVersion != newVersion) {
                setNewVersionIsAvailable(true);
                console.log(`New version available. Back-end version is: ${newVersion}. Current application version is: ${currentVersion}`);
            } else {
                console.log(`No new version available. Back-end version is: ${newVersion}. Current application version is: ${currentVersion}`);
            }
        }
    }, [data]);

    return {
        newVersionIsAvailable: newVersionIsAvailable,
        currentVersion: currentVersion,
        availableVersion: availableVersion,
    };
}
