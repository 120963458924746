import * as React from "react";
import { FunctionComponent } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const textVariants = cva(
    "antialiased disabled:text-text-disabled",
    {
        variants: {
            variant: {
                heading3xl: "font-tracking-tighter font-display text-[36px] font-medium leading-10",
                heading2xl: "font-tracking-tighter font-display text-[30px] font-medium leading-9",
                headingXl: "font-tracking-tight font-display text-[24px] font-medium leading-8",
                headingLg: "font-tracking-normal font-display text-[20px] font-medium leading-[1.625rem]",
                headingMd: "font-tracking-normal font-semibold font-body text-[16px] leading-6",
                headingSm: "font-tracking-normal font-semibold font-body text-[14px] leading-5",
                headingXs: "font-tracking-normal font-semibold font-body text-[13px] leading-5",
                bodyLg: "font-tracking-normal font-normal font-body text-[16px] leading-5",
                bodyMd: "font-tracking-normal font-normal font-body text-[14px] leading-5",
                bodySm: "font-tracking-normal font-normal font-body text-[13px] leading-4",
                bodyXs: "font-tracking-normal font-normal font-body text-[12px] leading-[14px]",
                inherit: ""
            },
            tone: {
                base: "text-text-default",
                secondary: "text-text-secondary",
                tertiary: "text-text-tertiary",
                disabled: "text-text-disabled",
                inherit: "text-inherit",
                success: "text-text-success-default",
                critical: "text-text-critical-default",
                warning: "text-text-warning-default",
                "text-inverse": "text-text-inverse-default",
                "text-inverse-secondary": "text-text-inverse-secondary",
            },
            fontWeight: {
                regular: "font-normal",
                medium: "font-medium",
                semibold: "font-semibold",
            },
            numeric: {
                false: null,
                true: "tabular-nums"
            },
            truncate: {
                false: null,
                true: "truncate"
            },
            visuallyHidden: {
                false: null,
                true: "invisible",
            },
            breakWord: {
                false: null,
                true: "break-words"
            }
        },
        compoundVariants: [
            {
                variant: "bodyLg",
                fontWeight: "medium",
                className: "font-tracking-normal font-body text-[14px] font-medium leading-5"
            }, {
                variant: "bodyLg",
                fontWeight: "semibold",
                className: "font-tracking-normal font-semibold font-body text-[14px] leading-5"
            }, {
                variant: "bodyMd",
                fontWeight: "medium",
                className: "font-tracking-normal font-body text-[13px] font-medium leading-5"
            }, {
                variant: "bodyMd",
                fontWeight: "semibold",
                className: "font-tracking-normal font-semibold font-body text-[13px] leading-5"
            }, {
                variant: "bodySm",
                fontWeight: "medium",
                className: "font-tracking-normal font-body text-[12px] font-medium leading-4"
            }, {
                variant: "bodySm",
                fontWeight: "semibold",
                className: "font-tracking-normal font-semibold font-body text-[12px] leading-4"
            },
            {
                variant: "bodyXs",
                fontWeight: "semibold",
                className: "font-tracking-normal font-semibold font-body text-[11px] leading-3"
            },

        ]
    }
);

interface TextProps
    extends React.HTMLAttributes<HTMLElement>, // Ensures type safety for all HTML attributes
        VariantProps<typeof textVariants> {
    as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "strong" | "legend";
    breakWord?: boolean;
    className?: string;
    id?: string;
    numeric?: boolean;
    truncate?: boolean;
    visuallyHidden?: boolean;
}


const Text: FunctionComponent<TextProps> = ({
                                                as = "p",
                                                children,
                                                className,
                                                fontWeight,
                                                variant = "bodyMd",
                                                numeric,
                                                tone,
                                                truncate,
                                                visuallyHidden,
                                                breakWord, ...props
                                            }) => {
    const Component = as;
    return (
        <Component
            className={cn(textVariants({
                fontWeight,
                variant,
                tone,
                numeric,
                truncate,
                className,
                visuallyHidden,
                breakWord
            }))}
            {...props}
        >
            {children}
        </Component>
    );
};


Text.displayName = "Text";

export { Text, textVariants };