import { useTrans } from "components";
import { AccountContext } from "context";
import { useContext } from "react";
import { CommandPaletteOption, CommandPaletteOptionType } from "../useCommandPaletteOptions";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";
import { Feature } from "models/account/Account";
import useRouting from "@/components/hooks/useRouting";

export default function useLoyaltyOptions(): CommandPaletteOption[] {
    const { t } = useTrans();
    const accountUser = useCurrentAccountUser();
    const { push } = useRouting();
    const { account } = useContext(AccountContext);

    const analytics = {
        visible: accountUser.hasPermission("loyalty"),
        group: t("navigation"),
        label: t("analytics"),
        description: "Loyalty",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/loyalty/analytics"),
        tags: [
            "analytics",
        ],
    };

    const rewards = {
        visible: accountUser.hasPermission("loyalty"),
        group: t("navigation"),
        label: t("rewards"),
        description: "Loyalty",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/loyalty/rewards"),
        tags: [
            "rewards",
        ],
    };

    const transactions = {
        visible: accountUser.hasPermission("loyalty"),
        group: t("navigation"),
        label: t("transactions"),
        description: "Loyalty",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/loyalty/transactions"),
        tags: [
            "transactions",
        ],
    };

    const tokens = {
        visible: accountUser.hasPermission("loyalty"),
        group: t("navigation"),
        label: "Tokens",
        description: "Loyalty",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/loyalty/tokens"),
        tags: [
            "tokens",
        ],
    };

    const staged = {
        visible: accountUser.hasPermission("loyalty"),
        group: t("navigation"),
        label: t("stagedCreditReceptions"),
        description: "Loyalty",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/loyalty/staged-credit-receptions"),
        tags: [
            "tokens",
        ],
    };

    const rules = {
        visible: accountUser.hasPermission("loyalty"),
        group: t("navigation"),
        label: t("rules"),
        description: "Loyalty",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/loyalty/rules"),
        tags: [
            "rules",
        ],
    };

    const tiers = {
        visible: accountUser.hasPermission("loyalty"),
        group: t("navigation"),
        label: t("tiers"),
        description: "Loyalty",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/loyalty/tiers"),
        tags: [
            "tiers",
        ],
    };

    const vouchers = {
        visible: accountUser.hasPermission("loyalty"),
        group: t("navigation"),
        label: t("promotionsAndVouchers"),
        description: "Loyalty",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/loyalty/promotion-engine/promotions"),
        tags: [
            "vouchers",
        ],
    };

    const referrals = {
        visible: accountUser.hasPermission("loyalty") && account.usesFeature(Feature.REFERRALS),
        group: t("navigation"),
        label: "Referrals",
        description: "Loyalty",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/loyalty/referrals"),
        tags: [
            "referrals",
        ],
    };

    const createReward = {
        visible: accountUser.hasPermission("loyalty"),
        group: t("create"),
        label: t("createANewReward"),
        description: t("rewards"),
        type: CommandPaletteOptionType.CREATE,
        handle: () => push("/loyalty/rewards/new"),
        tags: [
            t("reward"),
            "reward",
        ],
    };

    return [
        analytics,
        rewards,
        transactions,
        tokens,
        staged,
        rules,
        tiers,
        vouchers,
        referrals,
        createReward
    ];
}