import React, { useMemo } from "react";
import Row from "components/table_v2/components/row/Row";
import { HeaderItemType, Table } from "components/table_v2/types";
import BarebonesTable from "components/table_v2/components/BarebonesTable";
import Header from "components/table_v2/components/header/Header";
import { ButtonProps } from "components/buttons/ButtonComponent";

type Props = {
    table: Table;
    onRowClick?: (id: string) => void;
    pending: boolean;
    className?: string;
    filterValues?: any;
    onFilterChange?: any;
    emptyStateAction?: ButtonProps
    isPaginated?: boolean;
    actions?: JSX.Element | JSX.Element[];
    height?: "barebones" | "searchbar" | "tabs";
    onClickDropDownItem?: (item: any) => void;
    canOverflow?: boolean;
};

export default function SimpleBackendTable(props: Props) {
    const {
        table,
        pending,
        className,
        onFilterChange,
        isPaginated,
        canOverflow,
        emptyStateAction
    } = props;
    const height = props.height ? props.height : "barebones";
    const onHeaderClick = (item: HeaderItemType) => {
        onFilterChange && onFilterChange("order_by", item.sortable.key);
        onFilterChange && onFilterChange("direction", item.sortable.direction == "ASC" ? "DESC" : "ASC");
    };

    const header = useMemo(() => {
        return (
            <Header
                items={table.header.items}
                onHeaderClick={onHeaderClick}
            />
        );
    }, [table.header.items]);

    const rows = useMemo(() => {
        return table.rows.map(row =>
            <Row
                key={row.id}
                row={row}
                type={table.row_type}
                onRowClick={props.onRowClick ? props.onRowClick : row.on_row_click}
                onClickDropDownItem={props.onClickDropDownItem}
                canOverflow={canOverflow}
            />
        );
    }, [table.rows]);

    return (
        <BarebonesTable
            table={table}
            rows={rows}
            emptyStateAction={emptyStateAction}
            header={header}
            pending={pending}
            className={className}
            isPaginated={isPaginated}
            height={height}
            canOverflow={canOverflow}
        />
    );
}
