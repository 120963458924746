import React from "react";
import cn from "classnames";
import Text from "../typography/Text";

export type BannerStyles = "default" | "warning" | "primary" | "success" | "danger";

type BorderedBannerProps = {
    style: BannerStyles;
    body: string;
    title?: any;
    hidden?: boolean;
};

type ButtonProps = {
    onButtonClick?: any;
    buttonLabel?: string;
};

export default function Banner(props: BorderedBannerProps & ButtonProps) {
    const { body, title, style } = props;

    const containerCn = cn(
        style === "default" ? "text-gray-700 bg-gray-50 border-gray-200" : "",
        style === "warning" ? "text-gray-700 bg-amber-50 border-amber-200" : "",
        style === "primary" ? "text-gray-700 bg-primary-25 border-primary-200" : "",
        style === "success" ? "text-gray-700 bg-success-50 border-green-200" : "",
        style === "danger" ? "text-error-700 bg-error-50 border-error-200" : "",
    );

    const leftBorder = cn(
        style === "default" ? "border-gray-400" : "",
        style === "warning" ? "border-amber-400" : "",
        style === "primary" ? "border-primary-500" : "",
        style === "success" ? "border-success-500" : "",
        style === "danger" ? "border-error-500" : "",
    );

    return (
        <div
            className={`rounded-lg border-y border-r transition-all ${containerCn}`}
            role="alert"
            hidden={props.hidden}
        >
            <div className={`rounded-lg border-0 border-l-4 px-3 py-2 transition-all ${leftBorder}`}>
                <div className={'flex-col'}>
                    {title && (
                        <Text
                            render_as={"p"}
                            size={"sm"}
                            weight={"normal"}
                            className={"pb-1 text-inherit"}
                            dangerously_set_inner_html={true}
                        >
                            {title}
                        </Text>
                    )}
                    <Text
                        render_as={"p"}
                        size={"sm"}
                        weight={"light"}
                        className={"text-inherit"}
                        dangerously_set_inner_html={true}
                    >
                        {body}
                    </Text>
                    {props.onButtonClick && (
                        <button
                            className={"cursor-pointer text-primary-600 hover:underline"}
                            onClick={props.onButtonClick}
                        >
                            {props.buttonLabel}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
