import React from "react";

export default function ButtonsWrapper(props: {
    className?: string;
    children?: React.ReactNode;
}) {

    return (
        <div className={`rounded-b-lg bg-primary-50 px-6 py-5`}>
            {props.children}
        </div>
    );
}