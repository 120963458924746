import React, { useRef, useState } from "react";
import useAutoClose from "components/hooks/useAutoClose";
import { depth } from "core/depth";
import { isLeat } from "core";
import LeatLogo from "assets/svg/Leat_Stream_RGB_White.svg";
import LogoUrl from "assets/svg/p-logo.svg";
import NewVersionIcon from "components/navigation/icons/NewVersionIcon";
import SearchIcon from "components/navigation/icons/SearchIcon";
import SettingsIcon from "components/navigation/icons/SettingsIcon";
import UserDropdown from "components/navigation/UserDropdown";
import MobileMenu from "components/navigation/mobile/MobileMenu";
import { NavigationContext } from "@/components/navigation/navigationContext";
import useKeyPress from "@/core/useKeyPress";
import DesktopMenu from "@/components/navigation/desktop/DesktopMenu";
import { NavLink } from "react-router";

export default function Navigation() {
    const [isNavigationFocussed, setNavigationFocussed] = useState(false);
    const [activeParent, setActiveParent] = useState<string>(null);
    const [userDropdownOpen, setUserDropdownOpen] = useState(false);
    const navRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    useKeyPress({
        key: "Escape",
        onPress: () => {
            setIsOpen(false);
            setUserDropdownOpen(false);
            setNavigationFocussed(false);
        },
    });

    useAutoClose(
        navRef,
        () => {
            setNavigationFocussed(false);
            setUserDropdownOpen(false);
        },
        isNavigationFocussed || userDropdownOpen
    );

    const onMainNavClick = (path: string) => {
        setNavigationFocussed(!isNavigationFocussed);
        setActiveParent(path);
        setUserDropdownOpen(false);
    };

    const onMouseEnter = (path: string) => {
        isNavigationFocussed && setActiveParent(path);
    };

    return (
        <NavigationContext.Provider value={{
            activeParent: activeParent,
            onMouseEnter: onMouseEnter,
            onMainNavClick: onMainNavClick,
            setActiveParent: setActiveParent,
            isUserDropdownOpen: userDropdownOpen,
            setUserDropdownOpen: setUserDropdownOpen,
            isNavigationFocussed: isNavigationFocussed,
            setNavigationFocussed: setNavigationFocussed,
            isOpen: isOpen,
            setIsOpen: setIsOpen,
        }}
        >
            <nav
                className="fixed w-full bg-[#050E27] font-display text-gray-25 shadow-lg dark:border-b dark:border-b-slate-300 dark:bg-slate-900 dark:text-gray-100"
                style={{ zIndex: depth.navigation }}
                ref={navRef}
            >
                <div className="relative flex h-16 justify-between px-4 lg:px-12">
                    <div className="flex items-center justify-between">
                        <NavLink
                            to={"/home"}
                            className={"ml-2 block size-7"}
                            onClick={() => {
                                onMainNavClick(null);
                                setIsOpen(false);
                            }}
                        >
                            <img
                                className={"block size-full object-fill"}
                                src={isLeat() ? LeatLogo : LogoUrl}
                                alt={"logo"}
                            />
                        </NavLink>

                        <DesktopMenu/>
                    </div>

                    <div className="flex items-center justify-between gap-0.5 lg:gap-1.5">
                        <NewVersionIcon/>
                        <SearchIcon/>
                        <SettingsIcon/>
                        <UserDropdown/>
                        <MobileMenu/>
                    </div>
                </div>
            </nav>
        </NavigationContext.Provider>
    );
}

