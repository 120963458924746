import React from "react";

type Props = {
    label: string;
};

export default function TextInput(props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & Props) {
    const id = props.id || props.name;

    return (
        <div
            className={"relative"}
        >
            <input
                id={id}
                type={props.type ?? "text"}
                {...props}
                className={"font-light peer h-14 w-full rounded-lg border border-gray-100 pb-4 pt-8 text-gray-800 outline-none transition-all placeholder:text-transparent focus:border-gray-100 focus:outline-primary-100 focus:ring-0 dark:border-gray-400 dark:bg-slate-800 dark:text-gray-300 dark:focus:outline-primary-400"}
            />
            <label
                className={
                    "absolute left-3 top-2 text-xs font-normal text-gray-500 dark:text-gray-300" + // Filled in state
                    " peer-placeholder-shown:text-base peer-placeholder-shown:font-light peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-4" + // Empty
                    " peer-focus:top-2 peer-focus:left-3 peer-focus:font-medium dark:peer-focus:text-white peer-focus:text-primary-600 peer-focus:text-xs" + // Focus
                    " transition-all"
                }
                htmlFor={id}
            >
                {props.label}
            </label>
        </div>
    );
}