import React from "react";
import Text from "../../typography/Text";
import DisplayFormElementContainer from "./DisplayFormElementContainer";


function getBoolean(value: any) {
    switch (value) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}

type Props = {
    label: string
    value: string;
    underlying_value?: any;
};

export default function DisplayBooleanElement(props: Props) {
    return (
        <DisplayFormElementContainer label={props.label}>
            <div className={'flex items-center gap-2'}>
                <div
                    className={`h-fit rounded-full p-1 ${getBoolean(props.underlying_value) ? 'bg-green-500' : 'bg-red-600'}`}
                />
                <Text weight={"extralight"} color={"text-gray-500 dark:text-white"}>{props.value}</Text>
            </div>
        </DisplayFormElementContainer>
    );
}