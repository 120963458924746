import React, { useState } from "react";
import { useTrans } from "components";
import { Text } from "@/components/v2/Typography/text";
import { SelectNative } from "@/components/v2/SelectionAndInput/selectNative";
import { IconButton } from "@/components/v2/Actions/iconButton";
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "lucide-react";

type Props = {
    current_page: number;
    last_page: number;
    total: number;
    limit: number;
    onPageClick: (newPage: number) => void;
    onLimitChange: (newLimit: number) => void;
};

export default function TablePagination(props: Props) {
    const { current_page, total, last_page, onPageClick, onLimitChange } = props;
    const { t } = useTrans();
    const [limit, setLimit] = useState(props.limit ? props.limit : 10);
    const isLastPage = last_page == current_page;
    const isFirstPage = current_page === 1;

    const getItemRange = () => {
        const start = (current_page - 1) * limit + 1;
        const end = Math.min(current_page * limit, total);
        return `${start}-${end} ${t('of')} ${total?.toLocaleString()} ${t('items')}`;
    };

    return (

        <div className={'flex w-full flex-col-reverse gap-4 pt-4 md:flex-row md:gap-0'}>
            <div className={'flex w-full items-center gap-2 md:w-1/3'}>
                <Text
                    variant={"bodySm"}
                    as={"p"}
                    truncate
                    tone={'secondary'}
                >
                    {t("rowsPerPage")}:
                </Text>
                <div className={'w-16'}>
                    <SelectNative
                        name={"limit"}
                        value={limit}
                        onChange={e => {
                            const newLimit = Number(e.target.value);
                            onLimitChange(newLimit);
                            setLimit(newLimit);
                        }}
                    >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </SelectNative>
                </div>
            </div>
            <div className={"flex w-full items-center justify-center gap-2 md:w-1/3"}>
                <IconButton
                    size={'sm'}
                    disabled={isFirstPage}
                    onClick={() => onPageClick(1)}
                >
                    <ChevronsLeft className={'size-4'}/>
                </IconButton>
                <IconButton
                    size={'sm'}
                    disabled={current_page == 1}
                    onClick={() => onPageClick(current_page - 1)}
                >
                    <ChevronLeft className={'size-4'}/>
                </IconButton>
                <Text
                    variant={"bodySm"}
                    as={"p"}
                    className={'truncate px-2'}
                    tone={'secondary'}
                >
                    {getItemRange()}
                </Text>
                <IconButton
                    size={'sm'}
                    disabled={isLastPage}
                    onClick={() => onPageClick(current_page + 1)}
                >
                    <ChevronRight className={'size-4'}/>
                </IconButton>
                <IconButton
                    size={'sm'}
                    disabled={isLastPage}
                    onClick={() => onPageClick(last_page)}
                >
                    <ChevronsRight className={'size-4'}/>
                </IconButton>
            </div>
            <div className={'hidden md:flex md:w-1/3'}>

            </div>
        </div>
    );
}