import { useTrans } from "components";
import { AccountContext } from "context";
import { useContext } from "react";
import { Feature } from "models/account/Account";
import { CommandPaletteOption, CommandPaletteOptionType } from "../useCommandPaletteOptions";
import useRouting from "@/components/hooks/useRouting";

export default function useCrmOptions(): CommandPaletteOption[] {
    const { t } = useTrans();
    const { push } = useRouting();
    const { account } = useContext(AccountContext);

    const contacts = {
        visible: true,
        group: t("navigation"),
        label: t("contacts"),
        description: "CRM",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/crm/contacts/all"),
        tags: [
            "contacts",
        ],
    };

    const identifiers = {
        visible: true,
        group: t("navigation"),
        label: t("identifiers"),
        description: "CRM",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/crm/identifiers"),
        tags: [
            "identifiers",
        ],
    };

    const lists = {
        visible: true,
        group: t("navigation"),
        label: t("lists"),
        description: "CRM",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/crm/lists"),
        tags: [
            "lists",
        ],
    };

    const forms = {
        visible: true,
        group: t("navigation"),
        label: t("forms"),
        description: "CRM",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/crm/forms"),
        tags: [
            "forms",
        ],
    };

    const orders = {
        visible: true,
        group: t("navigation"),
        label: t("orders"),
        description: "CRM",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/crm/orders/all"),
        tags: [
            "orders",
        ],
    };

    const bookings = {
        visible: account.usesFeature(Feature.BOOKINGS),
        group: t("navigation"),
        label: t("bookings"),
        description: "CRM",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/crm/bookings/all"),
        tags: [
            "bookings",
        ],
    };

    const events = {
        visible: account.usesFeature(Feature.EVENTS),
        group: t("navigation"),
        label: t("events"),
        description: "CRM",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/crm/events/all"),
        tags: [
            "events",
        ],
    };

    const visits = {
        visible: account.usesFeature(Feature.VISITS),
        group: t("navigation"),
        label: t("visits"),
        description: "CRM",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/crm/visits/all"),
        tags: [
            "visits",
        ],
    };

    const shifts = {
        visible: account.usesFeature(Feature.SHIFTS),
        group: t("navigation"),
        label: t("shifts"),
        description: "CRM",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/crm/shifts/all"),
        tags: [
            "f",
        ],
    };

    const createList = {
        visible: true,
        group: t("create"),
        label: t("createContactList"),
        description: "CRM",
        type: CommandPaletteOptionType.CREATE,
        handle: () => push("/crm/lists"),
        tags: [
            "create list",
        ],
    };

    return [
        contacts,
        identifiers,
        lists,
        forms,
        orders,
        bookings,
        events,
        visits,
        shifts,
        createList,
    ];

}