import React from "react";
import {
    OptionsCouldNotBeLoaded
} from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/helpers/OptionsCouldNotBeLoaded";
import { CommandGroup, CommandList } from "@/components/v2/SelectionAndInput/command";
import { CommandEmpty } from "@/components/ui/command";
import { useTrans } from "components";

export function ComboBoxDropdown(props: {
    errorMessage?: string;
    noResultsMessage?: string;
    children: React.ReactNode;
}) {
    const { t } = useTrans();
    const { errorMessage, noResultsMessage } = props;

    return (
        <CommandList>
            {errorMessage && (
                <OptionsCouldNotBeLoaded message={errorMessage}/>
            )}

            <CommandEmpty>{noResultsMessage ?? t("noResultsFound")}</CommandEmpty>

            <CommandGroup tabIndex={-1}>
                {props.children}
            </CommandGroup>
        </CommandList>
    );
}