import React from "react";
import cn from "classnames";
import { TableColumnType } from "components/table_v2/types";

type Props = {
    column?: TableColumnType;
    children?: any;
    className?: string;
    onClick?: (column: TableColumnType) => void;
    isHeader?: boolean;
    canOverflow?: boolean;
    rowType?: "single" | "double";
};

export default function ColumnWrapper(props: Props) {
    const { column, onClick, rowType } = props;

    const columnCn = cn(
        `flex font-thin px-1 md:px-2 lg:px-3 my-auto items-center w-full`,
        props.canOverflow ? '' : 'truncate',
        { "h-12": rowType === "single" },
        { "h-[72px]": rowType === "double" },
        { "justify-start": column.align == "left" },
        { "justify-center": column.align == "center" },
        { "justify-end mr-2": column.align == "right" },
    );

    return (
        <td
            className={`${props.className ?? ""} table-cell border-0 border-b border-gray-50`}
        >
            {props.isHeader ? (
                <div className="font-light flex cursor-pointer items-center justify-start p-3 text-sm">
                    {props.children}
                </div>
            ) : (
                <div
                    className={columnCn}
                    onClick={() => onClick ? onClick(column) : null}
                >
                    {props.children}
                </div>
            )}
        </td>
    );
}