import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { reloadCurrentPage } from "core";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";

export default function useTrans() {
    const { t } = useTranslation();
    const { user } = useCurrentAccountUser();

    useEffect(() => {
        const language = user?.locale ?? localStorage.getItem("language");
        if (language) {
            changeLanguage(language);
        }
    }, [user]);

    const changeLanguage = (language: string) => {
        if (language != localStorage.getItem("language")) {
            localStorage.setItem("language", language);
            i18next.changeLanguage(language);

            reloadCurrentPage();
        }
    };

    const tWithVariables = (translationKey: string, variables: any) => {
        return t(translationKey, variables);
    };

    const language = i18next.language;

    return { t, tWithVariables, language, changeLanguage };
}