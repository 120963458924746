import React, { useState } from 'react';
import { useTrans } from "components";
import { Label } from "@/components/v2/SelectionAndInput/fieldset";
import { ComboBox } from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/ComboBox";
import { SingleSelectOption } from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/SingleSelectOption";
import { normalizeToArray } from "core";

import { ComboBoxDropdown } from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/ComboBoxDropdown";


import DisplayValueWrapper
    from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/helpers/DisplayValueWrapper";
import {
    DefaultOptionLayout
} from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/helpers/DefaultOptionLayout";

interface Option {
    value: string;
    label: string;
}

export type Props = {
    loading: boolean;
    onChange: (name: string, value: string) => void;
    values: Dict;
    industryOptions: Option[];
};

export default function IndustryComboBox(props: Props) {
    const { loading, onChange, values, industryOptions } = props;
    const { t } = useTrans();
    const [searchIndustry, setSearchIndustry] = useState("");

    const handleSelectIndustry = (selected: string) => {
        if (values.industry === selected) {
            onChange('industry', null);
        } else {
            onChange('industry', selected);
        }
    };

    return (
        <div>
            <Label>{t("industry")}</Label>
            <ComboBox
                loading={loading}
                search={searchIndustry}
                noValuesSelectedMessage={t('industry')}
                filterFn={(value, search) => {
                    if (!value) {
                        return 1;
                    }

                    const industry = industryOptions.find((industryOption) => industryOption.value === value);

                    if (!industry) {
                        return 0;
                    }

                    return industry.label.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
                }}
                setSearch={setSearchIndustry}
                selected={normalizeToArray(values.industry)}
                getDisplayValue={selected => (
                    <DisplayValueWrapper value={selected} onClick={handleSelectIndustry}>
                        <div className="leading-tight">
                            <DefaultOptionLayout
                                label={industryOptions.find((industryOption) => industryOption.value === selected)?.label}
                            />
                        </div>
                    </DisplayValueWrapper>
                )}
            >
                <ComboBoxDropdown noResultsMessage={t("noIndustryFound")}>
                    {industryOptions.map((industry) => (
                        <SingleSelectOption
                            key={industry.value}
                            value={industry.value}
                            onSelect={handleSelectIndustry}
                            selected={normalizeToArray(values.industry).includes(industry.label)}
                        >
                            <div className="leading-tight">
                                <DefaultOptionLayout label={industry.label}/>
                            </div>
                        </SingleSelectOption>
                    ))}
                </ComboBoxDropdown>
            </ComboBox>
        </div>
    );
}