import React from "react";
import Text from "components/typography/Text";
import MediaType from "components/MediaType";
import MediaComponent from "components/MediaComponent";

interface Props {
    title: string,
    subtitle?: string,
    media?: MediaType,
    children?: React.ReactNode;
    className?: string;
}

export default function SidebarMediaHeader(props: Props) {
    const { title, subtitle, media, className } = props;

    return (
        <div
            className={`hidden w-full flex-col items-center gap-3 rounded-lg bg-gray-700 bg-generalLinear p-4 lg:flex ${className ? className : ""}`}
        >
            {media && <MediaComponent
                media={media}
                className={'size-20 rounded-full border border-white object-cover shadow-md'}
            />}

            <div className={'mb-2 flex w-full flex-col gap-1'}>
                <Text color={'text-white'} weight={'normal'} size={'lg'} className={'w-full truncate text-center'}>
                    {title}
                </Text>
                <Text color={'text-white'} weight={'extralight'} className={'w-full truncate text-center'}>
                    {subtitle}
                </Text>
            </div>

            {props.children}
        </div>
    );
}
