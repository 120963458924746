import Text from "components/typography/Text";
import React from "react";
import { useTrans } from "components";
import { Button } from "@/components/v2/Actions/button";
import { Plus } from "lucide-react";

type Props = {
    heading?: string;
    previews: any[];
    canBeEdited: boolean;
    onDelete?: any;
    selectedGroupId?: any;
    setSelectedGroupId?: any;
    onCreate?: (groupId: number | null) => void;
    onSelect?: any;
};

export default function Previews(props: Props) {
    const { t } = useTrans();
    const emptyStateMessage = "Stepper filters to your group";
    const { heading, canBeEdited, onSelect, onCreate, previews, selectedGroupId, setSelectedGroupId } = props;

    return (
        <div>
            {heading && (
                <Text size={"sm"} className={"pb-3"} color={"text-gray-500"}>{heading}</Text>
            )}
            <div>
                {previews.map((preview: any) => (
                    <div key={preview.id} onClick={() => setSelectedGroupId && setSelectedGroupId(preview.id)}>
                        <div
                            className={`flex flex-col items-start gap-3 rounded-lg border ${preview.id == selectedGroupId ? "border-blue-300" : "border-gray-200"} bg-gray-50 p-3 dark:bg-slate-500`}
                        >
                            {(preview.filter_previews.length == 0 && emptyStateMessage &&
                                <Text
                                    render_as={"span"}
                                    color={"text-gray-400"}
                                    size={"sm"}
                                    className={"pl-2"}
                                >
                                    {emptyStateMessage}
                                </Text>
                            )}

                            {preview.filter_previews.map((filterPreview: any) => (
                                <div key={filterPreview.id} className={"fadeIn flex flex-col gap-3"}>
                                    <div className={`group`}>
                                        <div
                                            className={`min-h-[40px] items-center rounded-lg border border-gray-200 bg-white px-3 py-2 transition-all dark:bg-slate-600 ${filterPreview.is_valid ? "" : "border-red-600"} ${canBeEdited ? "cursor-pointer hover:bg-primary-25 dark:hover:bg-slate-400" : "cursor-not-allowed"}`}
                                            onClick={() => canBeEdited ? onSelect(filterPreview.id) : null}
                                        >
                                            <div className={"flex flex-col gap-1"}>
                                                <Text
                                                    size={"sm"}
                                                    render_as={"span"}
                                                    className={"no-select dark:text-white"}
                                                >
                                                    <strong>{filterPreview.type}:</strong>
                                                </Text>

                                                <Text
                                                    size={"sm"}
                                                    render_as={"span"}
                                                    className={"no-select dark:text-white"}
                                                >
                                                    {filterPreview.text}
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                    {filterPreview.operator_text && (
                                        <Text
                                            size={"sm"}
                                            render_as={"span"}
                                            color={"text-gray-400 dark:text-white"}
                                        >
                                            {filterPreview.operator_text}
                                        </Text>
                                    )}
                                </div>
                            ))}
                            <div>

                                {canBeEdited && (
                                    <Button
                                        variant={"default"}
                                        type="button"
                                        onClick={() => onCreate(preview.id)}
                                    >
                                        <Plus className={"size-4"} />
                                    </Button>
                                )}

                            </div>
                        </div>
                        <Text size={"sm"} className={"my-2"} color={"text-gray-400"}>{preview.operator_text}</Text>
                    </div>
                ))}
            </div>
        </div>
    );
}