import React from "react";
import cn from "classnames";

type Props = {
    id?: any;
    children: any,
    className?: string,
    align?: string,
    onRowClick?: (id: any) => void,
    numberOfColumns?: number
};

export default function RowWrapper(props: Props) {
    const { onRowClick } = props;

    const rowCn = cn(
        "hover:bg-primary-25 dark:hover:bg-slate-800 transition-colors w-full relative",
        onRowClick ? 'cursor-pointer active:bg-primary-50 ' : 'cursor-default',
        props.className,
    );

    return (
        <tr
            onClick={onRowClick ? () => onRowClick(props.id) : null}
            className={rowCn}
        >
            {props.children}
        </tr>
    );
}