import React, { useState } from "react";
import useFetcher from "repositories/base/useFetcher";
import useLocalStorage from "components/hooks/localStorage/useLocalStorage";
import useRouting from "components/hooks/useRouting";
import SelectableListItem from "components/lists/SelectableListItem";
import { useTrans } from "components";
import useAuth from "components/hooks/auth/useAuth";
import AuthContainer from "@/components/auth/AuthContainer";
import AuthCard from "@/components/auth/AuthCard";
import AuthHeader from "@/components/auth/AuthHeader";
import { Button } from "@/components/v2/Actions/button";

export default function SelectAccount() {
    const { t } = useTrans();
    const { logout } = useAuth();
    const { push } = useRouting();
    const { set } = useLocalStorage();
    const [data, setData] = useState([]);
    const apiUrl = '/after-login/select-account';
    useFetcher({
        url: apiUrl,
        dataSetter: setData,
    });

    const next = (selectedAccountId: any) => {
        set("currentAccountId", selectedAccountId);

        if (sessionStorage.getItem('redirect_url')) {
            push(sessionStorage.getItem('redirect_url'));
            sessionStorage.removeItem('redirect_url');
        } else {
            push("/home");
        }
    };

    return (
        <AuthContainer>
            <div>
                <AuthCard>
                    <AuthHeader
                        title={"Account"}
                        description={t("chooseAnAccount")}
                    />
                    {data.map((account: any) => (
                        <SelectableListItem
                            key={account.id}
                            title={account.company}
                            media={{
                                type: "image",
                                value: account.image,
                            }}
                            onClick={() => next(account.id)}
                            disabled={!account.active}
                        />
                    ))}
                </AuthCard>

                <div className={'flex justify-end pr-6 sm:mt-2 sm:pr-0'}>
                    <Button
                        variant={'default'}
                        onClick={() => logout(true)}
                    >
                        {t('logout')}
                    </Button>
                </div>
            </div>
        </AuthContainer>
    );
}
