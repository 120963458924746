import React, { useState } from 'react';
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import "components/forms/TipTap/OLD/style.css";
import Helper from "@piggy-loyalty/ui-components/lib/components/Helper";
import FloatingLabel from "@/components/forms/ContentEditable/FloatingLabel";
import emoji from "@/components/forms/TipTap/OLD/emoji/emoji";
import tipTapMergeTags from "@/components/forms/TipTap/mergeTagsMention/tipTapMergeTags";
import ToolbarButton from "@/components/forms/TipTap/OLD/ToolbarButton";

export type TagOption = {
    id: string,
    display: string
    hasChildren: boolean;
};

export type MergeTagGroup = {
    title: string;
    type: string;
    options: any[];
};

type Props = {
    name: string;
    placeholder?: string;
    label?: string;
    value?: any;
    defaultValue?: any;
    onBlur?: any;
    is_inline?: boolean;
    height?: string;
    merge_tags?: any[];
    top_helper?: any;
    bottom_helper?: any;
    required?: boolean;
    disabled?: boolean;
    output_format?: "text" | "html";
    toolbar_options?: string[];
};

export default function TipTap(props: Props) {
    const {
        value,
        defaultValue,
        name,
        label,
        is_inline,
        height,
        onBlur,
        toolbar_options,
        placeholder,
        output_format,
        merge_tags,
        top_helper,
        bottom_helper,
        required,
        disabled
    } = props;
    const [isFocused, setFocused] = useState(false);

    const extensions = [
        StarterKit,
        Placeholder.configure({
            placeholder: placeholder,
        }),
        Emoji.configure({
            emojis: gitHubEmojis,
            enableEmoticons: true,
            suggestion: emoji,
        }),
        Mention.configure({
            HTMLAttributes: {
                class: 'mention',
            },
            suggestion: merge_tags.length > 0 ? tipTapMergeTags(merge_tags) : null,
        }),
        TextAlign.configure({
            types: ['heading', 'paragraph'],
        }),
    ];

    const editor = useEditor({
        extensions,
        content: defaultValue,
        onBlur: e => {
            onBlur(name, output_format === "html" ? e.editor.getHTML() : e.editor.getText());
            setFocused(false);
        },
        onFocus: () => setFocused(true),
    });

    const getHeightInPixels = () => {
        if (height == "medium") {
            return "min-h-[150px]";
        }

        if (height == "large") {
            return "min-h-[350px]";
        }

        return "";
    };

    return (
        <div className={"w-full text-primary-600"}>
            {top_helper && (
                <div className={"text-gray-900"}>
                    <Helper text={top_helper.text} status={top_helper.status} align={top_helper.align}/>
                </div>
            )}

            <div className={`relative flex flex-col`}>

                <div
                    className={`rounded-lg border border-gray-100 p-2 ${getHeightInPixels()} text-gray-900`}
                >
                    <FloatingLabel
                        active={isFocused}
                        required={required}
                        focussed={isFocused}
                        disabled={disabled}
                        className={'font-normal text-sm text-gray-500'}
                    >
                        {label}
                    </FloatingLabel>

                    <EditorContent editor={editor}/>

                    <BubbleMenu
                        className={"flex gap-1 rounded-full border border-gray-200 bg-white p-1 shadow shadow-gray-300"}
                        tippyOptions={{ duration: 50 }}
                        editor={editor}
                    >
                        <ToolbarButton
                            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                            isActive={editor.isActive('heading', { level: 1 })}
                        >
                            H1
                        </ToolbarButton>

                        <ToolbarButton
                            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                            isActive={editor.isActive('heading', { level: 2 })}
                        >
                            H2
                        </ToolbarButton>

                        {/*<ToolbarButton*/}
                        {/*    onClick={() => editor.chain().focus().toggleBulletList().run()}*/}
                        {/*    isActive={editor.isActive('bulletList')}*/}
                        {/*>*/}
                        {/*    Bullet list*/}
                        {/*</ToolbarButton>*/}

                        <ToolbarButton
                            onClick={() => editor.chain().focus().toggleBold().run()}
                            isActive={editor.isActive('bold')}
                        >
                            Bold
                        </ToolbarButton>

                        <ToolbarButton
                            onClick={() => editor.chain().focus().toggleItalic().run()}
                            isActive={editor.isActive('italic')}
                        >
                            Italic
                        </ToolbarButton>

                        <ToolbarButton
                            onClick={() => editor.chain().focus().toggleStrike().run()}
                            isActive={editor.isActive('strike')}
                        >
                            Strike
                        </ToolbarButton>

                        <ToolbarButton
                            onClick={() => editor.chain().focus().setTextAlign('left').run()}
                            isActive={editor.isActive({ textAlign: 'left' })}
                        >
                            Left
                        </ToolbarButton>
                        <ToolbarButton
                            onClick={() => editor.chain().focus().setTextAlign('center').run()}
                            isActive={editor.isActive({ textAlign: 'center' })}
                        >
                            Center
                        </ToolbarButton>
                        <ToolbarButton
                            onClick={() => editor.chain().focus().setTextAlign('right').run()}
                            isActive={editor.isActive({ textAlign: 'right' })}
                        >
                            Right
                        </ToolbarButton>
                        <ToolbarButton
                            onClick={() => editor.chain().focus().setTextAlign('justify').run()}
                            isActive={editor.isActive({ textAlign: 'justify' })}
                        >
                            Justify
                        </ToolbarButton>
                        <ToolbarButton
                            onClick={() => editor.chain().focus().unsetTextAlign().run()}
                            isActive={false}
                        >
                            Unset text align
                        </ToolbarButton>
                    </BubbleMenu>
                </div>
            </div>

            {bottom_helper && (
                <div className={"text-gray-900"}>
                    <Helper {...bottom_helper}/>
                </div>
            )}
        </div>
    );
}

