import React from "react";

type Props = {
    children: any;
};

export default function CalloutCard(props: Props) {

    return (
        <div className={"space-y-2 rounded-md border border-gray-200 bg-primary-25 p-4"}>
            {props.children}
        </div>
    );
}