import React from "react";

type Props = {
    children: React.ReactNode;
};

export default function TableHead(props: Props) {

    return (
        <thead
            className={"font-extralight rounded-t-lg border-b border-border-default bg-bg-surface-secondary-default text-xs dark:bg-slate-900"}
        >
        <tr id={"heading"}>
            {props.children}
        </tr>
        </thead>
    );
}