import React from "react";
import Text from "../../typography/Text";
import DisplayFormElementContainer from "./DisplayFormElementContainer";

type Props = {
    value: any;
    label: string;
};

export default function DisplayTagElement(props: Props) {
    const TagElement = (props: any) => {
        return (
            <div className={"mt-1 flex w-fit rounded border border-primary-200 bg-primary-100 px-2 py-px"}>
                <Text
                    render_as={"span"}
                    size={"sm"}
                    weight={"normal"}
                    color={"text-primary-800"}
                >
                    {props.value}
                </Text>
            </div>
        );
    };

    return (
        <DisplayFormElementContainer label={props.label}>
            {Array.isArray(props.value) ? (
                <div className={"flex max-w-full gap-1 overflow-x-scroll"}>
                    {props.value.map((value: any, index: number) => {
                        return (
                            <TagElement key={index} value={value}/>
                        );
                    })}
                </div>
            ) : props.value && (
                <TagElement value={props.value}/>
            )}
        </DisplayFormElementContainer>
    );
}