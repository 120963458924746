import React from "react";

type Props = {
    children: React.ReactNode;
};

export default function PanelBody(props: Props) {
    return (
        <main className={"grow p-4"}>
            {props.children}
        </main>
    );
}