import React from "react";
import { toast as reactToast, ToastOptions } from 'react-toastify';
import { useTrans } from "components";
import NotificationSuccess from "assets/svg/icons/notificationSuccessV2.svg?react";
import NotificationError from "assets/svg/icons/notificationErrorV2.svg?react";
import NotificationWarning from "assets/svg/icons/notificationWarningV2.svg?react";

export default function useToast() {
    const { t } = useTrans();

    const ToastIcon = (props: { type: string }) => {
        if (props.type === "success") {
            return <NotificationSuccess />;
        }

        if (props.type === "error") {
            return <NotificationError />;
        }

        return <NotificationWarning/>;
    };

    const options: ToastOptions = {
        position: "bottom-center",
        closeButton: false,
        icon: ToastIcon,
    };

    const toast = (result: "success" | "error", message: string) => {
        if (result === "success") {
            return successToast(message);
        }

        errorToast(message);
    };

    const successToast = (message: string = t("success"), customOptions: ToastOptions = {}) => {
        reactToast.success(message, { ...options, ...customOptions });
    };

    const warningToast = (message: string = t("warning")) => {
        reactToast.warning(message, options);
    };

    const buildErrors = (errors: string[]) => {
        const messages = errors.map((message: string) => {
            return <li key={message}>{message}</li>;
        });

        return <>{messages}</>;
    };

    const errorToast = (error: any = t("somethingWentWrong")) => {
        let errorString = t("somethingWentWrong");

        if (error.message && error.errors && error.errors.length > 0) {
            const content = <div><p>{error.message}</p>{buildErrors(error.errors)}</div>;
            return reactToast.error(content, options);
        }

        if (error.errors && error.errors.length > 0) {
            return reactToast.error(buildErrors(error.errors), options);
        }

        if (error !== "" && typeof error === "string") {
            errorString = error;
        }

        reactToast.error(error.message ? error.message : errorString, options);
    };

    return { toast, successToast, warningToast, errorToast };
}
