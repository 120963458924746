import React from "react";
import FormElementWrapper from "@piggy-loyalty/ui-components/lib/components/elements/FormElementWrapper";
import Checkbox from "components/forms/FormElements/Checkbox/Checkbox";

type Props = {
    name: string;
    label: any;
    value: any;
    disabled: boolean;
    defaultValue?: any;
    onChange?: any;
    onBlur?: any;
    variant?: string;
    top_helper?: any;
    bottom_helper?: any;
    className?: string;
};

export default function FormElementCheckbox(props: Props) {
    const { disabled, bottom_helper, top_helper } = props;

    return (
        <FormElementWrapper
            name={"form-checkbox"}
            className={""}
            isDisabled={disabled}
            bottom_helper={bottom_helper}
            top_helper={top_helper}
        >
            <Checkbox {...props} />
        </FormElementWrapper>
    );
}