import React, { useEffect, useState } from 'react';
import { useToast, useTrans } from "components";
import useHttp from "core/http/useHttp";
import useRouting from "components/hooks/useRouting";
import AuthContainer from "components/auth/AuthContainer";
import { afterLogin } from "@/routing/Router";
import Text from "components/typography/Text";
import useAuth from "components/hooks/auth/useAuth";
import { paths } from "@/routing/paths";
import AuthCard from "@/components/auth/AuthCard";
import { Button } from "@/components/v2/Actions/button";
import AuthHeader from "@/components/auth/AuthHeader";

export default function Verify() {
    const { t } = useTrans();
    const http = useHttp();
    const { push, queryParams } = useRouting();
    const { login } = useAuth();
    const { successToast, errorToast } = useToast();
    const [pending, setPending] = useState(false);
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        setPending(true);
        http.get("/verify", {
            token: queryParams.verification_token
        })
            .then((res) => {
                setVerified(true);
                successToast(t("successfullyVerified"));
                login(res.data.data.token);
                push(afterLogin);
            })
            .catch(errorToast)
            .finally(() => setPending(false));
    }, []);

    return (
        <AuthContainer>
            <AuthCard>
                <AuthHeader
                    title={verified ? t('verify') : t('verificationFailed')}
                />
                {verified && (
                    <Text>{t("successfullyVerifiedDescription")}</Text>
                )}
                <Button
                    variant={"primary"}
                    onClick={() => push(paths.login)}
                    isLoading={pending}
                    disabled={pending}
                    className={'w-full'}
                >
                    {verified ? t('onboardingLogin') : t('backToLogin')}
                </Button>
            </AuthCard>
        </AuthContainer>
    );
}