import { useContext } from "react";
import { StripcardProgramContext } from "@/routing/store/stripcards/StripcardRoutes";

export default function useStripcardProgram() {
    const { selectedProgram, setSelectedProgram } = useContext(StripcardProgramContext);

    return {
        selectedProgramId: selectedProgram?.id,
        selectedProgram,
        setSelectedProgram
    };
}