import useLocalStorage from "components/hooks/localStorage/useLocalStorage";

export default function useFetch() {
    const localStorage = useLocalStorage();

    const call = (url: string, onSuccess: (res: any) => void, onError: (res: any) => void) => {
        return fetch(url, {
            headers: {
                Authorization: `Bearer ${localStorage.get("token")}`
            }
        })
            .then(res => {
                if (res.ok) {
                    res.json().then(onSuccess);
                } else {
                    res.json().then(onError);
                }
            });
    };

    return {
        call,
    };
}