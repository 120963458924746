import React from "react";
import GroupedElements from "components/forms/GroupedElements";
import FormElement from "components/forms/FormElement";
import FormGroup from "components/forms/FormGroup";
import { useTrans } from "components";
import { toSnakeCaseWithoutSpecialChars } from "core";
import { OptionType } from "@piggy-loyalty/ui-components/lib/form/types/base";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";

interface Props {
    isDisabled: boolean;
    options: OptionType[];
    setOptions: (options: any[]) => void;
    onBlur?: (name: string, options: any) => void;
}

//TODO fix this components with server UI options
export default function AddOptions(props: Props) {
    const { t } = useTrans();
    const { options, setOptions, onBlur, isDisabled } = props;

    const deleteOption = (index: number) => {
        options.splice(index, 1);
        setOptions([...options]);

        if (onBlur) {
            action();
        }
    };

    const onOptionChange = (index: number, name: any, value: any) => {
        const found: Record<string, any> = options[index];

        if (found) {
            found[name] = value;
        }

        setOptions([...options]);
    };

    const action = () => {
        onBlur("options", options);
    };

    const addOption = () => {
        options.push({ value: "", label: "" });
        setOptions([...options]);
    };

    const changeOptionLabel = (index: number, name: string, value: any) => {
        const found = options[index];
        if (found) {
            // First check if value has remained unchanged to default, i.e. current value is equal to formatted label
            if (!found.value || found.value == toSnakeCaseWithoutSpecialChars(found.label)) {
                found.value = toSnakeCaseWithoutSpecialChars(value);
            }
        }

        // Only then alter label
        onOptionChange(index, name, value);
    };

    return (
        <FormGroup label={t("options")}>
            {options.map((option, index) => {
                return (
                    <GroupedElements key={index}>
                        <FormElement
                            type={"text"}
                            name="label"
                            value={option.label}
                            label={t("label")}
                            onChange={(name, value) => changeOptionLabel(index, name, value)}
                            required={true}
                            disabled={isDisabled}
                            onBlur={action}
                        />
                        <FormElement
                            type={"text"}
                            name="value"
                            value={option.value}
                            label={t("value")}
                            onChange={(name, value) => onOptionChange(index, name, toSnakeCaseWithoutSpecialChars(value))}
                            required={true}
                            disabled={isDisabled}
                            onBlur={action}
                        />
                        {!isDisabled && (
                            <ButtonComponent
                                button_style={ButtonStyle.Danger}
                                onClick={() => deleteOption(index)}
                                icon={"delete"}
                            />
                        )}
                    </GroupedElements>
                );
            })}
            {!isDisabled && (
                <ButtonComponent
                    button_style={ButtonStyle.Secondary}
                    onClick={addOption}
                    icon={"plusRound"}
                    label={t("add")}
                />
            )}
        </FormGroup>
    );
}
