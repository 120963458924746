import React from "react";
import { Text } from "@/components/v2/Typography/text";
import { X } from "lucide-react";

export default function DisplayValueForSingleSelect(props: {
    label: string;
    onClear?: () => void;
}) {
    const { label, onClear } = props;

    return (
        <div className="flex w-full items-center justify-between leading-tight">
            <div className="flex items-center justify-center gap-3 ">
                <Text variant={"bodyMd"} tone={"base"} as={"p"}>{label}</Text>
            </div>
            
            {onClear && (
                <div
                    className="flex size-5 items-center justify-center rounded-full transition-bg hover:bg-bg-surface-selected"
                    onClick={(e) => {
                        e.stopPropagation();
                        onClear();
                    }}
                >
                    <X
                        className="size-3.5"
                        color="var(--neutral-500)"
                    />
                </div>
            )}
        </div>
    );
}