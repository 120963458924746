import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from "react-dropzone";
import * as Headless from '@headlessui/react';
import { X } from "lucide-react";
import cn from "classnames";
import { Button } from "@/components/v2/Actions/button";
import { IconButton } from "@/components/v2/Actions/iconButton";
import Thumbnail from "@/components/v2/ImagesAndIcons/thumbnail";

interface DropzoneProps {
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    onFileSelected: (file: File | null) => void;
    acceptedFileTypes?: Record<string, string[]>;
    ref?: React.ForwardedRef<HTMLInputElement>;
    previewUrl?: string | null;
}

export function Dropzone({
                             children,
                             className,
                             onFileSelected,
                             disabled,
                             ref,
                             previewUrl,
                             acceptedFileTypes = {
                                 "image/*": [".jpeg", ".jpg", ".png", ".gif"],
                                 "application/pdf": [".pdf"],
                             },
                         }: DropzoneProps): JSX.Element {
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(previewUrl || null);


    useEffect(() => {
        if (previewUrl) {
            setPreview(previewUrl);
        }
    }, [previewUrl]);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0) {
                const selectedFile = acceptedFiles[0];
                setFile(selectedFile);
                onFileSelected(selectedFile);

                // Generate preview for images
                if (selectedFile.type.startsWith("image/")) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setPreview(reader.result as string);
                    };
                    reader.readAsDataURL(selectedFile);
                } else {
                    setPreview(null);
                }
            }
        },
        [onFileSelected]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedFileTypes,
        maxFiles: 1,
        disabled,
    });

    const removeFile = () => {
        setFile(null);
        setPreview(null);
        onFileSelected(null);
    };

    return (
        <span
            data-slot="control"
            className={cn([
                className,
                "after:pointer-events-none after:absolute after:inset-0 after:rounded after:ring-inset after:ring-transparent after:focus-within:shadow-focus-ring after:focus-within:ring-border-focus",
                "before:has-[[data-disabled]]:bg-bg-surface-disabled before:has-[[data-disabled]]:shadow-none",
            ])}
        >
            {!file && !preview ? (
                <div
                    ref={ref}
                    {...getRootProps({ disabled })}
                    data-disabled={disabled ? true : undefined}
                    className={cn([
                        className,
                        "relative flex w-full appearance-none rounded p-8 items-center justify-center bg-inputV2-bg-surface cursor-pointer transition-colors",
                        "font-tracking-normal font-body text-[13px] font-regular leading-5 text-text-default placeholder:text-inputV2-text-placeholder",
                        "border border-dashed border-inputV2-border",
                        "active:bg-inputV2-bg-surface-active dark:bg-white/5",
                        "hover:border-inputV2-border-hover hover:bg-inputV2-bg-surface-hover",
                        "focus:outline-none",
                        "data-[disabled]:text-text-disabled data-[disabled]:border-transparent data-[disabled]:bg-bg-surface-disabled data-[disabled]:pointer-events-none",
                        isDragActive ? "bg-inputV2-bg-surface-hover" : "",
                    ])}
                >
                    <Headless.Input {...getInputProps({ disabled })} />
                    <Button disabled={disabled}>Upload file</Button>
                    {children}
                </div>
            ) : (
                <div className="relative w-full">
                    <div className="absolute right-2 top-2 z-10">
                        <IconButton onClick={removeFile}>
                            <X className="size-4"/>
                        </IconButton>
                    </div>
                    <div {...getRootProps()} className="cursor-pointer">
                        <input {...getInputProps()} />
                        <Thumbnail src={preview} alt={file ? file.name : "Preview"}/>
                    </div>
                </div>
            )}
        </span>
    );
}

export default Dropzone;

