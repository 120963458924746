import React from "react";
import { Loader } from "components";
import { LoaderType } from "components/Loader";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";

interface Props {
    title?: string;
    titleClasses?: string;
    subTitle?: any;
    button?: {
        onClick: any;
        label: string;
    };
    hasLoader?: boolean;
}

export default function SpecialPage(props: Props) {
    const { title, titleClasses, subTitle, button, hasLoader } = props;

    return (
        <div
            className={"default-bg-colors flex min-h-screen flex-col items-center justify-center text-center"}
        >
            {hasLoader && (
                <div>
                    <Loader visible={true} className={"loader"} type={'default'}/>
                </div>
            )}

            {title && (
                <h1 className={`2xl dark:text-white ${titleClasses}`}>{title}</h1>
            )}

            {subTitle}

            <div className={"mt-14"}>
                {button && (
                    <ButtonComponent
                        button_style={ButtonStyle.Primary}
                        label={button.label}
                        onClick={button.onClick}
                    />
                )}
            </div>
        </div>
    );
}