import React, { HTMLProps } from "react";
import { NavLink } from "react-router";

type Props = {
    to: string;
    onClick: any;
    onMouseEnter?: any;
    children: any;
    isActive: boolean;
    className?: HTMLProps<HTMLElement>["className"];
};

export default function IconOnlyNavItem(props: Props) {
    const { onClick, onMouseEnter, to, isActive, className } = props;

    return (
        <NavLink
            to={to}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            className={`btn-outline size-10 rounded-full p-2 text-center hover:bg-white/15 ${isActive ? "bg-white/15" : ""} ${className}`}
        >
            {props.children}
        </NavLink>
    );
}