import cn from "classnames";
import React, { useRef, useState } from "react";
import { acceptTypes } from "@piggy-loyalty/ui-components/lib/_helpers";
import { useTrans } from "components";
import Text from "../typography/Text";
import ButtonComponent, { ButtonStyle } from "../buttons/ButtonComponent";
import Icon from "../icons/Icon";

type Props = {
    name: string,
    file: File | null;
    id?: any,
    value?: any,
    defaultValue?: any,
    label?: string,
    onChange?: (name: string, file: File) => void,
    onBlur?: (name: string, file: File) => void,
    onClear?: (name: string) => void,
    disabled?: boolean,
    className?: string,
};

export default function FormFileUpload(props: Props) {
    const { t } = useTrans();
    const {
        name,
        id,
        label,
        onChange,
        onBlur,
        disabled,
        className
    } = props;
    const [file, setFile] = useState<File>(props.file ?? null);
    const inputRef = useRef(null);

    const onSubmit = (file: File | null) => {
        setFile(file);
        onChange && onChange(name, file);
        onBlur && onBlur(name, file);
    };

    const onClear = () => {
        setFile(null);
        props.onClear && props.onClear(name);
    };

    const onButtonClick = () => {
        inputRef.current.click();
    };

    const containerCn = cn(
        `relative flex container my-2 px-3 py-2 bg-white border border-gray-200 rounded-md h-14 transition-all ease-in-out`,
        className && className,
        disabled ? 'bg-gray-50 cursor-not-allowed' : ''
    );

    return (
        <div className={containerCn}>
            <div className={"relative flex grow cursor-default flex-wrap items-center justify-between gap-3"}>
                <div className={"relative flex grow flex-col justify-self-start"}>
                    <Text
                        size={file ? "xs" : "md"}
                        weight={file ? "normal" : "light"}
                        color={disabled ? 'text-gray-400' : 'text-gray-500'}
                    >
                        {label ?? t('fileUpload')}
                    </Text>
                    {file && (
                        <div className={'flex items-center gap-1'}>
                            <Text
                                size={"sm"}
                                weight={"light"}
                                color={disabled ? 'text-gray-400' : 'text-gray-500'}
                                className={`max-w-48 truncate break-all ${disabled ? '' : 'underline decoration-gray-900/10'}`}
                                hidden={disabled}
                            >
                                <a href={props.value} target={"_blank"} className={'group flex items-center gap-1'}>
                                    {t('file')}
                                    <Icon
                                        key={''}
                                        type={'arrowDiagonalRight'}
                                        attr={'div'}
                                        className={'size-2 bg-gray-500 opacity-0 transition-all duration-200 group-hover:opacity-100'}
                                    />
                                </a>
                            </Text>
                        </div>
                    )}
                </div>
                <div className={'flex gap-2 justify-self-end'}>
                    <ButtonComponent
                        button_style={ButtonStyle.Secondary}
                        label={file ? t('replaceFile') : t('chooseFile')}
                        onClick={onButtonClick}
                        disabled={disabled}
                    />
                    {file && (
                        <ButtonComponent
                            button_style={ButtonStyle.Secondary}
                            icon={'trash'}
                            onClick={onClear}
                            disabled={disabled}
                        />
                    )}
                </div>
            </div>
            <input
                accept={acceptTypes([])}
                ref={inputRef as any}
                id={`file-upload-${id}`}
                type="file"
                hidden
                onChange={(event) => onSubmit(event.target.files[0])}
                disabled={disabled}
            />
        </div>
    );
}
