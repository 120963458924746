import React from "react";
import Thumbnail from "@/components/v2/ImagesAndIcons/thumbnail";
import { Text } from "@/components/v2/Typography/text";
import { X } from "lucide-react";

export default function DisplayValueWithThumbnail(props: {
    label: string;
    thumbnail: string;
    description?: string;
    onClear?: () => void;
}) {
    const { label, description, thumbnail } = props;

    return (
        <div className="flex w-full items-center justify-between">
            <div className={'flex items-center gap-2'}>
                <Thumbnail
                    src={thumbnail}
                    className="size-10"
                />
                <div className="flex flex-col justify-center text-left">
                    <Text variant={"bodyMd"} tone={"base"} as={"p"}>{label}</Text>
                    {description && (
                        <Text
                            variant={"bodyXs"}
                            tone={"secondary"}
                            as={"p"}
                        >
                            {description}
                        </Text>
                    )}
                </div>
            </div>
            {props.onClear && (
                <div
                    className="flex size-5 items-center justify-center rounded-full transition-bg hover:bg-bg-surface-selected"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onClear();
                    }}
                >
                    <X
                        className="size-3.5"
                        color="var(--neutral-500)"
                    />
                </div>
            )}

        </div>
    );
}