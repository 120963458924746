import React from 'react';

interface Props {
    percentage: number;
    className?: string
}

export default function ProgressBar(props: Props) {
    const { percentage, className = '' } = props;

    return (
        <div className={`w-full rounded-lg bg-gray-200 dark:bg-gray-700 ${className}`}>
            <div
                className={"rounded-lg bg-blue-600 p-1.5 text-center text-xs font-medium leading-none text-blue-100"}
                style={{
                    width: `${percentage}%`,
                }}
            >
                {percentage}%
            </div>
        </div>
    );
}