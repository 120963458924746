import * as React from "react";
import { DateRange as DateRangeType } from "react-day-picker";
import { Popover, PopoverContent, } from "@/components/v2/Overlay/popover";
import DefaultDateRangeInputComponent
    from "@/components/v2/SelectionAndInput/dateRange/components/DefaultDateRangeInputComponent";
import DefaultCalendar from "@/components/v2/SelectionAndInput/dateRange/components/DefaultCalendar";

export interface DateRangeInputProps {
    dateRange: DateRangeType;
    onChange: (date: DateRangeType | null) => void;
    placeholder?: string;
}

export default function DateRange(props: DateRangeInputProps) {
    const { placeholder, onChange, dateRange } = props;

    return (
        <Popover>
            <DefaultDateRangeInputComponent
                dateRange={dateRange}
                placeholder={placeholder}
                onClear={() => onChange(null)}
            />

            <PopoverContent className="w-auto" align="start">
                <DefaultCalendar dateRange={dateRange} onChange={onChange}/>
            </PopoverContent>
        </Popover>
    );
}
