import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import Table from "components/table/Table";
import { useTrans } from "components";
import TableRow from "components/table/TableRow";
import TableHeading from "components/table/TableHeading";
import TableCell from "@/components/table/TableCell";
import TableHead from "components/table/TableHead";
import TableBody from "components/table/TableBody";
import ContentCard from "components/containers/ContentCard";
import PageContent from "components/containers/PageContent";

export default function Recipients() {
    const { t } = useTrans();
    const { cardId } = useParams<{ cardId: ID }>();
    const { account } = useContext(AccountContext);
    const apiUrl = `/accounts/${account.id}/giftcards/cards/${cardId}/recipients`;
    const [recipients, setRecipients] = useState<{
        email: string,
        name: string,
        created_at: string,
    }[]>([]);
    const { pending, mutate } = useFetcher({
        url: apiUrl,
        dataSetter: setRecipients,
    });

    return (
        <PageContent
            title={t("recipients")}
        >
            <ContentCard>
                <Table>
                    <TableHead>
                        <TableHeading>
                            {t("email")}
                        </TableHeading>
                        <TableHeading>
                            {t("name")}
                        </TableHeading>
                        <TableHeading>
                            {t("createdAt")}
                        </TableHeading>
                    </TableHead>

                    <TableBody>
                        {recipients.map(recipient => (
                            <TableRow key={recipient.name + recipient.email}>
                                <TableCell>
                                    {recipient.email}
                                </TableCell>
                                <TableCell>
                                    {recipient.name}
                                </TableCell>
                                <TableCell>
                                    {recipient.created_at}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </ContentCard>
        </PageContent>
    );
}

