import React from "react";
import useNavigation from "@/components/navigation/useNavigation";
import MobileMenuDropdown from "@/components/navigation/mobile/MobileMenuDropdown";
import IconOnlyButton from "@/components/navigation/icons/IconOnlyButton";

export default function MobileMenu() {
    const { isOpen, setIsOpen, setUserDropdownOpen } = useNavigation();

    return (
        <>
            <IconOnlyButton
                isActive={isOpen}
                onClick={() => {
                    setIsOpen(!isOpen);
                    setUserDropdownOpen(false);
                }}
                className={"lg:hidden"}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                >
                    {isOpen ? (
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                    ) : (
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                        />
                    )}
                </svg>
            </IconOnlyButton>

            <MobileMenuDropdown/>
        </>
    );

}