import React, { useEffect } from "react";
import { useToast, useTrans } from "components";
import MediaType from "components/MediaType";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import { ModalProps } from "components/hooks/useModal";
import MediaComponent from "../MediaComponent";
import { saveAs } from "file-saver";
import cn from "classnames";
import { createPortal } from "react-dom";
import { depth } from "core/depth";

type Props = {
    modal: ModalProps
    media: MediaType;
    fileName?: string;
};

export default function MediaPreviewModal(props: Props) {
    const { t } = useTrans();
    const { errorToast } = useToast();
    const { modal } = props;

    useEffect(() => {
        if (modal.isActive) {
            document.onkeydown = (evt: KeyboardEvent) => {
                if ("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) {
                    modal.close();
                }
            };
        }
    }, [modal.isActive]);

    const download = () => {
        try {
            saveAs(props.media.value, props.fileName ?? "image_download");
        } catch (e: any) {
            errorToast(`Could not download media. Error: ${e.message}`);
        }
    };

    const modalCn = cn(
        `flex absolute rounded-md max-w-[80vw] max-h-[80vh] overflow-auto overscroll-contain`,
        modal.isActive ? "visible opacity-100" : "opacity-0 invisible",
    );

    const portalCn = cn(
        `absolute flex top-0 left-0 justify-center items-center w-full h-full transition-all duration-300`,
        modal.isActive ? "visible opacity-100" : "opacity-0 invisible",
    );

    return createPortal(
        <div
            style={{ zIndex: depth.modal }}
            className={portalCn}
        >
            <div className={modalCn}>
                <div className={`flex size-full`}>
                    <div className={`relative flex min-h-80 min-w-80`}>
                        <MediaComponent
                            className={`m-auto`}
                            media={props.media}
                        />
                        <div className={'sticky right-0 top-0 z-10 h-10'}>
                            <div className={'absolute right-2 top-2 z-10 flex gap-2'}>
                                <ButtonComponent
                                    icon={'download'}
                                    label={t('download')}
                                    button_style={ButtonStyle.Secondary}
                                    onClick={download}
                                    className={'flex w-fit place-items-center p-2.5'}
                                />
                                <ButtonComponent
                                    icon={'close'}
                                    button_style={ButtonStyle.Secondary}
                                    onClick={modal.close}
                                    className={'w-10 p-2.5'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button
                onClick={modal.close}
                className={'left-0 top-0 flex h-screen w-screen bg-black/50'}
            />
        </div>, document.getElementById('portal')
    );
}
