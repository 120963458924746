import { parseUrl, StringifyOptions, stringifyUrl } from "query-string";
import useRouting from "components/hooks/useRouting";

const options: StringifyOptions = {
    arrayFormat: "bracket",
    skipNull: true,
    skipEmptyString: true,
};

export default function useQueryParams() {
    const { url } = useRouting();
    const current = parseUrl(window.location.href).query;

    const addParam = (key: string, value: any) => {
        const newURL = stringifyUrl({
            url: url,
            query: {
                ...current,
                [key]: value
            },
        }, options);

        window.history.replaceState(null, '', newURL);
    };

    const removeParam = (key: string) => {
        delete current[key];

        const newURL = stringifyUrl({
            url: url,
            query: current
        }, options);

        window.history.replaceState(null, '', newURL);
    };

    const setMany = (object: Record<string, any>) => {
        const newURL = stringifyUrl({
            url: url,
            query: {
                ...current,
                ...object
            }
        }, options);

        window.history.replaceState(null, '', newURL);
    };

    const getAll = (key: string): string[] => {
        const values = current[key];

        if (values === null || values === undefined) {
            return [];
        }

        if (Array.isArray(values)) {
            return values;
        }

        return [values];
    };

    const get = (key: string): string | null => {
        const value = current[key];

        if (value === null || value === undefined) {
            return null;
        }

        return value.toString();
    };

    return {
        get: get,
        getAll: getAll,
        setMany: setMany,
        current: current,
        addParam,
        removeParam
    };
}