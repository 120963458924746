import React, { useContext, useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import Sidebar from "components/sidebar/v2/Sidebar";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import SidebarHeader from "components/sidebar/v2/SidebarHeader";
import SidebarTabsContainer from "components/sidebar/v2/tabs/SidebarTabsContainer";
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import ContentNextToSidebar from "components/sidebar/ContentNextToSidebar";
import BasePage from "components/containers/BasePage";
import Cards from "modules/store/giftcards/programs/Cards";
import Transactions from "modules/store/giftcards/programs/Transactions";
import useGiftcardProgram from "@/routing/store/giftcards/useGiftcardProgram";
import { AccountContext } from "context";
import Statistics from "modules/store/giftcards/programs/statistics/Statistics";
import cn from "classnames";
import Reports from "@/modules/store/giftcards/programs/settling/Reports";
import Settings from "modules/store/giftcards/programs/Settings";
import SettlingSettings from "modules/store/giftcards/programs/settling/Settings";
import Groups from "modules/store/giftcards/programs/Groups";
import { depth } from "core/depth";
import useModal from "components/hooks/useModal";
import Modal from "components/modals/types/Modal";
import TextInput from "components/forms/Text/TextInput";
import { reloadCurrentPage } from "core";
import useHttp from "core/http/useHttp";
import SidebarItemWithSub from "@/components/sidebar/v2/tabs/SidebarItemWithSub";
import SidebarRouteSubItem from "@/components/sidebar/v2/tabs/SidebarRouteSubItem";
import SidebarRouteItem from "@/components/sidebar/v2/tabs/SidebarRouteItem";
import { Route, Routes } from "react-router";

export default function Overview() {
    const { t } = useTrans();
    const http = useHttp();
    const { errorToast, successToast } = useToast();
    const { selectedProgram, setSelectedProgram } = useGiftcardProgram();
    const { account } = useContext(AccountContext);
    const programs = account.giftcard_programs;
    const [createPending, setCreatePending] = useState(false);
    const [programName, setProgramName] = useState("");
    const modal = useModal();
    const routes = [
        {
            name: t("cards"),
            path: "../cards",
        },
        {
            name: t("transactions"),
            path: "../transactions",
        },
        {
            name: t("statistics"),
            path: "../statistics",
        },
        {
            name: t("settling"),
            path: "../settling",
            sub: [
                {
                    path: "../settling/reports",
                    name: t("reports"),
                },
                {
                    path: "../settling/settings",
                    name: t("settings"),
                },
            ],
        },
        {
            name: t("groups"),
            path: "../groups",
        },
        {
            name: t("settings"),
            path: "../settings",
        },
    ];

    useEffect(() => {
        if (selectedProgram) {
            return;
        }

        if (programs.length > 0) {
            setSelectedProgram(programs[0]);
        }
    }, [programs.length, selectedProgram]);

    const createProgram = () => {
        setCreatePending(true);
        http.post(`/accounts/${account.id}/giftcards/programs`, {
            name: programName
        })
            .then(() => {
                successToast();
                reloadCurrentPage();
            })
            .catch(errorToast)
            .finally(() => setCreatePending(false));
    };

    return (
        <BasePage>
            <Sidebar>
                <SidebarHeader>
                    <div className={`mb-3 flex w-full items-center justify-between px-3 pt-8`}>
                        <p className={'hidden text-xl text-white lg:block'}>
                            {t("giftcards")}
                        </p>

                        <button className={"btn-outline block text-white hover:text-white/70"} onClick={modal.open}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                            </svg>
                        </button>
                    </div>

                    <p className={"ml-1 mt-4 text-xs text-gray-200"}>{t('selectedProgram')}</p>
                    <Listbox
                        value={selectedProgram}
                        onChange={setSelectedProgram}
                    >
                        <ListboxButton
                            className={cn(
                                'relative block w-full rounded-lg bg-white py-1.5 pr-8 pl-3 text-left text-sm/6 shadow',
                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25'
                            )}
                        >
                            {selectedProgram?.name}
                            <ChevronDownIcon
                                className="group pointer-events-none absolute right-2.5 top-2.5 size-4 fill-black/60"
                                aria-hidden="true"
                            />
                        </ListboxButton>

                        <ListboxOptions
                            anchor="bottom"
                            style={{ zIndex: depth.navigation - 1 }}
                            transition
                            className={cn(
                                "z-10",
                                'w-[var(--button-width)] rounded-xl border border-black/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                                'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                            )}
                        >
                            {programs.map((program) => (
                                <ListboxOption
                                    key={program.name}
                                    value={program}
                                    className="group flex cursor-pointer select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-black/10"
                                >
                                    <CheckIcon
                                        className="invisible size-4 fill-black group-data-[selected]:visible"
                                    />
                                    <div className="text-sm/6">{program.name}</div>
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </Listbox>

                </SidebarHeader>
                <SidebarTabsContainer className={"min-w-72 max-w-72 divide-y divide-gray-500 py-0"}>
                    <div>
                        {routes.map(i => {

                            if (i.sub) {
                                return (
                                    <SidebarItemWithSub
                                        key={i.path}
                                        path={i.path}
                                        name={i.name}
                                    >
                                        {i.sub.map((i: any) => (
                                            <SidebarRouteSubItem
                                                key={i.path}
                                                path={i.path}
                                                name={i.name}
                                            />
                                        ))}
                                    </SidebarItemWithSub>
                                );
                            } else {
                                return <SidebarRouteItem key={i.path} path={i.path} name={i.name}/>;
                            }
                        })}
                    </div>
                </SidebarTabsContainer>
            </Sidebar>

            <ContentNextToSidebar>
                <Routes>
                    <Route
                        path={`/cards`}
                        element={<Cards/>}
                    />

                    <Route
                        path={`/transactions`}
                        element={<Transactions/>}
                    />

                    <Route
                        path={`/statistics`}
                        element={<Statistics/>}
                    />

                    <Route
                        path={`/settling/reports`}
                        element={<Reports/>}
                    />

                    <Route
                        path={`/settling/settings`}
                        element={<SettlingSettings/>}
                    />

                    <Route
                        path={`/groups`}
                        element={<Groups/>}
                    />

                    <Route
                        path={`/settings`}
                        element={<Settings/>}
                    />
                </Routes>
            </ContentNextToSidebar>

            <Modal
                title={t("createNewProgram")}
                modal={modal}
                primaryButton={{
                    label: t("create"),
                    onClick: createProgram,
                    pending: createPending,
                    disabled: !programName
                }}
            >
                <TextInput
                    name={"programName"}
                    label={t("programName")}
                    placeholder={t("programName")}
                    value={programName}
                    onChange={e => setProgramName(e.target.value)}
                />
            </Modal>
        </BasePage>
    );
}
