import React, { forwardRef, useEffect, useImperativeHandle, useState, } from 'react';
import List from "@/components/forms/TipTap/mergeTagsMention/List";

export const EmojiList = forwardRef((props: any, ref: any): any => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index: number) => {
        const item = props.items[index];

        if (item) {
            props.command({ name: item.name });
        }
    };

    const upHandler = () => {
        setSelectedIndex(((selectedIndex + props.items.length) - 1) % props.items.length);
    };

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
        selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => {
        return {
            onKeyDown: (x: any) => {
                if (x.event.key === 'ArrowUp') {
                    upHandler();
                    return true;
                }

                if (x.event.key === 'ArrowDown') {
                    downHandler();
                    return true;
                }

                if (x.event.key === 'Enter') {
                    enterHandler();
                    return true;
                }

                return false;
            },
        };
    }, [upHandler, downHandler, enterHandler]);

    return <List
        selectedIndex={selectedIndex}
        items={props.items}
        onSelect={selectItem}
        render={(item: any) => (
            <div className={"flex gap-2"}>
                {item.fallbackImage ? (
                    <img className={"my-auto ml-1 block size-4"} alt={"fallback"} src={item.fallbackImage}/>
                ) : (
                    item.emoji
                )}
                :{item.name}:
            </div>
        )}
    />;
});