import { useEffect } from "react";

type Props = {
    key: "Escape";
    onPress: () => void;
};

export default function useKeyPress(props: Props) {
    const { key, onPress } = props;

    const checkKey = (evt: KeyboardEvent) => {
        if (key == "Escape") {
            return (evt.key === "Escape" || evt.key === "Esc");
        }

        return false;
    };

    useEffect(() => {
        function onKeydown(evt: KeyboardEvent) {
            if ("key" in evt && checkKey(evt)) {
                onPress();
            }
        }

        window.addEventListener("keydown", onKeydown);

        return () => window.removeEventListener("keydown", onKeydown);
    }, []);
}