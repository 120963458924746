import Text from "components/typography/Text";
import React from "react";
import UIComponent from "components/UIComponent";
import SidebarCard from "components/cards/SidebarCard";

export type SidebarHeaderProps = {
    title?: string;
    back_button?: any;
    elements?: any;
    actions?: any[];
};

export default function SidebarHeader(props: SidebarHeaderProps) {
    const { title, back_button, elements, actions } = props;

    return (
        <div className={`mx-4 flex flex-col gap-2 border-b border-gray-500 pb-6`}>

            {title ? (
                <Text size={"xl"} color={"text-white"} className={'pt-8'}>
                    {title}
                </Text>
            ) : null}

            {back_button && (
                <div className={'pt-8'}>
                    <UIComponent element={back_button}/>
                </div>
            )}

            {(elements && Array.isArray(elements)) ? (
                <div className={'pt-4'}>
                    {elements?.map((element: any) => {
                        if (element.type == "sidebar_card") {
                            return <SidebarCard key={`${element.type}_${element.id}`} {...element} actions={actions}/>;
                        }

                        return <UIComponent key={`${element.type}_${element.id}`} element={element}/>;
                    })}
                </div>
            ) : elements}
        </div>
    );
}
