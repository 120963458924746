import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import Table from "components/table/Table";
import { useTrans } from "components";
import TableRow from "components/table/TableRow";
import TableHeading from "components/table/TableHeading";
import TableCell from "@/components/table/TableCell";
import TableHead from "components/table/TableHead";
import TableBody from "components/table/TableBody";
import ContentCard from "components/containers/ContentCard";
import PageContent from "components/containers/PageContent";

export default function Settlements() {
    const { t } = useTrans();
    const { transactionId } = useParams<{ transactionId: ID }>();
    const { account } = useContext(AccountContext);
    const [settlements, setSettlements] = useState<Dict[]>([]);
    const apiUrl = `/accounts/${account.id}/giftcards/transactions/${transactionId}/settlements`;
    useFetcher({
        url: apiUrl,
        dataSetter: setSettlements,
    });

    return (
        <PageContent
            title={t("settlements")}
        >
            <ContentCard>
                <Table>
                    <TableHead>
                        <TableHeading>
                            {t("amount")}
                        </TableHeading>
                        <TableHeading>
                            {t("businessProfile")}
                        </TableHeading>
                        <TableHeading>
                            {t("type")}
                        </TableHeading>
                        <TableHeading>
                            {t("channel")}
                        </TableHeading>
                        <TableHeading>
                            {t("createdAt")}
                        </TableHeading>
                    </TableHead>

                    <TableBody>
                        {settlements.map(settlement => (
                            <TableRow key={settlement.id}>
                                <TableCell>
                                    {settlement.amount}
                                </TableCell>
                                <TableCell>
                                    {settlement.business_profile}
                                </TableCell>
                                <TableCell>
                                    {settlement.type}
                                </TableCell>
                                <TableCell>
                                    {settlement.channel}
                                </TableCell>
                                <TableCell>
                                    {settlement.created_at}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </ContentCard>
        </PageContent>
    );
}

