import React from "react";

type Props = {
    children: any[];
    classNames?: string;
};

export default function GroupedElements(props: Props) {
    return (
        <div className={`form-element__group ${props.classNames}`}>
            {props.children}
        </div>
    );
}
