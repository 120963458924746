import MediaType from "../MediaType";
import { IconType } from "../icons/Icon";

export type PaginationType = {
    page: number;
    limit: number;
    lastPage: number;
    total: number;
};

export type Table = {
    name: string;
    description?: string;
    row_type: "single" | "double";
    title_bar: any;
    action_bar: ActionBarType;
    header: {
        items: HeaderItemType[];
    };
    rows: TableRowType[];
    empty_state_url?: string;
    empty_state?: EmptyStateType;
    is_draggable?: boolean;
    pagination?: PaginationType;
    has_config?: boolean;
};

export type TableRowType = {
    id: string | number;
    columns: TableColumnType[];
    on_row_click?: any;
};

export type ActionBarType = {
    has_search: boolean;
    actions: [];
    filters: [];
};

export type Sortable = {
    key: string;
    direction: "ASC" | "DESC";
    order_by: "";
    icons?: [];
};

export type TableColumnType = {
    value?: any;
    align?: string;
};

export type HeaderItemType = {
    key: any;
    value?: any;
    align?: string;
    sortable?: Sortable;
    tooltip?: any;
};

export type ConfigHeaderItemType = {
    type: string;
    id: any;
    label: string;
    disabled: boolean;
    visibility_icon: MediaType;
    drag_icon: MediaType;
};

export type EmptyStateType = {
    title?: string;
    description: string;
    icon?: IconType;
    iconBackgroundTailwindColor?: string;
};

export const defaultTable: Table = {
    name: "",
    description: "",
    row_type: "single",
    title_bar: {
        title: false,
        description: [],
        badge: [],
        actions: [],
    },
    action_bar: {
        has_search: false,
        actions: [],
        filters: [],
    },
    header: {
        items: [],
    },
    rows: [
        {
            id: "",
            columns: [],
        },
    ],
    pagination: {
        page: 1,
        limit: 10,
        lastPage: 1,
        total: 10,
    },
};
