import {useEffect} from "react";
import TagManager from 'react-gtm-module';

export default function WithGoogleTagManager(props: any) {

    useEffect(() => {
        if (import.meta.env.PROD) {
            const tagManagerArgs = {
                gtmId: import.meta.env.VITE_GTM_ID as string,
            };

            TagManager.initialize(tagManagerArgs);
        }
    }, []);

    return props.children;
}