import React from "react";
import { FilterItem } from "../../filters/OverviewHeader";
import { CommonProps } from "components/panel_v2/Panel";
import FormElement from "components/forms/FormElement";

export type FilterPanelProps = {
    onFilterChange?: any;
    filterValues?: any;
    filters?: Array<FilterItem | FilterItem[]>;
    container?: React.RefObject<HTMLDivElement>;
};

export default function FilterPanel(props: CommonProps & FilterPanelProps) {
    const { onFilterChange, filterValues, filters } = props;

    const buildElement = (filter: FilterItem) => (
        <FormElement
            {...filter}
            key={filter.name + "filter"}
            type={filter.filterType}
            value={filterValues[filter.name]}
            onChange={onFilterChange}
            className={'w-full'}
            barClassName={'flex flex-grow'}
        />
    );

    return (
        <div className={"flex flex-col gap-4"}>
            {filters.map((filter, index) => {
                if (Array.isArray(filter)) {
                    return (
                        <div className={"mb-6 flex gap-5"} key={`filter-group-${index}`}>
                            {filter.map(buildElement)}
                        </div>
                    );
                }

                return buildElement(filter);
            })}
        </div>
    );
}
