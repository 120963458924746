import React from "react";
import Text from "components/typography/Text";

type Props = {
    label?: string;
    children?: any;
    description?: string;
    innerHTML?: boolean;
    modal?: boolean;
    onClick?: any;
    fullWidth?: boolean;
    className?: string;
};

export default function FormGroup(props: Props) {
    const { label, children, innerHTML, description, modal, onClick, className, fullWidth } = props;

    return (
        <div
            className={`first:pt-0 last:pb-0 last-of-type:mb-0 last-of-type:pb-0 ${className ? className : ""} ${modal ? 'intro-on-top' : ''} ${fullWidth ? 'is-full-width' : 'max-w-3xl'}`}
            onClick={onClick ? onClick : null}
        >
            {innerHTML ? (
                <div className='' dangerouslySetInnerHTML={{ __html: label }}/>
            ) : (
                <div className='' hidden={!label && !description}>
                    {label && (
                        <Text size={"lg"}>
                            {label}
                        </Text>
                    )}
                    {description && (
                        <Text size={"sm"} color={"text-gray-500"}>
                            {description}
                        </Text>
                    )}
                </div>
            )}

            <div className='w-full'>
                {children}
            </div>
        </div>
    );
}

