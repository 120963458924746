import * as React from "react";
import { Calendar as CalendarIcon, X } from "lucide-react";
import { DateRange } from "react-day-picker";
import { InputGroup, TextInput } from "@/components/v2/SelectionAndInput/textInput";
import { PopoverAnchor, PopoverTrigger, } from "@/components/v2/Overlay/popover";
import { formatDateRange } from "components/v2/SelectionAndInput/dateRange/helpers/dateRangeHelpers";
import { useTrans } from "components";

type Props = {
    dateRange: DateRange;
    onClear: () => void;
    placeholder?: string;
};

export default function DefaultDateRangeInputComponent(props: Props) {
    const { t } = useTrans();
    const { dateRange, placeholder, onClear } = props;

    return (
        <PopoverTrigger
            data-slot={"control"}
            asChild
        >
            <PopoverAnchor>
                <InputGroup className={'relative flex items-center'}>
                    <CalendarIcon data-slot="icon" className="text-icon-default"/>
                    <TextInput
                        value={formatDateRange(dateRange)}
                        placeholder={placeholder === undefined ? t("searchBetweenDates") : placeholder}
                        data-slot="icon"
                        readOnly
                    />

                    {dateRange && (
                        <button
                            type={"button"}
                            className="absolute right-2 flex size-5 items-center justify-center rounded-full transition-bg hover:bg-gray-50"
                            onClick={(e) => {
                                e.stopPropagation(); // This stops the clicking of the clear button to re-open the popover.
                                onClear();
                            }}
                        >
                            <X
                                className="size-3.5"
                                color="var(--neutral-500)"
                            />
                        </button>
                    )}
                </InputGroup>
            </PopoverAnchor>
        </PopoverTrigger>
    );
}
