import React from "react";
import MobileNavItemWithDropdown from "@/components/navigation/mobile/MobileNavItemWithDropdown";
import MobileSubNavItem from "@/components/navigation/mobile/MobileSubNavItem";
import useApps from "@/components/navigation/items/useApps";

export default function Apps(props: {
    active: boolean;
}) {
    const { active } = props;
    const apps = useApps();

    return (
        <MobileNavItemWithDropdown
            path={"apps"}
            label={"Apps"}
        >
            {active && (
                <>
                    {apps.customerApp.usesFeature && (
                        <MobileSubNavItem
                            name={apps.customerApp.name}
                            path={apps.customerApp.path}
                        />
                    )}

                    {apps.businessApp.hasPermission && (
                        <MobileSubNavItem
                            name={apps.businessApp.name}
                            path={apps.businessApp.path}
                        />
                    )}

                    {apps.portalSessions.usesFeature && (
                        <MobileSubNavItem
                            name={apps.portalSessions.name}
                            path={apps.portalSessions.path}
                        />
                    )}

                    <MobileSubNavItem
                        name={apps.contactsPortal.name}
                        path={apps.contactsPortal.path}
                    />

                    <MobileSubNavItem
                        name={apps.widget.name}
                        path={apps.widget.path}
                    />

                    <MobileSubNavItem
                        name={apps.walletPasses.name}
                        path={apps.walletPasses.path}
                    />

                    <MobileSubNavItem
                        name={apps.integrations.name}
                        path={apps.integrations.path}
                    />
                </>
            )}
        </MobileNavItemWithDropdown>
    );
}
