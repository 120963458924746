import { useNavigate, useResolvedPath } from "react-router";
import { parse } from "query-string";

export default function useRouting() {
    const navigate = useNavigate();
    const queryParams = parse(location.search);
    const url = useResolvedPath("").pathname;
    const replace = (newUrl: string) => navigate(newUrl, { replace: true });

    const currentPath = window.location.pathname;

    const isPathActive = (path: string) => {
        return currentPath.includes(path);
    };


    return {
        goBack: () => navigate(-1),
        go: history.go,
        replace: replace,
        url: url,
        push: (url: string) => navigate(url),
        queryParams: queryParams,
        isPathActive: isPathActive,
        currentPath: currentPath,
    };
}
