import AccountUser from "models/accountUser/AccountUser";
import { createContext } from "react";

export const AccountUserContext = createContext<Partial<AccountUser>>({});

type FilterValuesContext = {
    queries: any;
    setQueries: any;
};

export const FilterValuesContext = createContext<Partial<FilterValuesContext>>({});