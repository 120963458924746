export const colors = {
    neutral: "#42F2DE",
    draft: "#FFCA00",
    scheduled: "#3492FF",
    primary: "#3430FF",
    success: "#00D377",
    warning: "#FFCA00",
    error: "#FF424E",
    bin: "#8F8FBA",
};
export const uiColors = {
    highlight_primary: "#3430FF",
    highlight_secondary: "#8F8FBA",
    highlight_success: "#00D377",
    highlight_warning: "#FFCA00",
    highlight_error: "#FF424E",
    dim_primary: "#1A16CC",
    dim_secondary: "#74748f",
    dim_success: "#039855",
    dim_warning: "#E8C135",
    dim_error: "#E02731",
};

export type TailwindBorderColors =
    | "border-gray-25"
    | "border-gray-50"
    | "border-gray-100"
    | "border-gray-200"
    | "border-gray-300"
    | "border-gray-400"
    | "border-gray-500"
    | "border-gray-600"
    | "border-gray-700"
    | "border-gray-800"
    | "border-gray-900"
    | "border-primary-25"
    | "border-primary-50"
    | "border-primary-100"
    | "border-primary-200"
    | "border-primary-300"
    | "border-primary-400"
    | "border-primary-500"
    | "border-primary-600"
    | "border-primary-700"
    | "border-primary-800"
    | "border-white"
    | "border-primary-900"
    | "border-success-500";

export type TailwindTextColors =
    | "text-gray-25"
    | "text-gray-50"
    | "text-gray-100"
    | "text-gray-200"
    | "text-gray-300"
    | "text-gray-400"
    | "text-gray-500"
    | "text-gray-600"
    | "text-gray-700"
    | "text-gray-800"
    | "text-gray-900"
    | "text-primary-25"
    | "text-primary-50"
    | "text-primary-100"
    | "text-primary-200"
    | "text-primary-300"
    | "text-primary-400"
    | "text-primary-500"
    | "text-primary-600"
    | "text-primary-700"
    | "text-primary-800"
    | "text-primary-900"
    | "text-piggyOrange-25"
    | "text-piggyOrange-50"
    | "text-piggyOrange-100"
    | "text-piggyOrange-200"
    | "text-piggyOrange-300"
    | "text-piggyOrange-400"
    | "text-piggyOrange-500"
    | "text-piggyOrange-600"
    | "text-piggyOrange-700"
    | "text-piggyOrange-800"
    | "text-piggyOrange-900"
    | "text-white"
    | "text-black"
    | "text-success-500"
    | "text-warning-900"
    | "text-error-300"
    | "text-error-400"
    | "text-error-500"
    | "text-error-600"
    | "text-error-700"
    | "text-error-800"
    | "text-error-900";
