import React from "react";
import ModalTabBar, { TabItem } from "components/tabs/ModalTabBar";

type Props = {
    title: any;
    activeTab: any;
    setActiveTab: any;
    tabs: TabItem[];
};

export default function MediaModalHeaderV2(props: Props) {
    const { title, tabs, activeTab, setActiveTab } = props;

    return (
        <div className="flex flex-col rounded-t-lg bg-gray-900">
            <div className={'flex w-full justify-between px-6 py-4'}>
                <h1 className={'text-xl text-white'}>{title}</h1>
            </div>
            <ModalTabBar tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>
        </div>
    );
}
