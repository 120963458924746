import FormElement from "components/forms/FormElement";
import FormGroup from "components/forms/FormGroup";
import useModal from "components/hooks/useModal";
import { useToast, useTrans } from "components/index";
import Modal from "components/modals/types/Modal";
import { Plus } from "lucide-react";
import AttributeCard from "modules/apps/businessApp/configurations/details/AttributeCard";
import { useEffect, useRef, useState } from "react";
import Sortable, { type SortableEvent } from "sortablejs";

type Props = {
	title: string;
	description: string;
	selected: any[];
	available: any[];
	update: any;
};

export default function DraggableList(props: Props) {
	const { t } = useTrans();
	const { title, description, selected, available, update } = props;
	const { errorToast } = useToast();
	const dragListRef = useRef(null);
	const [selectedContactAttribute, setSelectedContactAttribute] = useState("");
	const [updatePending, setUpdatePending] = useState(false);
	const modal = useModal();

	const onAdd = () => {
		setUpdatePending(true);
		update("add", selectedContactAttribute)
			.then(() => {
				modal.close();
				setSelectedContactAttribute(null);
			})
			.catch(errorToast)
			.finally(() => setUpdatePending(false));
	};

	const onRemove = (attributeId: any) => {
		setUpdatePending(true);
		update("remove", attributeId)
			.then(() => {
				modal.close();
				setSelectedContactAttribute(null);
			})
			.catch(errorToast)
			.finally(() => setUpdatePending(false));
	};

	useEffect(() => {
		if (dragListRef.current) {
			Sortable.create(dragListRef.current as HTMLElement, {
				handle: ".draggable",
				animation: 250,
				onEnd: (event) => position(event),
			});
		}
	}, []);

	const position = (event: SortableEvent) => {
		if (event.newDraggableIndex == event.oldDraggableIndex) {
			return;
		}

		if (event.newIndex == event.oldIndex) {
			return;
		}

		setUpdatePending(true);
		update("position", {
			id: event.item.id,
			new_position: event.newIndex,
		})
			.then(() => {
				modal.close();
				setSelectedContactAttribute(null);
			})
			.catch(errorToast)
			.finally(() => setUpdatePending(false));
	};

	return (
		<div className={"flex flex-col gap-1"}>
			<FormGroup label={title} description={description}>
				<div className={"flex flex-col gap-2"} ref={dragListRef}>
					{selected.map((selected: any) => (
						<AttributeCard
							key={selected.id}
							{...selected}
							onRemove={(id: any) => onRemove(id)}
							update={update}
							pending={updatePending}
						/>
					))}
				</div>
			</FormGroup>

			<button
				className={"mt-2 flex gap-1 font-light text-primary-600"}
				onClick={modal.open}
			>
				<Plus className={"my-auto size-4"} />
				<span>{t("addAttribute")}</span>
			</button>

			<Modal
				modal={modal}
				title={t("addContactAttributeCustomApp")}
				primaryButton={{
					onClick: onAdd,
					pending: updatePending,
					disabled: !selectedContactAttribute,
				}}
			>
				{available && (
					<FormElement
						key={selectedContactAttribute}
						type={"select"}
						name="contactAttribute"
						value={selectedContactAttribute}
						label={t("contactAttribute")}
						required={true}
						options={available}
						onChange={(name, value) => setSelectedContactAttribute(value)}
					/>
				)}
			</Modal>
		</div>
	);
}
