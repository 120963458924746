import React, { useRef } from "react";
import MediaType from "components/MediaType";
import MediaComponent from "components/MediaComponent";
import Text from "../typography/Text";
import { useTrans } from "components";
import { MediaPickerShape } from "components/media/MediaPicker";
import cn from "classnames";
import ImageComponent from "../image/ImageComponent";
import checkeredSmall from "assets/images/piggy-checkered-small.jpg";
import ButtonComponent, { ButtonStyle } from "../buttons/ButtonComponent";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

type Props = {
    name: string;
    media: MediaType | null;
    label?: string;
    value: any;
    onChange?: (name: string, media: MediaType) => void;
    onBlur?: (name: string, media: MediaType) => void;
    onClear?: (name: string) => void;
    disabled?: boolean;
    shape?: MediaPickerShape;
    container?: React.MutableRefObject<null>;
    className?: string;
};

export default function FormMediaPicker(props: Props) {
    const { t } = useTrans();
    const {
        media,
        onChange,
        onBlur,
        name,
        label,
        shape = name === "avatar" ? "round" : "square",
        disabled = false,
        className
    } = props;
    const inputRef = useRef(null);
    const onSubmit = (file: any) => {
        onChange && onChange(name, file);
        onBlur && onBlur(name, file);
    };

    const onClear = () => props.onClear && props.onClear(name);

    const onButtonClick = () => {
        inputRef.current.click();
    };

    const imageCn = cn(
        "h-8 w-8",
        shape === "round" ? "rounded-full" : "rounded",
    );

    const containerCn = cn(
        `relative flex container my-2 px-3 py-2 bg-white border border-gray-200 rounded-md h-auto transition-all ease-in-out`,
        className && className,
        disabled ? 'bg-gray-50 cursor-not-allowed' : '',
    );

    return (
        <div className={containerCn}>
            <div className={"relative flex grow cursor-default flex-wrap items-center justify-between gap-3"}>
                <div className={`group relative ${imageCn} shrink-0`}>
                    <MediaComponent
                        className={`z-10 flex ${imageCn} object-cover`}
                        media={media ?? {
                            type: "image",
                            value: "",
                            name: ""
                        }}
                    />
                    {media && (
                        <div>
                            <ImageComponent
                                src={checkeredSmall}
                                className={`${imageCn} absolute inset-0 z-0`}
                            />
                            <div className={`absolute inset-0 ${imageCn} border border-gray-200`}/>
                        </div>
                    )}
                </div>
                <div className={"relative flex grow flex-col justify-self-start"}>
                    <Text
                        size={media ? "xs" : "md"}
                        weight={media ? 'normal' : 'light'}
                        color={disabled ? 'text-gray-400' : 'text-gray-500'}
                    >
                        {label ?? t('mediaUpload')}
                    </Text>
                    {media && (
                        <div className={'flex items-center gap-1'}>
                            <Text
                                size={"sm"}
                                weight={"light"}
                                color={disabled ? 'text-gray-400' : 'text-gray-500'}
                                className={`max-w-48 truncate break-all ${disabled ? '' : 'underline decoration-gray-900/10'}`}
                                hidden={disabled}
                            >
                                <a href={props.value} target={"_blank"} className={'group flex items-center gap-1'}>
                                    {t('media')}
                                    <ArrowUpRightIcon
                                        className={'size-2 bg-gray-500 opacity-0 transition-all duration-200 group-hover:opacity-100'}
                                    />
                                </a>
                            </Text>
                        </div>
                    )}
                </div>
                <div className={'flex gap-2 justify-self-end'}>
                    <ButtonComponent
                        button_style={ButtonStyle.Secondary}
                        label={media ? t('replaceMedia') : t('chooseMedia')}
                        onClick={onButtonClick}
                        disabled={disabled}
                    />
                    {media && (
                        <ButtonComponent
                            button_style={ButtonStyle.Secondary}
                            icon={'trash'}
                            onClick={onClear}
                            disabled={disabled}
                        />
                    )}
                </div>
            </div>
            <input
                accept={"image/*"}
                ref={inputRef as any}
                id={`file_input-${name}`}
                type={"file"}
                hidden={true}
                onChange={(e) => onSubmit(e.target.files[0])}
                disabled={disabled}
            />

        </div>
    );
}
