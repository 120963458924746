import React from 'react';

export default function CardHeaderContainer(props: {
    children: any,
    className?: string
}) {
    const { className } = props;
    return (
        <div
            className={`flex items-center justify-between border-b border-gray-900/10 p-5 ${className ? className : ''}`}
        >
            {props.children}
        </div>
    );
}