import React from "react";
import cn from "classnames";
import TabBar, { TabItem } from "../tabs/TabBar_V2";
import EmptyState from "../EmptyState";

type Props = {
    tabs?: TabItem[];
    onTabSelect?: any;
    setActiveTab?: any;
    activeTab?: TabItem;
    children?: React.ReactNode;
    emptyState?: any;
    className?: any;
};

export default function ContentCard(props: Props) {
    const hasTabs = props.tabs && props.tabs.length > 0;
    const containerCn = cn(
        props.className ?? "",
        "dark:bg-slate-900 bg-white rounded-lg border border-gray-200 dark:border-gray-500 transition-all duration-300 px-2 py-4 lg:py-8 lg:px-8",
        hasTabs ? "pt-5 rounded-tl-none border-t-0 w-full" : "",
    );

    return (
        <div id={"content-card"} className={"w-full"}>
            {hasTabs && (
                <TabBar
                    onTabSelect={props.onTabSelect}
                    tabs={props.tabs}
                    activeTab={props.activeTab}
                    setActiveTab={props.setActiveTab}
                />
            )}
            <div className={containerCn}>
                {props.emptyState ? (
                    <EmptyState {...props.emptyState} />
                ) : (
                    props.children
                )}
            </div>
        </div>
    );
}
