export default function useGtm() {

    const push = (data: any) => {
        if (!window.dataLayer) {
            console.warn("dataLayer is not defined");
            return;
        }

        dataLayer.push(data);
    };

    return {
        push
    };
}