export type MediaSubType = "emoji" | "image" | "icon" | "string" | "number" | "video" | "lottie" | "avatar" | "gif";

export default class MediaType {
    id?: any;
    name?: string;
    color?: string;
    type: MediaSubType;
    value: string;
    thumbnail_url?: string;
    icons?: [];
}