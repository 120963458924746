import React from "react";

export type SpacerProps = {
    space: "small" | "medium" | "large"
};

export default function Spacer(props: SpacerProps) {
    const className = () => {
        if (props.space === "small") {
            return "mt-5 block";
        }

        if (props.space === "medium") {
            return "mt-10";
        }

        return "mt-20";
    };

    return (
        <div className={className()}/>
    );
}