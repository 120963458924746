import React from 'react';
import Text from "components/typography/Text";

type Props = {
    title: string;
    description?: string
};

export default function CardHeader(props: Props) {
    return (
        <div>
            <Text
                weight={"normal"}
                color={"text-gray-900 dark:text-white"}
                className={'font-display'}
            >
                {props.title}
            </Text>

            {props.description && (
                <Text
                    size={"sm"}
                    weight={"extralight"}
                    color={"text-gray-500 dark:text-white"}
                >
                    {props.description}
                </Text>
            )}
        </div>
    );
}