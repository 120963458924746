import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/v2/Actions/button";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
                      className,
                      classNames,
                      showOutsideDays = true,
                      ...props
                  }: CalendarProps) {
    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn("p-3", className)}
            classNames={{
                months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
                month: "space-y-4",
                caption: "flex justify-between relative items-center ",
                caption_label: "font-tracking-normal font-display text-[14px] font-medium leading-5",
                nav: "space-x-1 flex ",
                nav_button: cn(
                    buttonVariants({ variant: "default" }),
                    "h-7 w-7 bg-transparent p-0 hover:opacity-60"
                ),
                table: "w-full border-collapse space-y-1",
                head_row: "flex",
                head_cell:
                    "text-text-tertiary rounded-sm w-8 font-tracking-normal font-body text-[13px] font-regular leading-5 dark:text-zinc-400",
                row: "flex w-full mt-2",
                cell: cn(
                    "relative p-0 text-center font-tracking-normal font-body text-[13px] font-regular leading-5 rounded-sm focus-within:shadow-focus-ring focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-zinc-100 [&:has([aria-selected].day-outside)]:bg-zinc-100/50 [&:has([aria-selected].day-range-end)]:rounded-r-sm ",
                    props.mode === "range"
                        ? "[&:has(>.day-range-end)]:rounded-r-sm [&:has(>.day-range-start)]:rounded-l-sm first:[&:has([aria-selected])]:rounded-l-sm last:[&:has([aria-selected])]:rounded-r-sm"
                        : "[&:has([aria-selected])]:rounded-sm"
                ),
                day: cn(
                    // buttonVariants({ variant: "tertiary" }),
                    "h-8 w-8 p-0 font-normal rounded-sm aria-selected:opacity-100"
                ),
                day_range_start: "day-range-start",
                day_range_end: "day-range-end",
                day_today: "bg-bg-surface-info-default text-text-info-default",
                day_selected:
                    "bg-bg-fill-brand-default text-text-brand-on-bg-fill-default hover:bg-bg-fill-brand-hover hover:text-text-brand-on-bg-fill-hover focus-within:shadow-focus-ring",
                day_outside:
                    "day-outside text-text-tertiary aria-selected:bg-bg-fill-brand-default aria-selected:text-text-brand-on-bg-fill-default",
                day_disabled: "text-text-disabled",
                day_range_middle:
                    "aria-selected:bg-bg-surface-info-default aria-selected:text-text-info-default ",
                day_hidden: "invisible",
                ...classNames,
            }}
            components={{
                IconLeft: ({ className, ...props }) => (
                    <ChevronLeft className={cn("h-4 w-4", className)} {...props} />
                ),
                IconRight: ({ className, ...props }) => (
                    <ChevronRight className={cn("h-4 w-4", className)} {...props} />
                ),
            }}
            {...props}
        />
    );
}

Calendar.displayName = "Calendar";

export { Calendar };
