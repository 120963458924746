import { createContext } from "react";

export type Theme = "dark" | "light" | "system";

type ThemeContext = {
    theme: Theme;
    setTheme: (theme: Theme) => void;
};

export const ThemeContext = createContext<Partial<ThemeContext>>({});
