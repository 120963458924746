import React, { createContext } from "react";
import { Route, Routes } from "react-router";
import Overview from "@/modules/store/stripcards/programs/Overview";
// import CardsDetails from "modules/store/stripcards/cards/Giftcard";
// import TransactionDetails from "modules/store/stripcards/transactions/Giftcard";

export type StripcardProgram = {
    id: number;
    name: string
};

type StripcardProgramContext = {
    selectedProgram: StripcardProgram;
    setSelectedProgram: (program: StripcardProgram) => void
};

export const StripcardProgramContext = createContext<Partial<StripcardProgramContext>>({});

export default function StripcardRoutes() {
    return (
        <Routes>
            <Route index element={<Overview/>}/>
            {/*<Route path={`/cards/:cardId`} element={<CardsDetails/>}/>*/}
            {/*<Route path={`/transactions/:transactionId`} element={<TransactionDetails/>}/>*/}
        </Routes>
    );
}
