import React, { useContext, useState } from "react";
import { useToast, useTrans } from "components";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import useGiftcardProgram from "@/routing/store/giftcards/useGiftcardProgram";
import TextInput from "components/forms/Text/TextInput";
import useOnChange from "components/hooks/forms/useOnChange";
import NumberInput from "components/forms/Text/NumberInput";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import useHttp from "core/http/useHttp";
import Form from "components/forms/Form";
import FormElement from "components/forms/FormElement";
import MediaPicker from "components/media/MediaPicker";
import useModal from "@/components/hooks/useModal";
import DeleteModal from "@/components/modals/types/DeleteModal";
import useAuth from "@/components/hooks/auth/useAuth";
import { reloadCurrentPage } from "core";

export default function Settings() {
    const { t } = useTrans();
    const { clearSelectedPrograms } = useAuth();
    const { errorToast, successToast } = useToast();
    const { selectedProgramId } = useGiftcardProgram();
    const { account } = useContext(AccountContext);
    const [values, setValues] = useState<Dict>({});
    const onChange = useOnChange(setValues);
    const http = useHttp();
    const deleteModal = useModal();
    const [updatePending, setUpdatePending] = useState(false);
    const [deletePending, setDeletePending] = useState(false);
    const [data, setData] = useState({
        name: "",
        image_url: "",
        max_amount: 1000,
        min_amount: 0,
        wallet_pass_id: "",
        wallet_passes: [],
        expiration_days: 0,
    });
    const apiUrl = `/accounts/${account.id}/giftcards/programs/${selectedProgramId}/settings`;
    const { pending, mutate } = useFetcher({
        url: apiUrl,
        dataSetter: setData
    });

    const update = () => {
        setUpdatePending(true);
        http.put(apiUrl, values)
            .then(() => {
                mutate().then(() => setValues({}));
                successToast();
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    const destroy = () => {
        setDeletePending(true);
        http.delete(`/accounts/${account.id}/giftcards/programs/${selectedProgramId}`)
            .then(() => {
                successToast();
                clearSelectedPrograms();
                reloadCurrentPage();
            })
            .catch(e => {
                errorToast(e);
                setDeletePending(false);
            });
    };

    return (
        <PageContent
            title={t("settings")}
            actions={
                <ButtonComponent
                    button_style={ButtonStyle.Danger}
                    icon={"trash"}
                    label={t('delete')}
                    pending={pending}
                    type={'button'}
                    onClick={deleteModal.open}
                />
            }
        >
            <ContentCard>
                <Form className={"flex max-w-md flex-col gap-3"} onSubmit={update}>
                    <TextInput
                        id={"name"}
                        name={"name"}
                        label={t("name")}
                        placeholder={t("name")}
                        value={values.name ?? data.name}
                        onChange={e => onChange(e.target.name, e.target.value)}
                    />

                    <NumberInput
                        id={"expiration_days"}
                        name={"expiration_days"}
                        label={t("expirationDays")}
                        placeholder={t("expirationDays")}
                        value={values.expiration_days ?? data.expiration_days}
                        onChange={e => onChange(e.target.name, e.target.value)}
                    />

                    <NumberInput
                        id={"max_amount"}
                        name={"max_amount"}
                        label={t("maxAmount")}
                        placeholder={t("maxAmount")}
                        value={values.max_amount ?? data.max_amount}
                        onChange={e => onChange(e.target.name, e.target.value)}
                    />

                    <NumberInput
                        id={"min_amount"}
                        name={"min_amount"}
                        label={t("minPurchaseAmount")}
                        placeholder={t("minPurchaseAmount")}
                        value={values.min_amount ?? data.min_amount}
                        min={0}
                        onChange={e => onChange(e.target.name, e.target.value)}
                    />

                    <FormElement
                        type={"select"}
                        key={`${data.wallet_passes.length}-select-wallet-pass`}
                        name="wallet_pass_id"
                        value={values.wallet_pass_id ?? data.wallet_pass_id}
                        label={'Wallet Pass'}
                        // required={true}
                        options={data.wallet_passes}
                        defaultOptions={data.wallet_passes}
                        onChange={(name, value) => onChange(name, value)}
                    />

                    <MediaPicker
                        name={"image_url"}
                        media={{
                            type: "image",
                            value: values.image_url ?? data.image_url,
                        }}
                        with_files={true}
                        label={t('image')}
                        with_stock_images={true}
                        onChange={(name, media) => onChange(name, media.value)}
                    />

                    <ButtonComponent
                        className={"max-w-24"}
                        type={"submit"}
                        label={t('submit')}
                        pending={updatePending}
                        button_style={ButtonStyle.Primary}
                    />
                </Form>
            </ContentCard>

            <DeleteModal
                modal={deleteModal}
                primaryButton={{
                    onClick: destroy,
                    pending: deletePending,
                }}
            />
        </PageContent>
    );
}
