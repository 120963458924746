import React from "react";
import UIComponent from "components/UIComponent";
import ContactAttributeInterface from "models/contactAttributes/ContactAttributeInterface";

export type ElementProps = {
    id?: string;
    columns?: number;
    elements: [];
    onBlur: any;
};

export default function FormElementGroup(props: ElementProps) {
    const columns = props.columns !== undefined ? props.columns : 2;

    return (
        <div className={`grid-cols-${columns} grid gap-x-6`}>
            {props.elements.map((element: ContactAttributeInterface) =>
                <UIComponent key={element.id} element={element} onBlur={props.onBlur}/>
            )}
        </div>
    );
}
