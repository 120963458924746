import React, { useEffect, useRef, useState } from 'react';
import ReactAsyncSelect from 'react-select/async';
import Option from "components/forms/multiSelect/Option";
import { useTrans } from "components";
import FormElementBorder from "components/forms/FormElementBorder";
import FormElementPadding from "components/forms/FormElementPadding";
import FormElementMinHeight from "components/forms/FormElementMinHeight";
import debounce from "lodash/debounce";

type Props = {
    isMulti: boolean;
    value?: any;
    placeholder?: any;
    name: string;
    defaultValue?: any;
    options?: any;
    isLoading: boolean;
    defaultOptions?: any;
    onBlur?: (name: string, value: any) => void;
    onChange?: (name: string, value: any) => void;
    loadOptions?: any;
    onFocus?: () => void;
    onFocusLost?: () => void;
};

export default function AsyncSelect(props: Props) {
    const { t } = useTrans();
    const ref = useRef<any>();
    const [focussed, setFocussed] = useState(false);
    const { name, isMulti, options, onBlur, onChange, loadOptions, isLoading } = props;
    const [value, setValue] = useState(props.defaultValue);

    useEffect(() => {
        if (props.value === null || props.value === undefined) {
            return;
        }

        setValue(props.value);
    }, [props.value]);

    const handleChange = (newValue: any) => {
        if (isMulti) {
            const val = newValue.map((o: any) => o.value).join(";");

            onChange && onChange(name, val);

            setValue(val);
        } else {
            onChange && onChange(name, newValue.value);

            setValue(newValue.value);
        }
    };

    const getFormattedValue = (value: any) => {
        if (isMulti) {
            const defaultValues = Array.isArray(value) ? value : value?.split(";");

            return options?.filter((o: any) => defaultValues?.includes(o.value));
        } else {
            return value ? options?.find((o: any) => o.value == value) : undefined;
        }
    };

    return (
        <FormElementPadding>
            <FormElementMinHeight>
                <FormElementBorder isFocussed={focussed}>
                    <ReactAsyncSelect
                        ref={ref}
                        name={name}
                        isMulti={isMulti}
                        cacheOptions={true}
                        isLoading={isLoading}
                        onFocus={() => setFocussed(true)}
                        placeholder={props.placeholder ?? t("typeToSearch")}
                        value={getFormattedValue(value)}
                        defaultOptions={props.defaultOptions}
                        loadOptions={debounce(loadOptions, 500)}
                        onBlur={e => {
                            onBlur && onBlur(name, value);

                            setFocussed(false);
                        }}
                        onChange={handleChange}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            menu: menu => ({ ...menu, border: "none" }),
                            input: input => ({
                                ...input, outline: "none", border: "none", boxShadow: 'none',
                                '&:focus': {
                                    border: "none",
                                    outline: 'none',
                                },
                            }),
                            container: container => ({ ...container, width: '100%' }),
                            control: base => ({
                                ...base,
                                border: 0,
                                minHeight: "3.4rem",
                                boxShadow: 'none'
                            })
                        }}
                        loadingMessage={() => t("loading") + "..."}
                        components={{
                            Option: Option
                        }}
                    />
                </FormElementBorder>
            </FormElementMinHeight>
        </FormElementPadding>
    );
}