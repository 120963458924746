import { DateRange } from "react-day-picker";
import { isNullOrUndefined } from "core";
import moment from "moment/moment";
import { format } from "date-fns";

export function valueToDateRange(value: string): DateRange {
    if (isNullOrUndefined(value)) {
        return {
            from: null,
            to: null
        };
    }

    const inputs = value.split(";");

    const firstValue = inputs[0];
    const secondValue = inputs[1];

    return datesToDateRange(firstValue, secondValue);
}

export function datesToDateRange(start: string, end: string): DateRange {

    const from = start ? moment(start) : null;
    const to = end ? moment(end) : null;

    return {
        from: from?.isValid() ? from.toDate() : null,
        to: to?.isValid() ? to.toDate() : null
    };
}

// Helper function to format the date range.
export function formatDateRange(range?: DateRange): string {
    if (!range) {
        return "";
    }

    if (!range.from && !range.to) {
        return "";
    }

    if (range.from && !range.to) {
        return `${format(range.from, "dd MMMM yyyy")} - "..."`;
    }

    if (range.to && !range.from) {
        return `"..." - ${format(range.from, "dd MMMM yyyy")}`;
    }

    return `${format(range.from, "dd MMMM yyyy")} - ${format(range.to, "dd MMMM yyyy")}`;
}