import React, { useContext, useEffect, useState } from "react";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import useModal from "components/hooks/useModal";
import Modal from "components/modals/types/Modal";
import { useTrans } from "components";
import useRouting from "components/hooks/useRouting";

export default function OpenOrderModal() {
    const { t } = useTrans();
    const { push } = useRouting();
    const { account } = useContext(AccountContext);
    const [order, setOrder] = useState(null);
    const modal = useModal();
    useFetcher({
        url: `accounts/${account.id}/subscriptions/order/check`,
        dataSetter: setOrder,
    });

    useEffect(() => {
        if (order && order.order_waiting) {
            modal.open();
        }
    }, [order]);

    return (
        <Modal
            modal={modal}
            title={t('order')}
            primaryButton={{
                label: t('goToOrder'),
                onClick: () => push(`/settings/subscription`),
                pending: false,
            }}
        >
            <div>
                <h4>{t("orderWaitingForYourConfirmation")}</h4>
                <p>{t("confirmAndGetStarted")}
                </p>
            </div>
        </Modal>
    );
}