import React from "react";

type Props = {
    children: React.ReactNode
};

export default function BasePage(props: Props) {
    return (
        <main className={"relative"}>
            {props.children}
        </main>
    );
}
