import React from "react";
import useCommandPalette from "modules/global/commandPalette/useCommandPalette";
import useNavigation from "@/components/navigation/useNavigation";
import IconOnlyButton from "@/components/navigation/icons/IconOnlyButton";

export default function SearchIcon() {
    const navigation = useNavigation();
    const { isOpen, setIsOpen } = useCommandPalette();

    return (
        <IconOnlyButton
            isActive={isOpen}
            onClick={() => {
                setIsOpen(!isOpen);

                navigation.setIsOpen(false);
                navigation.setUserDropdownOpen(false);
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.1"
                stroke="currentColor"
                className="size-6"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
            </svg>
        </IconOnlyButton>
    );
}
