import React from 'react';
import { useTrans } from "components";
import { emails } from "core/services/urls/urls";
import AuthHeader from "@/components/auth/AuthHeader";
import AuthCard from "@/components/auth/AuthCard";
import ReactCodeInput from "react-code-input";

type Props = {
    code: string;
    setCode: (code: string) => void;
};

export default function MultiFactorAuthentication(props: Props) {
    const { code, setCode } = props;
    const { t } = useTrans();

    return (
        <AuthCard>
            <AuthHeader
                title={t('multiFactorAuthentication')}
                description={t('fillInTheCodeFromYourAuthenticatorApp')}
            />

            <div className={'flex flex-col items-center gap-2 w-full'}>
                <ReactCodeInput
                    onChange={(value) => setCode(value)}
                    value={code}
                    name="code"
                    className={' justify-between w-full'}
                    inputMode='tel'
                    type='number'
                    fields={6}
                    inputStyle={{
                        MozAppearance: 'textfield',
                        width: '60px',
                        height: '55px',
                        fontSize: '19px',
                        borderRadius: '8px',
                        fontWeight: "500",
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        textAlign: "center",
                    }}
                />
                <div className={"mt-3 flex items-center justify-center gap-1"}>
                    <p className={"font-extralight text-sm text-gray-500"}>
                        {t('troubleLoggingIn')}
                    </p>
                    <a href={`mailto:${emails.support}?subject=Trouble logging in using MFA&body=I am having trouble logging in with Multi factor authentication. Can you help me?`}>
                        <p className={"text-sm text-primary-600"}>{t("contactSupport")}</p>
                    </a>
                </div>
            </div>
        </AuthCard>
    );
}