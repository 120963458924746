import { useContext } from "react";
import { GiftcardProgramContext } from "@/routing/store/giftcards/GiftcardRoutes";

export default function useGiftcardProgram() {
    const { selectedProgram, setSelectedProgram } = useContext(GiftcardProgramContext);

    return {
        selectedProgramId: selectedProgram?.id,
        selectedProgram,
        setSelectedProgram
    };
}