import * as React from "react";
import { type DialogProps } from "@radix-ui/react-dialog";
import { Command as CommandPrimitive } from "cmdk";
import { Loader2, Search } from "lucide-react";

import { cn } from "@/lib/utils";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import ShowAfterDelay from "@/components/v2/SelectionAndInput/asyncComboboxMultiSelect/ShowAfterDelay";

const Command = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
    <CommandPrimitive
        ref={ref}
        className={cn(
            "flex h-full w-full flex-col overflow-hidden rounded",
            className
        )}
        {...props}
    />
));
Command.displayName = CommandPrimitive.displayName;

const CommandDialog = ({ children, ...props }: DialogProps) => {
    return (
        <Dialog {...props}>
            <DialogContent className="overflow-hidden p-0">
                <Command
                    className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:size-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:size-5"
                >
                    {children}
                </Command>
            </DialogContent>
        </Dialog>
    );
};

const CommandInput = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Input>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    loading?: boolean,
}
>(({ className, loading, ...props }, ref) => {
    const [showLoader, setShowLoader] = React.useState(false);

    return (
        <div className="relative flex items-center border-b pl-3" cmdk-input-wrapper="">
            <Search className="size-4 shrink-0 text-icon-default"/>
            <CommandPrimitive.Input
                ref={ref}
                className={cn([
                    // Basic layout
                    'relative block w-full appearance-none rounded px-3 py-1.5',
                    // Typography
                    'antialiased font-tracking-normal font-normal font-body text-[14px] leading-5 text-text-default data-[disabled]:text-text-disabled dark:text-text-inverse-default',
                    // Border
                    'border-0',
                    // Background color
                    // 'bg-inputV2-bg-surface hover:bg-inputV2-bg-surface-hover active:bg-inputV2-bg-surface-active data-[disabled]:bg-bg-surface-disabled dark:bg-white/5',
                    // Hide default focus styles
                    'focus:outline-none focus:ring-0',
                    // Invalid state
                    'data-[invalid]:border-border-critical data-[invalid]:data-[hover]:border-border-critical data-[invalid]:dark:border-border-critical data-[invalid]:data-[hover]:dark:border-border-critical',
                    // Disabled state
                    'data-[disabled]:border-transparent dark:data-[hover]:data-[disabled]:border-transparent ',
                ])}
                {...props}
            />

            <ShowAfterDelay loading={loading}>
                <div className="absolute right-2 top-1/2 flex -translate-y-1/2 items-center">
                    <Loader2 className="size-4 animate-spin"/>
                </div>
            </ShowAfterDelay>
        </div>
    );
});

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.List
        ref={ref}
        className={cn("max-h-[300px] overflow-y-auto overflow-x-hidden", className)}
        {...props}
    />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Empty>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
    <CommandPrimitive.Empty
        ref={ref}
        className="font-tracking-normal py-6 text-center font-body text-[13px] font-medium leading-5 text-text-secondary"
        {...props}
    />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Group>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Group
        ref={ref}
        className={cn(
            "overflow-hidden p-1 pt-1 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:pt-3 [&_[cmdk-group-heading]]:pb-1 [&_[cmdk-group-heading]]:text-text-default [&_[cmdk-group-heading]]:font-tracking-normal [&_[cmdk-group-heading]]:font-display [&_[cmdk-group-heading]]:text-[13px] [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:leading-5",
            className
        )}
        {...props}
    />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Separator
        ref={ref}
        className={cn("-mx-1 h-px bg-border-default", className)}
        {...props}
    />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Item
        ref={ref}
        className={cn(
            "relative flex cursor-default gap-1.5 select-none items-center rounded-sm px-2 py-1.5 outline-none",
            // Text
            "font-tracking-normal font-body text-[13px] font-regular leading-5 text-text-default",
            // Disabled
            "data-[disabled=true]:pointer-events-none  data-[disabled=true]:text-text-disabled",
            // Selected
            "data-[selected=true]:bg-bg-surface-selected data-[selected=true]:text-accent-foreground",
            // Icon
            "[&_svg]:pointer-events-none [&_svg]:shrink-0",
            className
        )}
        {...props}
    />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({
                             className,
                             ...props
                         }: React.HTMLAttributes<HTMLSpanElement>) => {
    return (
        <span
            className={cn(
                "ml-auto text-xs tracking-widest text-muted-foreground",
                className
            )}
            {...props}
        />
    );
};
CommandShortcut.displayName = "CommandShortcut";

export {
    Command,
    CommandDialog,
    CommandInput,
    CommandList,
    CommandEmpty,
    CommandGroup,
    CommandItem,
    CommandShortcut,
    CommandSeparator,
};
