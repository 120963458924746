import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

export const SelectNative = forwardRef(function Select(
    { className, ...props }: { className?: string } & Omit<Headless.SelectProps, 'as' | 'className'>,
    ref: React.ForwardedRef<HTMLSelectElement>
) {
    return (
        <span
            data-slot="control"
            className={clsx([
                className,
                // Basic layout
                'relative block w-full',
                // Background color + shadow applied to inset pseudo-element, so shadow blends with border in light mode
                'before:absolute before:inset-px before:rounded before:bg-inputV2-bg-surface before:shadow-input before:hover:shadow-input-hover',
                // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
                'dark:before:hidden',
                // Focus ring
                'after:pointer-events-none after:absolute after:inset-0 after:rounded after:ring-inset after:ring-transparent after:focus-within:shadow-focus-ring after:focus-within:ring-border-focus',
                // Disabled state
                'before:has-[[data-disabled]]:bg-bg-surface-disabled before:has-[[data-disabled]]:shadow-none',
            ])}
        >
      <Headless.Select
          ref={ref}
          {...props}
          className={clsx([
              // Basic layout
              'relative block w-full rounded px-3 py-1.5',
              // Options (multi-select)
              '[&_optgroup]:font-semibold',
              // Typography
              'font-tracking-normal font-normal font-body text-[14px] leading-5 text-text-default antialiased placeholder:text-inputV2-text-placeholder data-[disabled]:text-text-disabled dark:text-text-inverse-default',
              // Border
              'border border-inputV2-border data-[hover]:border-inputV2-border-hover dark:border-border-inverse-default dark:data-[hover]:border-border-inverse-hover',
              // Background color
              'bg-inputV2-bg-surface hover:bg-inputV2-bg-surface-hover active:bg-inputV2-bg-surface-active data-[disabled]:bg-bg-surface-disabled dark:bg-white/5',
              // Hide default focus styles
              'focus:outline-none',
              // Invalid state
              'data-[invalid]:border-border-critical data-[invalid]:data-[hover]:border-border-critical data-[invalid]:dark:border-border-critical data-[invalid]:data-[hover]:dark:border-border-critical',
              // Disabled state
              'data-[disabled]:border-transparent dark:data-[hover]:data-[disabled]:border-transparent ',
          ])}
      />
    </span>
    );
});