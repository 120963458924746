import useSWR from "swr";
import { useEffect } from "react";
import { useToast } from "components";
import useHttp from "core/http/useHttp";

type Config = {
    url: string,
    params?: any;
    dataSetter: (data: any, meta: any) => void,
    revalidateOnFocus?: boolean;
    revalidateOnMount?: boolean;
    refreshInterval?: number;
};

export default function useFetcher(config: Config) {
    const http = useHttp();
    const { errorToast } = useToast();

    const swrKey = () => {
        if (!config.url) return null;
        return config.params
            ? [config.url, JSON.stringify(config.params)]
            : [config.url];
    };

    const fetcher = ([url, params]: [string, any]) => {
        return params
            ? http.get(url, JSON.parse(params))
            : http.get(url);
    };

    const {
        data,
        error,
        isValidating,
        mutate
    } = useSWR(swrKey(), fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: config.revalidateOnFocus,
        revalidateOnMount: config.revalidateOnMount,
        refreshInterval: config.refreshInterval,
    });

    useEffect(() => {
        if (data) {
            config.dataSetter(data.data.data, data.data.meta);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            errorToast(error);
        }
    }, [error]);

    return {
        data,
        pending: isValidating,
        error,
        isValidating,
        mutate
    };
}