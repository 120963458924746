import React, { useContext } from "react";
import NavItemWithDropdown from "@/components/navigation/desktop/NavItemWithDropdown";
import SubNavItem from "@/components/navigation/desktop/SubNavItem";
import { AccountContext } from "context";
import useData from "@/components/navigation/items/useData";

export default function Data(props: {
    active: boolean;
}) {
    const { account } = useContext(AccountContext);
    const { active } = props;
    const data = useData();

    return (
        <NavItemWithDropdown
            path={"data"}
            label={"Data"}
        >
            {active && (
                <>
                    <SubNavItem
                        name={data.reports.name}
                        path={data.reports.path}
                    />
                    <SubNavItem
                        name={data.imports.name}
                        path={data.imports.path}
                    />
                    <SubNavItem
                        name={data.exports.name}
                        path={data.exports.path}
                    />
                    <SubNavItem
                        name={data.generating.name}
                        path={data.generating.path}
                    />
                    <SubNavItem
                        name={data.files.name}
                        path={data.files.path}
                    />
                    <SubNavItem
                        name={data.backgroundTasks.name}
                        path={data.backgroundTasks.path}
                    />
                </>
            )}
        </NavItemWithDropdown>
    );
}

