import Text from "components/typography/Text";
import Badge, { BadgeProps } from "components/badge/Badge";
import React from "react";

interface Props {
    title: string,
    description: string,
    badge?: BadgeProps,
    actions: [],
}

export default function TitleBar(props: Props) {
    const { title, description, badge, actions } = props;

    return (
        <div className={'mb-4 flex w-full justify-between border-b bg-white pb-4'}>
            <div className={'mx-px flex flex-col gap-1'}>
                <div className={'flex items-center gap-2'}>
                    <Text size={'lg'}>{title}</Text>
                    {badge && (
                        <Badge {...badge} className={'p-1'}/>
                    )}
                </div>
                <Text
                    size={'sm'}
                    weight={'extralight'}
                    color={'text-gray-500'}
                >
                    {description}
                </Text>
            </div>
            <div className={'flex items-end gap-2'}>
                {actions?.map((action: any) => action)}
            </div>
        </div>
    );
}
