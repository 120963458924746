import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router";
import { capitalize, isLeat } from "core";
import LeatLogo from "assets/svg/Leat_Stream_RGB_Black.svg";
import PiggyLogo from "assets/svg/p-logo.svg";

export default function Seo() {
    const location = useLocation();

    const isNumber = (number: any) => {
        return !isNaN(parseFloat(number)) && !isNaN(number - 0);
    };

    const title = useMemo(() => {
        const results: string[] = [];
        location.pathname.split("/").forEach((item) => {
            if (!isNumber(item) && item !== "") {
                results.push(capitalize(item));
            }
        });

        if (results.length === 0) {
            return isLeat() ? "Leat: Business Dashboard" : "Piggy: Business Dashboard";
        }

        return results.join(" | ");
    }, [location.pathname]);

    const getLogo = () => {
        return isLeat() ? LeatLogo : PiggyLogo;
    };

    return (
        <Helmet>
            <title>{title}</title>
            <link rel="icon" href={getLogo()}/>
            <link rel="favicon" href={getLogo()}/>
            <link rel="icon" sizes="192x192" href={getLogo()}/>
            <link rel="icon" sizes="128x128" href={getLogo()}/>
        </Helmet>
    );
}
