import React, { useState } from "react";
import { useToast, useTrans } from "components";
import useFetcher from "@/repositories/base/useFetcher";
import useOnChange from "@/components/hooks/forms/useOnChange";
import useHttp from "@/core/http/useHttp";
import Cookies from "js-cookie";
import Form from "@/components/forms/Form";
import AuthContainer from "@/components/auth/AuthContainer";
import useRouting from "@/components/hooks/useRouting";
import { paths } from "@/routing/paths";
import AuthFooter from "@/components/auth/AuthFooter";
import AuthCard from "@/components/auth/AuthCard";
import AuthHeader from "@/components/auth/AuthHeader";
import { Field, FieldGroup, Fieldset, Label } from "@/components/v2/SelectionAndInput/fieldset";
import { TextInput } from "@/components/v2/SelectionAndInput/textInput";
import { Button } from "@/components/v2/Actions/button";
import CountryComboBox from "@/modules/auth/create/components/CountryComboBox";
import IndustryComboBox from "@/modules/auth/create/components/IndustryComboBox";

export default function CreateAccount() {
    const { t } = useTrans();
    const [data, setData] = useState(null);
    const [values, setValues] = useState({
        company: "",
        industry: "",
        country: "",
        website: "",
        phone: "",
    });
    const { push } = useRouting();
    const { errorToast } = useToast();
    const [pending, setPending] = useState(false);
    const http = useHttp();
    const onChange = useOnChange(setValues);
    const apiUrl = '/after-login/create-account';
    const { pending: loading } = useFetcher({
        url: apiUrl,
        dataSetter: (res: any) => {
            setData(res);
            setValues({ company: "", industry: null, phone: "", website: "", country: res.country_value });
        },
    });

    const isPayloadValid = Boolean(
        values.company &&
        values.industry &&
        values.country &&
        values.website &&
        values.phone
    );

    const submit = () => {
        setPending(true);
        http.post(apiUrl, {
            ...values,
            campaign_url: Cookies.get('__gtm_campaign_url'),
            referrer_url: Cookies.get('__gtm_referrer'),
        })
            .then(() => push(paths.setupBrandkit))
            .catch(e => {
                errorToast(e);
                setPending(false);
            });
    };

    if (!data) {
        return null;
    }

    return (
        <AuthContainer>
            <AuthCard>
                <AuthHeader
                    title={`${t("welcome")}, ${data.user.first_name} `}
                    description={t("helpUsSetUpAccount")}
                />
                <Form onSubmit={submit} className={"flex flex-col gap-3"}>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label>{t("company")}</Label>
                                <TextInput
                                    name={"company"}
                                    placeholder={t("company")}
                                    type={"text"}
                                    required={true}
                                    onChange={e => onChange(e.target.name, e.target.value)}
                                />
                            </Field>
                            <Field>
                                <IndustryComboBox
                                    industryOptions={data.industry_options}
                                    loading={loading}
                                    values={values}
                                    onChange={onChange}
                                />
                            </Field>
                            <Field>
                                <CountryComboBox
                                    countries={data.countries}
                                    loading={loading}
                                    values={values}
                                    onChange={onChange}
                                />
                            </Field>
                            <Field>
                                <Label>{t("website")}</Label>
                                <TextInput
                                    name={"website"}
                                    placeholder={t("website")}
                                    type={"text"}
                                    required={true}
                                    onChange={e => onChange(e.target.name, e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>{t("phone")}</Label>
                                <TextInput
                                    name={"phone"}
                                    placeholder={t("phone")}
                                    type={"text"}
                                    required={true}
                                    onChange={e => onChange(e.target.name, e.target.value)}
                                />
                            </Field>
                        </FieldGroup>
                    </Fieldset>

                    <Button
                        type={"submit"}
                        variant={"primary"}
                        isLoading={pending}
                        disabled={!isPayloadValid}
                    >
                        {t('continue')}
                    </Button>
                </Form>
            </AuthCard>
            <AuthFooter/>
        </AuthContainer>
    );
}