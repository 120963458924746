import { createContext } from "react";

export const UnauthorizedContext = createContext<Partial<{
    data: {
        title: string,
        message: string;
        submit_text: string;
        type: string;
    },
    setData: any;
}>>({});

