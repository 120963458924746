import React from "react";
import Text from "components/typography/Text";
import UIComponent from "components/UIComponent";
import MediaType from "components/MediaType";
import cn from "classnames";
import MediaComponent from "components/MediaComponent";

interface Props {
    title: string,
    subtitle?: string,
    media?: MediaType,
    media_variant?: string,
    elements?: [];
    actions?: [];
    mediaBackgroundColor?: string;
    contact_actions?: boolean;
}

export default function SidebarCard(props: Props) {
    const { title, subtitle, media_variant, media, mediaBackgroundColor, actions, elements } = props;

    const mediaCn = cn(
        media_variant === 'normal' && 'w-[80px] h-[80px] rounded-full border-4 border-white object-cover',
        media_variant === 'squared' && 'w-full h-full object-cover',
    );

    return (
        <div className={'flex w-full flex-col items-center gap-3 rounded-lg bg-generalLinear p-4 '}>
            {media && <MediaComponent media={media} className={mediaCn}/>}

            <div className={'mb-2 flex w-full flex-col gap-1'}>
                <Text color={'text-white'} weight={'normal'} size={'lg'} className={'w-full truncate text-center'}>
                    {title}
                </Text>
                <Text color={'text-white'} weight={'extralight'} className={'w-full truncate text-center'}>
                    {subtitle}
                </Text>
            </div>
            <div className={'flex w-full flex-col gap-2'}>
                {elements?.map((element: any) => (
                    <UIComponent
                        key={element.type}
                        element={element}
                        className={'max-h-[40px] grow items-center justify-center rounded-lg'}
                    />
                ))}
                {actions?.map((action: any) => action)}
            </div>
        </div>
    );
}
