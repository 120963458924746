import React from 'react';

type Props = {
    children: any;
};

export default function FormElementPadding(props: Props) {

    return (
        <div className={"py-2"}>
            {props.children}
        </div>
    );
}