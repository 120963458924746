import React from 'react';
import QuickActionItem from "modules/home/components/QuickActionItem";
import { ButtonProps } from "components/buttons/ButtonComponent";
import Card from "components/cards/composition/Card";
import CardHeaderContainer from "components/cards/composition/CardHeaderContainer";
import CardHeader from "components/cards/composition/CardHeader";
import CardContent from "components/cards/composition/CardContent";

type Props = {
    title: string;
    description: string;
    button?: ButtonProps;
    elements: any[];
};

export default function QuickActions(props: Props) {
    return (
        <Card>
            <CardHeaderContainer>
                <CardHeader title={props.title} description={props.description}/>
            </CardHeaderContainer>
            <CardContent>
                <div className={"grid grid-cols-2 gap-3"}>
                    {props.elements.map((element: any) => <QuickActionItem key={element.id} {...element} />)}
                </div>
            </CardContent>
        </Card>
    );
}