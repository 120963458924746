import React, { useContext, useEffect, useRef, useState } from "react";
import Panel from "components/panel_v2/Panel";
import { useToast, useTrans } from "components";
import usePanel from "components/hooks/usePanel";
import { AccountContext } from "context";
import useHttp from "core/http/useHttp";
import FormElement from "components/forms/FormElement";
import useFetcher from "repositories/base/useFetcher";
import Previews from "components/filters/new/Previews";
import DisplayElement from "components/DisplayElement";
import Modal from "components/modals/types/Modal";
import useModal from "components/hooks/useModal";
import { depth } from "core/depth";
import { Button } from "@/components/v2/Actions/button";
import { Plus } from "lucide-react";

type Props = {
    parentIdentifier: string;
    canBeEdited: boolean;
    onFilterUpdate?: () => void;
    emptyStateMessage?: string;
    heading?: string;
};

export default function FilterComponentBuilder(props: Props) {
    const { canBeEdited, parentIdentifier, onFilterUpdate, emptyStateMessage, heading } = props;
    const { t } = useTrans();
    const http = useHttp();
    const { account } = useContext(AccountContext);
    const apiUrl = `/accounts/${account.id}/filters/${parentIdentifier}`;
    const { errorToast } = useToast();
    const panel = usePanel();
    const panelRef = useRef(null);
    const [showPending, setShowPending] = useState(false);
    const [updatePending, setUpdatePending] = useState(false);
    const [createPending, setCreatePending] = useState(false);
    const [createNewGroupPending, setCreateNewGroupPending] = useState(false);
    const [selectedFilterId, setSelectedFilterId] = useState(null);
    const [availableTypes, setAvailableTypes] = useState([]);
    const [availableOptions, setAvailableOptions] = useState([]);
    const [deletePending, setDeletePending] = useState(false);
    const [testPending, setTestPending] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [filterSubject, setFilterSubject] = useState(null);
    const [testData, setTestData] = useState(null);
    const [testResult, setTestResult] = useState(null);
    const testModal = useModal();
    useFetcher({
        url: `${apiUrl}/available-types`,
        dataSetter: setAvailableTypes,
    });
    useFetcher({
        url: selectedFilter ? `${apiUrl}/${selectedFilter.id}/available-options` : null,
        dataSetter: setAvailableOptions,
    });
    const [previews, setPreviews] = useState([]);
    const previewsFetcher = useFetcher({
        url: `${apiUrl}/previews`,
        dataSetter: setPreviews,
    });

    const fetchFilterValues = (inputValue: string, callback: any) => {
        return http.get(`${apiUrl}/groups/${selectedGroupId}/filters/${selectedFilter.id}/filter-values`, {
            keyword: inputValue,
        })
            .then(res => callback(res.data.data))
            .catch(errorToast);
    };

    const fetchTestValues = (inputValue: string, callback: any) => {
        return http.get(`${apiUrl}/groups/${selectedGroupId}/filters/${selectedFilter.id}/test-values`, {
            keyword: inputValue,
        })
            .then(res => callback(res.data.data))
            .catch(errorToast);
    };

    useEffect(() => {
        if (testModal.isActive && selectedFilter.supports_testing) {
            http.get(`${apiUrl}/groups/${selectedGroupId}/filters/${selectedFilter.id}/test`)
                .then(res => setTestData(res.data.data))
                .catch(errorToast)
                .finally(() => setShowPending(false));
        }
    }, [testModal.isActive, selectedFilter?.supports_testing]);

    useEffect(() => {
        if (selectedFilterId) {
            setShowPending(true);
            http.get(`${apiUrl}/${selectedFilterId}`)
                .then(res => setSelectedFilter(res.data.data))
                .catch(errorToast)
                .finally(() => setShowPending(false));
        }
    }, [selectedFilterId]);

    const create = (type: string) => {
        setCreatePending(true);
        http.post(apiUrl, {
            type: type,
            group_id: selectedGroupId,
        })
            .then(res => {
                setSelectedGroupId(res.data.data.group_id);
                setSelectedFilterId(res.data.data.id);
                onFilterUpdate && onFilterUpdate();
                previewsFetcher.mutate();
            })
            .catch(errorToast)
            .finally(() => setCreatePending(false));
    };

    const update = (name: string, value: any) => {
        if (name == "option" || name == "attribute" || name == "operator" || name == "sub_option") {
            setSelectedFilter(null);
        }

        setUpdatePending(true);
        http.put(`${apiUrl}/${selectedFilter.id}`, {
            [name]: value
        })
            .then(res => {
                setSelectedFilter(res.data.data);
                onFilterUpdate && onFilterUpdate();
                previewsFetcher.mutate();
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    const destroy = () => {
        setDeletePending(true);
        http.delete(`${apiUrl}/groups/${selectedGroupId}/filters/${selectedFilter.id}`)
            .then(res => {
                previewsFetcher.mutate();
                setSelectedFilterId(null);
                setSelectedGroupId(null);
                setSelectedFilter(null);
                onFilterUpdate && onFilterUpdate();
            })
            .catch(errorToast)
            .finally(() => setDeletePending(false));
    };

    const test = () => {
        setTestPending(true);
        http.post(`${apiUrl}/groups/${selectedGroupId}/filters/${selectedFilter.id}/test`, {
            subject: filterSubject,
        })
            .then(res => {
                setTestResult(res.data.data);
            })
            .catch(errorToast)
            .finally(() => setTestPending(false));
    };

    useEffect(() => {
        if (selectedFilterId) {
            panel.open();
        } else {
            panel.close();
        }
    }, [selectedFilterId]);

    const reset = () => {
        setSelectedFilterId(null);
        setSelectedFilter(null);
        setAvailableOptions([]);
        setTestData(null);
        setTestResult(null);
        setFilterSubject(null);
    };

    return (
        <div>
            <Previews
                previews={previews}
                canBeEdited={canBeEdited}
                selectedGroupId={selectedGroupId}
                setSelectedGroupId={setSelectedGroupId}
                onDelete={() => {
                    setSelectedFilterId(null);
                }}
                onCreate={panel.open}
                onSelect={setSelectedFilterId}
            />

            {canBeEdited && (
                <div className={"mt-4 flex gap-3"}>
                    <Button
                        variant={"default"}
                        type="button"
                        onClick={() => {
                            setSelectedGroupId(null);
                            panel.open();
                        }}
                        isLoading={createNewGroupPending}
                    >
                        {t("filterGroup")}
                        <Plus className={"size-4"} />
                    </Button>
                </div>
            )}

            <Panel
                {...panel}
                close={() => {
                    panel.close();
                    reset();
                }}
                customZIndex={depth.panel + 1}
                title={t("filter")}
                type={'default'}
                hasFooter={true}
                ref={panelRef}
                label={{
                    custom: t("test"),
                }}
                actions={{
                    custom: selectedFilter && selectedFilter.supports_testing ? testModal.open : null,
                    delete: selectedFilter ? destroy : null,
                }}
                pending={showPending || deletePending}
            >
                <div className={"flex size-full flex-col"}>

                    {selectedFilter ? (
                        <DisplayElement type={"text"} value={selectedFilter.name} label={t("type")}/>
                    ) : (
                        <>
                            {availableTypes.length > 0 && (
                                <FormElement
                                    type={"select"}
                                    name={"type"}
                                    label={t("type")}
                                    onBlur={(name, value) => create(value)}
                                    container={panelRef?.current}
                                    options={availableTypes}
                                />
                            )}
                        </>
                    )}

                    {selectedFilter ? (
                        <>
                            {availableOptions.length ? (
                                <FormElement
                                    type={"select"}
                                    label={t("option")}
                                    name={"option"}
                                    defaultValue={selectedFilter.option}
                                    container={panelRef?.current}
                                    options={availableOptions}
                                    onBlur={update}
                                />
                            ) : null}

                            {selectedFilter.needs_attribute ? (
                                <FormElement
                                    type={"select"}
                                    label={selectedFilter.sub_option_label}
                                    name={"sub_option"}
                                    placeholder={t("select")}
                                    defaultValue={selectedFilter.attribute}
                                    container={panelRef?.current}
                                    searchable={selectedFilter.available_attributes.length > 5}
                                    options={selectedFilter.available_attributes}
                                    onBlur={update}
                                    sort_type={"none"}
                                />
                            ) : null}

                            {(selectedFilter.needs_operator && selectedFilter.available_operators.length > 0) ? (
                                <FormElement
                                    type={"select"}
                                    label={t("operator")}
                                    name={"operator"}
                                    placeholder={t("select")}
                                    defaultValue={selectedFilter.operator}
                                    container={panelRef?.current}
                                    options={selectedFilter.available_operators}
                                    onBlur={update}
                                    sort_type={"none"}
                                />
                            ) : null}

                            {(selectedFilter.needs_value && selectedFilter.filter_value_element_type) ? (
                                <FormElement
                                    type={selectedFilter.filter_value_element_type}
                                    name={"value"}
                                    label={t("value")}
                                    onBlur={update}
                                    placeholder={t("select")}
                                    defaultValue={selectedFilter.value}
                                    searchable={true}
                                    container={panelRef?.current}
                                    options={selectedFilter.available_filter_values}
                                    defaultOptions={selectedFilter.available_filter_values}
                                    loadOptions={fetchFilterValues}
                                    sort_type={"none"}
                                />
                            ) : null}

                        </>
                    ) : null}
                </div>
            </Panel>

            <Modal
                modal={testModal}
                title={t('test')}
                primaryButton={{
                    label: t('test'),
                    onClick: test,
                    pending: testPending,
                    disabled: testPending
                }}
            >
                <div>
                    {(testData && selectedFilter) ? (
                        <div className={"min-h-36"}>
                            {testData.element_type ? (
                                <FormElement
                                    type={testData.element_type}
                                    name={"subject"}
                                    label={t("subject")}
                                    // value={filterSubject}
                                    onBlur={(name, value) => setFilterSubject(value)}
                                    placeholder={t("select")}
                                    defaultValue={selectedFilter.value}
                                    searchable={true}
                                    options={testData.default_options}
                                    defaultOptions={testData.default_options}
                                    loadOptions={fetchTestValues}
                                    sort_type={"none"}
                                />
                            ) : null}

                            {testResult && (
                                <div>
                                    <p>{testResult.result ? "Filter passed!" : "Filter failed"}</p>
                                    <p>{testResult.reason}</p>
                                </div>
                            )}
                        </div>

                    ) : null}

                </div>
            </Modal>
        </div>
    );
}
