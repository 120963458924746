import { useTrans } from "components";
import { AccountContext } from "context";
import { useContext } from "react";
import { CommandPaletteOption, CommandPaletteOptionType } from "../useCommandPaletteOptions";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";
import useRouting from "@/components/hooks/useRouting";
import useApps from "@/components/navigation/items/useApps";

export default function useAppsOptions(): CommandPaletteOption[] {
    const { t } = useTrans();
    const accountUser = useCurrentAccountUser();
    const { push } = useRouting();
    const apps = useApps();
    const { account } = useContext(AccountContext);

    const customerApp = {
        visible: account.uses_customer_app,
        group: t("navigation"),
        label: "Customer App",
        description: "Apps",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push(apps.customerApp.path),
        tags: [
            "customer app",
        ],
    };

    const businessApp = {
        visible: accountUser.hasPermission("devices"),
        group: t("navigation"),
        label: "Business App",
        description: "Apps",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push(apps.businessApp.path),
        tags: [
            "business app",
            "devices",
        ],
    };

    const portalSessions = {
        visible: account.is_demo_account,
        group: t("navigation"),
        label: "Portal Sessions",
        description: "Apps",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push(apps.portalSessions.path),
        tags: [
            "portal sessions",
        ],
    };

    const contactPortal = {
        visible: true,
        group: t("navigation"),
        label: t("contactsPortal"),
        description: "Apps",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push(apps.contactsPortal.path),
        tags: [
            "contact portal",
        ],
    };

    const widget = {
        visible: true,
        group: t("navigation"),
        label: "Widget",
        description: "Apps",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push(apps.widget.path),
        tags: [
            "widget",
        ],
    };

    const walletPass = {
        visible: true,
        group: t("navigation"),
        label: "Wallet pass",
        description: "Apps",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push(apps.walletPasses.path),
        tags: [
            "wallet pass",
        ],
    };

    const integrations = {
        visible: true,
        group: t("navigation"),
        label: t("integrations"),
        description: "Apps",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push(apps.integrations.path),
        tags: [
            "integrations",
            "shopify",
            "mollie",
        ],
    };

    const narrowcasting = {
        visible: accountUser.hasPermission("devices"),
        group: t("navigation"),
        label: t("narrowcasting"),
        description: "Apps",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/apps/business-app/narrowcasting"),
        tags: [
            "narrowcasting",
            "ipad",
            "tablet",
        ],
    };

    const addNewDevice = {
        visible: accountUser.hasPermission("devices"),
        group: t("create"),
        label: t("createLinkToken"),
        description: t("businessApp"),
        type: CommandPaletteOptionType.CREATE,
        handle: () => push(apps.businessApp.path),
        tags: [
            "create link token",
            "token",
        ],
    };

    const createAccessToken = {
        visible: true,
        group: t("create"),
        label: t("createAnAccessToken"),
        description: "Access Token",
        type: CommandPaletteOptionType.CREATE,
        handle: () => push("/apps/integrations/personal-access-tokens"),
        tags: [
            "api",
            "access token"
        ],
    };

    return [
        customerApp,
        businessApp,
        narrowcasting,
        portalSessions,
        contactPortal,
        widget,
        walletPass,
        integrations,
        addNewDevice,
        createAccessToken,
    ];
}