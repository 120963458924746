import React from "react";

type Props = {
    onClick: any;
    isActive: boolean;
    children: any
};

export default function ToolbarButton(props: Props) {
    const { onClick, isActive } = props;

    return (
        <button
            onClick={onClick}
            className={`rounded-full px-1.5 py-1 ${isActive ? "bg-primary-300" : "hover:bg-primary-100"}`}
        >
            {props.children}
        </button>
    );
}