import React, { useContext, useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import { AccountContext } from "context";
import MediaModalContentItem from "components/media/modal/MediaModalContentItem";
import SearchBar from "components/table_v2/components/searchbar/SearchBar";
import useHttp from "core/http/useHttp";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import { useDebounce } from "use-debounce";
import ItemsWrapper from "components/media/mediaModalComponents/ItemsWrapper";
import ButtonsWrapper from "components/media/mediaModalComponents/ButtonsWrapper";

type Props = {
    selected: any;
    setSelected: any;
    onSubmit: any;
    onCancel: any;
};

export default function StockImages(props: Props) {
    const { t } = useTrans();
    const { selected, setSelected, onSubmit, onCancel } = props;
    const { account } = useContext(AccountContext);
    const apiUrl = `/accounts/${account.id}/media-modal/stock-images`;
    const { errorToast } = useToast();
    const [keyword, setKeyword] = useState(null);
    const [debouncedKeyword] = useDebounce(keyword, 300);
    const [page, setPage] = useState(1);
    const http = useHttp();
    const [pending, setPending] = useState(false);
    const [images, setImages] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const fetch = (pageNumber: number) => {
        setPending(true);
        http.get(apiUrl, {
            keyword: keyword,
            page: pageNumber,
            limit: 30,
        })
            .then((res: any) => {
                const data = res.data.data;

                setImages(prevState => [
                    ...prevState,
                    ...data.items,
                ]);
                setHasMore(data.has_more);
            })
            .catch(errorToast)
            .finally(() => setPending(false));
    };

    useEffect(() => {
        fetch(page);
    }, [page]);

    useEffect(() => {
        if (debouncedKeyword !== null) {
            setImages([]);
            fetch(1);
        }
    }, [debouncedKeyword]);

    return (
        <>
            <div className={"flex gap-2 px-6 pt-4"}>
                <SearchBar
                    onChange={(name, value) => setKeyword(value)}
                    value={keyword}
                    pending={pending}
                />
            </div>

            <ItemsWrapper>
                <ul className="flex w-full flex-wrap justify-center">
                    {images.map((item: any, index: number) =>
                        <MediaModalContentItem
                            key={item.id + index}
                            item={item}
                            onSelect={setSelected}
                            isActive={item.id == selected?.id}
                        />
                    )}
                </ul>

                <div className="mt-2 flex justify-center">
                    <ButtonComponent
                        button_style={ButtonStyle.Primary}
                        label={pending ? t('fetchingDataButton') : t("clickToLoadMore")}
                        onClick={() => setPage(prevState => prevState + 1)}
                        pending={pending}
                        disabled={!hasMore}
                    />
                </div>
            </ItemsWrapper>

            <ButtonsWrapper>
                <div className={"flex justify-end gap-2"}>
                    <ButtonComponent
                        button_style={ButtonStyle.Secondary}
                        label={t('cancel')}
                        onClick={onCancel}
                    />
                    <ButtonComponent
                        button_style={ButtonStyle.Primary}
                        label={t('choose')}
                        onClick={onSubmit}
                        disabled={!selected}
                    />
                </div>
            </ButtonsWrapper>
        </>
    );
}
