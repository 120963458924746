import React, { useContext } from "react";
import { PanelContext } from "components/panel_v2/panelV2/context/PanelContext";

export default function PanelCloseButton() {
    const { close } = useContext(PanelContext);

    return (
        <button onClick={close} className={"rounded-full p-1 text-white hover:bg-white/15"}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="currentColor"
                className="size-6"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
            </svg>
        </button>
    );
}