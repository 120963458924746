import React from "react";
import { Command, CommandGroup, CommandList } from "@/components/v2/SelectionAndInput/command";

export function SelectDropdown(props: {
    children: React.ReactNode;
}) {
    return (
        <Command>
            <CommandList>
                <CommandGroup tabIndex={-1}>
                    {props.children}
                </CommandGroup>
            </CommandList>
        </Command>
    );
}