// Catch preload chunk errors before anything loads
window.addEventListener("vite:preloadError", (event) => {
	console.warn("Vite preload error detected. Reloading...", event);
	window.location.reload();
});

import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/index.sass";
import "core-js/stable";
import "regenerator-runtime/runtime";
import App from "./components/App";
import "./../src/core/i18n/i18n";
import SpecialPage from "./modules/global/errorPages/SpecialPage";

import * as Sentry from "@sentry/react";
import ErrorHasOccurred, {
	chunkErrors,
} from "./modules/global/errorPages/ErrorHasOccurred";

const isProd = import.meta.env.PROD;

if (isProd) {
	Sentry.init({
		dsn: "https://2af5c9597d9243029625827dabb03039@sentry.io/1824691",
		environment: import.meta.env.MODE,
		release: import.meta.env.VITE_VERSION ?? undefined,
		ignoreErrors: [
			...chunkErrors,
			"Load failed",
			"Illegal invocation",
			"No error message",
			"Can't find variable: gmo",
			"NetworkError when attempting to fetch resource.",
			"Failed to fetch",
			"ResizeObserver loop completed with undelivered notifications.",
			'Permission denied to access property "nodeType"', // TinyMCE error.
			"Non-Error promise rejection captured with value: Object Not Found Matching Id:",
			"Object captured as exception with keys: message", // Some err related to not being able to load hubspot js
		],
	});

	Sentry.setContext("account", {
		accountId: localStorage.getItem("currentAccountId"),
	});
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<Sentry.ErrorBoundary fallback={ErrorHasOccurred}>
		<React.Suspense
			fallback={
				<SpecialPage title={"Loading"} titleClasses={"loading-ellipsis"} />
			}
		>
			<App />
		</React.Suspense>
	</Sentry.ErrorBoundary>,
);
