import React from "react";
import MobileNavItemWithDropdown from "@/components/navigation/mobile/MobileNavItemWithDropdown";
import MobileSubNavItem from "@/components/navigation/mobile/MobileSubNavItem";
import useData from "@/components/navigation/items/useData";

export default function Data(props: {
    active: boolean;
}) {
    const { active } = props;
    const data = useData();

    return (
        <MobileNavItemWithDropdown
            path={"data"}
            label={"Data"}
        >
            {active && (
                <>
                    <MobileSubNavItem
                        name={data.reports.name}
                        path={data.reports.path}
                    />
                    <MobileSubNavItem
                        name={data.imports.name}
                        path={data.imports.path}
                    />
                    <MobileSubNavItem
                        name={data.exports.name}
                        path={data.exports.path}
                    />
                    <MobileSubNavItem
                        name={data.generating.name}
                        path={data.generating.path}
                    />
                    <MobileSubNavItem
                        name={data.files.name}
                        path={data.files.path}
                    />
                    <MobileSubNavItem
                        name={data.backgroundTasks.name}
                        path={data.backgroundTasks.path}
                    />
                </>
            )}
        </MobileNavItemWithDropdown>
    );
}

