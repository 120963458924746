import React, { useContext, useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import useFilterValues from "modules/loyalty/transactions/useFilterValues";
import useModal from "components/hooks/useModal";
import Export from "components/Export";
import useRouting from "components/hooks/useRouting";
import useOnChange from "components/hooks/forms/useOnChange";
import useHttp from "core/http/useHttp";
import useLocalStorage from "components/hooks/localStorage/useLocalStorage";
import FormGroup from "components/forms/FormGroup";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import useQueryParams from "@/core/useQueryParams";
import usePanel from "components/hooks/usePanel";
import PanelV2 from "components/panel_v2/panelV2/PanelV2";
import PanelHeader from "components/panel_v2/panelV2/PanelHeader";
import PanelTitleAndCloseButton from "components/panel_v2/panelV2/PanelTitleAndCloseButton";
import PanelBody from "components/panel_v2/panelV2/PanelBody";
import DisplayElement from "components/DisplayElement";
// import GiftcardSearchForGiftcardProgram
//     from "modules/store/stripcards/programs/components/GiftcardSearchForGiftcardProgram";
import useStripcardProgram from "@/routing/store/stripcards/useStripcardProgram";
import usePaginatedResponse from "components/table/hooks/usePaginatedResponse";
import TableHead from "components/table/TableHead";
import TableHeading from "components/table/TableHeading";
import TableBody from "components/table/TableBody";
import TableRow from "components/table/TableRow";
import TableCell from "@/components/table/TableCell";
import Table from "components/table/Table";
import TablePagination from "components/table/TablePagination";

const initialTransaction = {
    code: "",
};

export default function Transactions() {
    const { push, queryParams } = useRouting();
    const { t } = useTrans();
    const [selectedCardId, setSelectedTransactionId] = useState(queryParams["transaction_id"]);
    const { errorToast, successToast } = useToast();
    const createModal = useModal();
    const exportModal = useModal();
    const [createPending, setCreatePending] = useState(false);
    const { selectedProgramId } = useStripcardProgram();
    const { account } = useContext(AccountContext);
    const [emails, setEmails] = useState("");
    const [values, setValues] = useState<Dict>({});
    const onChange = useOnChange(setValues);
    const [filterValues, setFilterValues, onFilterChange] = useFilterValues();
    const { set } = useLocalStorage();
    const { addParam, removeParam } = useQueryParams();
    const panel = usePanel();
    const [selectedTransactionPending, setSelectTransactionPending] = useState(false);
    const { paginatedResponse, setPaginatedResponse } = usePaginatedResponse<{
        id: number,
        amount: number,
        created_at: number,
    }>();
    const http = useHttp();
    const [transaction, setTransaction] = useState<Dict>(initialTransaction);
    const apiUrl = `/accounts/${account.id}/stripcards/programs/${selectedProgramId}/transactions`;
    const { pending, mutate } = useFetcher({
        url: apiUrl,
        params: filterValues,
        dataSetter: setPaginatedResponse
    });

    const create = () => {
        setCreatePending(true);
        http.post(apiUrl, values)
            .then((res) => {
                mutate();
                successToast(res.data.data.message);
                createModal.close();
                setValues({});
            })
            .catch(errorToast)
            .finally(() => setCreatePending(false));
    };

    useEffect(() => {
        if (selectedCardId) {
            addParam("transaction_id", selectedCardId);
        }
    }, [selectedCardId]);

    useEffect(() => {
        if (!selectedCardId) {
            return;
        }

        panel.open();

        setSelectTransactionPending(true);
        http.get(`${apiUrl}/${selectedCardId}`)
            .then(res => setTransaction(res.data.data))
            .catch(errorToast)
            .finally(() => setSelectTransactionPending(false));
    }, [selectedCardId]);

    useEffect(() => {
        if (!panel.isActive) {
            setSelectedTransactionId(null);
            setTransaction(initialTransaction);
            removeParam("transaction_id");
        }
    }, [panel.isActive]);

    return (
        <PageContent
            title={t("transactions")}
            actions={[
                <ButtonComponent
                    key={"newTransaction"}
                    button_style={ButtonStyle.Primary}
                    label={t("newTransaction")}
                    icon={"plusRound"}
                    onClick={createModal.open}
                />,
            ]}
        >
            <ContentCard>
                <Table>
                    <TableHead>
                        <TableHeading>
                            {t("amount")}
                        </TableHeading>
                        <TableHeading>
                            {t("createdAt")}
                        </TableHeading>
                    </TableHead>

                    <TableBody>
                        {paginatedResponse.data.map(card => (
                            <TableRow key={card.id}>
                                <TableCell>
                                    {card.amount}
                                </TableCell>
                                <TableCell>
                                    {card.created_at}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    {...paginatedResponse}
                    limit={filterValues.limit}
                    onPageClick={page => onFilterChange("page", page)}
                    onLimitChange={limit => onFilterChange("limit", limit)}
                />
            </ContentCard>

            <Export
                modal={exportModal}
                emails={emails}
                setEmails={setEmails}
                filterValues={{
                    ...filterValues,
                    stripcard_program_id: selectedProgramId,
                }}
                type={"stripcard_transactions"}
            />

            <PanelV2
                {...panel}
            >
                <PanelHeader className={"flex items-center justify-between"}>
                    <PanelTitleAndCloseButton title={t("transaction")}/>
                    <ButtonComponent
                        label={t("more")}
                        button_style={ButtonStyle.Secondary}
                        icon={"arrowDiagonalRight"}
                        onClick={() => push(`/store/stripcards/transactions/${transaction.id}`)}
                    />
                </PanelHeader>
                <PanelBody>
                    <div className={"flex grow flex-col gap-4"}>
                        <FormGroup className={'flex w-full'}>
                            <DisplayElement type={"text"} value={transaction.amount} label={t("amount")}/>
                            <DisplayElement type={"text"} value={transaction.shop_name} label={t("businessProfile")}/>
                            <DisplayElement type={"text"} value={transaction.type} label={t("type")}/>
                            <DisplayElement type={"text"} value={transaction.created_at} label={t("createdAt")}/>
                        </FormGroup>
                    </div>
                </PanelBody>
            </PanelV2>
        </PageContent>
    );
}


