import { createContext } from "react";

type MixPanelContextProps = {
    trackPage: (pageName: string, additionalProps?: any) => void,
    trackFlow: (domain: string, stepName: string) => void,
    trackEvent: (eventName: string, props: any) => void,
    identifyUser: (id: string) => void,
    forgetUser: () => void
};

const MixPanelContext = createContext<Partial<MixPanelContextProps>>({});

export default MixPanelContext;