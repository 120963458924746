import React from "react";
import Text, { TextProps } from "./Text";
import { Link } from "react-router";
import MediaType from "components/MediaType";
import MediaComponent from "components/MediaComponent";

interface LinkProps extends TextProps {
    to: string;
    media?: MediaType;
    is_external?: boolean;
    icon_left?: boolean;
    should_stop_propagation?: boolean;
}

export default function LinkComponent(props: LinkProps) {
    if (props.is_external) {
        return (
            <a
                href={props.to}
                className={`hover:underline ${props.className ? props.className : ''}`}
                target={"_blank"}
            >
                {TextComponent(props)}
            </a>
        );
    }


    return (
        <Link
            to={props.to}
            className={`hover:underline ${props.className ? props.className : ''}`}
            target={props.is_external ? "_blank" : null}
            onClick={(e) => props.should_stop_propagation ? e.stopPropagation() : null}
        >
            {TextComponent(props)}
        </Link>
    );
}

const TextComponent = (props: LinkProps) => {
    return (
        <Text {...props} className={"flex items-center font-display text-sm hover:underline dark:text-white"}>
            {props.icon_left && props.media && (
                <MediaComponent
                    media={props.media}
                    className={"mr-2 inline-block size-3 text-white"}
                />
            )}
            {props.children ? props.children : props.text}
            {!props.icon_left && props.media && (
                <MediaComponent
                    media={props.media}
                    className={"ml-2 inline-block size-4"}
                />
            )}
        </Text>
    );
};
