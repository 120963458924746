import * as React from 'react';
import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import { Check, Minus } from 'lucide-react';

export function CheckboxGroup({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
    return (
        <div
            data-slot="control"
            {...props}
            className={clsx(className,
                // Inline error layout
                '[&>[data-slot=error]]:pt-1 '
                )}
        />
    );
}

export function CheckboxField({
                                  className,
                                  ...props
                              }: { className?: string } & Omit<Headless.FieldProps, 'as' | 'className'>) {
    return (
        <Headless.Field
            {...props}
            className={clsx(
                className,
                // Base layout
                'grid grid-cols-[1.125rem_1fr] items-center gap-x-2 sm:grid-cols-[1rem_1fr]',
                // Control layout
                '[&>[data-slot=control]]:col-start-1 [&>[data-slot=control]]:row-start-1 [&>[data-slot=control]]:justify-self-center',
                // Label layout
                '[&>[data-slot=label]]:col-start-2 [&>[data-slot=label]]:row-start-1 [&>[data-slot=label]]:justify-self-start [&>[data-slot=label]]:py-1',
                // Description layout
                '[&>[data-slot=description]]:col-start-2 [&>[data-slot=description]]:row-start-2 [&>[data-slot=description]]:-mt-1 [&>[data-slot=description]]:pb-1',
                // Inline error layout
                '[&>[data-slot=error]]:col-start-2 [&>[data-slot=error]]:row-start-2 [&>[data-slot=error]]:pt-1'
            )}
        />
    );
}

const base = [
    // Basic layout
    'relative isolate flex !size-4 items-center justify-center rounded ',
    // Unchecked
    'bg-inputV2-bg-surface ring-1 ring-inputV2-border ring-inset',
    // Checked
    'group-data-[checked]:bg-bg-fill-brand-default group-data-[checked]:text-icon-on-bg-default group-data-[checked]:ring-0 ',
    // Hover unchecked
    'group-data-[hover]:bg-inputV2-bg-surface-hover group-data-[hover]:ring-inputV2-border-hover',
    // Hover checked
    'group-data-[checked]:group-data-[hover]:bg-bg-fill-brand-hover group-data-[checked]:group-data-[hover]:text-icon-on-bg-default ',
    // Focus ring
    'group-data-[focus]:shadow-focus-ring',
    // Disabled
    'group-data-[disabled]:bg-checkbox-bg-surface-disabled group-data-[checked]:group-data-[disabled]:text-icon-on-bg-default group-data-[disabled]:ring-0 group-data-[disabled]:pointer-events-none',
];

export function Checkbox({
                             className,
                             ...props
                         }: {
    className?: string
} & Omit<Headless.CheckboxProps, 'as' | 'className'>) {
    return (
        <Headless.Checkbox
            data-slot="control"
            {...props}
            className={clsx(className, 'group inline-flex')}
        >
      <span className={clsx(base, className)} >
        <svg
            className=" opacity-0 group-data-[checked]:group-data-[disabled]:opacity-100 group-data-[checked]:opacity-100"
            viewBox="0 0 16 16"
            fill="none"
        >
            {/* Checkmark icon */}
            <Check className="opacity-100 group-data-[indeterminate]:opacity-0 " size={16} strokeWidth={3}/>
            {/* Indeterminate icon */}
            <Minus className="opacity-0 group-data-[indeterminate]:opacity-100 " size={16} strokeWidth={3}/>
        </svg>
      </span>
        </Headless.Checkbox>
    );
}
