import React from 'react';
import cn from "classnames";
import MediaType from "components/MediaType";
import MediaComponent from "components/MediaComponent";

type Props = {
    className?: string,
    iconClassName?: string
    id?: string;
    icon: MediaType;
    onClick?: any;
};

export default function DraggableButton(props: Props) {
    const { className, iconClassName, id, icon } = props;

    const containerCn = cn(
        "flex items-center justify-center h-6 w-6",
        "transition-all ease-in-out",
        className ?? ""
    );

    const iconCn = cn(
        "select-none",
        iconClassName ?? "",
    );

    return (
        <div
            className={containerCn}
            id={id}
            onClick={props.onClick}
        >
            <MediaComponent
                media={{ value: icon.value, type: "icon" }}
                className={'mt-2'}
            />
        </div>
    );
}