import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import useOnChange from "components/hooks/forms/useOnChange";

export default function useOnDebouncedChange(initialState: any, setStateFunc: any) {
    const [values, setValues] = useState(initialState);
    const [debounced] = useDebounce(values, 300);
    const onChange = useOnChange(setValues);

    useEffect(() => {
        setStateFunc(debounced);
    }, [debounced]);

    return onChange;
}
