import React, { useContext, useEffect, useState } from 'react';
import AsyncSelect from "components/forms/multiSelect/AsyncSelect";
import useHttp from "core/http/useHttp";
import { AccountContext } from "context";
import { useToast } from "components";
import FormElementPadding from "../FormElementPadding";
import FormElementMinHeight from "../FormElementMinHeight";
import FormElementBorder from "../FormElementBorder";
import Text from "../../typography/Text";

type Props = {
    value?: any;
    name: string;
    defaultValue?: any;
    onBlur?: (name: string, value: any) => void;
    onChange?: (name: string, value: any) => void;
    loadOptions?: any;
    placeholder?: string
    isMulti?: boolean;
};

export default function BusinessProfileSearch(props: Props) {
    const http = useHttp();
    const { value } = props;
    const { account } = useContext(AccountContext);
    const apiUrl = `/accounts/${account.id}/search`;
    const { errorToast } = useToast();
    const [pending, setPending] = useState(false);
    const [options, setOptions] = useState([]);
    const [defaultOptions, setDefaultOptions] = useState([]);

    useEffect(() => {
        if (value !== null) {
            fetchFilterValues("", setDefaultOptions, value);
        }
    }, [value]);

    const fetchFilterValues = (inputValue: string, callback: any, currentValues: string) => {
        setPending(true);
        return http.get(apiUrl, {
            entity: "business_profile",
            keyword: inputValue,
            current: currentValues,
        })
            .then(res => {
                const data = res.data.data;

                setOptions(data);
                return callback(data);
            })
            .catch(e => {
                errorToast(e);

                setOptions([]);
                return callback([]);
            })
            .finally(() => setPending(false));
    };

    if (value === null) {
        return (
            <FormElementPadding>
                <FormElementMinHeight>
                    <FormElementBorder isFocussed={false}>
                        <div className={"my-auto flex items-center justify-center"}>
                            <Text>Loading..</Text>
                        </div>
                    </FormElementBorder>
                </FormElementMinHeight>
            </FormElementPadding>
        );
    }

    return (
        <AsyncSelect
            {...props}
            options={options}
            isLoading={pending}
            isMulti={props.isMulti}
            defaultOptions={defaultOptions}
            loadOptions={(i: string, cb: any) => fetchFilterValues(i, cb, value)}
        />
    );
}