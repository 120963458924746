import React from "react";
import { NavLink } from "react-router";

type Props = {
    name: string;
    path: string;
};

export default function SubNavItem(props: Props) {
    const { name, path } = props;

    return (
        <li className={"mx-2 my-0.5 rounded-lg transition-all hover:bg-white/10"}>
            <NavLink
                to={path}
                className={({ isActive }) => `inline-block btn-outline rounded-lg w-full px-3 py-2 text-sm ${isActive ? "bg-white/10" : ""}`}
            >
                {name}
            </NavLink>
        </li>
    );

}