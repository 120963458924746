import * as Headless from '@headlessui/react';
import React, { forwardRef } from 'react';
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { Spinner } from "@/components/v2/FeedbackIndicators/spinner";

const buttonVariants = cva(
    "relative inline-flex items-center justify-center cursor-pointer group whitespace-nowrap rounded-lg transition-all " +
    "focus-visible::shadow-focus-ring focus-visible::ring-border-focus " +
    "[&_svg]:pointer-events-none [&_svg]:shrink-0",
    {
        variants: {
            variant: {
                default:
                    "bg-bg-fill-default text-text-brand-default shadow-button-default " +
                    " data-[hover]:bg-bg-fill-hover data-[hover]:text-text-brand-hover data-[hover]:shadow-button-hover " +
                    " data-[active]:data-[hover]:bg-bg-fill-active  data-[active]:data-[hover]:text-text-brand-active data-[active]:data-[hover]:shadow-button-active ",
                primary:
                    "bg-bg-fill-brand-default text-text-brand-on-bg-fill-default shadow-button-primary-default " +
                    " data-[hover]:bg-bg-fill-brand-hover data-[hover]:text-text-brand-on-bg-fill-hover data-[hover]:shadow-button-primary-hover " +
                    " data-[active]:data-[hover]:bg-bg-fill-brand-active data-[active]:data-[hover]:text-text-brand-on-bg-fill-active data-[active]:data-[hover]:shadow-button-primary-active ",
                tertiary:
                    "bg-bg-fill-transparent-default text-text-brand-default " +
                    " data-[hover]:bg-bg-fill-transparent-hover data-[hover]:text-text-brand-hover data-[hover]:shadow-button-hover " +
                    " data-[active]:data-[hover]:bg-bg-fill-transparent-active data-[active]:data-[hover]:text-text-brand-active data-[active]:data-[hover]:shadow-button-active ",
                inverse:
                    "bg-bg-fill-inverse-default text-text-inverse-default" +
                    " data-[hover]:bg-bg-fill-inverse-hover data-[hover]:text-text-inverse-hover data-[hover]:shadow-button-primary-hover " +
                    " data-[active]:data-[hover]:bg-bg-fill-inverse-active data-[active]:data-[hover]:text-text-inverse-active data-[active]:data-[hover]:shadow-button-primary-active ",
            },
            tone: {
                default: "",
                critical: "",
            },
            size: {
                default: "size-9",
                sm: "size-6",
                // lg: "size-8", 🆕New variant
            },
            disabled: {
                false: null,
                true: "pointer-events-none bg-bg-fill-disabled text-text-disabled shadow-none",
            },
        },
        compoundVariants: [
            {
                variant: "default",
                tone: "critical",
                class: "data-[hover]:text-text-critical-hover data-[active]:data-[hover]:text-text-critical-active bg-bg-fill-default text-text-critical-default data-[active]:data-[hover]:bg-bg-fill-active data-[hover]:bg-bg-fill-hover",
            },
            {
                variant: "primary",
                tone: "critical",
                class: "bg-bg-fill-critical-default text-text-critical-on-bg-fill data-[hover]:bg-bg-fill-critical-hover data-[hover]:data-[active]:bg-bg-fill-critical-active",
            },
            {
                variant: "default",
                disabled: true,
                class: "pointer-events-none bg-bg-fill-disabled text-text-disabled shadow-none",
            },
            {
                variant: "primary",
                disabled: true,
                class: "pointer-events-none bg-bg-fill-disabled text-text-disabled shadow-none",
            },
            {
                variant: "tertiary",
                disabled: true,
                class: "pointer-events-none bg-bg-fill-disabled text-text-disabled shadow-none",
            },
            {
                variant: "inverse",
                disabled: true,
                class: "pointer-events-none bg-bg-fill-inverse-disabled text-text-inverse-disabled",
            },
        ],
        defaultVariants: {
            variant: "default",
            tone: "default",
            size: "default",
        },
    }
);

export interface IconButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean
    isLoading?: boolean
    /* Add ariaLabel to support users who use speech activation software as well as sighted screen reader users */
    ariaLabel?: string
}

export const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IconButtonProps>(
    ({ ariaLabel, className, variant, tone, size, disabled, isLoading, asChild = false, children, ...props }, ref) => {
        const Comp = asChild ? Slot : Headless.Button;

        const combinedDisabled = disabled || isLoading;

        return (
            <Comp
                ref={ref}
                disabled={combinedDisabled}
                aria-label={ariaLabel}
                className={cn(
                    buttonVariants({ variant, tone, size, disabled: combinedDisabled }),
                    className
                )}
                {...props}
            >
                <TouchTarget>
                    <span
                        className="inline-flex gap-2 transition-transform group-active:translate-y-[0.5px] group-disabled:translate-y-0"
                    >{children}</span>
                </TouchTarget>
                {isLoading && <span className={"py-auto px-auto absolute"}><Spinner/></span>}
            </Comp>
        );
    }
);

IconButton.displayName = "IconButton";


/**
 * Expand the hit area to at least 44×44px on touch devices
 */
export function TouchTarget({ children }: { children: React.ReactNode }) {
    return (
        <>
      <span
          className="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden"
          aria-hidden="true"
      />
            {children}
        </>
    );
}
