import React from "react";
import Text from "components/typography/Text";
import { openInNewTab, openInSameTab } from "core";
import useRouting from "components/hooks/useRouting";
import MediaComponent from "components/MediaComponent";
import cn from "classnames";
import Badge, { BadgeProps } from "components/badge/Badge";

type Props = {
    title: string;
    subtitle?: string;
    action?: any;
    media?: any;
    mediaSize?: string;
    badge?: BadgeProps;
};

export default function TableItem(props: Props) {
    const { title, subtitle, action, media, mediaSize, badge } = props;
    const { push } = useRouting();

    const onClick = () => {
        if (action) {
            if (action.type == "internal_url") {
                push(action.data.path);
                return;
            }

            if (action.type == "external_url") {
                openInNewTab(action.data.path);

                return;
            }

            if (action.type == "external_url_same_tab") {
                openInSameTab(action.data.path);

                return;
            }
        }
    };

    const mediaCn = cn(
        `flex items-center justify-center text-center object-cover`,
        mediaSize == "sm" ? 'w-8 h-8 rounded-full min-w-[2rem]' : 'w-12 h-12 rounded-lg min-w-[3rem]',
        { "bg-primary-50 text-2xl": media?.type == "emoji" }
    );

    return (
        <div
            className={'group flex max-w-md flex-row flex-nowrap gap-4 hover:cursor-pointer'}
            onClick={onClick}
        >
            {media && (
                <MediaComponent
                    media={media}
                    className={mediaCn}
                />
            )}
            <div className={'flex flex-col justify-center'}>
                <Text
                    color={'text-primary-600 dark:text-white'}
                    weight={"extralight"}
                    className={`w-full truncate ${action ? 'group-hover:cursor-pointer group-hover:underline' : ''}`}
                >
                    {title}
                </Text>
                <Text
                    color={'text-gray-500 dark:text-white'}
                    size={'sm'}
                    weight={'extralight'}
                    className={'w-full truncate'}
                    dangerously_set_inner_html={true}
                >
                    {subtitle}
                </Text>
            </div>
            {badge && (
                <Badge {...badge} />
            )}
        </div>
    );
}
