import React from "react";

type Props = {
    title: string;
    sub_title?: string;
    onClick?: any;
    badge?: any;
};

export default function SimpleCard(props: Props) {
    const { title, sub_title, badge, onClick } = props;

    return (
        <div
            className={`mt-6 rounded-2xl border border-gray-200 p-5 ${onClick ? "hover:shadow-primary-hover transition-all hover:cursor-pointer hover:transition-all" : ""}`}
            onClick={onClick}
        >
            <div className={"flex items-center gap-2"}>
                <p className={"text-lg font-medium text-gray-900"}>{title}</p>
                {badge && (
                    <p className={"m-0 rounded-full bg-primary-50 px-4 py-1 text-primary-700"}>{badge}</p>
                )}
            </div>
            <p className={"mt-2 text-gray-400"}>{sub_title}</p>
        </div>
    );
}
