import React, { useContext } from "react";
import CRM from "@/components/navigation/mobile/items/CRM";
import useNavigation from "@/components/navigation/useNavigation";
import Loyalty from "@/components/navigation/mobile/items/Loyalty";
import Marketing from "@/components/navigation/mobile/items/Marketing";
import Store from "@/components/navigation/mobile/items/Store";
import Apps from "@/components/navigation/mobile/items/Apps";
import Data from "@/components/navigation/mobile/items/Data";
import Automation from "@/components/navigation/mobile/items/Automation";
import { Feature } from "models/account/Account";
import { AccountContext } from "context";

export default function MobileMenuDropdown() {
    const { account } = useContext(AccountContext);
    const { activeParent, isOpen } = useNavigation();

    return (
        <div
            className={`fadeIn navigation absolute right-0 top-16 w-full border-t border-t-slate-100 backdrop-blur-md lg:hidden ${isOpen ? "visible h-fit overflow-auto" : "hidden"}`}
        >
            <div className="font-normal flex flex-col gap-1 p-4 text-white">
                <CRM active={activeParent === "crm"}/>

                {account.usesFeature(Feature.LOYALTY) && (
                    <Loyalty active={activeParent === "loyalty"}/>
                )}

                <Marketing active={activeParent === "marketing"}/>
                <Store active={activeParent === "store"}/>
                <Apps active={activeParent === "apps"}/>
                <Data active={activeParent === "data"}/>
                <Automation active={activeParent === "automation"}/>
            </div>
        </div>
    );
}