import { useTrans } from "@/components";
import { TagOption } from "@/components/forms/TipTap/OLD/TipTap";
import React from "react";

type Props = {
    selectedIndex: number;
    items: any;
    onSelect: any;
    render: (item: any) => React.ReactNode;
};

export default function List(props: Props) {
    const { items, selectedIndex, onSelect, render } = props;
    const { t } = useTrans();

    return (
        <div
            className="flex max-h-96 min-w-48 max-w-72 flex-col gap-0.5 overflow-y-auto rounded-lg border border-gray-200 bg-white p-1"
        >
            {items.length ? items.map((item: TagOption, index: number) => (
                    <button
                        key={index}
                        className={`text-nowrap rounded-md px-2 py-1.5 text-sm hover:bg-gray-50 ${index === selectedIndex ? 'bg-gray-50' : ''}`}
                        onClick={() => onSelect(index)}
                    >
                        {render(item)}
                    </button>
                ))
                : (
                    <div className="item">{t('noResults')}</div>
                )}
        </div>
    );

}