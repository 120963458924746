import React from "react";
import MobileNavItemWithDropdown from "@/components/navigation/mobile/MobileNavItemWithDropdown";
import MobileSubNavItem from "@/components/navigation/mobile/MobileSubNavItem";
import useStore from "@/components/navigation/items/useStore";

export default function Store(props: {
    active: boolean;
}) {
    const { active } = props;
    const store = useStore();

    return (
        <MobileNavItemWithDropdown
            path={"store"}
            label={"Store"}
        >
            {active && (
                <>
                    <MobileSubNavItem
                        name={store.prepaid.name}
                        path={store.prepaid.path}
                    />

                    <MobileSubNavItem
                        name={store.giftcards.name}
                        path={store.giftcards.path}
                    />

                    {store.stripcards.usesFeature && (
                        <MobileSubNavItem
                            name={store.stripcards.name}
                            path={store.stripcards.path}
                        />
                    )}

                    <MobileSubNavItem
                        name={store.internalStore.name}
                        path={store.internalStore.path}
                    />
                </>
            )}
        </MobileNavItemWithDropdown>
    );
}

