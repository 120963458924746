import React, { CSSProperties } from "react";
import cn from "classnames";

type Props = {
    children: any;
    className?: string;
    href?: string;
    onClick?: any;
    hidden?: boolean;
    onMouseEnter?: any;
    onMouseLeave?: any;
    target?: string;
    style?: CSSProperties;
};

export default function Anchor(props: Props) {
    const classNames = cn(
        "cursor-pointer",
        props.className ?? "",
    );
    
    return (
        <a
            href={props.href}
            hidden={props.hidden}
            className={classNames}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onClick={props.onClick}
            target={props.target}
            style={props.style}
        >
            {props.children}
        </a>
    );
}