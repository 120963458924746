import React, { useContext, useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import Text from "components/typography/Text";
import { AccountContext } from "context";
import useHttp from "core/http/useHttp";

type Props = {
    parentIdentifier: string;
    heading: string;
};

export default function ReadOnlyFiltersPreview(props: Props) {
    const { parentIdentifier, heading } = props;
    const http = useHttp();
    const { t } = useTrans();
    const { account } = useContext(AccountContext);
    const apiUrl = `/accounts/${account.id}/filters/${parentIdentifier}`;
    const { errorToast } = useToast();
    const [data, setData] = useState(null);

    useEffect(() => {
        http.get(apiUrl)
            .then(res => setData(res.data.data))
            .catch(errorToast);
    }, [parentIdentifier]);

    if (!data) {
        return null;
    }

    return (
        <div className={'mt-2 w-4/5'}>
            <div className={'flex justify-between pb-2'}>
                <Text size={"sm"} weight={'normal'}>{heading}</Text>
            </div>

            {data.groups.length === 0 && (
                <Text
                    size={'sm'}
                    weight={'thin'}
                    className={'flex pl-2'}
                >{t('noEntryCriteriaConfiguredForThisTier')}</Text>
            )}

            {data.groups.map((group: any) => (
                <div key={group.id}>
                    <div
                        className={"flex flex-wrap items-center gap-3 rounded-lg border border-gray-200 bg-gray-50 p-3"}
                    >
                        {group.previews.map((r: any) => (
                            <div key={r.id} className={"fadeIn flex items-center gap-4 p-1"}>
                                <div className={`group relative`}>
                                    <Text
                                        size={"sm"}
                                        weight={'thin'}
                                        dangerously_set_inner_html={true}
                                        render_as={"span"}
                                    >
                                        {r.description}
                                    </Text>
                                </div>
                                {r.operator_text && (
                                    <Text
                                        size={"sm"}
                                        render_as={"span"}
                                        color={"text-gray-400"}
                                    >{r.operator_text}</Text>
                                )}
                            </div>
                        ))}
                    </div>
                    <Text size={"sm"} className={"my-2"} color={"text-gray-400"}>{group.operator_text}</Text>
                </div>
            ))}
        </div>
    );
}
