import axios, { Method } from "axios";
import useAuth from "components/hooks/auth/useAuth";
import useLocalStorage from "components/hooks/localStorage/useLocalStorage";
import useRouting from "components/hooks/useRouting";
import { UnauthorizedContext } from "context/unauthorized/UnauthorizedContext";
import useFetch from "core/http/useFetch";
import { urls } from "core/services/urls/urls";
import flatten from "lodash/flatten";
import { stringify } from "query-string";
import { useContext } from "react";
import ApiError from "models/ApiError";
import { UsageContext } from "context/providers/UsagesProvider";

const statusCodes = {
    BAD_REQUEST: 400,
    UNAUTHENTICATED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    TOO_MANY_ATTEMPTS: 429,
    MAINTENANCE_MODE: 503,
};

export const piggyErrorCodes = {
    ACCOUNT_INACTIVE: 7002,
    ACCOUNT_NOT_FOUND: 1022,
    USAGE_LIMIT_REACHED: 60000,
    MODULE_NOT_ENABLED: 61000,
    USER_NOT_LINKED_TO_SOCIAL: 61010,
    USER_EXISTS_LOGIN_WITH_SOCIAL: 61011,
    USER_DOES_NOT_HAVE_ACCESS_TO_ACCOUNT: 7011,
};

export default function useHttp() {
    const { push } = useRouting();
    const { call } = useFetch();
    const localStorage = useLocalStorage();
    const { setUsageData } = useContext(UsageContext);
    const { setData } = useContext(UnauthorizedContext);
    const { logout, login } = useAuth();
    const baseUrl = `${urls.API_HOST}/api/v1/business`;

    const get = (
        url: string,
        payload: any = {},
        config: any = {}
    ) => {
        return request("GET", url, payload, config);
    };

    const post = (
        url: string,
        payload: any = {},
        config: any = {}
    ) => {
        return request("POST", url, payload, config);
    };

    const put = (
        url: string,
        payload: any = {},
        config: any = {}
    ) => {
        return request("PUT", url, payload, config);
    };

    const destroy = (
        url: string,
        payload: any = {},
        config: any = {}
    ) => {
        return request("DELETE", url, payload, config);
    };

    const request = (
        method: Method,
        url: string,
        payload: any,
        config: any
    ) => {
        const instance = axios.create({
            baseURL: baseUrl,
            headers: {
                Authorization: `Bearer ${localStorage.get("token")}`,
                "X-Account-Id": localStorage.get("currentAccountId"),
                "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            ...config,
        });

        instance.interceptors.response.use(
            (response) => {
                if (response instanceof Blob) {
                    return response;
                }

                if (response.data.data) {
                    if (response.data.meta) {
                        response.data.pagination = {
                            page: Number(response.data.meta.page),
                            limit: Number(response.data.meta.limit),
                            lastPage: Number(response.data.meta.last_page),
                            total: Number(response.data.meta.total),
                        };
                    }
                }

                return response;
            },
            (error) => {
                const apiError = new ApiError();

                if (error.response) {
                    apiError.status = error.response.status;
                    apiError.code = error.response.data.code;
                    apiError.message = error.response.data.message;
                    apiError.data = error.response.data.data;

                    if (error.response.data.errors) {
                        apiError.errors = flatten(
                            Object.values(error.response.data.errors)
                        ); // for each entry create an array, then flatten.;
                    }
                }

                console.table({
                    message: apiError.message,
                    status: apiError.status,
                    code: apiError.code,
                });

                // Set Execution Trace ID
                if (apiError.status === statusCodes.BAD_REQUEST) {
                    const executionTraceId = error.response.headers['x-execution-trace-id'];

                    if (executionTraceId) {
                        sessionStorage.setItem('lastTraceId', executionTraceId);
                    }
                }

                if (apiError.status == statusCodes.UNAUTHENTICATED) {
                    if (!sessionStorage.getItem('redirect_url')) {
                        sessionStorage.setItem('redirect_url', window.location.pathname as string);
                    }

                    return call(
                        `${urls.API_HOST}/api/v1/business/refresh-token`,
                        (res) => {
                            const token = res.data.token;
                            console.log("Stepper access token received", token);
                            login(token);
                            console.log(
                                `Performing retry call to ${method}, ${url}`
                            );
                            return request(
                                method,
                                url,
                                payload,
                                config
                            );
                        },
                        (res) => {
                            // Since this
                            logout(true);
                            console.error("Could not fetch access token'");
                            apiError.message = res.message;
                            return Promise.resolve(apiError);
                        }
                    );
                }

                if (apiError.status == statusCodes.NOT_FOUND) {
                    apiError.message += ` Endpoint: ${url} (404)`;
                }

                if (apiError.status == statusCodes.TOO_MANY_ATTEMPTS) {
                    console.error("Too many attempts'");

                    apiError.message = "Too many attempts";

                    logout(true);

                    return Promise.reject(apiError);
                }

                if (apiError.status == statusCodes.MAINTENANCE_MODE) {
                    push("/maintenance-mode");
                }

                if (apiError.status == statusCodes.FORBIDDEN) {
                    if (apiError.code == piggyErrorCodes.ACCOUNT_INACTIVE) {
                        push("/inactive-account");
                    } else if (apiError.code == piggyErrorCodes.USER_DOES_NOT_HAVE_ACCESS_TO_ACCOUNT) {
                        console.error("User does not have access to this account.");
                        logout(true);
                    } else {
                        setData(apiError.data);
                    }
                }

                if (apiError.status == statusCodes.BAD_REQUEST) {
                    // Can be removed in future versions
                    if (apiError.code == piggyErrorCodes.ACCOUNT_INACTIVE) {
                        push("/inactive-account");
                    }

                    if (apiError.code == piggyErrorCodes.ACCOUNT_NOT_FOUND) {
                        if (localStorage.get("replaced")) {
                            localStorage.destroy("replaced");
                            localStorage.destroy("token");
                        }

                        localStorage.set("replaced", "yes");

                        window.location.replace("/");
                    }

                    if (apiError.code == piggyErrorCodes.USAGE_LIMIT_REACHED) {
                        console.log(apiError.data);
                        setUsageData(apiError.data);
                    }

                    if (apiError.code == piggyErrorCodes.MODULE_NOT_ENABLED) {
                        push(apiError.data.internal_url);
                        console.log(apiError.data);
                    }
                }

                return Promise.reject(apiError);
            }
        );

        return instance.request({
            url: url.replace(/\/$/, ""),
            method,
            params: method == "GET" ? payload : {},
            paramsSerializer: (params) =>
                stringify(params, {
                    arrayFormat: "bracket",
                    skipEmptyString: true,
                }),
            data: payload,
        });
    };

    return {
        get,
        post,
        put,
        delete: destroy,
        request,
    };
}
