import React from "react";
import { PlacesType, Tooltip, VariantType } from 'react-tooltip';
import Text from "components/typography/Text";

interface Props {
    id: string;
    content: string;
    children?: React.ReactNode;
    place?: PlacesType;
    tooltipVariant?: VariantType;
    hidden?: boolean;
    className?: string;
}

/** @deprecated Please use PiggyToolTipV2**/
export default function PiggyTooltip(props: Props) {
    const { id, place, hidden, tooltipVariant } = props;
    return (
        <div
            data-tooltip-content={props.content}
            data-tooltip-id={id}
            hidden={hidden}
            className={`${props.className ?? ''} cursor-pointer`}
        >
            {props.children ? (
                props.children
            ) : (
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={"m-auto"}
                >
                    <path
                        d="M8.99999 10.333L10.5613 9.55237C11.2387 9.2137 11.6667 8.52103 11.6667 7.7637V7.66637C11.6667 6.5617 10.7713 5.66637 9.66666 5.66637H8.99999C7.89532 5.66637 6.99999 6.5617 6.99999 7.66637M8.99999 13.0066V12.9999M14.1855 3.81417C17.0488 6.67817 17.0488 11.3215 14.1855 14.1855C11.3215 17.0488 6.67816 17.0488 3.81416 14.1855C0.950823 11.3215 0.950823 6.67817 3.81416 3.81417C6.67816 0.950838 11.3215 0.950838 14.1855 3.81417Z"
                        stroke={tooltipVariant === "light" ? "#D9D9E9" : "#353454"}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}

            {props.content && (
                <Tooltip
                    id={id}
                    place={place ? place : 'right'}
                    variant={tooltipVariant === "light" ? 'light' : 'dark'}
                    arrowColor={tooltipVariant === "light" ? "#FFFFFF" : "#101028"}
                    aria-haspopup='true'
                    className={`max-w-sm !rounded-lg !px-3 !py-2 !opacity-100 !shadow-md !shadow-slate-900/10`}
                >
                    <Text
                        size={'xs'}
                        weight={'light'}
                        color={tooltipVariant === "light" ? "text-gray-700" : "text-white"}
                        dangerously_set_inner_html={true}
                    >
                        {props.content}
                    </Text>
                </Tooltip>
            )}
        </div>
    );
}
