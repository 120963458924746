import React from "react";
import { FilterItem } from "../../../filters/OverviewHeader";
import SearchBar from "components/table_v2/components/searchbar/SearchBar";
import Panel from "components/panel_v2/Panel";
import FormElement from "components/forms/FormElement";
import { useTrans } from "components";
import usePanel from "components/hooks/usePanel";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import cn from "classnames";
import { Filter } from "lucide-react";
import { Button } from "@/components/v2/Actions/button";

type Props = {
    has_search: boolean;
    pending: boolean;
    className?: string;
    filterValues: any;
    onFilterChange: any;
    actions?: any;
    filters?: Array<FilterItem | FilterItem[]>;
    searchBarLabel?: string;
};

export default function FiltersSearchBar(props: Props) {
    const {
        pending,
        filterValues,
        onFilterChange,
        has_search,
        actions,
        filters,
        searchBarLabel
    } = props;
    const { t } = useTrans();
    const panel = usePanel();

    const containerCn = cn(
        'justify-between grid grid-cols-2 pb-4',
        props.className && props.className
    );

    return (
        <div className={containerCn}>
            <div className={'flex justify-start gap-2'}>
                {has_search && (
                    <SearchBar
                        value={filterValues.keyword}
                        onChange={(name, value) => onFilterChange("keyword", value)}
                        className={'max-w-lg'}
                        pending={pending}
                        label={searchBarLabel}
                    />
                )}

                {filters?.length > 0 && (
                    <Button
                        variant={'default'}
                        tone={"default"}
                        onClick={panel.open}
                    >
                        <Filter size={15}/>
                        {t('filters')}
                    </Button>
                )}
            </div>

            <div className={'flex justify-end gap-2'}>
                {Array.isArray(actions) ? actions.map((action: any) =>
                    <ButtonComponent
                        key={action.id + action.label}
                        button_style={ButtonStyle.Secondary}
                        label={action.label}
                        icon={action.iconLeft}
                        onClick={action.onClick}
                        hidden={action.hidden}
                    />
                ) : actions}
            </div>

            {filters && (
                <Panel
                    {...panel}
                    title={t('filters')}
                    type={"default"}
                    onFilterChange={onFilterChange}
                    filterValues={filterValues}
                    label={{
                        cancel: t('close')
                    }}
                >
                    <div>
                        {filters.map((f: any) => (
                            <FormElement
                                key={f.id}
                                {...f}
                                onChange={onFilterChange}
                            />
                        ))}
                    </div>
                </Panel>
            )}
        </div>
    );
}
