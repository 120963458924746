import React from 'react';
import { animated, useSpring } from "react-spring";

type Props = {
    children: React.ReactNode;
};

export default function AuthCard(props: Props) {
    const appear = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 300
    });

    return (
        <animated.div
            style={appear}
            className={"w-screen border-primary-700 bg-white p-6 sm:max-w-[480px] sm:rounded-lg sm:border sm:border-gray-900/10 dark:bg-slate-600"}
        >
            {props.children}
        </animated.div>
    );
}
