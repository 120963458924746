import React from "react";
import DisplayFormElementContainer from "./DisplayFormElementContainer";
import MediaComponent from "components/MediaComponent";

export default function DisplayMediaElement(props: any) {
    return (
        <DisplayFormElementContainer label={props.label}>
            <div className={'flex items-center gap-2'}>
                <MediaComponent
                    media={{ value: props.value, type: "image" }}
                    className={"my-1 flex size-12 select-none rounded-lg"}
                />
            </div>
        </DisplayFormElementContainer>
    );
}
