import React from "react";

type Props = {
    children: React.ReactNode;
};

export default function TableBody(props: Props) {

    return (
        <tbody className={" text-sm"}>
        {props.children}
        </tbody>
    );
}