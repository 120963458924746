import React from "react";
import MediaComponent from "components/MediaComponent";
import Text from "components/typography/Text";
import useBackendAction from "components/hooks/useBackendAction";
import MediaType from "components/MediaType";
import Icon from "components/icons/Icon";

type Props = {
    id: string;
    title: string;
    media: MediaType;
    action: any;
};

export default function QuickActionItem(props: Props) {
    const { handleAction } = useBackendAction(props);

    return (
        <div
            id={props.id}
            className={"active:shadow-primary-active group cursor-pointer rounded-lg border border-gray-50 bg-gray-50 p-4 transition-all hover:border-primary-600 dark:bg-slate-700"}
            onClick={handleAction}
        >
            <div id={props.id} className={"flex w-full items-center justify-between"}>
                <div className={"flex items-center space-x-4"}>
                    <MediaComponent media={props.media} className={"size-8"}/>
                    <Text
                        size={"sm"}
                        weight={"normal"}
                        color={"text-gray-700 dark:text-white"}
                        className={"break-words group-hover:text-gray-900"}
                    >
                        {props.title}
                    </Text>
                </div>
                <Icon
                    type={"dashArrowForward"}
                    attr={"div"}
                    className={"invisible size-5 bg-gray-300 opacity-0 transition-all group-hover:visible group-hover:opacity-100"}
                />
            </div>
        </div>
    );
}