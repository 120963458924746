import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { stringifyUrl } from "query-string";
import useRouting from "components/hooks/useRouting";
import useOnDebouncedChange from "components/hooks/forms/useOnDebouncedChange";
import { FilterValuesContext } from "context/Context";

export default function useFilterValues(initialState: Dict = {}): [Dict, Dispatch<SetStateAction<Dict>>, (key: string, value: any) => void] {
    const { setQueries, queries } = useContext(FilterValuesContext);
    const { url, replace, queryParams } = useRouting();
    const [filterValues, setFilterValues] = useState({
        ...initialState,
        ...queryParams,
        ...queries[url],
    });
    const onChange = useOnDebouncedChange(filterValues, setFilterValues);
    const filterValuesWithoutPage = Object.assign({}, filterValues);
    delete filterValuesWithoutPage.page;

    useEffect(() => {
        // Resets pagination when filtering
        const anyFilterHasValue = Object.keys(filterValuesWithoutPage).some(k => filterValuesWithoutPage[k]);

        if (anyFilterHasValue && filterValues.page) {
            setFilterValues((prevState: any) => ({
                ...prevState,
                page: 1,
            }));
        }
    }, [JSON.stringify(filterValuesWithoutPage)]);

    useEffect(() => {
        setQueries((prevState: any) => ({
            ...prevState,
            [url]: filterValues
        }));

        replace(stringifyUrl({
            url: url,
            query: filterValues,
        }));

    }, [JSON.stringify(filterValues)]);

    return [filterValues, setFilterValues, onChange];
}
