import React, { useState } from "react";
import { useTabs, useTrans } from "components";
import useAuth from "components/hooks/auth/useAuth";
import useModal from "components/hooks/useModal";
import TabItem from "components/filters/TabItem";
import Password from "modules/user/Password";
import MultiFactorAuthentication from "modules/user/MultiFactorAuthentication";
import Sidebar from "components/sidebar/v2/Sidebar";
import SidebarHeader from "components/sidebar/v2/SidebarHeader";
import SidebarMediaHeader from "components/sidebar/v2/SidebarMediaHeader";
import SidebarTabsContainer from "components/sidebar/v2/tabs/SidebarTabsContainer";
import SidebarTabItem from "components/sidebar/v2/tabs/SidebarTabItem";
import ContentNextToSidebar from "components/sidebar/ContentNextToSidebar";
import BasePage from "components/containers/BasePage";
import Profile from "./Profile";
import DropdownButton from "components/buttons/DropdownButton";
import DeleteModal from "components/modals/types/DeleteModal";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";
import Permissions from "modules/user/Permissions";
import Settings from "@/modules/user/Settings";

export default function Overview() {
    const { t } = useTrans();
    const { logout } = useAuth();
    const dialog = useModal();
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const { user } = useCurrentAccountUser();
    const { tabs, activeTab, setActiveTab } = useTabs([
        {
            name: t("profile"),
            key: "profile",
        },
        {
            name: t("password"),
            key: "password",
        },
        {
            name: t("permissions"),
            key: "permissions",
        },
        {
            name: t("multiFactorAuthentication"),
            key: "multi-factor-authentication",
        },
        {
            name: t("settings"),
            key: "settings",
        },
    ]);

    return (
        <BasePage>
            <Sidebar>
                <SidebarHeader>
                    <SidebarMediaHeader
                        className={"mt-4"}
                        title={user.fullName()}
                        subtitle={user.email}
                        media={{
                            type: "image",
                            value: user.avatar,
                        }}
                    >
                        <div className={'flex w-full flex-col gap-2'}>
                            <DropdownButton
                                key={"dropdown"}
                                label={t("actions")}
                                position="right"
                                disabled={false}
                                className={'flex max-h-[40px] grow items-center justify-center rounded-lg'}
                                items={[
                                    [
                                        {
                                            style: "danger",
                                            label: t('logout'),
                                            icon: "logout",
                                            onClick: dialog.open
                                        }
                                    ]
                                ]}
                            />
                        </div>
                    </SidebarMediaHeader>
                </SidebarHeader>
                <SidebarTabsContainer>
                    {tabs.map((tab, index) => <SidebarTabItem
                        key={index}
                        tab={tab}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                    />)}
                </SidebarTabsContainer>
            </Sidebar>

            <ContentNextToSidebar>
                <TabItem active={activeTab.key == tabs[0].key}>
                    <Profile/>
                </TabItem>

                <TabItem active={activeTab.key == tabs[1].key}>
                    <Password/>
                </TabItem>

                <TabItem active={activeTab.key == tabs[2].key}>
                    <Permissions/>
                </TabItem>

                <TabItem active={activeTab.key == tabs[3].key}>
                    <MultiFactorAuthentication/>
                </TabItem>

                <TabItem active={activeTab.key == tabs[4].key}>
                    <Settings/>
                </TabItem>
            </ContentNextToSidebar>

            <DeleteModal
                modal={dialog}
                primaryButton={{
                    label: t('logout'),
                    onClick: () => {
                        setIsLoggingOut(true);
                        logout(true);
                    },
                    pending: isLoggingOut,
                }}
            >
                <p>{t("doYouWantToLogout")}</p>
            </DeleteModal>
        </BasePage>
    );
}
