import UIComponent from "components/UIComponent";
import React from "react";

export type FormRowType = {
    type: string;
    elements: any[];
    data: any;
    onChange: any;
    onClick: any;
    onBlur: any;
    barClassName?: string;
    pending: boolean;
};

export default function FormRow(props: FormRowType) {
    const { type, elements, data, onChange, onClick, onBlur, barClassName, pending } =
        props;

    return (
        <div className={"flex gap-2"}>
            {elements.map((el) => (
                <UIComponent
                    key={el.id}
                    element={el}
                    data={data}
                    onChange={onChange}
                    onClick={onClick}
                    onBlur={onBlur}
                    barClassName={barClassName}
                    pending={pending}
                />
            ))}
        </div>
    );
}
