import React, { useContext, useState } from "react";
import { useToast } from "components";
import useHttp from "core/http/useHttp";
import { AccountContext } from "context";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";

type Props = {
    onSuccess: () => any;
    type: "image";
};

const fileUploadAccepts = {
    "image": "image/*",
};

export default function UploadButton(props: Props) {
    const { onSuccess, type } = props;
    const { errorToast } = useToast();
    const [pending, setPending] = useState(false);
    const http = useHttp();
    const { account } = useContext(AccountContext);
    const url = `/accounts/${account.id}/files`;
    const hiddenFileInput = React.useRef(null);

    const upload = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPending(true);

        const uploadedFile = event.target.files[0];
        const data = new FormData();
        data.append("file", uploadedFile);
        data.append("title", uploadedFile?.name ?? "");
        data.append("visibility", "public");

        http.post(url, data)
            .then(() => onSuccess())
            .catch(errorToast)
            .finally(() => setPending(false));
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    return (
        <>
            <ButtonComponent
                label={"Upload"}
                button_style={ButtonStyle.Primary}
                onClick={handleClick}
                pending={pending}
                disabled={pending}
            />
            <input
                accept={fileUploadAccepts[type]}
                type="file"
                ref={hiddenFileInput}
                onChange={upload}
                style={{ display: 'none' }}
            />
        </>
    );
}