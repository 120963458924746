import React from "react";
import { TableColumnType, TableRowType } from "components/table_v2/types";
import Icon from "components/icons/Icon";
import { openInNewTab, openInSameTab } from "core";
import useRouting from "components/hooks/useRouting";
import Column from "components/table_v2/components/column/Column";

type Props = {
    row: TableRowType,
    onRowClick?: (id: any) => void,
    type?: "single" | "double"
};

export default function DraggableRow(props: Props) {
    const { row, type } = props;
    const { push } = useRouting();
    const hasOnClick = row.on_row_click || props.onRowClick;

    const onClick = () => {
        if (row.on_row_click) {
            if (row.on_row_click.type == "internal_url") {
                push(row.on_row_click.value);

                return;
            }

            if (row.on_row_click.type == "external_url") {
                openInNewTab(row.on_row_click.value);

                return;
            }

            if (row.on_row_click.type == "external_url_same_tab") {
                openInSameTab(row.on_row_click.value);

                return;
            }

            if (row.on_row_click.type == "open_panel") {
                props.onRowClick(row.on_row_click.value);

                return;
            }

            if (row.on_row_click.type == "draggable") {
                props.onRowClick(row.on_row_click.value);

                return;
            }
        }

        if (props.onRowClick) {
            props.onRowClick(row.id);
        }

        return;
    };

    return (
        <tr
            key={row.id}
            id={row.id.toString()}
            className={`relative w-full transition-colors hover:bg-primary-25 ${hasOnClick ? 'cursor-pointer active:bg-primary-50 ' : 'cursor-default'}`}
            onClick={hasOnClick ? onClick : null}
        >
            <td className="draggable table-cell w-16 border-0 border-b border-gray-25 hover:cursor-grab active:cursor-grabbing">
                <div className={'flex justify-center'}>
                    <Icon type={"grab-list"} attr={"svg"}/>
                </div>
            </td>

            {row.columns.map((column: TableColumnType, index: number) =>
                <Column
                    key={`${row.id}--${index}`}
                    column={column}
                    hasOnClick={hasOnClick}
                    rowType={type}
                />
            )}
        </tr>
    );
}