import React, { useState } from 'react';
import { useToast, useTrans } from "components";
import useRouting from "components/hooks/useRouting";
import AuthContainer from "components/auth/AuthContainer";
import useHttp from "core/http/useHttp";
import useAuth from "components/hooks/auth/useAuth";
import useOnChange from "components/hooks/forms/useOnChange";
import Form from "components/forms/Form";
import useFetcher from "repositories/base/useFetcher";
import { paths } from "@/routing/paths";
import AuthFooter from "@/components/auth/AuthFooter";
import AuthCard from "@/components/auth/AuthCard";
import AuthHeader from "@/components/auth/AuthHeader";
import ReadOnly from "@/components/v2/SelectionAndInput/readOnly";
import { Field, FieldGroup, FieldLayout, Label } from "@/components/v2/SelectionAndInput/fieldset";
import { TextInput } from '@/components/v2/SelectionAndInput/textInput';
import { Button } from "@/components/v2/Actions/button";
import PasswordInput from "@/components/v2/SelectionAndInput/PasswordInput";

export default function InvitationSignUp() {
    const { t } = useTrans();
    const http = useHttp();
    const { login } = useAuth();
    const { push, queryParams } = useRouting();
    const token = queryParams.token?.toString();
    const { errorToast } = useToast();
    const [pending, setPending] = useState(false);
    const [data, setData] = useState({
        email: "",
        invited_by_account: "",
    });
    const [values, setValues] = useState<{
        first_name: string;
        last_name: string;
        password: string;
        password_confirmation: string;
    }>({
        first_name: "",
        last_name: "",
        password: "",
        password_confirmation: "",
    });
    const onChange = useOnChange(setValues);
    const apiUrl = '/invitation/sign-up';
    useFetcher({
        url: apiUrl,
        params: queryParams,
        dataSetter: setData,
    });

    const submit = () => {
        setPending(true);
        http.post(apiUrl, {
            ...values,
            token: token,
        })
            .then((res) => {
                login(res.data.data.token);
                push(paths.pendingInvitations);
            })
            .catch(e => {
                errorToast(e);
                setPending(false);
            });
    };

    return (
        <AuthContainer>
            <AuthCard>
                <AuthHeader
                    title={t("signUp")}
                    description={t("invitedByAccount", {
                        company: data.invited_by_account,
                    })}
                />

                {token ? (
                    <Form onSubmit={submit} className={"flex flex-col gap-3"}>
                        <FieldGroup>
                            <Field>
                                <Label>{t("email")}</Label>
                                <ReadOnly>
                                    {data.email}
                                </ReadOnly>
                            </Field>

                            <FieldLayout>
                                <Field className={"w-full"}>
                                    <Label>{t("firstName")}</Label>
                                    <TextInput
                                        name={"first_name"}
                                        placeholder={t("firstName")}
                                        autoComplete={"given-name"}
                                        value={values.first_name}
                                        type={"text"}
                                        required={true}
                                        onChange={e => onChange(e.target.name, e.target.value)}
                                    />
                                </Field>
                                <Field className={"w-full"}>
                                    <Label>{t("lastName")}</Label>
                                    <TextInput
                                        name={"last_name"}
                                        placeholder={t("lastName")}
                                        autoComplete={"family-name"}
                                        value={values.last_name}
                                        type={"text"}
                                        required={true}
                                        onChange={e => onChange(e.target.name, e.target.value)}
                                    />
                                </Field>
                            </FieldLayout>
                            <Field>
                                <Label>{t("password")}</Label>
                                <PasswordInput
                                    name={"password"}
                                    placeholder={t("password")}
                                    autoComplete={"new-password"}
                                    value={values.password}
                                    required={true}
                                    onChange={e => onChange(e.target.name, e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>{t("confirmPassword")}</Label>
                                <PasswordInput
                                    name={"password_confirmation"}
                                    placeholder={t("confirmPassword")}
                                    autoComplete={"new-password"}
                                    value={values.password_confirmation}
                                    required={true}
                                    onChange={e => onChange(e.target.name, e.target.value)}
                                />
                            </Field>

                            <Button
                                variant="primary"
                                isLoading={pending}
                                type={'submit'}
                            >
                                {t('continue')}
                            </Button>
                        </FieldGroup>
                    </Form>
                ) : (
                    <p>No token found.</p>
                )}

            </AuthCard>
            <AuthFooter/>
        </AuthContainer>
    );
}