import React, { useEffect, useState } from "react";
import useFetcher from "repositories/base/useFetcher";
import SelectableListItem from "components/lists/SelectableListItem";
import { useToast, useTrans } from "components";
import Modal from "components/modals/types/Modal";
import useModal from "components/hooks/useModal";
import useHttp from "core/http/useHttp";
import Text from "components/typography/Text";
import AuthContainer from "@/components/auth/AuthContainer";
import useRouting from "@/components/hooks/useRouting";
import { afterLogin } from "@/routing/Router";
import { paths } from "@/routing/paths";
import AuthCard from "@/components/auth/AuthCard";
import AuthHeader from "@/components/auth/AuthHeader";
import { Button } from "@/components/v2/Actions/button";

export default function PendingInvitations() {
    const { t } = useTrans();
    const [invitations, setInvitations] = useState<{
        id: number,
        invited_for_account: string,
        image: string,
        active: boolean,
    }[]>([]);
    const { push } = useRouting();
    const { errorToast, successToast } = useToast();
    const [selectedInvitation, setSelectedInvitation] = useState(null);
    const [updatePending, setUpdatePending] = useState(false);
    const http = useHttp();
    const modal = useModal();
    const apiUrl = '/after-login/pending-invitations';
    const { pending } = useFetcher({
        url: apiUrl,
        dataSetter: setInvitations,
    });

    useEffect(() => {
        if (selectedInvitation) {
            modal.open();
        } else {
            modal.close();
        }
    }, [selectedInvitation]);

    const accept = () => {
        setUpdatePending(true);
        http.post(`${apiUrl}/${selectedInvitation.id}/accept`)
            .then(() => {
                successToast();
                push(paths.selectAccount);
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    const decline = () => {
        setUpdatePending(true);
        http.post(`${apiUrl}/${selectedInvitation.id}/decline`)
            .then(() => {
                successToast();
                push(paths.selectAccount);
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    return (
        <AuthContainer>
            <div>
                <AuthCard>
                    <AuthHeader
                        title={t("invitations")}
                    />
                    {!pending && invitations.length === 0 && (
                        <p className={"text-gray-500"}>You have no pending invitations</p>
                    )}

                    {invitations.map(i => (
                        <SelectableListItem
                            key={i.id}
                            title={i.invited_for_account}
                            subTitle={i.invited_for_account}
                            active={selectedInvitation?.id == i.id}
                            media={{
                                type: "image",
                                value: i.image,
                            }}
                            onClick={() => setSelectedInvitation(i)}
                            disabled={!i.active}
                        />
                    ))}

                    <Modal
                        modal={{
                            ...modal,
                            close: () => {
                                setSelectedInvitation(null);
                                modal.close();
                            },
                        }}
                        title={t("confirm")}
                        primaryButton={{
                            label: t("accept"),
                            onClick: accept,
                            pending: updatePending,
                        }}
                        secondaryButton={{
                            label: t("decline"),
                            onClick: decline,
                            pending: updatePending,
                        }}
                    >
                        <div>
                            {selectedInvitation && (
                                <Text>{t("wantToAcceptInvitation", { account: selectedInvitation.invited_for_account })}</Text>
                            )}
                        </div>
                    </Modal>
                </AuthCard>
                <Button
                    className={"mt-2"}
                    value={'default'}
                    onClick={() => push(afterLogin)}
                    isLoading={updatePending}
                >
                    {t('back')}
                </Button>
            </div>
        </AuthContainer>
    );
}
