import React from "react";
import RowWrapper from "components/table_v2/components/row/RowWrapper";
import { TableColumnType, TableRowType } from "components/table_v2/types";
import { openInNewTab, openInSameTab } from "core";
import useRouting from "components/hooks/useRouting";
import Column from "components/table_v2/components/column/Column";

type Props = {
    row: TableRowType,
    onRowClick?: (id: string) => void,
    type?: "single" | "double",
    onClickDropDownItem?: (item: any) => void,
    canOverflow?: boolean;
};

export default function Row(props: Props) {
    const { row, type } = props;
    const { push } = useRouting();
    const hasOnClick = row.on_row_click || props.onRowClick;
    const onClick = (item?: any) => {
        if (row.on_row_click) {
            if (row.on_row_click.type == "internal_url") {
                push(row.on_row_click.value);

                return;
            }

            if (row.on_row_click.type == "external_url") {
                openInNewTab(row.on_row_click.value);

                return;
            }

            if (row.on_row_click.type == "external_url_same_tab") {
                openInSameTab(row.on_row_click.value);

                return;
            }

            if (row.on_row_click.type == "open_panel") {
                props.onRowClick(row.on_row_click.value);

                return;
            }
        }

        props.onClickDropDownItem && props.onClickDropDownItem(item);
        props.onRowClick && props.onRowClick(item);
    };

    return (
        <RowWrapper
            key={row.id}
            id={row.id}
            onRowClick={hasOnClick ? onClick : null}
        >
            {row.columns.map((column: TableColumnType, index: number) =>
                <Column
                    key={`${row.id}-${column.value?.id}-${index}`}
                    column={column}
                    hasOnClick={hasOnClick}
                    rowType={type}
                    onClick={onClick}
                    canOverflow={props.canOverflow}
                />
            )}
        </RowWrapper>
    );
}
