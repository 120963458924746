import React from "react";
import cn from "classnames";


interface Props {
    className?: string;
    required?: boolean;
    focussed: boolean;
    prefix?: boolean;
    disabled?: boolean;
    active: boolean;
    children: any;
}

export default function FloatingLabel(props: Props) {
    const { className, active, children, required, prefix, disabled, focussed } = props;

    const classNames = cn(
        'floating-label',
        className ? className : '',
        active && !disabled ? 'active' : '',
        prefix && 'has-prefix',
        disabled && 'is-disabled',
        focussed ? '' : 'not-focussed'
    );

    return (
        <p className={classNames}>
            {children}
            {required && (
                <span className="required ml-0.5">*</span>
            )}
        </p>
    );
}
