import { CircleHelp } from "lucide-react";
import type React from "react";
import { type PlacesType, Tooltip, type VariantType } from "react-tooltip";

interface Props {
	id: string;
	content: string;
	children?: React.ReactNode;
	place?: PlacesType;
	tooltipVariant?: VariantType;
}

export default function PiggyTooltipV2(props: Props) {
	const { id, content, place = "bottom", tooltipVariant = "dark" } = props;

	return (
		<div>
			<a className={`anchor-${id}`}>
				{props.children ? props.children : <CircleHelp />}
			</a>
			<Tooltip
				anchorSelect={`.anchor-${id}`}
				place={place}
				variant={tooltipVariant}
				className={"!z-50 !text-xs"} // You need to use important for the classNames to work
			>
				{content}
			</Tooltip>
		</div>
	);
}
