import React, { ReactNode } from "react";
import useRouting from "@/components/hooks/useRouting";
import SubNavigation from "@/components/navigation/desktop/SubNavigation";
import useNavigation from "@/components/navigation/useNavigation";

type Props = {
    path: string;
    label: string;
    children: ReactNode;
};

export default function NavItemWithDropdown(props: Props) {
    const { path, label } = props;
    const { isPathActive } = useRouting();
    const { onMainNavClick, onMouseEnter } = useNavigation();

    return (
        <button
            onMouseEnter={() => onMouseEnter(path)}
            className={`btn-outline relative flex select-none items-center gap-1 rounded-full px-3 py-2 tracking-wide transition-all hover:bg-white/15 ${isPathActive(path) ? "bg-white/15 dark:bg-gray-400/15" : ""}`}
            onClick={() => onMainNavClick(path)}
        >
            <p className={`text-sm tracking-wide`}>
                {label}
            </p>

            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="mt-1 hidden size-3 xl:inline-block"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
            </svg>

            <SubNavigation className={"-left-0.5"}>
                {props.children}
            </SubNavigation>
        </button>
    );
}
