import React from "react";

type Props = {
    className?: string;
    children: React.ReactNode;
    ref?: React.RefObject<HTMLDivElement>;
};

const Table = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <div
            id={"table-container"}
            className={`relative overflow-auto border border-border-default text-gray-700 dark:border-gray-200 dark:text-white ${props.className ? props.className : ""}`}
            ref={ref} // Use the forwarded ref here
        >
            <table className={"w-full border-separate border-spacing-0 whitespace-nowrap "}>
                {props.children}
            </table>
        </div>
    );
});

Table.displayName = "Table";

export default Table;