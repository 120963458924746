import React, { useContext } from "react";
import { PanelContext } from "components/panel_v2/panelV2/context/PanelContext";

type Props = {
    className?: string;
    children: React.ReactNode;
};

export default function PanelHeader(props: Props) {
    const { className } = props;
    const { close } = useContext(PanelContext);

    return (
        <header className={`h-20 bg-gray-600 p-4 shadow ${className ? className : ""}`}>
            {props.children}
        </header>
    );
}