import React from "react";
import { X } from "lucide-react";

export default function DisplayValueWrapper(props: {
    value: string;
    onClick: (value: string) => void;
    children?: React.ReactNode;
}) {
    const { onClick, value, children } = props;

    return (
        <div className="flex w-full items-center justify-between">
            <div className={'flex gap-2'}>
                {children}
            </div>
            <div>
                <div
                    className="flex size-5 items-center justify-center rounded-full transition-bg hover:bg-bg-surface-selected"
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick(value);
                    }}
                >
                    <X
                        className="size-3.5"
                        color="var(--neutral-500)"
                    />
                </div>
            </div>
        </div>
    );
}