import React from "react";

type Props = {
    columns: number;
    rows: number;
    draggable?: boolean;
    type?: string;
};

export default function TableSkeleton(props: Props) {
    return (
        <>
            {[...Array(props.rows)].map((_element, index: number) =>
                <tr
                    key={`form_element_skeleton_${index}`}
                    className={'border-b border-gray-50'}
                >
                    {[...Array(props.columns)].map((_element, index: number) =>
                        <td
                            key={`form_element_skeleton_col_${index}`}
                            className={`table-cell animate-pulse`}
                        >
                            <div
                                className={`flex grow items-center ${props.type == "double" ? 'h-[72px]' : 'h-12'}`}
                            >
                                <div className={'m-2 h-8 w-28 rounded-lg bg-gray-50'}/>
                            </div>
                        </td>
                    )}
                </tr>
            )}
        </>
    );
}
