export const depth = {
    details: 0,
    detailsTop: 2,
    navigation: 20,
    fullScreen: 30,
    fullScreenEditor: 40,
    panel: 41,
    modal: 43,
    usageModal: 50,
    alertClosed: 50,
    alertOpen: 50,
    tooltip: 50
};
