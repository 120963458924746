import React from "react";
import useNavigation from "@/components/navigation/useNavigation";
import { useTrans } from "components";
import { NavLink } from "react-router";

export default function Automation(props: {
    active: boolean;
}) {
    const { t } = useTrans();
    const { setActiveParent, setIsOpen } = useNavigation();
    const { active } = props;

    return (
        <NavLink
            to={"/automation"}
            className={"text-left"}
            onClick={() => {
                setActiveParent("automation");
                setIsOpen(false);
            }}
        >
            <div
                className={`flex cursor-pointer items-center justify-between rounded-lg hover:bg-white/15 ${active ? "bg-white/15" : ""}`}
            >
                <p className={"p-2 pl-4 text-sm text-gray-25"}>{t("automation")}</p>
            </div>
        </NavLink>
    );
}

