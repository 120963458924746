import React from "react";
import { FilterItem } from "../../filters/OverviewHeader";
import SimpleBackendTable from "components/table_v2/regular/SimpleBackendTable";
import { Table } from "components/table_v2/types";
import Pagination from "components/pagination/Table/Pagination";
import TitleBar from "components/table_v2/components/TitleBar";
import ConfigSideMenu from "modules/crm/contacts/config/ConfigSideMenu";
import FiltersSearchBar from "components/table_v2/components/searchbar/FiltersSearchBar";
import { ButtonProps } from "components/buttons/ButtonComponent";
import { SortableEvent } from "sortablejs";

type Props = {
    table: Table;
    onRowClick?: (id: string) => void;
    pending?: boolean;
    actions?: any;
    titleBarActions?: [];
    className?: string;
    onFilterChange: any;
    filterValues: any;
    emptyStateAction?: ButtonProps;
    pagination?: any;
    filters?: Array<FilterItem | FilterItem[]>;
    height?: "barebones" | "searchbar" | "tabs"
    onClickDropDownItem?: (item: any) => void;
    searchBarLabel?: string
    configPanel?: UseConfigPanelPropsWithoutRef
    mutate?: any;
    canOverflow?: boolean;
};

export interface UseConfigPanelPropsWithoutRef {
    open: () => void;
    close: () => void;
    toggle: () => void;
    isActive: boolean;
    pending: boolean;
    pendingItem: any;
    data: any;
    addAll: () => void;
    removeAll: () => void;
    onAdd: (id: ID) => void;
    onRemove: (id: ID) => void;
    position: (event: SortableEvent) => void;
}

export default function BackendTable(props: Props) {
    const {
        table,
        className,
        pending,
        onRowClick,
        onFilterChange,
        filterValues,
        emptyStateAction,
        actions,
        titleBarActions,
        height,
        searchBarLabel,
        configPanel,
        canOverflow
    } = props;
    const pagination = props.pagination ? props.pagination : table.pagination ? table.pagination : null;

    return (
        <div className={className}>
            {table.title_bar && (
                <TitleBar {...table.title_bar} actions={titleBarActions}/>
            )}

            {(actions || table.action_bar) && (
                <FiltersSearchBar
                    {...table.action_bar}
                    actions={actions}
                    filterValues={filterValues}
                    onFilterChange={onFilterChange}
                    pending={pending}
                    searchBarLabel={searchBarLabel}
                />
            )}

            <div className={"relative"}>
                <SimpleBackendTable
                    table={table}
                    pending={pending}
                    onRowClick={onRowClick ? onRowClick : null}
                    filterValues={filterValues}
                    onFilterChange={onFilterChange}
                    height={height ? height : "searchbar"}
                    isPaginated={pagination !== null}
                    onClickDropDownItem={props.onClickDropDownItem}
                    canOverflow={canOverflow}
                    emptyStateAction={emptyStateAction}
                />

                {pagination && (
                    <Pagination
                        pagination={pagination}
                        filterValues={filterValues}
                        onFilterChange={onFilterChange}
                    />
                )}

                {table.has_config && configPanel && configPanel.isActive && (
                    <ConfigSideMenu
                        tableMutate={props.mutate}
                        configPanel={props.configPanel}
                    />
                )}
            </div>
        </div>
    );
}
