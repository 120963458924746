import React, { useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import useHttp from "@/core/http/useHttp";
import useFetcher from "@/repositories/base/useFetcher";
import ProgressBar from "@/components/ProgressBar";
import useRouting from "@/components/hooks/useRouting";
import { selectAccount } from "@/routing/Router";
import AuthContainer from "@/components/auth/AuthContainer";
import { motion, AnimatePresence } from "framer-motion";
import AuthCard from "@/components/auth/AuthCard";
import AuthHeader from "@/components/auth/AuthHeader";

export default function ConfiguringAccount() {
    const { t } = useTrans();
    const { errorToast } = useToast();
    const { push } = useRouting();
    const [hasCompleted, setHasCompleted] = useState(false);
    const [progress, setProgress] = useState(0);
    const [taskIndex, setTaskIndex] = useState(0);
    const http = useHttp();
    const apiUrl = "/after-login/configuring-account";
    const { mutate } = useFetcher({
        url: apiUrl,
        dataSetter: (res: any) => {
            setProgress(res.progress);
            setHasCompleted(res.has_completed);
        },
        refreshInterval: hasCompleted ? null : 1000,
    });

    const TASKS = [
        t('creatingLoyaltyProgram'),
        t('setupInitialBusinessProfile'),
        t('creatingContactsPortal'),
        t('settingUpAutomatedEmails'),
        t('creatingInitialWalletPass')
    ];

    useEffect(() => {
        http.post(apiUrl)
            .then(() => mutate())
            .catch(errorToast);
    }, []);

    useEffect(() => {
        if (hasCompleted) {
            push(selectAccount);
        }
    }, [hasCompleted]);

    useEffect(() => {
        if (!hasCompleted) {
            const interval = setInterval(() => {
                setTaskIndex((prevIndex) => (prevIndex + 1) % TASKS.length);
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [hasCompleted]);

    return (
        <AuthContainer>
            <div className="flex items-center justify-center gap-4">
                <AuthCard>
                    <AuthHeader
                        title={t("configure")}
                    />
                    <div className="flex flex-col gap-6">
                        <div className="relative h-10 overflow-hidden">
                            <AnimatePresence mode="wait">
                                <motion.div
                                    key={taskIndex}
                                    initial={{ y: "100%", opacity: 0 }}
                                    animate={{ y: "0%", opacity: 1 }}
                                    exit={{ y: "-100%", opacity: 0 }}
                                    transition={{ duration: 0.7, ease: "easeInOut" }}
                                    className=" absolute w-full text-center text-lg"
                                >
                                    {TASKS[taskIndex]}
                                </motion.div>
                            </AnimatePresence>
                        </div>

                        <ProgressBar percentage={progress}/>
                    </div>
                </AuthCard>
            </div>
        </AuthContainer>
    );
}