import React, { useState } from "react";
import { Route, Routes } from "react-router";
import ResetPassword from "@/modules/user-actions/ResetPassword";
import MaintenanceMode from "@/modules/global/errorPages/MaintenanceMode";
import ForgotPassword from "@/modules/user-actions/ForgotPassword";
import Login from "@/modules/auth/login/Login";
import InactiveAccount from "@/modules/auth/login/InactiveAccount";
import AdminLogin from "@/modules/auth/login/AdminLogin";
import DefaultSignUp from "@/modules/auth/signUp/DefaultSignUp";
import Verify from "@/modules/auth/verify/Verify";
import { UnauthorizedContext } from "@/context/unauthorized/UnauthorizedContext";
import LinkUserToSocial from "@/modules/auth/social/LinkUserToSocial";
import InvitationSignUp from "@/modules/auth/signUp/InvitationSignUp";
import AccountsRoute from "@/routing/accounts/AccountsRoutes";
import { FilterValuesContext } from "@/context/Context";
import CheckEmail from "@/modules/auth/signUp/CheckEmail";
import PrivateRoute from "@/routing/components/PrivateRoute";
import CustomHead from "components/CustomHead";
import { ThemeContext } from "@/context/ThemeContext";
import { themeKey } from "@/components/theme/useTheme";
import SelectAccount from "@/modules/auth/SelectAccount";
import PendingInvitations from "@/modules/auth/PendingInvitations";
import CreateAccount from "@/modules/auth/create/CreateAccount";
import SetupBrandKit from "@/modules/auth/create/SetupBrandKit";
import AfterLogin from "@/modules/auth/AfterLogin";
import ConfiguringAccount from "@/modules/auth/create/ConfiguringAccount";
import { paths } from "./paths";
import Intercom from "@/components/intercom/Intercom";

export const selectAccount = "/select-account";
export const afterLogin = "/after-login";
export const home = "/home";
export const signUpRoute = "/sign-up";
export const signUpAsRoute = "/sign-up-as"; // TODO @Rens Can we remove this safely or are there links to this specific page?
export const verifyRoute = "/verify";
export const linkSocialRoute = "/link-user-to-social";
export const forgotPassword = "/forgot-password";
export const checkEmail = "/check-email";

export default function Router() {
    const [data, setData] = useState(null);
    const [queries, setQueries] = useState({});
    const [theme, setTheme] = useState(localStorage[themeKey] ?? "light");

    return (
        <Intercom>
            <ThemeContext.Provider value={{
                theme,
                setTheme
            }}
            >
                <UnauthorizedContext.Provider value={{
                    data: data,
                    setData: setData
                }}
                >
                    <FilterValuesContext.Provider value={{
                        queries: queries,
                        setQueries: setQueries
                    }}
                    >
                        <CustomHead/>
                        <Routes>
                            <Route path={"/login"} element={<Login/>}/>
                            <Route path={"/"} element={<Login/>}/>
                            <Route path={"/admin-login"} element={<AdminLogin/>}/>

                            <Route path={signUpAsRoute} element={<DefaultSignUp/>}/>
                            <Route path={signUpRoute} element={<DefaultSignUp/>}/>
                            <Route path={verifyRoute} element={<Verify/>}/>
                            <Route path={linkSocialRoute} element={<LinkUserToSocial/>}/>
                            <Route path={forgotPassword} element={<ForgotPassword/>}/>
                            <Route path={checkEmail} element={<CheckEmail/>}/>

                            <Route
                                path={afterLogin}
                                element={
                                    <PrivateRoute>
                                        <AfterLogin/>
                                    </PrivateRoute>}
                            />
                            <Route
                                path={paths.createAccount}
                                element={
                                    <PrivateRoute>
                                        <CreateAccount/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path={paths.setupBrandkit}
                                element={
                                    <PrivateRoute>
                                        <SetupBrandKit/>
                                    </PrivateRoute>}
                            />
                            <Route
                                path={paths.configureAccount}
                                element={
                                    <PrivateRoute>
                                        <ConfiguringAccount/>
                                    </PrivateRoute>}
                            />
                            <Route
                                path={selectAccount}
                                element={
                                    <PrivateRoute>
                                        <SelectAccount/>
                                    </PrivateRoute>}
                            />
                            <Route
                                path={paths.pendingInvitations}
                                element={
                                    <PrivateRoute>
                                        <PendingInvitations/>
                                    </PrivateRoute>}
                            />

                            <Route path="/confirm-reset-password" element={<ResetPassword/>}/>
                            <Route path="/invitation/sign-up" element={<InvitationSignUp/>}/>
                            <Route path="/inactive-account" element={<InactiveAccount/>}/>
                            <Route path="/maintenance-mode" element={<MaintenanceMode/>}/>

                            <Route path={"*"}>
                                <Route path={"*"} element={<AccountsRoute/>}/>
                            </Route>
                        </Routes>
                    </FilterValuesContext.Provider>
                </UnauthorizedContext.Provider>
            </ThemeContext.Provider>
        </Intercom>
    );
}
