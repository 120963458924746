import React from "react";
import BackendTableDraggable from "components/table_v2/draggable/BackendTableDraggable";
import BackendTable, { UseConfigPanelPropsWithoutRef } from "components/table_v2/regular/BackendTable";
import { Table as TableType } from "components/table_v2/types";
import { SortableEvent } from "sortablejs";
import { ButtonProps } from "components/buttons/ButtonComponent";

interface Props {
    table: TableType;
    pending?: boolean;
    filterValues?: any;
    filters?: any;
    onFilterChange?: (fieldName: any, value: any) => void;
    onRowClick?: (data: any) => void;
    onDragEnd?: (event: SortableEvent) => any;
    actions?: any;
    titleBarActions?: any;
    height?: "barebones" | "searchbar" | "tabs";
    onClick?: any;
    onClickDropDownItem?: (item: any) => void;
    searchBarLabel?: string
    mutate?: any;
    configPanel?: UseConfigPanelPropsWithoutRef;
    canOverflow?: boolean;
    clearFilterValues?: any;
    emptyStateAction?: ButtonProps;
}

export default function Table(props: Props) {
    const {
        table,
        filterValues,
        onFilterChange,
        onDragEnd,
        canOverflow,
        emptyStateAction
    } = props;
    const actions = props.actions ? props.actions : table.action_bar ? table.action_bar?.actions : null;

    if (!table) {
        console.log("No table found for:", table);
        return null;
    }

    if (table.is_draggable) {
        return (
            <BackendTableDraggable
                {...props}
                actions={actions}
                filterValues={filterValues}
                onFilterChange={onFilterChange}
                emptyStateAction={emptyStateAction}
                onDragEnd={onDragEnd}
            />
        );
    }

    return (
        <BackendTable
            {...props}
            actions={actions}
            filterValues={filterValues}
            onFilterChange={onFilterChange}
            emptyStateAction={emptyStateAction}
            onClickDropDownItem={props.onClickDropDownItem}
            searchBarLabel={props.searchBarLabel}
            configPanel={props.configPanel}
            mutate={props.mutate}
            canOverflow={canOverflow}
        />
    );
}
