import React from "react";
import Sidebar, { SidebarProps } from "../sidebar/Sidebar";
import PageBody from "components/containers/PageBody";
import PageBodyWithSidebar from "components/containers/PageBodyWithSidebar";

type Props = {
    actions?: any;
    sidebar?: SidebarProps;
    children: JSX.Element | JSX.Element[];
};

export default function Page(props: Props) {
    const hasSidebar = props.sidebar != undefined;

    return (
        <main
            className={"flex w-full"}
            role="main"
        >
            {hasSidebar && <Sidebar {...props.sidebar} actions={props.actions}/>}

            {hasSidebar ? (
                <PageBodyWithSidebar>
                    {props.children}
                </PageBodyWithSidebar>
            ) : (
                <PageBody>
                    {props.children}
                </PageBody>
            )}
        </main>
    );
}
