import React, { useContext, useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import Modal from "components/modals/types/Modal";
import useModal from "components/hooks/useModal";
import useHttp from "core/http/useHttp";
import { AccountContext } from "context";
import useRouting from "../hooks/useRouting";
import { depth } from "core/depth";
import {UsageContext, UsageData, UsageDataType} from "context/providers/UsagesProvider";
import SimpleCard from "components/cards/SimpleCard";

const plans = {
    FREE: "FREE",
    STARTER: "STARTER",
    PROFESSIONAL: "PROFESSIONAL",
    ENTERPRISE: "ENTERPRISE",
};

type Plan = keyof typeof plans;

export default function UsageModal() {
    const { t } = useTrans();
    const { usageData, setUsageData } = useContext(UsageContext);
    const { account } = useContext(AccountContext);
    const { errorToast } = useToast();
    const modal = useModal();
    const http = useHttp();
    const [pending, setPending] = useState(false);
    const [nextPlan, setNextPlan] = useState<Plan>(null);
    const { push } = useRouting();

    useEffect(() => {
        if (usageData) {
            modal.open();
            setPending(true);
            http.get(`/accounts/${account.id}/usages`, {
                limit: usageData.limit,
                type: usageData.type
            })
                .then((res) => {
                    setNextPlan(res.data.data.next_plan);
                })
                .catch(errorToast)
                .finally(() => setPending(false));
        } else {
            modal.close();
        }
    }, [usageData]);

    const resetUsageData = () => {
        setUsageData(null);
        setNextPlan(null);
    };

    const goToSubscription = () => {
        resetUsageData();
        push('/settings/subscription');
    };

    const planTexts = {
        [plans.FREE]: {
            name: t("free"),
            description: t("free"),
        },
        [plans.STARTER]: {
            name: t("starter"),
            description: t("starterPlanDescription"),
        },
        [plans.PROFESSIONAL]: {
            name: t("professional"),
            description: t("professionalPlanDescription"),
        },
        [plans.ENTERPRISE]: {
            name: t("enterprise"),
            description: t("enterprisePlanDescription"),
        },
    };

    const usageLimitTexts: Record<UsageDataType, string> = {
        [UsageDataType.NUMBER_OF_USERS]: t("users"),
        [UsageDataType.NUMBER_OF_FORMS]: t("forms"),
        [UsageDataType.NUMBER_OF_UNITS]: t("units"),
        [UsageDataType.NUMBER_OF_EMAILS]: t("emails"),
        [UsageDataType.NUMBER_OF_WIDGETS]: t("widgets"),
        [UsageDataType.NUMBER_OF_REWARDS]: t("rewards"),
        [UsageDataType.NUMBER_OF_CONTACTS]: t("contacts"),
        [UsageDataType.NUMBER_OF_GIFTCARDS]: t("giftcards"),
        [UsageDataType.NUMBER_OF_SHOPS]: t("businessProfiles"),
        [UsageDataType.NUMBER_OF_AUTOMATIONS]: t("automations"),
        [UsageDataType.NUMBER_OF_LOYALTY_RULES]: t("loyaltyRules"),
        [UsageDataType.NUMBER_OF_CONTACT_LISTS]: t("contactLists"),
        [UsageDataType.NUMBER_OF_LOYALTY_TOKENS]: t("loyaltyTokens"),
        [UsageDataType.NUMBER_OF_EMAIL_RECIPIENTS]: t("emailRecipients"),
        [UsageDataType.NUMBER_OF_CONTACT_ATTRIBUTES]: t("customAttributes"),
    };

    if (!usageData) {
        return null;
    }

    return (
        <Modal
            modal={modal}
            title={t('usageLimitReached')}
            depth={depth.usageModal}
            primaryButton={{
                label: t('seePlans'),
                onClick: goToSubscription,
                pending: pending,
            }}
            secondaryButton={{
                label: t('cancel'),
                onClick: resetUsageData,
                pending: pending,
            }}
        >
            {usageData && (
                <div>
                    <p className={"text-lg font-medium text-gray-900"}>
                        {t("currentPlanIsLimited", {
                            limit: usageData.limit,
                            type: usageLimitTexts[usageData.type],
                        })}
                    </p>

                    <p className={"text-sm text-gray-400"}>
                        {t("upgradeYourPlan", {
                            plan: planTexts[nextPlan]?.name,
                            type: usageLimitTexts[usageData.type],
                        })}
                    </p>

                    <SimpleCard
                        title={planTexts[nextPlan]?.name}
                        onClick={goToSubscription}
                        sub_title={planTexts[nextPlan]?.description}
                    />
                </div>
            )}
        </Modal>
    );
}
