import React from "react";
import useRouting from "components/hooks/useRouting";
import { linkSocialRoute } from "@/routing/Router";
import useHttp, { piggyErrorCodes } from "core/http/useHttp";
import { GoogleOAuthProvider } from '@react-oauth/google';
import useLocalStorage from "components/hooks/localStorage/useLocalStorage";
import GoogleButton from "@/components/auth/GoogleButton";

const id = "736434070411-o1v5vuk95c6ubn5n3d9ktt1grr45jcp2.apps.googleusercontent.com";

type Props = {
    onSuccess: any;
    onError: any;
    authUrl: string;
};

export const gAuthToken = "g_auth_token";

export default function GoogleAuth(props: Props) {
    const http = useHttp();
    const { set } = useLocalStorage();
    const { push } = useRouting();
    const [pending, setPending] = React.useState(false);

    const handleCredentialResponse = (token: string) => {
        setPending(true);
        http.post(props.authUrl, {
            token: token
        })
            .then(props.onSuccess)
            .catch((e: any) => {

                if (e.code == piggyErrorCodes.USER_NOT_LINKED_TO_SOCIAL) {
                    set(gAuthToken, token);
                    push(linkSocialRoute);

                    return;
                }

                if (e.code == piggyErrorCodes.USER_EXISTS_LOGIN_WITH_SOCIAL) {
                    set(gAuthToken, token);
                    push(linkSocialRoute);

                    return;
                }

                props.onError(e);

                setPending(false);
            });
    };

    return (
        <GoogleOAuthProvider clientId={id}>
            <GoogleButton pending={pending} onClick={handleCredentialResponse}/>
        </GoogleOAuthProvider>
    );
}