import React from "react";
import NavItemWithDropdown from "@/components/navigation/desktop/NavItemWithDropdown";
import SubNavItem from "@/components/navigation/desktop/SubNavItem";
import useStore from "@/components/navigation/items/useStore";

export default function Store(props: {
    active: boolean;
}) {
    const { active } = props;
    const store = useStore();

    return (
        <NavItemWithDropdown
            path={"store"}
            label={"Store"}
        >
            {active && (
                <>
                    <SubNavItem
                        name={store.prepaid.name}
                        path={store.prepaid.path}
                    />

                    <SubNavItem
                        name={store.giftcards.name}
                        path={store.giftcards.path}
                    />

                    {store.stripcards.usesFeature && (
                        <SubNavItem
                            name={store.stripcards.name}
                            path={store.stripcards.path}
                        />
                    )}

                    <SubNavItem
                        name={store.internalStore.name}
                        path={store.internalStore.path}
                    />
                </>
            )}
        </NavItemWithDropdown>
    );
}

