import React, { useState } from "react";
import Dropdown, { DropdownPosition } from "components/buttons/dropdown/Dropdown";
import { Button } from "@/components/v2/Actions/button";

type Props = {
    className?: string;
    pending?: boolean;
    disabled?: boolean;
    label?: string;
    hidden?: boolean;
    icon?: React.ReactNode;
    position?: DropdownPosition;
    items: any;
};

export default function DropdownButton(props: Props) {
    const {
        label, hidden, items,
        position = "right",
        pending = false,
        disabled = false,
    } = props;

    const [visible, setVisible] = useState(false);
    const ref = React.useRef(null);

    if (hidden) {
        return null;
    }

    return (
        <div
            ref={ref}
            className={`relative flex`}
        >
            <Dropdown
                parentRef={ref}
                visible={visible}
                setVisible={setVisible}
                position={position}
                items={items}
            />

            <Button
                className={props.className}
                variant={"default"}
                type="button"
                isLoading={pending}
                disabled={disabled}
                hidden={hidden}
                onClick={() => setVisible(!visible)}
            >
                {props.icon}

                {label}
            </Button>
        </div>
    );
}
