import React from "react";
import useRouting from "@/components/hooks/useRouting";
import useNavigation from "@/components/navigation/useNavigation";
import { NavLink } from "react-router";

type Props = {
    path: string;
    label: string;
};

export default function NavItemWithoutDropdown(props: Props) {
    const { path, label } = props;
    const { isPathActive } = useRouting();
    const { onMouseEnter, onMainNavClick } = useNavigation();

    return (
        <NavLink
            to={path}
            onMouseEnter={() => onMouseEnter(path)}
            className={`btn-outline relative flex select-none items-center gap-1 rounded-full px-3 py-2 tracking-wide transition-all hover:bg-white/15 ${isPathActive(path) ? "bg-white/15 dark:bg-gray-400/15" : ""}`}
            onClick={() => onMainNavClick(path)}
        >
            <p className={`text-sm tracking-wide`}>
                {label}
            </p>
        </NavLink>
    );
}
