import React from 'react';

type Props = {
    children: any;
};

export default function FormElementMinHeight(props: Props) {

    return (
        <div className={"min-h-[3.4rem]"}>
            {props.children}
        </div>
    );
}