import React, { useEffect, useState } from "react";
import { useToast } from "components";
import useAuth from "components/hooks/auth/useAuth";
import useRouting from "components/hooks/useRouting";
import Account from "models/account/Account";
import useHttp from "core/http/useHttp";
import AuthContainer from "components/auth/AuthContainer";
import Text from "components/typography/Text";

export default function AdminLogin() {
    const { login, switchToAccount } = useAuth();
    const { queryParams, replace } = useRouting();
    const { errorToast } = useToast();
    const [account, setAccount] = useState(new Account());

    const http = useHttp();

    useEffect(() => {
        if (queryParams.account_id && queryParams.token) {
            login(queryParams.token as string);
            http.get(`/accounts/${queryParams.account_id}`)
                .then((res) => setAccount(res.data.data))
                .catch(errorToast);
        }
    }, []);

    useEffect(() => {
        if (account.id) {
            setTimeout(() => {
                switchToAccount(account);
                replace("/home");
            }, 2000);
        }
    }, [account]);

    return (
        <AuthContainer>
            <Text>Misschien wil iemand van team tech wel een kopje koffie...</Text>
        </AuthContainer>
    );
}
