import { Card } from "@/components/v2/Layout/card/Card";
import { PageHeader, PageTitle, } from "@/components/v2/Layout/page/components/pageHeader";
import { Page } from "@/components/v2/Layout/page/page";
import { Field, FieldGroup, Label, } from "@/components/v2/SelectionAndInput/fieldset";
import ReadOnly from "@/components/v2/SelectionAndInput/readOnly";
import { useTrans } from "components";
import { capitalize } from "core";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";

export default function Permissions() {
    const { t } = useTrans();
    const accountUser = useCurrentAccountUser();
    const enabledPermissions = Object.entries(accountUser.permissions)
        .filter(([, isEnabled]) => isEnabled)
        .map(([permission]) => capitalize(permission));

    const disabledPermissions = Object.entries(accountUser.permissions)
        .filter(([, isEnabled]) => !isEnabled)
        .map(([permission]) => capitalize(permission));

    return (
        <Page>
            <PageHeader>
                <PageTitle title={t("permissions")}/>
            </PageHeader>

            <Card>
                <FieldGroup className={"mb-5 w-full"}>
                    <div className={"flex w-[44rem] gap-5"}>
                        <Field className={"w-80"}>
                            <Label>{t("currentRole")}</Label>
                            <ReadOnly>{accountUser.role?.name}</ReadOnly>
                        </Field>
                    </div>

                    <div className={"flex w-[44rem] gap-5"}>
                        <Field className={"mr-4 w-80"}>
                            <Label>{t("enabledForYou")}</Label>
                            <div className={"mt-1 flex flex-col gap-1"}>
                                {enabledPermissions.map((p) => (
                                    <ReadOnly key={p}>{p}</ReadOnly>
                                ))}
                            </div>
                        </Field>

                        <Field className={"h-full w-80"}>
                            <Label>{t("disabledForYou")}</Label>
                            <div className={"mt-1 flex flex-col gap-1"}>
                                {disabledPermissions.map((p) => (
                                    <ReadOnly key={p}>{p}</ReadOnly>
                                ))}
                            </div>
                        </Field>
                    </div>
                </FieldGroup>
            </Card>
        </Page>
    );
}
