import React from 'react';
import ButtonComponent from "components/buttons/ButtonComponent";
import CardHeaderContainer from "components/cards/composition/CardHeaderContainer";
import CardHeader from "components/cards/composition/CardHeader";
import Card from "components/cards/composition/Card";
import CardContent from "components/cards/composition/CardContent";

type Props = {
    title: string;
    description: string;
    button?: any;
    elements: any[];
};

export default function PlanMeeting(props: Props) {
    return (
        <Card>
            <CardHeaderContainer>
                <CardHeader title={props.title} description={props.description}/>
            </CardHeaderContainer>
            <CardContent>
                <div>
                    {props.button && (
                        <ButtonComponent {...props.button} className={""}/>
                    )}
                </div>
            </CardContent>
        </Card>
    );
}