import React, { useState } from "react";
import UIComponent from "../UIComponent";
import Icon, { IconType } from "../icons/Icon";
import Text from "../typography/Text";
import PiggyTooltip from "components/ui/PiggyTooltip";
import cn from "classnames";

type Props = {
    title: string;
    icon: IconType;
    sections: any;
    header_elements: any;
    onBlur: any;
    id?: string;
    tooltip?: any;
    values?: any;
    is_open?: boolean;
    onMouseOver?: any;
    onMouseOut?: any;
    onActiveChange?: any;
};

export default function DropdownCard(props: Props) {
    const {
        title,
        icon,
        sections,
        tooltip,
        onBlur,
        onMouseOut,
        onMouseOver,
        header_elements,
        is_open,
        id
    } = props;
    const [isCardOpen, setIsCardOpen] = useState(is_open);

    const cardHeader = cn(
        'border border-gray-200 bg-gray-50 p-4 rounded-t-lg flex justify-between cursor-pointer',
        isCardOpen ? 'rounded-t-lg' : 'rounded-lg'
    );

    const hoverContainerCn = cn(
        "rounded-xl bg-white p-0.5 border-2 transition-all duration-100 border-white",
        isCardOpen ? '' : 'hover:border-primary-400'
    );

    return (
        <div className={hoverContainerCn} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            <div className={'flex flex-col'}>
                <div
                    className={cardHeader}
                    onClick={() => setIsCardOpen(!isCardOpen)}
                >
                    <div className={'flex items-center gap-3'}>
                        {icon && (
                            <Icon
                                attr={'svg'}
                                type={icon}
                                className={'size-6 text-primary-600'}
                            />
                        )}

                        <Text size={'sm'}>{title}</Text>

                        {tooltip && (
                            <PiggyTooltip id={tooltip} content={tooltip}>
                                <Icon type={'tooltipExclamation'} attr={'svg'} className={'size-5 rounded-full'}/>
                            </PiggyTooltip>
                        )}
                    </div>
                    <div className={"flex items-center gap-3"}>
                        {header_elements.map((e: any) => <UIComponent element={e} key={e.id} onBlur={onBlur}/>)}
                        <Icon
                            attr={'svg'}
                            type={"chevronDown"}
                            className={`size-4 transition-transform ${isCardOpen ? 'rotate-180' : ''}`}
                        />
                    </div>
                </div>
                <div
                    className={`rounded-b-lg border border-t-0 border-gray-200 transition-all duration-300 ${isCardOpen ? 'max-h-[1400px] p-3 pb-2 ' : 'mx-3 max-h-0 overflow-hidden border-none'}`}
                >
                    {sections.map((e: any) => <UIComponent element={e} key={e.id} onBlur={onBlur}/>)}
                </div>
            </div>
        </div>
    );
}

