import React, { useEffect, useState } from 'react';
import { useToast, useTrans } from "components";
import useRouting from "components/hooks/useRouting";
import AuthContainer from "components/auth/AuthContainer";
import useHttp from "core/http/useHttp";
import { paths } from "@/routing/paths";
import ReactCodeInput from "react-code-input";
import { afterLogin } from "@/routing/Router";
import useAuth from "@/components/hooks/auth/useAuth";
import AuthFooter from "@/components/auth/AuthFooter";
import AuthHeader from "@/components/auth/AuthHeader";
import AuthCard from "@/components/auth/AuthCard";
import { Button } from "@/components/v2/Actions/button";

export default function CheckEmail() {
    const { t } = useTrans();
    const { successToast, errorToast } = useToast();
    const http = useHttp();
    const [disabled, setDisabled] = useState(false);
    const { push, queryParams } = useRouting();
    const email = queryParams?.email;
    const { login } = useAuth();
    const [code, setCode] = useState("");
    const [pending, setPending] = useState(false);
    const isMobile = window.innerWidth < 640;

    const resendVerify = () => {
        if (disabled) {
            return;
        }

        setDisabled(true);
        http.post(`/verify/send`, {
            email
        })
            .then(() => successToast(t('resendVerificationEmail')))
            .catch(errorToast)
            .finally(() => {

                setTimeout(() => {
                    setDisabled(false);
                }, 60000); //1 minute
            });
    };

    const verify = () => {
        setPending(true);
        http.post(`/verify/code`, { code: code, email: email })
            .then((res) => {
                successToast(t("successfullyVerified"));
                login(res.data.data.token);
                push(afterLogin);
            })
            .catch(errorToast)
            .finally(() => setPending(false));
    };

    useEffect(() => {
        if (code?.length === 6) {
            verify();
        }
    }, [code]);

    useEffect(() => {
        if (!email) {
            push(paths.login);
        }
    }, []);

    return (
        <AuthContainer>
            <AuthCard>
                <AuthHeader
                    title={t('verifyEmail')}
                    description={t("emailSentTo", { email })}
                />

                <div className={'flex w-full flex-col gap-4'}>
                    <p className={'font-extralight text-sm text-gray-500 dark:text-white'}>{t('pleaseCheckYourInbox')}</p>
                    <ReactCodeInput
                        onChange={(value) => setCode(value)}
                        value={code}
                        name="code"
                        disabled={pending}
                        className={' justify-between'}
                        inputMode='tel'
                        fields={6}
                        inputStyle={{
                            MozAppearance: 'textfield',
                            width: isMobile ? '45px' : '60px',
                            height: isMobile ? '50px' : '65px',
                            fontSize: isMobile ? '16px' : '19px',
                            borderRadius: '8px',
                            fontWeight: "400",
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            textAlign: "center",
                        }}
                    />
                    <Button
                        isLoading={pending}
                        variant={"primary"}
                        disabled={code?.length < 6}
                        onClick={verify}
                    >
                        {t('verify')}
                    </Button>
                </div>
                <button onClick={resendVerify} className={"mt-6 text-xs text-primary-600 hover:underline"}>
                    {t('resendEmail')}
                </button>
            </AuthCard>
            <AuthFooter/>
        </AuthContainer>
    );
}