import React, { useEffect, useRef, useState } from "react";
import { ChevronDown } from "lucide-react";

import { cn } from "@/lib/utils";
import { Command, CommandInput, } from "@/components/v2/SelectionAndInput/command";
import { Popover, PopoverContent, PopoverTrigger, } from "@/components/v2/Overlay/popover";
import { useTrans } from "components";
import { normalizeToArray } from "core";

export interface Option {
    value: string;
    label: string;
    disabled?: boolean;
    description?: string;
    icon?: React.ReactNode;
}

export interface Props {
    getDisplayValue: (option: string) => React.ReactNode;
    filterFn: (value: string, search: string, keywords?: string[]) => number;
    search: string;
    loading: boolean;
    setSearch: (search: string) => void;
    noValuesSelectedMessage?: string;
    searchPlaceholder?: string;
    selected: string[] | string;
    disabled?: boolean;
    className?: string;
    noResultsMessage?: string;
    children: React.ReactNode;
}

export function ComboBox(props: Props) {
    const { t } = useTrans();
    const {
        getDisplayValue,
        filterFn,
        loading,
        search,
        setSearch,
        noValuesSelectedMessage,
        searchPlaceholder,
        disabled = false,
        className,
    } = props;
    const [open, setOpen] = useState(false);
    const selected = normalizeToArray(props.selected);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <span
                    data-slot="control"
                    className={cn([
                        className,
                        // Basic layout
                        'relative block w-full',
                        // Background color + shadow applied to inset pseudo-element, so shadow blends with border in light mode
                        'before:absolute before:inset-px before:rounded before:bg-inputV2-bg-surface before:shadow',
                        // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
                        'dark:before:hidden',
                        // Focus ring
                        'after:pointer-events-none after:absolute after:inset-0 after:rounded after:ring-inset after:ring-transparent after:focus-within:shadow-focus-ring after:focus-within:ring-border-focus',
                        // Disabled state
                        'before:has-[[data-disabled]]:bg-bg-surface-disabled before:has-[[data-disabled]]:shadow-none',
                    ])}
                >
                    <button
                        type="button"
                        role="combobox"
                        aria-expanded={open}
                        className={cn([
                            // Basic layout
                            'relative flex flex-row items-center w-full rounded px-3 py-1.5',
                            // Options (multi-select)
                            '[&_optgroup]:font-semibold',
                            // Typography
                            'antialiased font-tracking-normal font-normal font-body text-[14px] leading-5 text-text-default placeholder:text-inputV2-text-placeholder data-[disabled]:text-text-disabled dark:text-text-inverse-default',
                            // Border
                            'border border-inputV2-border data-[hover]:border-inputV2-border-hover dark:border-border-inverse-default dark:data-[hover]:border-border-inverse-hover',
                            // Background color
                            'bg-inputV2-bg-surface hover:bg-inputV2-bg-surface-hover active:bg-inputV2-bg-surface-active data-[disabled]:bg-bg-surface-disabled dark:bg-white/5',
                            // Hide default focus styles
                            'focus:outline-none',
                            // Invalid state
                            'data-[invalid]:border-border-critical data-[invalid]:data-[hover]:border-border-critical data-[invalid]:dark:border-border-critical data-[invalid]:data-[hover]:dark:border-border-critical',
                            // Disabled state
                            'data-[disabled]:border-transparent dark:data-[hover]:data-[disabled]:border-transparent ',
                        ])}
                        disabled={disabled}
                    >
                        <div className="relative w-full items-center">
                            <div className={'flex flex-wrap gap-1.5'}>
                                {selected.length === 0 ? (
                                    <span
                                        className={"text-inputV2-text-placeholder"}
                                    >
                                        {noValuesSelectedMessage ?? t("select")}
                                    </span>
                                ) : (
                                    <>
                                        {selected.map((item) => (
                                            <React.Fragment key={item}>
                                                {getDisplayValue(item)}
                                            </React.Fragment>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                        <ChevronDown className={"size-4 shrink-0 translate-x-0.5 "} color="var(--neutral-500)"/>
                    </button>
                </span>
            </PopoverTrigger>
            <PopoverContent className={cn("p-0 w-[--radix-popover-trigger-width]", className)}>
                <Command filter={filterFn}>
                    <CommandInput
                        value={search}
                        loading={loading}
                        onValueChange={setSearch}
                        placeholder={searchPlaceholder ?? t("typeToSearch")}
                    />
                    {props.children}
                </Command>
            </PopoverContent>
        </Popover>
    );
}