import { useTrans } from "components";
import { Feature } from "models/account/Account";
import { useContext } from "react";
import { AccountContext } from "context";

export default function useLoyalty() {
    const { t } = useTrans();
    const { account } = useContext(AccountContext);
    const base = "/loyalty";

    return {
        analytics: {
            name: t("analytics"),
            path: `${base}/analytics`,
        },
        rewards: {
            name: t("rewards"),
            path: `${base}/rewards`,
        },
        transactions: {
            name: t("transactions"),
            path: `${base}/transactions/all`,
        },
        tokens: {
            name: "Tokens",
            path: `${base}/tokens`,
        },
        staged: {
            name: "Staged",
            path: `${base}/staged-credit-receptions`,
        },
        rules: {
            name: t("rules"),
            path: `${base}/rules`,
        },
        tiers: {
            name: t("tiers"),
            path: `${base}/tiers`,
        },
        promotions: {
            name: t("promotionsAndVouchers"),
            path: `${base}/promotion-engine/promotions`,
        },
        referrals: {
            name: "Referrals",
            path: `${base}/referrals`,
            usesFeature: account.usesFeature(Feature.REFERRALS),
        },
    };
}