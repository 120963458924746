import React, { useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import useHttp from "@/core/http/useHttp";
import FormElement from "@/components/forms/FormElement";
import Preview from "@/modules/auth/create/components/Preview";
import useFetcher from "@/repositories/base/useFetcher";
import { asFormData } from "core";
import useModal from "@/components/hooks/useModal";
import WarningModal from "@/components/modals/types/WarningModal";
import MobilePreview from "@/modules/auth/create/components/MobilePreview";
import AuthContainer from "@/components/auth/AuthContainer";
import { paths } from "@/routing/paths";
import useRouting from "@/components/hooks/useRouting";
import AuthCard from "@/components/auth/AuthCard";
import AuthHeader from "@/components/auth/AuthHeader";
import { Button } from "@/components/v2/Actions/button";
import { EyeIcon } from "lucide-react";
import { Field, FieldGroup, Fieldset, Label } from "@/components/v2/SelectionAndInput/fieldset";
import ColorInput from "@/components/v2/SelectionAndInput/colorInput";

export default function SetupBrandKit() {
    const { t } = useTrans();
    const { errorToast } = useToast();
    const { push } = useRouting();
    const [account, setAccount] = useState(null);
    const [pending, setPending] = useState(false);
    const [showMobilePreview, setShowMobilePreview] = useState(false);
    const [logo, setLogo] = useState(null);
    const [logoAsUrl, setLogoAsUrl] = useState(null);
    const [color, setColor] = useState(null);
    const http = useHttp();
    const modal = useModal();
    const apiUrl = '/after-login/create-brand-kit';
    const isPayloadValid = Boolean(color && logo);
    useFetcher({
        url: apiUrl,
        dataSetter: setAccount
    });

    useEffect(() => {
        if (logo) {
            const reader = new FileReader();
            reader.onload = e => setLogoAsUrl(e.target.result);
            reader.readAsDataURL(logo);
        }
    }, [logo]);

    const update = () => {
        setPending(true);
        http.post(apiUrl, asFormData({
            logo: logo,
            color: color,
        }))
            .then(() => push(paths.configureAccount))
            .catch(error => {
                errorToast(error);
                setPending(false);
            });
    };

    const skipStep = () => {
        setPending(true);
        http.put(`${apiUrl}/skip-step`)
            .then(() => push(paths.configureAccount))
            .catch(error => {
                errorToast(error);
                setPending(false);
            });
    };

    if (!account) {
        return null;
    }

    return (
        <AuthContainer>
            <div>
                {showMobilePreview ? (
                    <MobilePreview
                        color={color}
                        logo={logoAsUrl}
                        onClick={() => setShowMobilePreview(!showMobilePreview)}
                    />
                ) : (
                    <div className="flex items-center justify-center gap-4">
                        <div className="flex items-center justify-center gap-4">
                            <AuthCard>
                                <AuthHeader
                                    title={t('brandKit')}
                                    description={t('setLogoAndColors')}
                                />
                                <div className={'flex flex-col gap-6 '}>
                                    <Button
                                        variant={'default'}
                                        className={'w-full sm:hidden'}
                                        onClick={() => setShowMobilePreview(!showMobilePreview)}

                                    >
                                        <div className={'flex items-center gap-2'}>
                                            <EyeIcon className={'size-5'}/>
                                            <p>
                                                {t("preview")}
                                            </p>
                                        </div>
                                    </Button>

                                    <div className={'border-b border-gray-900/10 sm:hidden'}/>

                                    <div className={'flex flex-col gap-1'}>
                                        <Fieldset>
                                            <FieldGroup>
                                                <FormElement
                                                    type={"media_upload"}
                                                    name={"logo"}
                                                    label={"Logo"}
                                                    onChange={(name, value) => setLogo(value)}
                                                    disabled={pending}
                                                />

                                                <Field>
                                                    <Label>{t('primaryBrandColor')}</Label>
                                                    <ColorInput
                                                        onChange={(value) => setColor(value)}
                                                        value={color ?? ""}
                                                    />
                                                </Field>
                                                <Button
                                                    onClick={update}
                                                    variant={'primary'}
                                                    isLoading={pending}
                                                    disabled={!isPayloadValid}
                                                    className={'w-full'}
                                                >
                                                    {t("continue")}
                                                </Button>
                                            </FieldGroup>
                                        </Fieldset>
                                        <Button
                                            onClick={modal.open}
                                            variant={'default'}
                                            isLoading={pending}
                                        >
                                            {t("skip")}
                                        </Button>
                                    </div>
                                </div>
                            </AuthCard>
                            <Preview
                                color={color}
                                logo={logoAsUrl}
                            />
                        </div>
                    </div>
                )}

                <WarningModal
                    modal={modal}
                    title={t('continueWithoutSaving')}
                    primaryButton={{
                        label: t("continue"),
                        onClick: skipStep,
                        pending: pending,
                    }}
                    secondaryButton={{
                        onClick: modal.close,
                        pending: pending
                    }}
                >
                    {t('notBeAbleToSetupFunctionalEmailWithColors')}
                </WarningModal>
            </div>
        </AuthContainer>
    );
}
