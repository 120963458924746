import React from "react";
import UIComponent from "components/UIComponent";
import { TableColumnType } from "components/table_v2/types";
import ColumnWrapper from "components/table_v2/components/column/ColumnWrapper";

type Props = {
    column: TableColumnType;
    className?: string;
    hasOnClick?: boolean;
    canOverflow?: boolean;
    rowType: "single" | "double";
    onClick?: any;
};

export default function Column(props: Props) {
    const { column, rowType } = props;

    return (
        <ColumnWrapper
            column={column}
            rowType={rowType}
            canOverflow={props.canOverflow}
        >
            <UIComponent
                element={column.value}
                className={'xl:text-2xl'}
                onClick={props.onClick}
            />
        </ColumnWrapper>
    );
}