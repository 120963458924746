import React, { useEffect, useState } from "react";
import AuthContainer from "@/components/auth/AuthContainer";
import useRouting from "@/components/hooks/useRouting";
import { paths } from "@/routing/paths";
import useHttp from "@/core/http/useHttp";
import { useToast } from "components";
import AuthHeader from "@/components/auth/AuthHeader";
import AuthCard from "@/components/auth/AuthCard";

enum Step {
    CREATE_ACCOUNT = "account_creation",
    INVITATIONS = "pending_invitations",
    CONFIGURE = "configuring_account",
    BRANDKIT = "brand_kit",
    SELECT_ACCOUNT = "select_account",
}

const stepData = [
    {
        type: Step.CREATE_ACCOUNT,
        path: paths.createAccount,
        label: "Stepper account",
    },
    {
        type: Step.INVITATIONS,
        path: paths.pendingInvitations,
        label: "Invitations",
    },
    {
        type: Step.CONFIGURE,
        path: paths.configureAccount,
        label: "Configure account",
    },
    {
        type: Step.BRANDKIT,
        path: paths.setupBrandkit,
        label: "Setup brandkit",
    },
    {
        type: Step.SELECT_ACCOUNT,
        path: paths.selectAccount,
        label: "Select account",
    }
];

export default function AfterLogin() {
    const { push, queryParams } = useRouting();
    const http = useHttp();
    const { errorToast } = useToast();
    const [step, setStep] = useState<Step>(null);
    const apiUrl = '/after-login';

    // Do not use the fetcher here, since we never want this to be cached.
    useEffect(() => {
        http.get(apiUrl)
            .then(res => setStep(res.data.data.step))
            .catch(errorToast);
    }, []);

    useEffect(() => {
        if (queryParams.stay) {
            return;
        }

        if (!step) {
            return;
        }

        const foundStep = stepData.find(s => s.type == step);
        push(foundStep.path);
    }, [step]);

    return (
        <AuthContainer>
            {queryParams.stay && (
                <AuthCard>
                    <AuthHeader
                        title={"After login"}
                        description={`Recommended step: ${step}`}
                    />
                    <div className={"grid grid-cols-2 gap-3"}>
                        {stepData.map(step => (
                            <button
                                key={step.type}
                                onClick={() => push(step.path)}
                                className={"rounded-lg border border-gray-200 p-8 shadow hover:scale-101"}
                            >
                                <p className={"text-gray-400"}>
                                    {step.label}
                                </p>
                            </button>
                        ))}
                    </div>
                </AuthCard>
            )}
        </AuthContainer>
    );
}