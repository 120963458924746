import { useTrans } from "components";
import { Feature } from "models/account/Account";
import { useContext } from "react";
import { AccountContext } from "context";


export default function useCrm() {
    const { t } = useTrans();
    const { account } = useContext(AccountContext);
    const base = "/crm";

    return {
        contacts: {
            name: t("contacts"),
            path: `${base}/contacts/all`,
        },
        identifiers: {
            name: t("identifiers"),
            path: `${base}/identifiers`,
        },
        lists: {
            name: t("lists"),
            path: `${base}/lists`,
        },
        forms: {
            name: t("forms"),
            path: `${base}/forms`,
        },
        orders: {
            name: t("orders"),
            path: `${base}/orders/all`,
        },
        bookings: {
            name: t("bookings"),
            path: `${base}/bookings`,
            usesFeature: account.usesFeature(Feature.BOOKINGS),
        },
        events: {
            name: t("events"),
            path: `${base}/events/all`,
            usesFeature: account.usesFeature(Feature.EVENTS),
        },
        visits: {
            name: t("visits"),
            path: `${base}/visits/all`,
            usesFeature: account.usesFeature(Feature.VISITS),
        },
        shifts: {
            name: t("shifts"),
            path: `${base}/shifts/all`,
            usesFeature: account.usesFeature(Feature.SHIFTS),
        },
    };
}