import React from "react";

type Props = {
    className?: string;
    children?: React.ReactNode;
};

export default function SidebarTabsContainer(props: Props) {
    return (
        <nav className={`w-full py-4 pl-20 lg:mt-0 lg:px-3 ${props.className ? props.className : ""}`}>
            {props.children}
        </nav>
    );
}
