import { useState } from 'react';

export interface UsePanelProps {
    open: () => void;
    close: () => void;
    isActive: boolean;
}

export default function usePanel(): UsePanelProps {
    const [isActive, setActive] = useState(false);

    const close = () => {
        setActive(false);
    };

    const open = () => {
        setActive(true);
    };

    return {
        isActive,
        open,
        close,
    };
}