import React from "react";

export default function ModalButtonsWrapper(props: {
    children?: React.ReactNode;
}) {

    return (
        <div className={'flex justify-end gap-2 border-x border-b bg-gray-50 px-6 py-4 dark:bg-slate-400'}>
            {props.children}
        </div>
    );
}