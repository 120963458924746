import React from "react";
import Text from "../../typography/Text";

type Props = {
    label?: string;
    children: any;
    is_underlined?: boolean;
};

export default function DisplayFormElementContainer(props: Props) {
    const is_underlined = props.is_underlined ?? true;

    return (
        <div className={`relative mb-2 w-full pt-3 ${is_underlined ? 'border-b border-gray-100' : ''}`}>
            <div className={'py-1 pl-1'}>

                {props.label ? (
                    <Text weight={"normal"} color={"text-gray-500 dark:text-white"} size={"xs"}>{props.label}</Text>
                ) : null}

                <div className={"min-h-[1.7rem]"}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}
