import React from "react";
import Icon, { IconType } from "components/icons/Icon";
import Text from "components/typography/Text";

export type TabItem = {
    name: string;
    key: any;
    isActive?: boolean;
    icon?: IconType;
    onClick?: any;
    hidden?: boolean;
};

type Props = {
    tabs: TabItem[];
    activeTab: any;
    setActiveTab: any;
};

export default function ModalTabBar(props: Props) {
    const {
        tabs,
        activeTab,
        setActiveTab
    } = props;

    return (
        <div className={'w-full'}>
            <div className={`flex items-end bg-gray-900`}>
                <span className={"min-h-3 w-10 border-b-0"}/>
                {tabs.filter(t => !t.hidden).map(tab => {
                    const isActiveTab = tab.key == activeTab?.key;

                    return (
                        <div
                            key={tab.key}
                            className={`relative flex cursor-pointer ${isActiveTab ? 'h-10' : 'h-9'} `}
                        >
                            <div
                                className={`absolute -left-3 bottom-0 z-10 h-3`}
                                hidden={!isActiveTab}
                            >
                                <div
                                    className={"tabs-corner-before left relative size-3 overflow-hidden"}
                                />
                            </div>
                            <div
                                className={`flex select-none items-center rounded-t-xl px-6 py-1 transition duration-200 ease-linear ${isActiveTab ? 'shadow-tab bg-white py-2' : 'cursor-pointer bg-primary-100 hover:bg-[#caceea]'}`}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab.icon && (
                                    <Icon type={tab.icon} attr={"div"} className="tab-item__icon"/>
                                )}
                                <Text
                                    render_as={"label"}
                                    weight={"normal"}
                                    className={`cursor-pointer ${isActiveTab ? 'opacity-100' : 'cursor-pointer opacity-60'}`}
                                >
                                    {tab.name}
                                </Text>
                            </div>
                            <div
                                className={`absolute -right-3 bottom-0 z-10 h-3`}
                                hidden={!isActiveTab}
                            >
                                <div
                                    className={"tabs-corner-before right relative size-3 overflow-hidden"}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
