import React from "react";
import { NavLink } from "react-router";
import useNavigation from "@/components/navigation/useNavigation";

type Props = {
    path: string;
    name: string;
};

export default function MobileSubNavItem(props: Props) {
    const { setIsOpen } = useNavigation();
    const { path, name } = props;

    return (
        <li>
            <NavLink
                to={path}
                onClick={() => setIsOpen(false)}
                className={({ isActive }) => `p-2 pl-4 w-full btn-outline text-sm text-gray-25 inline-block rounded-lg hover:bg-white/15 ${isActive ? "bg-white/15" : ""}`}
            >
                {name}
            </NavLink>
        </li>
    );

}