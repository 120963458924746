import React from "react";
import NavItemWithDropdown from "@/components/navigation/desktop/NavItemWithDropdown";
import SubNavItem from "@/components/navigation/desktop/SubNavItem";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";
import useCrm from "@/components/navigation/items/useCrm";

export default function CRM(props: {
    active: boolean;
}) {
    const { active } = props;
    const crm = useCrm();
    const accountUser = useCurrentAccountUser();

    if (!accountUser.hasPermission("contacts")) {
        return null;
    }

    return (
        <NavItemWithDropdown
            path={"crm"}
            label={"CRM"}
        >
            {active && (
                <>
                    <SubNavItem
                        name={crm.contacts.name}
                        path={crm.contacts.path}
                    />
                    <SubNavItem
                        name={crm.identifiers.name}
                        path={crm.identifiers.path}
                    />
                    <SubNavItem
                        name={crm.lists.name}
                        path={crm.lists.path}
                    />
                    <SubNavItem
                        name={crm.forms.name}
                        path={crm.forms.path}
                    />
                    <SubNavItem
                        name={crm.orders.name}
                        path={crm.orders.path}
                    />
                    {crm.bookings.usesFeature && (
                        <SubNavItem
                            name={crm.bookings.name}
                            path={crm.bookings.path}
                        />
                    )}
                    {crm.events.usesFeature && (
                        <SubNavItem
                            name={crm.events.name}
                            path={crm.events.path}
                        />
                    )}
                    {crm.visits.usesFeature && (
                        <SubNavItem
                            name={crm.visits.name}
                            path={crm.visits.path}
                        />
                    )}
                    {crm.shifts.usesFeature && (
                        <SubNavItem
                            name={crm.shifts.name}
                            path={crm.shifts.path}
                        />
                    )}
                </>
            )}
        </NavItemWithDropdown>
    );
}

