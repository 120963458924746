import React from "react";
import { Text } from "@/components/v2/Typography/text";
import Thumbnail from "@/components/v2/ImagesAndIcons/thumbnail";

export function OptionLayoutWithThumbnail(props: {
    label: string;
    thumbnail: string;
    description?: string;
}) {
    const { label, description, thumbnail } = props;

    return (
        <div className="flex items-center gap-2">
            {thumbnail ? (
                <Thumbnail
                    src={thumbnail}
                    className="size-10"
                />
            ) : (
                <Thumbnail className="size-10"/>
            )}
            <div className="flex flex-col justify-center text-left">
                <Text variant={"bodyMd"} tone={"base"} as={"p"}>{label}</Text>
                {description && (
                    <Text
                        variant={"bodyXs"}
                        tone={"secondary"}
                        as={"p"}
                    >
                        {description}
                    </Text>
                )}
            </div>
        </div>
    );
}