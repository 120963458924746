import { useTrans } from "components";
import { useContext } from "react";
import { AccountContext } from "context";

export default function useStore() {
    const { t } = useTrans();
    const { account } = useContext(AccountContext);
    const base = "/store";

    return {
        prepaid: {
            name: t("prepaid"),
            path: `${base}/prepaid`,
        },
        giftcards: {
            name: t("giftcards"),
            path: `${base}/giftcards/program/cards`,
        },
        stripcards: {
            name: t("stripcards"),
            path: `${base}/stripcards`,
            usesFeature: account.uses_stripcards,
        },
        internalStore: {
            name: t("internalStore"),
            path: `${base}/internal-store/settings`,
        },
    };
}