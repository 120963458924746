import React, { useContext, useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import Modal from "components/modals/types/Modal";
import useModal from "components/hooks/useModal";
import { depth } from "core/depth";
import useHttp from "core/http/useHttp";
import { UnauthorizedContext } from "context/unauthorized/UnauthorizedContext";
import Text from "components/typography/Text";
import useRouting from "components/hooks/useRouting";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";

export default function UnauthorizedModal() {
    const { t } = useTrans();
    const { successToast, errorToast } = useToast();
    const http = useHttp();
    const { data, setData } = useContext(UnauthorizedContext);
    const modal = useModal();
    const { goBack } = useRouting();
    const { user } = useCurrentAccountUser();
    const [pending, setPending] = useState(false);

    useEffect(() => {
        if (data) {
            modal.open();
        } else {
            modal.close();
        }
    }, [data]);

    const resendVerify = () => {
        setPending(true);
        http.post(`/verify/send`, { email: user.email })
            .then(() => successToast(t('resendVerificationEmail')))
            .catch(errorToast)
            .finally(() => setPending(false));
    };

    const submit = () => {
        if (data.type == "verify_email") {
            resendVerify();
        } else {
            setData(null);
            goBack();
        }
    };

    return (
        <Modal
            modal={modal}
            depth={depth.usageModal}
            title={data?.title}
            primaryButton={{
                onClick: submit,
                label: data?.submit_text,
                pending: pending,
                disabled: pending
            }}
            secondaryButton={{
                label: t('back'),
                onClick: () => {
                    setData(null);
                    goBack();
                }
            }}
        >
            <Text>{data?.message}</Text>
        </Modal>
    );
}
