import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
    Combobox,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Transition,
    TransitionChild
} from "@headlessui/react";
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { depth } from "core/depth";
import { useTrans } from "components";
import Fuse from "fuse.js";
import { groupBy } from "lodash";
import useCommandPalette from "modules/global/commandPalette/useCommandPalette";
import useCommandPaletteOptions, { CommandPaletteOption } from "@/modules/global/commandPalette/useCommandPaletteOptions";

export default function CommandPalette() {
    const { t } = useTrans();
    const options = useCommandPaletteOptions();
    const { isOpen, setIsOpen } = useCommandPalette();
    const [selectedAction, setSelectedAction] = useState<CommandPaletteOption | null>(null);
    const [search, setSearch] = useState('');
    const [secondarySearch, setSecondarySearch] = useState('');
    const secondaryInputRef = useRef(null);
    const ref = useRef(null);

    useEffect(() => {
        if (isOpen) {
            ref.current?.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        if (selectedAction) {
            secondaryInputRef.current?.focus();
        }
    }, [selectedAction]);

    useEffect(() => {
        function onKeydown(e: KeyboardEvent) {
            if (e.key == "k" && (e.metaKey || e.ctrlKey)) {
                setIsOpen(!isOpen);
            }
        }

        window.addEventListener("keydown", onKeydown);

        return () => window.removeEventListener("keydown", onKeydown);
    }, [isOpen, selectedAction]);

    const onSelect = (option: CommandPaletteOption) => {
        if (option) {

            if (option.type === "action") {
                setSelectedAction(option);
            } else {
                setIsOpen(false);
                option.handle();
            }
        }
    };

    const filteredOptions = useMemo(() => {

        if (search === "") {
            return options;
        }

        const fuse = new Fuse(options, {
            keys: [
                "value",
                'label',
                "tags",
                {
                    name: "description",
                    weight: 0.5,
                },
            ],
            threshold: 0.4,
        });

        const result = fuse.search(search);

        return result.map(i => i.item);

    }, [options, search]);

    const handleAction = () => {
        if (selectedAction) {
            selectedAction.handle();
        }
    };

    const getGroupedOptions = groupBy(filteredOptions, g => g.group);

    return (
        <Transition
            show={isOpen}
            afterLeave={() => {
                setSearch("");
                setSecondarySearch("");
            }}
        >
            <Dialog
                style={{
                    zIndex: depth.tooltip + 1
                }}
                onClose={() => {
                    if (selectedAction) {
                        setSelectedAction(null);
                    } else {
                        setIsOpen(false);
                    }
                }}
                className="fixed inset-0 overflow-y-auto p-4 pt-[25vh]"
            >
                <TransitionChild
                    enter={"duration-400 ease-out"}
                    enterFrom={"opacity-0"}
                    enterTo={"opacity-100"}
                    leave={"duration-300 ease-in"}
                    leaveFrom={"opacity-100"}
                    leaveTo={"opacity-0"}
                >
                    <DialogBackdrop className="fixed inset-0 bg-black/50"/>
                </TransitionChild>

                <TransitionChild
                    enter={"duration-400 ease-out"}
                    enterFrom={"opacity-0 scale-95"}
                    enterTo={"opacity-100 scale-100"}
                    leave={"duration-300 ease-in"}
                    leaveFrom={"opacity-100 scale-100"}
                    leaveTo={"opacity-0 scale-95"}
                >
                    <DialogPanel
                        className="relative mx-auto max-w-xl divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5 dark:divide-white/10 dark:bg-slate-800 dark:ring-white/30"
                    >
                        {selectedAction === null ? (
                            <Combobox immediate={true} onChange={onSelect}>
                                <div className={"flex items-center px-4"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="size-6 text-gray-500"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                                        />
                                    </svg>

                                    <ComboboxInput
                                        ref={ref}
                                        autoFocus={true}
                                        onChange={(event) => setSearch(event.target.value)}
                                        displayValue={(option: any) => option?.label}
                                        className={"h-12 w-full border-0 bg-transparent text-base text-gray-800 placeholder:text-gray-400 focus:ring-0 dark:text-white"}
                                        placeholder={t("search") + "..."}
                                    />
                                </div>

                                {filteredOptions.length > 0 && (
                                    <ComboboxOptions className={"max-h-96 overflow-y-auto text-sm"}>

                                        {Object.keys(getGroupedOptions).map(group => (

                                            <div className={"p-2"} key={group}>
                                                <p className={"px-4 py-1 text-xs font-medium text-gray-400"}>{group}</p>

                                                {getGroupedOptions[group]?.map((option: CommandPaletteOption) => (
                                                    <ComboboxOption
                                                        as={Fragment}
                                                        key={option.type + option.group + option.label}
                                                        value={option}
                                                    >
                                                        {({ focus, selected }) => (
                                                            <div
                                                                className={`cursor-pointer rounded-lg px-4 py-2 ${focus ? "bg-primary-600 text-white shadow" : "bg-white text-gray-900 dark:bg-slate-800 dark:text-white"}`}
                                                            >
                                                                <span>{option.label}</span>
                                                                <span
                                                                    className={`ml-3 ${focus ? "text-primary-300" : "text-gray-400 dark:text-white"}`}
                                                                >
                                                                     {option.description}
                                                                 </span>
                                                            </div>
                                                        )}
                                                    </ComboboxOption>
                                                ))}
                                            </div>
                                        ))}
                                    </ComboboxOptions>
                                )}

                                {search && filteredOptions.length == 0 && (
                                    <p className={"p-4 text-sm text-gray-500"}>{t("noResultsFound")}</p>
                                )}
                            </Combobox>
                        ) : (
                            <div className={"flex items-center px-4"}>
                                <ArrowUturnLeftIcon className={"size-6 text-gray-500"}/>
                                <form
                                    className={"w-full"}
                                    onSubmit={e => {
                                        e.preventDefault();
                                        handleAction();
                                    }}
                                >
                                    <input
                                        ref={secondaryInputRef}
                                        value={secondarySearch}
                                        onChange={e => setSecondarySearch(e.target.value)}
                                        placeholder={selectedAction.data.placeholder ?? t("search") + "..."}
                                        className={"ml-3 h-12 w-full border-0 bg-transparent text-base text-gray-800 placeholder:text-gray-400 focus:ring-0 dark:text-white"}
                                    />
                                </form>
                            </div>
                        )}
                    </DialogPanel>
                </TransitionChild>
            </Dialog>
        </Transition>
    );
}