import { useTrans } from "components";
import { AccountContext } from "context";
import { useContext } from "react";
import { CommandPaletteOption, CommandPaletteOptionType } from "../useCommandPaletteOptions";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";
import { Feature } from "models/account/Account";
import useRouting from "@/components/hooks/useRouting";

export default function useStoreOptions(): CommandPaletteOption[] {
    const { t } = useTrans();
    const accountUser = useCurrentAccountUser();
    const { push } = useRouting();
    const { account } = useContext(AccountContext);

    const prepaid = {
        visible: accountUser.hasPermission("prepaid"),
        group: t("navigation"),
        label: t("prepaid"),
        description: "Store",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/store/prepaid"),
        tags: [
            "prepaid",
        ],
    };

    const giftcards = {
        visible: accountUser.hasPermission("giftcards"),
        group: t("navigation"),
        label: t("giftcards"),
        description: "Store",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/store/giftcards/program/cards"),
        tags: [
            "giftcards",
        ],
    };

    const stripcards = {
        visible: account.usesFeature(Feature.STRIPCARDS),
        group: t("navigation"),
        label: t("stripcards"),
        description: "Store",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/store/stripcards"),
        tags: [
            "stripcards",
        ],
    };

    const internalStore = {
        visible: true,
        group: t("navigation"),
        label: t("internalStore"),
        description: "Store",
        type: CommandPaletteOptionType.NAVIGATION,
        handle: () => push("/store/internal-store/settings"),
        tags: [
            "internalStore",
        ],
    };

    return [
        prepaid,
        giftcards,
        stripcards,
        internalStore
    ];
}