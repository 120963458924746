import { useContext, useState } from "react";
import useHttp from "core/http/useHttp";
import { AccountContext } from "context";
import MediaType from "../MediaType";
import { useToast } from "components";

export default function useFileUpload(props: any) {
    const { account } = useContext(AccountContext);
    const { errorToast } = useToast();
    const [preview, setPreview] = useState<any>("");
    const [fileName, setFileName] = useState(props.label);
    const http = useHttp();

    const fileHandler = (file: any) => {

        if (Array.isArray(file)) {
            file = file[0];
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => setPreview(reader.result);
        reader.onerror = error => errorToast(error);

        const data = new FormData();
        data.append("file", file);
        data.append("title", file.name);
        data.append("visibility", "public");

        setFileName(file.name);

        http.post(`/accounts/${account.id}/files`, data)
            .then((res: any) => {

                const media = new MediaType();
                media.type = "image";
                media.value = res.data.data.url;
                media.id = `media:${res.data.data.media.id}`;
                media.name = res.data.data.title;

                props.onChange && props.onChange(props.name, media);
                props.onBlur && props.onBlur(props.name, media);
            })
            .catch(errorToast);
    };

    return {
        fileHandler,
        fileName,
        preview,
    };
}
