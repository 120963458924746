import React from "react";
import cn from "classnames";

type Props = {
    className?: string;
    active?: boolean;
    children: any;
};

export default function TabItem(props: Props) {
    const containerCn = cn(
        "transition-all duration-300 ease-in-out font-display",
        props.active ? 'opacity-100' : 'opacity-0',
        props.className,
    );

    return (
        <div
            className={containerCn}
            hidden={!props.active}
        >
            {props.children}
        </div>
    );
}