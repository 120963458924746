import React, { useState } from "react";
import { PiggyContext } from "@/context/piggy/PiggyContext";
import usePanel from "@/components/hooks/usePanel";
import AccountRoutes from "@/routing/accounts/AccountRoutes";

export default function AccountsRoute() {
    const helpCentre: any = usePanel();
    const [isUpgradeModalVisible, setUpgradeModalVisible] = useState(false);
    const [upgradeModalContent, setUpgradeModalContent] = useState({
        module: ""
    });

    return (
        <PiggyContext.Provider
            value={{
                isUpgradeModalVisible,
                setUpgradeModalVisible,
                upgradeModalContent,
                setUpgradeModalContent,
                helpCentre,
            }}
        >
            <AccountRoutes/>
        </PiggyContext.Provider>
    );
}
