import React, { useContext, useEffect, useState } from "react";
import { useToast, useTrans } from "components";
import { AccountContext } from "context";
import Account from "models/account/Account";
import useAuth from "components/hooks/auth/useAuth";
import Badge from "components/badge/Badge";
import useModal from "../hooks/useModal";
import MediaComponent from "components/MediaComponent";
import { abbreviate, openInSameTab } from "core";
import DeleteModal from "components/modals/types/DeleteModal";
import useHttp from "core/http/useHttp";
import { NavLink } from "react-router";
import useHelpCenter from "components/hooks/helpcenter/useHelpCenter";
import useRouting from "components/hooks/useRouting";
import ThemeIcon from "components/navigation/icons/ThemeIcon";
import useCurrentAccountUser from "models/accountUser/useCurrentAccountUser";
import useNavigation from "@/components/navigation/useNavigation";

export default function UserDropdown() {
    const { setUserDropdownOpen, isUserDropdownOpen, setIsOpen, setNavigationFocussed } = useNavigation();
    const { t } = useTrans();
    const [isSelectAccountActive, setSelectAccountActive] = useState(true);
    const [pending, setPending] = useState(false);
    const { errorToast } = useToast();
    const { user } = useCurrentAccountUser();
    const { account } = useContext(AccountContext);
    const helpCenter = useHelpCenter();
    const { switchToAccount } = useAuth();
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
    const { logout } = useAuth();
    const dialog = useModal();
    const http = useHttp();
    const { isPathActive } = useRouting();

    useEffect(() => {
        http.get("/accounts")
            .then((res) => setAccounts(res.data.data))
            .catch(errorToast);
    }, []);

    useEffect(() => {
        if (!isUserDropdownOpen) {
            setSelectAccountActive(false);
        }
    }, [isUserDropdownOpen]);

    useEffect(() => {
        if (selectedAccount) {
            setTimeout(() => selectAccount(selectedAccount), 1500); // Nice
        }
    }, [selectedAccount]);

    const name = () => {
        if (user.fullName()) {
            return user.fullName();
        }

        return user.email;
    };

    const selectAccount = (selectedAccount: Account) => {
        switchToAccount(selectedAccount);
        openInSameTab(window.location.origin);
    };

    const onClick = () => {
        setUserDropdownOpen(!isUserDropdownOpen);
        setIsOpen(false);
        setNavigationFocussed(false);
    };

    return (
        <div className={"lg:relative"}>

            {user.avatar ? (
                <button
                    onClick={onClick}
                    className={`btn-outline block size-10 overflow-hidden rounded-full p-1 transition-all hover:bg-white/15 ${(isUserDropdownOpen || isPathActive("/user")) ? "bg-white/15" : ""}`}
                >
                    <img
                        alt={"avatar"}
                        src={user.avatar}
                        className={"size-full rounded-full border border-white object-cover shadow-lg"}
                    />
                </button>
            ) : (
                <button
                    onClick={onClick}
                    className={`btn-outline flex size-10 items-center justify-center rounded-full hover:bg-white/15 dark:border-t-gray-300 ${isUserDropdownOpen ? "bg-white/15" : ""}`}
                >
                    <div
                        className={`font-light flex size-8 select-none items-center justify-center rounded-full border border-white bg-primary-600 text-lg text-white shadow-lg`}
                    >
                        {abbreviate(name())}
                    </div>
                </button>
            )}

            <div
                className={`absolute left-0 top-16 w-full border-t border-t-gray-300 bg-[#050E27]/95 p-3 shadow-lg backdrop-blur-md lg:left-auto lg:right-0 lg:top-10 lg:w-80 lg:rounded-lg lg:border-transparent dark:bg-slate-900/95 dark:lg:border lg:dark:border-gray-400`}
                hidden={!isUserDropdownOpen}
            >
                {!isSelectAccountActive ? (
                    <div>
                        <NavLink
                            to={"/user"}
                            className={({ isActive }) => `flex rounded-lg btn-outline transition-all hover:bg-white/10 ${isActive ? "bg-white/10" : ""}`}
                            onClick={() => {
                                setUserDropdownOpen(false);
                            }}
                        >
                            <div className="flex gap-3 px-3 py-2">
                                <MediaComponent
                                    media={{
                                        type: user.avatar ? "image" : "string",
                                        value: user.avatar ?? name(),
                                    }}
                                    className={"size-12 rounded-full border border-gray-200 object-cover text-xl"}
                                />

                                <div className="flex flex-col text-left">
                                    <p className="text-base">{user.firstname} {user.lastname}</p>
                                    <p className="font-light text-sm opacity-40">{user.email}</p>
                                </div>
                            </div>
                        </NavLink>

                        {(user.isSystemAdmin || user.isSystemSuperAdmin) && (
                            <>
                                <div className={"my-2 border-b border-white/10"}/>

                                <div className={"p-2"}>
                                    <div
                                        className={"relative flex h-10 w-full rounded-lg border border-gray-400 opacity-40"}
                                    >
                                        <ThemeIcon type={"light"}/>
                                        <ThemeIcon type={"dark"}/>
                                        <ThemeIcon type={"system"}/>

                                        <div className="slider"/>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className={"my-2 border-b border-white/10"}/>

                        <div className="flex flex-col">
                            <a
                                className={"btn-outline flex items-center justify-between rounded-lg px-3 py-2 text-sm transition-all hover:bg-white/10"}
                                target={"_blank"}
                                href={helpCenter.url}
                            >
                                {t("helpCenter")}

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                                    />
                                </svg>
                            </a>

                            <button
                                className={"btn-outline flex w-full items-center justify-between rounded-lg px-3 py-2 text-left text-sm transition-all hover:bg-white/10"}
                                onClick={() => dialog.open()}
                            >
                                {t("logout")}

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                                    />
                                </svg>

                            </button>
                        </div>

                        <div className={"my-2 border-b border-white/10"}/>

                        <div className={"mt-2"}>
                            <button
                                className={"btn-outline flex w-full items-center justify-between rounded-lg px-3 py-2 text-sm transition-all hover:bg-white/10"}
                                onClick={() => setSelectAccountActive(true)}
                            >
                                {t('switchAccount')}

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                ) : (
                    <div
                        className={`flex-col justify-center`}
                    >
                        <button
                            className={`btn-outline flex gap-2 rounded-lg px-3 py-2 text-sm hover:bg-white/10`}
                            onClick={() => setSelectAccountActive(false)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="my-auto size-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7"/>
                            </svg>

                            <span className={"ml-1"}>{t("back")}</span>
                        </button>

                        <div className={"my-2 border-b border-white/10"}/>

                        <div className={`max-h-96 overflow-y-auto px-0.5 py-2`}>
                            <div
                                className={`flex w-full items-center justify-between rounded-lg p-2 text-sm hover:bg-white/10`}
                            >
                                <span className={"w-2/3"}>{account.company}</span>

                                <div className={"flex w-1/4 items-center justify-center"}>
                                    <Badge uppercase={true} value={t('active')} color={'highlight_success'}/>
                                </div>
                            </div>

                            {accounts.filter(acc => acc.id !== account.id).map(account => (
                                <button
                                    key={account.id}
                                    className={`btn-outline mt-1 flex w-full items-center justify-between rounded-lg p-2 text-left text-sm hover:bg-white/10 ${selectedAccount?.id == account.id ? "bg-white/10" : ""}`}
                                    onClick={() => setSelectedAccount(account)}
                                >
                                    <span className={"w-2/3"}>{account.company}</span>

                                    <div className={"flex w-1/4 items-center justify-center"}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className={`size-5 ${selectedAccount?.id == account.id ? "spin-easing" : ""}`}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                            />
                                        </svg>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <DeleteModal
                modal={dialog}
                primaryButton={{
                    onClick: () => {
                        setPending(true);
                        logout(true);
                    },
                    label: t('logout'),
                    pending: pending
                }}

            >
                <p>{t("doYouWantToLogout")}</p>
            </DeleteModal>
        </div>
    );
}
