import React, { createContext } from "react";
import { Route, Routes } from "react-router";
import Overview from "@/modules/store/giftcards/programs/Overview";
import CardsDetails from "@/modules/store/giftcards/cards/Details";
import TransactionDetails from "@/modules/store/giftcards/transactions/Details";
import ReportDetails from "@/modules/store/giftcards/settlementReports/Details";
import { GiftcardProgram } from "@/models/account/Account";

type GiftcardProgramContext = {
    selectedProgram: GiftcardProgram;
    setSelectedProgram: (program: GiftcardProgram) => void
};

export const GiftcardProgramContext = createContext<Partial<GiftcardProgramContext>>({});

export default function GiftcardRoutes() {
    return (
        <Routes>
            <Route path={"program"}>
                <Route path={"*"} element={<Overview/>}/>
            </Route>

            <Route path={`/cards/:cardId`} element={<CardsDetails/>}/>
            <Route path={`/transactions/:transactionId`} element={<TransactionDetails/>}/>

            <Route path={`/programs/:programId/settlement-reports/:reportId`} element={<ReportDetails/>}/>
        </Routes>
    );
}
