import React from "react";
import { NavLink } from "react-router";
import useSidebar from "../hooks/useSidebar";

type Props = {
    name: string;
    path: string;
    hidden?: boolean;
};

export default function SidebarRouteItem(props: Props) {
    const { setOpen, setActiveParent } = useSidebar();

    return (
        <NavLink
            to={props.path}
            hidden={props.hidden}
            className={({ isActive }) => `btn-outline my-1 block min-w-52 rounded-full px-3 py-2 transition-all duration-300 hover:bg-gray-800 lg:w-full lg:px-4 dark:hover:bg-white/10 ${
                isActive ? `bg-gray-500 dark:bg-white/10` : ""
            }`}
            onClick={() => {
                setOpen(false);
                setActiveParent(null);
            }}
        >
            <div className={"flex items-center justify-between"}>
                <p className={"text-sm text-gray-25"}>{props.name}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="hidden size-4 stroke-gray-100 lg:block"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                </svg>
            </div>
        </NavLink>
    );
}
