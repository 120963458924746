import React from "react";
import useNavigation from "@/components/navigation/useNavigation";

type Props = {
    path: string;
    label: string;
    children: React.ReactNode;
};

export default function MobileNavItemWithDropdown(props: Props) {
    const { activeParent, setActiveParent } = useNavigation();
    const { path, label } = props;
    const isActive = activeParent === path;

    return (
        <>
            <button
                className={"text-left"}
                onClick={() => setActiveParent(isActive ? null : path)}
            >
                <div
                    className={`flex cursor-pointer items-center justify-between rounded-lg hover:bg-white/15 ${isActive ? "bg-white/15" : ""}`}
                >
                    <p className={"p-2 pl-4 text-sm text-gray-25"}>{label}</p>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className={`mr-4 size-3 ${isActive ? "rotate-180" : ""}`}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                    </svg>
                </div>
            </button>

            {isActive && (
                <ul className={"ml-8 mt-1 flex flex-col gap-1 rounded-lg"}>
                    {props.children}
                </ul>
            )}
        </>
    );

}