import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import useFetcher from "repositories/base/useFetcher";
import { AccountContext } from "context";
import { useToast, useTrans } from "components";
import PageContent from "components/containers/PageContent";
import ContentCard from "components/containers/ContentCard";
import DisplayElement from "components/DisplayElement";
import FormGroup from "components/forms/FormGroup";
import useGiftcardProgram from "@/routing/store/giftcards/useGiftcardProgram";
import DisplayBooleanElement from "components/forms/DisplayFormElements/DisplayBooleanElement";
import TextInput from "components/forms/Text/TextInput";
import ButtonComponent, { ButtonStyle } from "components/buttons/ButtonComponent";
import Form from "components/forms/Form";
import useHttp from "core/http/useHttp";

type Props = {
    onUpdate: () => void;
};

export default function Information(props: Props) {
    const { onUpdate } = props;
    const { t } = useTrans();
    const { account } = useContext(AccountContext);
    const { reportId } = useParams<{ reportId: ID }>();
    const { selectedProgramId } = useGiftcardProgram();
    const { successToast, errorToast } = useToast();
    const [updatePending, setUpdatePending] = useState(false);
    const http = useHttp();
    const [name, setName] = useState("");
    const apiUrl = `/accounts/${account.id}/giftcards/programs/${selectedProgramId}/settling/reports/${reportId}/information`;
    const [report, setReport] = useState<Dict>({});
    const { mutate } = useFetcher({
        url: apiUrl,
        dataSetter: data => {
            setReport(data);
            setName(data.name);
        },
    });

    const update = () => {
        setUpdatePending(true);
        http.put(apiUrl, {
            name
        })
            .then(() => {
                onUpdate();
                mutate();
                successToast();
            })
            .catch(errorToast)
            .finally(() => setUpdatePending(false));
    };

    return (
        <PageContent
            title={t("information")}
        >
            <ContentCard>
                <FormGroup>
                    <DisplayBooleanElement
                        value={report.status}
                        label={t("status")}
                        underlying_value={report.status === "OPEN"}
                    />
                    <div className={"flex gap-2"}>
                        <DisplayElement type={"text"} value={report.from} label={t("from")}/>
                        <DisplayElement type={"text"} value={report.until} label={t("until")}/>
                    </div>

                    <Form onSubmit={update}>
                        <TextInput
                            name="name"
                            required={true}
                            value={name}
                            label={t("name")}
                            placeholder={t('name')}
                            onChange={e => setName(e.target.value)}
                        />

                        <ButtonComponent
                            className={"mt-4"}
                            button_style={ButtonStyle.Primary}
                            label={t('submit')}
                            pending={updatePending}
                            type={"submit"}
                        />
                    </Form>
                </FormGroup>
            </ContentCard>
        </PageContent>
    );
}

