import React, { ReactNode } from "react";
import BaseModal from "@/components/modals/components/BaseModal";
import { ModalHeader } from "@/components/modals/components/ModalHeader";
import { ButtonProps } from "components/buttons/ButtonComponent";
import { useTrans } from "components";
import { ModalProps } from "components/hooks/useModal";
import ModalWidth from "@/components/modals/components/ModalWidth";
import ModalBody from "@/components/modals/components/ModalBody";
import ModalButtonsWrapper from "@/components/modals/components/ModalButtonsWrapper";
import { Button } from "@/components/v2/Actions/button";

type Props = {
    modal: ModalProps
    title?: string;
    primaryButton?: ButtonProps;
    secondaryButton?: ButtonProps;
    onClose?: any;
    depth?: any;
    onOutsideClick?: any;
    children?: ReactNode;
    classNames?: string;
};

export default function Modal(props: Props) {
    const { t } = useTrans();
    const { title, secondaryButton, primaryButton, modal, onClose } = props;

    return (
        <BaseModal {...props}>
            <ModalWidth supports={{
                sm: true,
                md: true,
                lg: true,
            }}
            >
                <ModalHeader title={title} onClose={onClose ?? modal.close}/>

                <ModalBody>
                    {props.children}
                </ModalBody>

                <ModalButtonsWrapper>

                    <Button
                        variant={"default"}
                        type="button"
                        hidden={secondaryButton?.hidden}
                        onClick={() => {
                            secondaryButton?.onClick && secondaryButton?.onClick();
                            onClose && onClose();
                            modal.close && modal.close();
                        }}
                        isLoading={secondaryButton?.pending}
                        disabled={secondaryButton?.disabled ?? false}
                    >
                        {secondaryButton?.label ?? t('cancel')}
                    </Button>

                    {primaryButton && (
                        <Button
                            variant={"primary"}
                            type="button"
                            hidden={primaryButton.hidden}
                            onClick={primaryButton.onClick}
                            isLoading={primaryButton.pending}
                            disabled={primaryButton?.disabled ?? false}
                        >
                            {primaryButton?.label ?? t('create')}
                        </Button>
                    )}
                </ModalButtonsWrapper>
            </ModalWidth>
        </BaseModal>
    );
}