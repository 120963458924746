import React, { useEffect, useMemo, useRef } from "react";
import { HeaderItemType, Table } from "components/table_v2/types";
import Sortable, { SortableEvent } from "sortablejs";
import DraggableRow from "components/table_v2/components/row/DraggableRow";
import BarebonesTable from "components/table_v2/components/BarebonesTable";
import DraggableHeader from "components/table_v2/components/header/DraggableHeader";

type Props = {
    table: Table;
    pending: boolean;
    onDragEnd: (e: SortableEvent, itemId: any) => void;
    className?: string;
    emptyStateAction?: any;
    onFilterChange?: any;
    onRowClick?: (id: string) => void;
    isPaginated?: boolean;
    height?: "barebones" | "searchbar" | "tabs"
};

export default function SimpleBackendTableDraggable(props: Props) {
    const {
        table,
        pending,
        onDragEnd,
        className,
        onFilterChange,
        isPaginated,
        emptyStateAction
    } = props;
    const dragList = useRef(null);
    const height = props.height ? props.height : "barebones";

    useEffect(() => {
        if (dragList.current && table.rows.length) {
            Sortable.create(dragList.current, {
                handle: '.draggable',
                animation: 250,
                onEnd: (event: SortableEvent) => {
                    if (event.newDraggableIndex != event.oldDraggableIndex) {
                        onDragEnd(event, event.item.id);
                    }
                }
            });
        }
    }, [table]);

    const onHeaderClick = (item: HeaderItemType) => {
        onFilterChange("order_by", item.sortable.key);
        onFilterChange("direction", item.sortable.direction == "ASC" ? "DESC" : "ASC");
    };

    const header = useMemo(() => {
        return (
            <DraggableHeader
                items={table.header.items}
                onHeaderClick={onHeaderClick}
            />
        );
    }, [table.header.items]);

    const rows = useMemo(() => {
        return table.rows.map(row =>
            <DraggableRow
                key={row.id}
                row={row}
                onRowClick={props.onRowClick ? props.onRowClick : row.on_row_click}
                type={table.row_type}
            />
        );
    }, [table.rows]);

    return (
        <BarebonesTable
            table={table}
            rows={rows}
            header={header}
            pending={pending}
            className={className}
            isPaginated={isPaginated}
            dragListRef={dragList}
            height={height}
            emptyStateAction={emptyStateAction}
        />
    );
}
