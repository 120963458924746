import React from "react";
import Text from "components/typography/Text";
import DisplayFormElementContainer from "components/forms/DisplayFormElements/DisplayFormElementContainer";

type DisplayFormElementProps = {
    value: string;
    label?: string;
    is_underlined?: boolean;
};

export default function DisplayFormElement(props: DisplayFormElementProps) {
    const is_underlined = props.is_underlined ?? true;

    return (
        <DisplayFormElementContainer label={props.label} is_underlined={is_underlined}>
            <Text weight={"extralight"} color={"text-gray-500 dark:text-white"}>{props.value}</Text>
        </DisplayFormElementContainer>
    );
}