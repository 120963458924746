import React, { CSSProperties } from 'react';

type Props = {
    className?: string;
    onSubmit: any;
    children: any;
    hidden?: boolean;
    onChange?: any;
    style?: CSSProperties;
};

const Form = React.forwardRef((props: Props, ref) => {
    return (
        <form
            hidden={props.hidden}
            ref={ref as React.RefObject<HTMLFormElement>}
            className={props.className && props.className}
            style={props.style}
            onSubmit={e => {
                e.preventDefault();
                props.onSubmit();
            }}
        >
            {props.children}
        </form>
    );
});

Form.displayName = 'Form';

export default Form;
