import React, { createContext, useState } from "react";

export enum UsageDataType {
    NUMBER_OF_USERS,
    NUMBER_OF_FORMS,
    NUMBER_OF_UNITS,
    NUMBER_OF_EMAILS,
    NUMBER_OF_WIDGETS,
    NUMBER_OF_REWARDS,
    NUMBER_OF_CONTACTS,
    NUMBER_OF_GIFTCARDS,
    NUMBER_OF_SHOPS,
    NUMBER_OF_AUTOMATIONS,
    NUMBER_OF_LOYALTY_RULES,
    NUMBER_OF_CONTACT_LISTS,
    NUMBER_OF_LOYALTY_TOKENS,
    NUMBER_OF_EMAIL_RECIPIENTS,
    NUMBER_OF_CONTACT_ATTRIBUTES,
}

export type UsageData = {
    type: UsageDataType;
    limit: number;
}

type ContextProps = {
    usageData: UsageData;
    setUsageData: any;
};

export const UsageContext = createContext<Partial<ContextProps>>({
    usageData: null,
});

export const UsagesProvider = ({ children }: any) => {
    const [usageData, setUsageData] = useState<UsageData>(null);

    return (
        <UsageContext.Provider value={{ usageData: usageData, setUsageData: setUsageData }}>
            {children}
        </UsageContext.Provider>
    );
};