import { useTrans } from "components";
import { CommandPaletteOption, CommandPaletteOptionType } from "../useCommandPaletteOptions";
import { openInNewTab } from "core";
import { urls } from "@/core/services/urls/urls";

export default function useActionOptions(): CommandPaletteOption[] {
    const { t } = useTrans();

    const createQrCode = {
        visible: true,
        group: t("action"),
        label: t("createQrCode"),
        description: "Qr code",
        type: CommandPaletteOptionType.ACTION,
        value: "create-qr-code",
        tags: [
            "qr-code",
        ],
        handle: (text: string) => {
            openInNewTab(`${urls.FORM_HOST}/qr?value=${text}`);
        },
        data: {
            placeholder: t("fillInValue"),
        }
    };

    return [
        createQrCode,
    ];
}