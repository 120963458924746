import React from "react";
import { CommandItem, } from "@/components/v2/SelectionAndInput/command";
import { Check } from "lucide-react";
import { PopoverClose } from "@radix-ui/react-popover";

export interface Option {
    value: string;
    label: string;
    disabled?: boolean;
    description?: string;
    icon?: React.ReactNode;
}

export interface Props<T> {
    value: string;
    onSelect: (selected: string) => void;
    selected: boolean;
    children: React.ReactNode;
}

export function SingleSelectOption<T>(props: Props<T>) {
    const { onSelect, value, selected } = props;

    return (
        <CommandItem
            value={value}
            onSelect={value => onSelect(value)}
        >
            <PopoverClose className="flex size-full">
                <div className="flex w-full items-center justify-between">
                    {props.children}

                    {selected && <Check size={14}/>}
                </div>
            </PopoverClose>
        </CommandItem>
    );
}