import React, { useState } from "react";
import RadioInput, { RadioInputProps } from "./RadioInput";

type Props = {
    id: string;
    options: RadioInputProps[];
    direction: "horizontal" | "vertical";
    onBlur: any;
};

export default function RadioElement(props: Props) {
    const { id, options } = props;
    const [selectedOption, setSelectedOption] = useState(options.find((o) => o.is_selected)?.id ?? options[0]?.id);

    const onBlur = (value: any) => props.onBlur(id, value);

    return (
        <div
            className={`flex min-h-[3.4rem] transition-all ease-in-out ${props.direction == "horizontal" ? 'flex-row' : 'flex-col' } my-0.5 w-full gap-4`}
        >
            {props.options.map((o: any) => {
                return (
                    <RadioInput
                        key={o.id + id}
                        id={o.id + id}
                        option={o}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        onBlur={onBlur}
                    />
                );
            })}
        </div>
    );
}