import React, { ForwardedRef } from 'react';
import cn from "classnames";
import Text from "components/typography/Text";
import useRouting from '../hooks/useRouting';
import { IconType } from '../icons/Icon';
import { openInNewTab, openInSameTab } from "core";
import { Loader } from "components";

export enum ButtonStyle {
    Primary = 'primary',
    Secondary = 'secondary',
    Danger = 'danger',
    Warning = 'warning',
    Ghost = 'ghost'
}

export type buttonSizes = "xs" | "small" | "normal" | "large" | "wide";

export type ButtonAction = {
    type: string;
    value: any;
};

export type ButtonProps = {
    id?: any;
    type?: 'submit' | 'reset' | 'button' | undefined;
    className?: string;
    button_style?: ButtonStyle;
    label?: string;
    icon?: IconType;
    onClick?: any;
    action?: ButtonAction;
    pending?: boolean;
    hidden?: boolean;
    disabled?: boolean;
    size?: buttonSizes
};

/**
 * Renders a Button in one of the predefined styles. See `ButtonStyle`.
 * If you need a Button with custom colors, use `CustomButtonComponent` instead.
 */
const ButtonComponent = React.forwardRef((props: ButtonProps, ref: ForwardedRef<any>) => {
    const { push, go } = useRouting();
    const {
        className,
        button_style = ButtonStyle.Primary,
        disabled = false,
        pending = false,
        size = 'normal',
        type = 'button',
        hidden = false,
    } = props;

    const backgroundCn = cn(
        { "bg-primary-600 dark:bg-primary-600 dark:hover:bg-primary-500 hover:bg-primary-700 active:bg-primary-800 focus:outline-orange-400": button_style === ButtonStyle.Primary },
        { "bg-white dark:bg-slate-800 hover:bg-primary-25 dark:hover:bg-slate-700 active:bg-primary-50 focus:outline-blue-200": button_style == ButtonStyle.Secondary },
        { "bg-transparent hover:bg-gray-600": button_style === ButtonStyle.Ghost },
        { "bg-error-600 hover:bg-error-700 active:bg-error-800": button_style === ButtonStyle.Danger },
        { "dark:bg-slate-500 bg-white hover:bg-[#fafafa]": button_style === ButtonStyle.Warning },
        "focus:outline-1 transition-all"
    );

    const textColorCn = cn(
        { "text-white dark:text-gray-300 dark:hover:text-white": button_style === ButtonStyle.Primary },
        { "text-gray-700 dark:text-gray-200": button_style === ButtonStyle.Secondary },
        { "text-white": button_style === ButtonStyle.Ghost },
        { "text-white": button_style === ButtonStyle.Danger },
        { "text-error-600 dark:text-error-200 dark:text-gray-200": button_style === ButtonStyle.Warning },
    );

    const borderCn = cn(
        { "hover:border-gray-900": button_style === ButtonStyle.Primary },
        { "border": button_style === ButtonStyle.Secondary },
        { "border border-gray-600 active:border-white": button_style === ButtonStyle.Ghost },
        { "border active:border-white": button_style === ButtonStyle.Warning },
        { "": button_style === ButtonStyle.Danger },
    );

    const buttonCn = cn(
        backgroundCn,
        borderCn,
    );

    const onClick = () => {
        if (disabled) {
            return;
        }

        if (props.action) {
            switch (props.action.type) {
                case "internal_url":
                    push(props.action.value);
                    break;

                case "external_url":
                    openInNewTab(props.action.value);
                    break;

                case "internal_url_with_params":
                    push(props.action.value);
                    go(0);
                    break;

                case "external_url_same_tab":
                    openInSameTab(props.action.value);
                    break;

            }
        }

        props.onClick && props.onClick({
            ...props,
            name: props.id,
            value: props.id,
        });
    };

    return (
        <button
            type={type}
            className={`inline-block rounded-lg px-3 shadow-sm ${disabled || pending ? 'cursor-not-allowed border border-gray-900/10 bg-gray-50' : buttonCn} ${className && className} ${size == "xs" ? "h-[28px]" : size == "small" ? "h-[35px]" : "h-10"}`}
            ref={ref}
            hidden={hidden}
            disabled={disabled || pending}
            onClick={onClick}
        >
            <div className={`relative flex items-center justify-center gap-2`}>
                {/*{props.icon &&*/}
                {/*    <Icon*/}
                {/*        type={props.icon}*/}
                {/*        attr={'div'}*/}
                {/*        className={`size-4 ${disabled ? 'bg-gray-400' : iconColorCn} ${pending ? 'opacity-0' : 'opacity-100'}`}*/}
                {/*    />*/}
                {/*}*/}

                {props.label &&
                    <Text
                        className={`${disabled ? 'text-gray-400' : textColorCn} whitespace-nowrap ${pending ? 'opacity-0' : 'opacity-100'}`}
                        render_as='p'
                        color={textColorCn}
                        size={(size == "small" || size == "xs") ? 'xs' : 'sm'}
                        weight={'normal'}
                    >
                        {props.label}
                    </Text>
                }

                <Loader visible={pending} className={"absolute size-5"}/>
            </div>
        </button>
    );
});


ButtonComponent.displayName = "ButtonComponent";

export default ButtonComponent;